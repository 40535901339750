import { Pipe, PipeTransform } from '@angular/core';

import { MEMBERSHIP_LISTS_DATA } from 'minga/libraries/shared/membership_list/constants';

import { MembershipListTableData } from '../types';

@Pipe({ name: 'membershipListDescription' })
export class MembershipListDescriptionPipe implements PipeTransform {
  transform({ listType }: MembershipListTableData): string {
    return MEMBERSHIP_LISTS_DATA[listType]?.description || '';
  }
}
