import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { MingaPermission } from 'minga/domain/permissions';
import { PermissionsService } from 'src/app/permissions';

import { ToolType } from '../tools.types';

const getType = (
  canAccessTeacher,
  canAccessStudent,
  isParent,
  canAccessAdmin,
): ToolType | null => {
  if (canAccessAdmin) return 'admin';
  if (canAccessTeacher) return 'teacher';
  if (canAccessStudent) return 'student';
  if (isParent) return 'parent';
  return null;
};

@Injectable({ providedIn: 'root' })
export class ToolsService implements OnDestroy {
  private _destroyed$ = new ReplaySubject<void>(1);
  private _toolType = new BehaviorSubject<ToolType>(null);

  public toolType$ = this._toolType.asObservable();

  constructor(private _permissions: PermissionsService) {}

  public setUsersToolType() {
    combineLatest([
      this._permissions.observePermission(MingaPermission.STUDENT_TOOLS),
      this._permissions.observePermission(MingaPermission.TEACHER_TOOLS),
      this._permissions.observePermission(MingaPermission.IS_PARENT),
      this._permissions.observePermission(MingaPermission.ADMIN_TOOLS),
    ])
      .pipe(
        takeUntil(this._destroyed$),
        map(
          ([canAccessStudent, canAccessTeacher, isParent, canAccessAdmin]) => {
            return getType(
              canAccessTeacher,
              canAccessStudent,
              isParent,
              canAccessAdmin,
            );
          },
        ),
      )
      .subscribe(toolType => {
        this._toolType.next(toolType);
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
