<div
  fxLayout="row"
  fxLayoutAlign.gt-sm="end end"
  [style.marginBottom]="'16px'">
  <mg-btn
    fxGrow="nogrow"
    variant="filled"
    [responsive]="true"
    (pressed)="teamsService.openModal()">
    {{ MESSAGES.BUTTON_LABEL_NEW_TEAM }}
  </mg-btn>
</div>
<ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>

<!-- Main template content -->
<ng-template #mainContentTemplate>
  <mg-membership-list-table
    placeholderIcon="leaderboard"
    [types]="lists"
    [canChangeName]="true"
    [canChangeImage]="true"
    [canChangeStatus]="true"
    [canChangeColor]="true"
    [refreshList]="teamsService.refreshLists.asObservable()">
  </mg-membership-list-table>
</ng-template>
