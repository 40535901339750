import { Pipe, PipeTransform } from '@angular/core';

import { ColumnInfo } from 'minga/libraries/shared/reports_columns';

@Pipe({ name: 'reportTableCellStyles' })
export class ReportTableCellStylesPipe implements PipeTransform {
  transform(column: ColumnInfo, item: any) {
    const style: Record<string, string | number> = {};

    if (typeof column?.customWidth === 'number') {
      style.flex = `0 ${column.customWidth}px`;
    }

    if (item)
      style.color = column.accessorFn
        ? column?.accessorFn(item, true)
        : item?.color || undefined;

    return style;
  }
}
