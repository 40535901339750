import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { EMPTY, interval, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import * as people_collection_pb from 'minga/proto/people_collection/people_collection_pb';
import { PeopleCollection } from 'minga/proto/people_collection/people_collection_ng_grpc_pb';
import { RootService } from 'src/app/minimal/services/RootService';

import { KioskService } from '@modules/kiosk/services';

import {
  CloseTimeoutDuration,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MediaService } from '@shared/services/media';

import { KioskPerson, KioskType } from '../../../types';

@Component({
  selector: 'mg-kiosk-type-assigner',
  templateUrl: './kiosk-type-assigner.component.html',
  styleUrls: ['./kiosk-type-assigner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskTypeAssignerComponent implements AfterViewInit, OnDestroy {
  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Inputs

  @Input() public types: KioskType[] = [];
  @Input() public typeIcon: string;
  @Input() public cardTitle = 'Kiosk';
  @Input() public title = 'Scan or type your ID number';
  @Input() public enterLabel = 'Enter';
  @Input() public disableSubmit = false;

  // Outputs

  @Output() public response = new EventEmitter<KioskPerson>();

  // Events

  public focusSubject = new Subject();

  // Derived state

  public readonly inputField = this._fb.control('');

  public readonly canSubmit$ = this.inputField.valueChanges.pipe(
    takeUntil(this._destroyedSubject),
    startWith(''),
    map(v => v?.length > 2),
  );

  // Component constructor

  constructor(
    public media: MediaService,
    private _kiosk: KioskService,
    private _fb: FormBuilder,
    private _systemAlertModal: SystemAlertModalService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _peopleCollectionService: PeopleCollection,
    private _root: RootService,
  ) {}

  // Lifecycle

  ngAfterViewInit(): void {
    // initial focus
    setTimeout(() => {
      this.focusSubject.next();
    }, 0);

    // focus logic for when logout modal is open
    this._kiosk.alertModalOpen$
      .pipe(
        switchMap(open => (open ? EMPTY : interval(1000))),
        takeUntil(this._destroyedSubject),
      )
      .subscribe(() => this.focusSubject.next());
  }

  ngOnDestroy() {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this.focusSubject.complete();
  }

  // Public methods

  public async submitForm() {
    const value = this._parseBarcodeScanData(
      this.inputField?.value?.trim() ?? '',
    );

    /**
     * Clear the input field quickly so ids don't append. This helps prevent
     * valid ids getting appended to invalid ids from the previous checkin
     * attempt, and causing compounding check-in errors.
     *
     * Resetting early also fixes the enter/return key constantly re-submitting
     * an invalid id number.
     */
    this.inputField.reset();

    if (value.length <= 2) return;
    try {
      const person = await this._root.addLoadingPromise(
        this._fetchPersonByStudentId(value),
      );

      if (person) {
        this.response.emit(person);
      } else {
        this._kiosk.alertModalOpenSubject.next(true);
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.ERROR,
          heading: 'ID number not valid',
          message: 'Please try again',
          closeTimeout: CloseTimeoutDuration.LONG,
        });

        // must wait until modal is closed before setting focus to input field again
        await modalRef.afterClosed().toPromise();
        this._kiosk.alertModalOpenSubject.next(false);
        this.focusSubject.next();
      }
    } catch (error) {
      this._systemAlertSnackBar.error(
        'Error: ' + error?.message ?? 'Unknown error',
      );
      this.focusSubject.next();
    }
  }

  private async _fetchPersonByStudentId(barcodeText: string) {
    const request = new people_collection_pb.GetPersonByStudentIdRequest();
    request.setStudentId(barcodeText);
    const response = await this._peopleCollectionService.getPersonByStudentId(
      request,
    );
    if (!response.hasPerson()) return null;
    const person = response.getPerson().toObject();
    return {
      personHash: person.personHash,
      displayName: person.displayName,
      profilePicture: person.profileImageUrl,
      badge: person.badgeRoleName,
      email: person.email,
      studentId: person.studentId,
      grade: person.grade,
    } as KioskPerson;
  }

  private _parseBarcodeScanData(data: string) {
    if (!data) return '';
    if (data.startsWith('{') && data.endsWith('}')) {
      try {
        const parsed = JSON.parse(data);
        if (
          parsed &&
          parsed?.type === 'ID' &&
          parsed?.data &&
          parsed?.data?.id
        ) {
          return String(parsed.data.id);
        }
      } catch (error) {
        // if it fails to parse, that means it is a normal string. Carry on
        return data;
      }
    }
    return data;
  }
}
