import { Injectable } from '@angular/core';

import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { EMPTY, Observable, from } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { MingaPermission } from 'minga/domain/permissions';
import {
  CreateUserListPayload,
  UpdateUserListPayload,
} from 'minga/domain/userList';
import { UserList } from 'minga/domain/userList';
import { PermissionsService } from 'src/app/permissions';

import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { UserListFilterService } from '@shared/components/user-list-filter/services/user-list-filter.service';
import { UserListService } from '@shared/services/user-list';

import { PEOPLE_USER_LIST_PAGE_DEFAULT_STATE } from '../constants';
import {
  PeopleUserListPageLoadingStates,
  PeopleUserListPageState,
} from '../types';

@Injectable()
export class PeopleUserListsPageStore extends ComponentStore<PeopleUserListPageState> {
  /** Loading states */
  public readonly isLoading$ = this.select(({ loading }) => loading);

  /** Filters */
  public readonly filters$ = this.select(({ filters }) => filters);

  /** All lists */
  public readonly lists$ = this.select(({ lists }) =>
    Array.from(lists, ([, v]) => v),
  );

  /** Total User Lists */
  public readonly totalLists$ = this.select(({ totalLists }) => totalLists);

  /** Active list */
  public readonly activeListId$ = this.select(
    ({ activeListId }) => activeListId,
  );

  public readonly activeList$ = this.select(
    this.state$,
    this.activeListId$,
    ({ lists }, activeListId) =>
      (activeListId && lists?.get(activeListId)) || null,
  );

  /** New list */
  public readonly isCreatingNewList$ = this.select(
    ({ activeListId }) => activeListId === null,
  );

  /** Service Constructor */
  constructor(
    public permissions: PermissionsService,
    public snackBar: SystemAlertSnackBarService,
    public alertModal: SystemAlertModalService,
    private _ulService: UserListService,
    private _ulFilterService: UserListFilterService,
  ) {
    super({
      ...PEOPLE_USER_LIST_PAGE_DEFAULT_STATE,
      filters: {
        ...PEOPLE_USER_LIST_PAGE_DEFAULT_STATE.filters,
        showMine: !permissions.hasPermission(MingaPermission.USER_LIST_ADMIN),
      },
    });
  }

  public readonly setFilter = this.updater(
    (
      state,
      [filterName, value]: [keyof PeopleUserListPageState['filters'], any],
    ) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterName]: value,
        },
      };
    },
  );

  public readonly setLoadingState = this.updater(
    (state, [stateName, value]: [PeopleUserListPageLoadingStates, any]) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [stateName]: value,
        },
      };
    },
  );

  public readonly getList = this.effect((listId$: Observable<number>) =>
    listId$.pipe(
      tap(() => {
        this.setLoadingState(['activeList', true]);
      }),
      switchMap(listId =>
        from(this._ulService.fetch(listId)).pipe(
          tapResponse(
            list => {
              this.setState(state => {
                const lists = new Map(state.lists);
                lists.set(list.id, list);
                return { ...state, lists };
              });
              this.setLoadingState(['activeList', false]);
            },
            () => {
              this.setLoadingState(['activeList', false]);
            },
          ),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  public readonly getAllLists = this.effect(
    (
      $: Observable<{
        pageIndex: number;
        pageSize: number;
        showInactive: boolean;
      }>,
    ) => {
      return $.pipe(
        tap(() => {
          this.setLoadingState(['allLists', true]);
        }),
        switchMap(({ pageIndex, pageSize, showInactive }) =>
          from(
            this._ulService.fetchAll(showInactive, pageSize, pageIndex),
          ).pipe(
            tapResponse(
              ({ lists, totalLists }) => {
                this.setState(state => {
                  return {
                    ...state,
                    lists: new Map(lists.map(list => [list.id, list])),
                    totalLists,
                  };
                });
                this.setLoadingState(['allLists', false]);
              },
              () => {
                this.setLoadingState(['allLists', false]);
              },
            ),
            catchError(() => EMPTY),
          ),
        ),
      );
    },
  );

  public readonly createList = this.effect(
    (
      data$: Observable<{
        payload: CreateUserListPayload;
        afterSuccess: (userList: UserList) => void;
      }>,
    ) => {
      return data$.pipe(
        tap(() => {
          this.setLoadingState(['create', true]);
        }),
        switchMap(({ payload, afterSuccess }) => {
          return from(this._ulService.create(payload)).pipe(
            tapResponse(
              newList => {
                this.setState(({ lists, ...state }) => {
                  lists.set(newList.id, newList);
                  return { ...state, lists };
                });
                this.setLoadingState(['create', false]);
                afterSuccess(newList);
                this.snackBar.success('User list created successfully');
              },
              () => {
                this.setLoadingState(['create', false]);
              },
            ),
            catchError(() => EMPTY),
          );
        }),
      );
    },
  );

  public readonly setActiveList = this.effect(
    (listId$: Observable<number | null>) => {
      return listId$.pipe(
        switchMap(async listId => {
          this.setState(state => {
            return { ...state, activeListId: listId };
          });
          if (listId) this.getList(listId);
        }),
      );
    },
  );

  public readonly updateList = this.effect(
    (
      data$: Observable<{
        changes: UpdateUserListPayload;
        afterSuccess: (userList: UserList) => void;
      }>,
    ) => {
      return data$.pipe(
        withLatestFrom(this.activeList$),
        tap(() => {
          this.setLoadingState(['update', true]);
        }),
        switchMap(([{ changes, afterSuccess }, original]) => {
          return from(
            this._ulService.update({
              ...original,
              ...changes,
            }),
          ).pipe(
            tapResponse(
              updatedList => {
                this.setState(({ lists, ...state }) => {
                  lists.set(updatedList.id, updatedList);
                  return { ...state, lists };
                });
                this.setLoadingState(['update', false]);
                afterSuccess(updatedList);
                this.snackBar.success('User list updated successfully');
              },
              () => {
                this.setLoadingState(['update', false]);
              },
            ),
            catchError(() => EMPTY),
          );
        }),
      );
    },
  );

  public readonly deleteList = this.effect(
    (options$: Observable<() => void>) => {
      return options$.pipe(
        withLatestFrom(this.activeListId$),
        switchMap(async ([closeFn, listId]) => {
          const modalRef = await this.alertModal.open({
            modalType: SystemAlertModalType.WARNING,
            heading: 'Are you sure you want to delete this list?',
            closeBtn: 'Cancel',
            confirmActionBtn: 'Delete',
          });
          const { type } = await modalRef.afterClosed().toPromise();
          if (type === SystemAlertCloseEvents.CONFIRM) {
            this.setLoadingState(['delete', true]);
            await this._ulService.delete(listId);
            this.setState(state => {
              const lists = new Map(state.lists);
              lists.delete(listId);
              return { ...state, lists };
            });
            this.setLoadingState(['delete', false]);
            closeFn();
            this.snackBar.default('User list deleted successfully');
          }
        }),
      );
    },
  );

  public readonly toggleListActiveStatus = this.effect(
    (listId$: Observable<number>) => {
      return listId$.pipe(
        switchMap(listId => {
          const list = this.get(({ lists }) => lists.get(listId));
          const payload = {
            ...list,
            active: !list.active,
          } as UpdateUserListPayload;
          return from(this._ulService.update(payload)).pipe(
            tapResponse(
              updatedList => {
                this.setState(state => {
                  const lists = new Map(state.lists);
                  lists.set(updatedList.id, updatedList);
                  return { ...state, lists };
                });
                this.snackBar.success(
                  `User list status set to ${
                    updatedList.active ? 'active' : 'inactive'
                  }`,
                );
              },
              () => {},
            ),
            catchError(() => EMPTY),
          );
        }),
      );
    },
  );
}
