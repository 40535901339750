<!-- Header -->
<header
  class="main-layout-header"
  fxLayout="row"
  fxLayoutAlign="space-between center">
  <!-- Logo -->
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px">
    <img
      (click)="devReset()"
      src="/assets/logo/kiosk-logo.svg"
      alt="Kiosk" />
  </div>
  <!-- Utilities -->
  <div class="main-layout-header-utils">
    <div
      fxLayout="row"
      fxLayoutGap="8px">
      <ng-container
        *ngIf="(isKioskMode$ | async) === false; else contextMenuDropdown">
        <mg-btn
          variant="icon-secondary"
          size="large"
          iconSet="minga"
          icon="mg-logout"
          (pressed)="handleKioskAction(ACTIONS.LOGOUT)">
        </mg-btn>
      </ng-container>

      <ng-template #contextMenuDropdown>
        <mg-btn
          variant="icon-secondary"
          size="large"
          iconSet="minga"
          icon="mg-more-vert"
          [matMenuTriggerFor]="dropdownMenu">
        </mg-btn>
        <mat-menu
          #dropdownMenu="matMenu"
          xPosition="before">
          <button
            mat-menu-item
            (click)="handleKioskAction(ACTIONS.LOGOUT)">
            <mg-text
              variant="body-md"
              color="surface">
              {{ MSG.LOGOUT }}
            </mg-text>
          </button>
          <button
            mat-menu-item
            (click)="handleKioskAction(ACTIONS.EDIT)">
            <mg-text
              variant="body-md"
              color="surface">
              {{ MSG.EDIT }}
            </mg-text>
          </button>
          <button
            mat-menu-item
            (click)="handleKioskAction(ACTIONS.RESET)">
            <mg-text
              variant="body-md"
              color="surface">
              {{ MSG.RESET }}
            </mg-text>
          </button>
        </mat-menu>
      </ng-template>
    </div>
  </div>
</header>

<!-- Main content -->
<div class="main-layout-content">
  <router-outlet *ngIf="isKioskEnabled$ | async; else disabled"></router-outlet>

  <ng-template #disabled>
    <section>
      <mg-text
        variant="header-xl"
        textAlign="center"
        spacing="1"
        color="surface-alt">
        {{ MSG.CATEGORY_SELECTION_TITLE }}
      </mg-text>
      <mg-text
        variant="header-xxl-secondary"
        textAlign="center"
        spacing="8">
        {{ MSG.CATEGORY_SELECTION_SUBTITLE }}
      </mg-text>
      <mg-empty-state
        class="kiosk-disabled"
        [title]="MSG.NO_TYPES_TITLE"
        [subtitle]="MSG.NO_TYPES_SUB_TITLE">
      </mg-empty-state>
    </section>
  </ng-template>
</div>

<!-- Footer -->
<footer
  *ngIf="footerPortal as portal"
  class="main-layout-footer">
  <ng-template [cdkPortalOutlet]="footerPortal"></ng-template>
</footer>

<ng-template #logOutTemplate>
  <div
    class="logout-form"
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="12px">
    <mg-form-text-input
      [label]="MSG.EMAIL_LABEL"
      [control]="form.get('email')">
    </mg-form-text-input>
    <mg-form-text-input
      [label]="MSG.PIN_LABEL"
      inputType="password"
      autoComplete="new-password"
      [control]="form.get('pin')">
    </mg-form-text-input>
    <mg-btn
      variant="text-secondary"
      (pressed)="sendEmail()">
      {{ MSG.SYSTEM_ALERT_MODAL_EMAIL_PIN }}
    </mg-btn>
  </div>
</ng-template>
