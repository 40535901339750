import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import { UserStorage } from 'minga/app/src/app/services/UserStorage';
import {
  MingaSettingsService,
  MingaStoreFacadeService,
} from 'minga/app/src/app/store/Minga/services';
import { mgResolveImageUrl } from 'minga/app/src/app/util/asset';
import {
  IMgStreamFilter,
  IMgStreamItem,
  toStreamFilterMessage,
} from 'minga/app/src/app/util/stream';
import { IHallPass } from 'minga/domain/hallPass';
import { MembershipListType } from 'minga/domain/membershipList';
import { MingaPermission } from 'minga/domain/permissions';
import { IPersonIdentityListItem } from 'minga/domain/studentID';
import { Grade, mingaSettingTypes } from 'minga/libraries/util';
import { BoolDelta, StringDelta } from 'minga/proto/common/delta_pb';
import { StreamItemMetadata } from 'minga/proto/common/stream_pb';
import { ImageInfo } from 'minga/proto/image/image_pb';
import { StudentIdManager } from 'minga/proto/student/student_id_ng_grpc_pb';
import {
  BulkIdImageUploadRequest,
  BulkIdImageUploadRow,
  DeleteStudentIdImageRequest,
  DeleteStudentIdRequest,
  ExportStudentIdListRequest,
  GetMingaStudentIdsRequest,
  PersonIdentityListItem,
  PersonIdentityListRequest,
  ReadStudentIdRequest,
  ReadStudentIdResponse,
  StudentIdStatusCountRequest,
  UpdateStudentIdRequest,
} from 'minga/proto/student/student_id_pb';
import { HallPassMapper } from 'minga/shared-grpc/hall_pass';
import { IPersonIdentityListProtoMapper } from 'minga/shared-grpc/student_id';
import { SentryService } from 'src/app/minimal/services/Sentry/Sentry.service';
import { PermissionsService } from 'src/app/permissions';

import { ViewIdCodeFormats } from '@shared/components/view-id';
import { ViewIdService } from '@shared/components/view-id/services/view-id.service';
import {
  StudentIdImageService,
  StudentIdPresetSize,
} from '@shared/services/student-id-image/StudentIdImage.service';

export interface IStudentId {
  personHash: string;
  image?: string;
  grade?: string;
  studentIdNumber?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  mingaAddress?: string;
  icons?: string[];
  fileName?: string;
  active?: boolean;
  tempPhoto?: boolean;
  printId?: boolean;
  roleType?: string;
  badgeIconUrl?: string;
  noPhoto?: boolean;
  noAccess?: string;
  isOffline?: boolean;
  idField1?: string;
  idField2?: string;
  hallPasses?: IHallPass[];
}

export interface IStudentIdImage {
  image: string;
  icons?: string[];
  noAccess?: string;
}

export interface IStudentIdStatusCounts {
  active: number;
  inactive: number;
  tempPhoto: number;
  noPhoto: number;
  printId: number;
}

export interface IUpdateStudentIdOptions {
  personHash: string;
  imageUrl?: string;
  active?: boolean;
  studentIdNumber?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  fileName?: string;
  tempPhoto?: boolean;
  printId?: boolean;
}

export interface IBulkStudentIdFileUpload {
  personHash: string;
  fileName: string;
}

export interface IStudentIdSettings {
  idSchoolName: string;
  idMingaStudentId: boolean;
  idLogoAssetPath: string;
  idBackgroundColor: string;
  idFontColor: string;
  idBarcodeType: ViewIdCodeFormats;
  idShowRole: boolean;
  idShowGrade: boolean;
  idStaffChangePicture: boolean;
  idUploadTempPhoto: boolean;
  idShowEnglishSuicideLifeline: boolean;
  idShowSpanishSuicideLifeline: boolean;
  idShowDomesticViolenceHotline: boolean;
  idShowCustomLifeline: string;
  idEnableGradeColors: boolean;
  idGradeColors: IStudentIdGradeColors;
  idHideNum: boolean;
  idEnableOffline: boolean;
  passStudentsEndPasses: boolean;
  idShowIdField1: boolean;
  idShowIdField2: boolean;
}

/* Colors for each grade available in a Minga */
export type IStudentIdGradeColors = {
  [key in Grade]?: string | string[];
};

export enum IStudentIdMembershipLists {
  STICKER = MembershipListType.STICKER,
  NO_ACCESS = MembershipListType.NO_ACCESS,
  ID_MANAGER = MembershipListType.ID_MANAGER,
}

export const STUDENT_ID_STORAGE_KEY = 'minga_student_id';

/**
 * Handle CRUD operations for student Ids. Also handles storing a student ID
 * for quicker retrieval.
 */
@Injectable({ providedIn: 'root' })
export class StudentIdService {
  studentId$ = new BehaviorSubject<IStudentId>({
    image: '',
    personHash: '',
    active: true,
    isOffline: true,
  });

  idSettings$: Observable<IStudentIdSettings>;
  isOfflineIdEnabled = false;
  isIdOffline = false;

  private _allowPrinting = new BehaviorSubject<{
    loaded: boolean;
    print: boolean;
  }>({
    loaded: false,
    print: false,
  });
  allowPrinting$ = this._allowPrinting.asObservable();

  private _useQrCodeSubject = new BehaviorSubject<boolean>(false);
  public useQrCode$ = this._useQrCodeSubject.asObservable();

  constructor(
    private studentIdManager: StudentIdManager,
    private localStorage: UserStorage,
    private authInfoService: AuthInfoService,
    private studentIdImageService: StudentIdImageService,
    private mingaStore: MingaStoreFacadeService,
    private router: Router,
    private permissions: PermissionsService,
    private _settingService: MingaSettingsService,
    private _sentry: SentryService,
    private _viewId: ViewIdService,
  ) {
    this.idSettings$ = this.mingaStore
      .getMingaAsObservable()
      .pipe(
        map(mingaInfo => {
          const studentIdSettings: IStudentIdSettings = {
            idSchoolName: '',
            idMingaStudentId: false,
            idLogoAssetPath: '',
            idBackgroundColor: '#1C2F59',
            idFontColor: '#FFFFFF',
            idBarcodeType: ViewIdCodeFormats.BAR_128,
            idShowRole: false,
            idShowGrade: false,
            idUploadTempPhoto: false,
            idStaffChangePicture: false,
            idShowEnglishSuicideLifeline: true,
            idShowSpanishSuicideLifeline: false,
            idShowDomesticViolenceHotline: true,
            idShowCustomLifeline: '',
            idEnableGradeColors: false,
            idGradeColors: {},
            idHideNum: false,
            idEnableOffline: false,
            passStudentsEndPasses: false,
            idShowIdField1: false,
            idShowIdField2: false,
          };
          if (mingaInfo?.settings) {
            for (const setting of mingaInfo.settings) {
              switch (setting.name) {
                case mingaSettingTypes.ID_SCHOOL_NAME: {
                  studentIdSettings.idSchoolName = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_BACKGROUND_COLOR: {
                  studentIdSettings.idBackgroundColor = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_FONT_COLOR: {
                  studentIdSettings.idFontColor = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_BARCODE_TYPE: {
                  studentIdSettings.idBarcodeType = setting.value;
                  if (
                    studentIdSettings.idBarcodeType === ViewIdCodeFormats.QR
                  ) {
                    this._useQrCodeSubject.next(true);
                  } else {
                    this._useQrCodeSubject.next(false);
                  }

                  break;
                }
                case mingaSettingTypes.ID_LOGO_ASSET_PATH: {
                  studentIdSettings.idLogoAssetPath = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_MINGA_STUDENT_ID: {
                  studentIdSettings.idMingaStudentId = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_SHOW_GRADE: {
                  studentIdSettings.idShowGrade = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_SHOW_ROLE: {
                  studentIdSettings.idShowRole = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_UPLOAD_TEMP_PHOTO: {
                  studentIdSettings.idUploadTempPhoto = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_STAFF_CHANGE_PICTURE: {
                  studentIdSettings.idStaffChangePicture = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_SHOW_ENGLISH_SUICIDE_LIFELINE: {
                  studentIdSettings.idShowEnglishSuicideLifeline =
                    setting.value;
                  break;
                }
                case mingaSettingTypes.ID_SHOW_SPANISH_SUICIDE_LIFELINE: {
                  studentIdSettings.idShowSpanishSuicideLifeline =
                    setting.value;
                  break;
                }
                case mingaSettingTypes.ID_SHOW_DOMESTIC_VIOLENCE_HOTLINE: {
                  studentIdSettings.idShowDomesticViolenceHotline =
                    setting.value;
                  break;
                }
                case mingaSettingTypes.ID_SHOW_CUSTOM_LIFELINE: {
                  studentIdSettings.idShowCustomLifeline = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_ENABLE_GRADE_COLORS: {
                  studentIdSettings.idEnableGradeColors = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_GRADE_COLORS: {
                  // used for determing id colors per grade
                  studentIdSettings.idGradeColors = setting.value;
                  break;
                }
                case mingaSettingTypes.HIDE_ID_NUM_ON_IDS: {
                  studentIdSettings.idHideNum = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_ENABLE_OFFLINE: {
                  studentIdSettings.idEnableOffline = setting.value;
                  break;
                }
                case mingaSettingTypes.PASS_STUDENTS_END_PASSES: {
                  studentIdSettings.passStudentsEndPasses = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_SHOW_ID_FIELD_1: {
                  studentIdSettings.idShowIdField1 = setting.value;
                  break;
                }
                case mingaSettingTypes.ID_SHOW_ID_FIELD_2: {
                  studentIdSettings.idShowIdField2 = setting.value;
                  break;
                }
              }
            }
            if (!studentIdSettings.idSchoolName) {
              studentIdSettings.idSchoolName = mingaInfo.name;
            }
            if (!studentIdSettings.idLogoAssetPath) {
              studentIdSettings.idLogoAssetPath = mingaInfo.logo || '';
            }
          }
          return studentIdSettings;
        }),
      )
      .pipe(shareReplay());
    this.idSettings$.subscribe(settings => {
      this.isOfflineIdEnabled = settings.idEnableOffline;
    });
  }

  async readLocalStudentId() {
    await this._loadLocalStudentId();
  }

  async updateImage(assetPath: string) {
    const request = new UpdateStudentIdRequest();
    request.setStudentIdUrl(assetPath);
    request.setPersonHash(this.authInfoService.authPersonHash);
    await this._deleteLocalStudentId();
    return await this.studentIdManager.updateStudentId(request);
  }

  setAllowPrint(value: boolean) {
    const currentVal = this._allowPrinting.getValue();
    currentVal.print = value;
    this._allowPrinting.next(currentVal);
  }

  setLoaded(value: boolean) {
    const currentVal = this._allowPrinting.getValue();
    currentVal.loaded = value;
    this._allowPrinting.next(currentVal);
  }

  resetAllowPrint() {
    this._allowPrinting.next({ loaded: false, print: false });
  }

  async updateStudentId(updateOptions: IUpdateStudentIdOptions) {
    const request = new UpdateStudentIdRequest();

    request.setPersonHash(updateOptions.personHash);

    if (updateOptions.imageUrl) {
      request.setAssetPath(updateOptions.imageUrl);
    }

    if ('active' in updateOptions) {
      const delta = new BoolDelta();
      delta.setNewBool(!!updateOptions.active);
      request.setActive(delta);
    }
    if ('tempPhoto' in updateOptions) {
      const delta = new BoolDelta();
      delta.setNewBool(!!updateOptions.tempPhoto);
      request.setTempPhoto(delta);
    }
    if ('printId' in updateOptions) {
      const delta = new BoolDelta();
      delta.setNewBool(!!updateOptions.printId);
      request.setPrintId(delta);
    }
    if ('studentIdNumber' in updateOptions) {
      const delta = new StringDelta();
      delta.setNewString(updateOptions.studentIdNumber);
      request.setStudentIdNumber(delta);
    }
    if (updateOptions.name) {
      const delta = new StringDelta();
      delta.setNewString(updateOptions.name);
      request.setDisplayName(delta);
    }
    if (updateOptions.firstName) {
      const delta = new StringDelta();
      delta.setNewString(updateOptions.firstName);
      request.setFirstName(delta);
    }
    if (updateOptions.lastName) {
      const delta = new StringDelta();
      delta.setNewString(updateOptions.lastName);
      request.setLastName(delta);
    }
    if (updateOptions.fileName) {
      const delta = new StringDelta();
      delta.setNewString(updateOptions.fileName);
      request.setFileName(delta);
    }

    // reset if this is the current user's student id
    if (updateOptions.personHash === this.authInfoService.authPersonHash) {
      await this._deleteLocalStudentId();
    }

    return await this.studentIdManager.updateStudentId(request);
  }

  async processStudentIdMessage(
    response: ReadStudentIdResponse,
    storeImages: boolean,
  ): Promise<IStudentId> {
    const mingaIconInfoMessage = response.getMemberIconsList();
    const noAccess = response.getNoAccess();
    const studentIdNumber = response.getStudentIdNumber();
    const grade = response.getGrade();
    const name = response.getName();
    const firstName = response.getFirstName();
    const lastName = response.getLastName();
    const mingaAddress = response.getMingaAddress();
    const icons: string[] = [];
    if (mingaIconInfoMessage) {
      let x = 0;
      for (const message of mingaIconInfoMessage) {
        const mingaIconInf: ImageInfo.AsObject = message.toObject();
        const icon = mgResolveImageUrl(mingaIconInf, [
          'bannerlibpreview',
          'preview',
          'cardbanner',
          'raw',
        ]);
        if (storeImages) this._storeCachedIcon(x, icon);
        icons.push(icon);
        x++;
      }
    }
    let noAccessSrc = '';
    if (noAccess) {
      const mingaIconInf: ImageInfo.AsObject = noAccess.toObject();
      const icon = mgResolveImageUrl(mingaIconInf, [
        'bannerlibpreview',
        'preview',
        'cardbanner',
        'raw',
      ]);

      if (storeImages) this._storeCachedNoAccessImage(icon);
      noAccessSrc = icon;
    }

    const fileName = response.getFileName();
    const imgString = fileName;
    const personHash = response.getPersonHash();
    const idField1 = response.getIdField1();
    const idField2 = response.getIdField2();
    const hallPassesList = response.getHallPassesList();
    const retrievedId: IStudentId = {
      personHash: personHash || '',
      image: imgString,
      icons,
      studentIdNumber,
      grade,
      name,
      firstName,
      lastName,
      mingaAddress,
      fileName,
      active: !!response.getActive(),
      tempPhoto: !!response.getTempPhoto(),
      printId: !!response.getPrintId(),
      roleType: response.getRoleType(),
      badgeIconUrl: response.getBadgeIconUrl(),
      noAccess: noAccessSrc,
      idField1,
      idField2,
      hallPasses: hallPassesList?.length
        ? hallPassesList.map(HallPassMapper.fromProto)
        : undefined,
    };

    return retrievedId;
  }

  async processStudentIdImage(
    response: ReadStudentIdResponse,
    storeImages: boolean,
  ) {
    const fileName = response.getFileName();
    let image: Blob | undefined;
    if (fileName) {
      image = await this.studentIdImageService.getStudentIdImageAsBlob(
        fileName,
        StudentIdPresetSize.ID_FULL,
      );
      if (image && storeImages) this._storeCacheIDImage(image);
    }
    return image;
  }

  /**
   * Get student id image uploaded by student and minga custom
   * icon if available. Only return studentId data if student has
   * a student id image.
   */
  async read(personHash?: string): Promise<IStudentId | null> {
    if (!personHash) {
      await this.readLocalStudentId();
    } else {
      // wipe out previous value since it's likely to be the wrong person.
      this.studentId$.next({ image: '', personHash: '', active: true });
    }
    const request = new ReadStudentIdRequest();
    // if hash is provided, return student id info of specified
    // person. otherwise return student id info of current person.
    if (personHash) {
      request.setPersonHash(personHash);
    }

    try {
      const response = await this.studentIdManager.readStudentId(request);
      const retrievedId: IStudentId = await this.processStudentIdMessage(
        response,
        !personHash,
      );

      this.processStudentIdImage(response, !personHash)
        .then(image => {
          if (image) {
            retrievedId.image = URL.createObjectURL(image);
            this.studentId$.next({ ...retrievedId });
          }
        })
        .catch(e => {
          this._sentry.captureMessageAsError(
            'Error getting student id images',
            e,
          );
        });

      if (!personHash) {
        // only set it offline to off if we got something returned
        if (retrievedId.personHash) {
          retrievedId.isOffline = false;
          this._storeLocalStudentId(retrievedId);
        }
      }

      this.studentId$.next({ ...retrievedId });
      this.isIdOffline = false;
      this.setLoaded(true);

      return retrievedId;
    } catch (e) {
      this.isIdOffline = true;
      throw new Error(e.message);
    }
  }

  async getStatusCounts(): Promise<IStudentIdStatusCounts> {
    const request = new StudentIdStatusCountRequest();

    const response = await this.studentIdManager.getStudentIdStatusCounts(
      request,
    );

    return {
      active: response.getActive() || 0,
      inactive: response.getInactive() || 0,
      noPhoto: response.getNoPhoto() || 0,
      tempPhoto: response.getTempPhoto() || 0,
      printId: response.getPrintId() || 0,
    };
  }

  isStudentIdOffline(): boolean {
    return this.isIdOffline;
  }

  private async _loadLocalStudentId() {
    const storedId = await this.localStorage.getItem<IStudentId>(
      STUDENT_ID_STORAGE_KEY + this.authInfoService.authPersonHash,
    );

    if (!storedId) {
      return;
    }
    if (storedId.image) {
      const cachedIdImage = await this._getCachedIDImage();
      if (cachedIdImage) {
        storedId.image = URL.createObjectURL(cachedIdImage);
      }
    }

    const icons = storedId.icons;
    if (icons && !this.isOfflineIdEnabled) {
      for (let index = 0; index < icons.length; index++) {
        const cachedImage = await this._getCachedIcon(index);
        if (cachedImage) {
          icons[index] = URL.createObjectURL(cachedImage);
        }
      }

      storedId.icons = icons;
    } else {
      // if we don't set this, the cached data are urls to cdn, which will work
      // if the user has internet, but we couldn't get access to minga backend
      // for whatever reason, so the stickers might be wrong.
      storedId.icons = [];
    }

    if (storedId.noAccess && !this.isOfflineIdEnabled) {
      const cachedNoAccess = await this._getCachedNoAccessImage();
      if (cachedNoAccess) {
        storedId.noAccess = URL.createObjectURL(cachedNoAccess);
      }
    } else {
      storedId.noAccess = '';
    }

    if (storedId) {
      storedId.isOffline = true;
      this.studentId$.next({ ...storedId });
    }

    return null;
  }

  private async _storeCacheIDImage(blob: Blob) {
    await this.localStorage.setItem('studentIdImage', blob);
  }
  private async _getCachedIDImage(): Promise<Blob> {
    return this.localStorage.getItem('studentIdImage');
  }
  private async _storeCachedIcon(index: number, icon: string) {
    const result = await fetch(icon);
    if (result.ok) {
      try {
        const blob = await result.blob();
        await this.localStorage.setItem('idicon-' + index, blob);
      } catch (err) {
        console.error('Error caching blob for icon', err);
      }
    }
  }
  private async _getCachedIcon(index: number): Promise<Blob> {
    return this.localStorage.getItem('idicon-' + index);
  }
  private async _storeCachedNoAccessImage(icon: string) {
    const result = await fetch(icon);
    if (result.ok) {
      try {
        const blob = await result.blob();
        await this.localStorage.setItem('noAccessIcon', blob);
      } catch (e) {
        console.error('Error caching blob for no access icon', e);
      }
    }
  }
  private async _getCachedNoAccessImage(): Promise<Blob> {
    return this.localStorage.getItem('noAccessIcon');
  }

  private async _storeLocalStudentId(id: IStudentId) {
    await this.localStorage.setItem(
      STUDENT_ID_STORAGE_KEY + this.authInfoService.authPersonHash,
      id,
    );
  }

  private async _deleteLocalStudentId() {
    await this.localStorage.removeItem(
      STUDENT_ID_STORAGE_KEY + this.authInfoService.authPersonHash,
    );
    this.studentId$.next({ image: '', personHash: '' });
  }

  async deleteStudentIdImage(personHash: string) {
    const request = new DeleteStudentIdImageRequest();
    request.setPersonHash(personHash);

    await this.studentIdManager.deleteStudentIdImage(request);
  }

  async delete() {
    const request = new DeleteStudentIdRequest();
    await this._deleteLocalStudentId();
    return await this.studentIdManager.deleteStudentId(request);
  }

  async getAllIdListItemsInMinga(): Promise<IPersonIdentityListItem[]> {
    const request = new PersonIdentityListRequest();
    const response = await this.studentIdManager.getPersonIdentityList(request);
    const rows = response.getRowsList();
    return rows.map((row: PersonIdentityListItem) =>
      IPersonIdentityListProtoMapper.fromProto(row),
    );
  }

  async bulkUpdateStudentIdImage(items: IBulkStudentIdFileUpload[]) {
    const request = new BulkIdImageUploadRequest();
    for (const item of items) {
      const msg = new BulkIdImageUploadRow();
      msg.setFileName(item.fileName);
      msg.setPersonhash(item.personHash);
      request.addRows(msg);
    }

    return this.studentIdManager.bulkIdImageUpload(request);
  }

  downloadList(filter: IMgStreamFilter) {
    const streamFilter = toStreamFilterMessage(filter);
    const request = new ExportStudentIdListRequest();
    request.setFilter(streamFilter);

    return this.studentIdManager.exportStudentIdList(request);
  }

  /**
   * Opens browser print preview dialog
   */
  async launchStudentIdPrintDialog(personHash: string) {
    await this.router.navigate(
      [
        '',
        {
          outlets: { o: [this._viewId.getIdOverlayRoute(), personHash, true] },
        },
      ],
      {},
    );
  }

  async checkDisplayId(personHash: string) {
    const caller = this.authInfoService.authPersonHash;
    const isOwn = personHash == caller;
    const override = this.permissions.hasPermission(
      MingaPermission.ADMINISTER_STUDENT_ID,
    );
    const setting = await this._settingService.getSettingValue(
      mingaSettingTypes.HIDE_ID_NUM,
    );
    return !setting || isOwn || override;
  }

  public async getMingaStudentIds(
    limit: number,
    offset: number,
    filter: IMgStreamFilter,
  ): Promise<IMgStreamItem<ReadStudentIdResponse.AsObject>[]> {
    const request = new GetMingaStudentIdsRequest();
    request.setFilter(toStreamFilterMessage(filter));
    request.setLimit(limit);
    request.setOffset(offset);

    const response = await this.studentIdManager.getMingaStudentIds(request);
    const items = response.getItemsList();

    return items.map(res => {
      const item = res.getItem().toObject();
      const itemMetadata: StreamItemMetadata = res.getItemMetadata();
      let itemIndex = null;
      let itemId = null;

      if (itemMetadata) {
        itemIndex = itemMetadata.getIndex();
        itemId = itemMetadata.getId();
      }

      return { item, itemIndex, itemId };
    });
  }
}
