import { Directive, Input, OnDestroy, OnInit } from '@angular/core';

import * as day from 'dayjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { MingaRoleType } from 'minga/domain/permissions';
import { IPeopleCollectionPersonDetails } from 'minga/domain/person';
import { PeopleCollection } from 'minga/proto/people_collection/people_collection_ng_grpc_pb';
import { GetPeopleByRolesRequest } from 'minga/proto/people_collection/people_collection_pb';
import { IPeopleCollectionPersonDetailsProtoMapper } from 'minga/shared-grpc/person';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';
import { TypeEnum, TypeUnion } from 'src/app/store/root/rootActions';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { FormSelectComponent } from '../components/form-select/form-select.component';

type TeacherSearchStorage = {
  date: day.Dayjs;
  data: IPeopleCollectionPersonDetails[];
};

@Directive({
  selector: '[mgTeacherSearch]',
  exportAs: 'mgTeacherSearch',
})
export class TeacherSearchDirective implements OnInit, OnDestroy {
  /** Constants */
  private readonly _roleTypes = [
    MingaRoleType.TEACHER,
    MingaRoleType.STAFF,
    MingaRoleType.MANAGER,
    MingaRoleType.OWNER,
    MingaRoleType.DISTRICT_MANAGER,
  ];
  private readonly _localStorageKey = 'teacher-people-collection';
  private readonly _cacheHours = 27;

  /** General Subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Data */
  private readonly _dataSubject = new BehaviorSubject<
    IPeopleCollectionPersonDetails[]
  >([]);
  private readonly _data$ = this._dataSubject.asObservable();

  /** Form Select Properties */
  private _isLoadingSubject = this._formSelect.isLoading$;

  /** Logout Effect */
  private readonly _onLogout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TypeEnum.Logout),
        map(() => this._clearLocalStorage()),
      ),
    { dispatch: false },
  );

  private _canGrantHallPass = false;

  /** Inputs */
  @Input() useCachedValues = true;
  @Input() set canGrantHallPass(value: boolean) {
    this._canGrantHallPass = value;
  }
  @Input() excludeCurrentUser = false;

  mingaHash = '';

  /** Directive Constructor */
  constructor(
    private _actions$: Actions<TypeUnion>,
    private _formSelect: FormSelectComponent,
    private _peopleCollection: PeopleCollection,
    private _systemSnack: SystemAlertSnackBarService,
    private _authInfo: AuthInfoService,
    private _mingaInfo: MingaStoreFacadeService,
  ) {
    this._mingaInfo.getMingaAsObservable().subscribe(minga => {
      this.mingaHash = minga?.hash || '';
    });
  }

  ngOnInit(): void {
    this._updateSelectOptionsSubscription();
    this._fetchIfNecessary();
  }

  async _fetchIfNecessary() {
    const shouldFetchTeachers = await this._getFromLocalStorage();
    if (shouldFetchTeachers || !this.useCachedValues) this._fetchTeachers();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._dataSubject.complete();
  }

  private _getStorageKey(): string {
    const key = this._localStorageKey + ':' + this.mingaHash;
    if (this._canGrantHallPass) {
      return key + ':hallpass';
    }
    return key;
  }

  private _updateSelectOptionsSubscription() {
    return this._data$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(data => {
        this._formSelect.options = data
          .map(teacher => ({
            label: teacher.firstName + ' ' + teacher.lastName,
            value: teacher.personHash,
          }))
          .filter(option => {
            if (this.excludeCurrentUser) {
              return option.value !== this._authInfo.authPersonHash;
            }
            return true;
          })
          .sort((a, b) => a.label.localeCompare(b.label));
      });
  }

  private _storeInLocalStorage(data: IPeopleCollectionPersonDetails[]): void {
    // don't store empty data.
    if (!data || data.length === 0) {
      return;
    }
    try {
      const date = day();
      const dataToStore = JSON.stringify({
        date,
        data,
      } as TeacherSearchStorage);
      localStorage.setItem(this._getStorageKey(), dataToStore);
    } catch (error) {
      this._systemSnack.open({
        message: 'Error storing teachers collection to storage',
        type: 'error',
      });
    }
  }

  private _clearLocalStorage(): void {
    localStorage.removeItem(this._localStorageKey);
  }

  private async _getFromLocalStorage(): Promise<boolean> {
    const dataString = await localStorage.getItem(this._getStorageKey());
    if (!dataString) return true;
    try {
      const { data, date }: TeacherSearchStorage = JSON.parse(dataString);
      const now = day();
      this._dataSubject.next(data);
      return now.diff(date, 'hours') >= this._cacheHours;
    } catch (error) {
      this._systemSnack.open({
        message: 'Error retrieving teachers collection from storage',
        type: 'error',
      });
    }
  }

  private async _fetchTeachers(): Promise<void> {
    try {
      this._isLoadingSubject.next(true);
      const result = await this._listPeople();
      this._storeInLocalStorage(result);
      this._dataSubject.next(result);
    } catch (error) {
      this._systemSnack.open({
        message: 'Error fetching teachers',
        type: 'error',
      });
    } finally {
      this._isLoadingSubject.next(false);
    }
  }

  private async _listPeople() {
    const request = new GetPeopleByRolesRequest();
    request.setRoleList(this._roleTypes);
    request.setCanGrantHallPass(this._canGrantHallPass);
    const response = await this._peopleCollection.getPeopleByRoles(request);
    const peopleList = response.getPersonList();
    return peopleList.map(IPeopleCollectionPersonDetailsProtoMapper.fromProto);
  }
}
