import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { IStreamSwipeStackDataSource } from 'minga/app/src/app/components/StreamSwipeStack/types';
import {
  IMgStreamControl,
  IMgStreamFilter,
  IMgStreamItem,
  mgStreamControlObservable,
} from 'minga/app/src/app/util/stream';
import { wrap } from 'minga/libraries/util/wrap';
import { ChallengeResponse as ChallengeResponseProto } from 'minga/proto/content/challenge/challenge_response_ng_grpc_pb';
import {
  ChallengeResponseCard,
  StreamChallengeResponseCardResponse,
} from 'minga/proto/content/challenge/challenge_response_pb';

export class ChallengeResponseStackStream
  implements IStreamSwipeStackDataSource<ChallengeResponseCard.AsObject>
{
  /** @internal */
  private _hasSeeked: boolean = false;

  /** @internal */
  private _previewInitialItems = new BehaviorSubject<
    ChallengeResponseCard.AsObject[]
  >([]);

  /**
   * The threshold until the stream requests more items
   */
  private readonly threshold = 3;

  private _stackIndex = new BehaviorSubject(0);

  readonly currentItemData$: Observable<ChallengeResponseCard.AsObject | null>;
  readonly previewItemData$: Observable<ChallengeResponseCard.AsObject | null>;
  readonly previewPlaceholderItemData$: Observable<ChallengeResponseCard.AsObject | null>;
  readonly stackIndex$: Observable<number>;
  readonly stackSize$: Observable<number>;
  private items$: Observable<IMgStreamItem<ChallengeResponseCard.AsObject>[]>;

  private streamControl: IMgStreamControl<ChallengeResponseCard.AsObject>;

  readonly loading$: Observable<boolean>;

  constructor(
    private responseProto: ChallengeResponseProto,
    previewInitialItems: ChallengeResponseCard.AsObject[],
    public readonly ownerContextHash: string,
    private readonly filter: IMgStreamFilter | null = null,
  ) {
    this._previewInitialItems.next(previewInitialItems);
    let streamFilter: IMgStreamFilter = Object.assign(
      {},
      { ownerContextHash },
      filter,
    );

    this.streamControl = mgStreamControlObservable(
      this.responseProto,
      'streamResponsesControl',
      'streamResponses',
      streamFilter,
    );

    this.items$ = this.streamControl.asObservable().pipe(shareReplay());

    this.stackSize$ = this.items$.pipe(map(() => this.getStackSize()));

    const itemsAndIndex$ = combineLatest([
      this.items$,
      this._previewInitialItems,
      this._stackIndex,
    ]);

    const getItem = (
      items: IMgStreamItem<ChallengeResponseCard.AsObject>[],
      previewItems: ChallengeResponseCard.AsObject[],
      index: number,
    ): ChallengeResponseCard.AsObject | null => {
      const wrappedIdx = wrap(index, 0, this.getStackSize());
      return items[wrappedIdx]?.item || null;
    };

    this.currentItemData$ = itemsAndIndex$.pipe(
      map(([data, previewData, index]) => getItem(data, previewData, index)),
      map(item => item || null),
    );

    this.previewItemData$ = itemsAndIndex$.pipe(
      map(([data, previewData, index]) =>
        getItem(data, previewData, index + 1),
      ),
      map(item => item || null),
    );

    this.previewPlaceholderItemData$ = itemsAndIndex$.pipe(
      map(([data, previewData, index]) =>
        getItem(data, previewData, index + 2),
      ),
      map(item => item || null),
    );

    this.stackIndex$ = this._stackIndex.asObservable();

    this.loading$ = this.streamControl.loading$;

    // Start off with a single digest
    this._stackIndex.next(this._stackIndex.getValue());
  }

  setPreviewInitialItems(
    previewInitialItems: ChallengeResponseCard.AsObject[],
  ) {
    this._previewInitialItems.next([...previewInitialItems]);
  }

  setIndexByResponseContextHash(responseContextHash: string) {
    const index = this.indexOfResponseContextHash(responseContextHash);
    if (index === -1) {
      console.warn(
        `Could not find item with response context hash ${responseContextHash}`,
      );
    } else {
      this.setIndex(index);
    }
  }

  indexOfResponseContextHash(responseContextHash: string): number {
    const items = this.streamControl._getItems();
    const stackSize = this.getStackSize();

    for (let i = 0; stackSize > i; ++i) {
      const item = items[i]?.item;
      if (!item) continue;

      if (item.contextHash === responseContextHash) {
        return i;
      }
    }

    return -1;
  }

  seekToResponseContextHash(
    responseContextHash: string,
  ): Observable<number | null> {
    if (!this._hasSeeked) {
      this._seekFront();
    }
    // keep track of the item list so that we can jump to the requested
    // context hash if one was selected.
    return this.items$.pipe(
      map(items => {
        if (responseContextHash) {
          if (items.length > 0) {
            const findIndex = items.findIndex(
              item => item.itemId == responseContextHash,
            );
            if (findIndex > -1) {
              return findIndex;
            } else {
              if (this.streamControl.isDone) {
                // we've reached the end of the stream, must not exist.
                return -1;
              }

              // if the hash isn't here, try to get more items until we find it.
              this._seekFront();

              return null;
            }
          }
        }
        return null;
      }),
    );
  }

  getStackSize() {
    if (this.streamControl.length > 0) {
      return this.streamControl.length;
    } else {
      return this._previewInitialItems.getValue().length;
    }
  }

  setIndex(newIndex: number) {
    if (newIndex < 0) throw new Error('Index cannot be negative');
    if (newIndex > this.getStackSize() - 1)
      throw new Error('Index cannot be greater than stack size');

    this._stackIndex.next(newIndex);

    if (newIndex > this.getStackSize() - this.threshold) {
      this._seekFront();
    } else if (!this._hasSeeked) {
      this._seekFront();
    }
  }

  next() {
    const nextIndex = wrap(
      this._stackIndex.getValue() + 1,
      0,
      this.getStackSize(),
    );

    this._stackIndex.next(nextIndex);

    if (nextIndex > this.getStackSize() - this.threshold) {
      this._seekFront();
    } else if (!this._hasSeeked) {
      this._seekFront();
    }
  }

  previous() {
    const previousIndex = wrap(
      this._stackIndex.getValue() - 1,
      0,
      this.getStackSize(),
    );

    this._stackIndex.next(previousIndex);

    if (!this._hasSeeked) {
      this._seekFront();
    }
  }

  /** @internal */
  private _seekFront() {
    this._hasSeeked = true;
    this.streamControl.seekFront();
  }
}
