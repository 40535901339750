import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

import { IContentInfoDraftContext } from 'minga/app/src/app/content/public_interfaces';
import {
  CancelConfirmationDialogComponent,
  DebugContentDialogComponent,
  EventCodeCheckInDialogComponent,
  IEventCodeCheckInDialogResponse,
  SuccessDialog,
} from 'minga/app/src/app/dialog';
import { SaveCancelDialog } from 'minga/app/src/app/dialog/SaveCancel';
import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import {
  ContentEvents,
  IBlockedContextMenuOpenEvent,
  IContentOpenEvent,
  IContextMenuOpenEvent,
  IGalleryPhotoOpenEvent,
  ILikesPeopleEvent,
} from 'minga/app/src/app/minimal/services/ContentEvents';
import {
  EventContentService,
  IEventContentPeopleEvent,
} from 'minga/app/src/app/minimal/services/EventContent';
import { MgModalService } from 'minga/app/src/app/minimal/services/MgModal';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';
import { StreamManager } from 'minga/app/src/app/minimal/services/StreamManager';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { ContentBuilderService } from 'minga/app/src/app/services/ContentBuilder';
import { ContentMetadataService } from 'minga/app/src/app/services/ContentMetadata';
import { ReportService } from 'minga/app/src/app/services/Report';
import { EventStatus } from 'minga/domain/event';
import { MingaPermission } from 'minga/domain/permissions';
import { ContentCleanup } from 'minga/proto/content/cleanup_ng_grpc_pb';
import { ContentInfoQuery } from 'minga/proto/content/common_pb';
import { ContentMetadata } from 'minga/proto/content/content_pb';
import { MingaContent } from 'minga/proto/content/minga_ng_grpc_pb';
import { MingaUnpinContent } from 'minga/proto/content/minga_pb';
import {
  LongCardView,
  LongEventCardView,
  ShortEventCardView,
} from 'minga/proto/gateway/content_views_pb';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

export interface IContentContextState {
  commentsIntoView?: boolean;
  commentInputFocus?: boolean;
  commentReplyInputFocus?: boolean;
  imageFocusIndex?: number;
  responseContextHash?: string;
  responseStreamState?: string;
}

@Injectable({ providedIn: 'root' })
export class ContentState {
  private _overrideAiSubject = new Subject<ContentMetadata.AsObject>();
  private _contentContextState: Map<string, IContentContextState>;
  private _likesPeopleContentMap: Map<
    string,
    | ShortEventCardView.AsObject
    | LongEventCardView.AsObject
    | LongCardView.AsObject
  >;

  get onOverrideAi(): Observable<ContentMetadata.AsObject> {
    return this._overrideAiSubject.asObservable();
  }

  constructor(
    private _contentEvents: ContentEvents,
    private _rootService: RootService,
    private _permissions: PermissionsService,
    private _router: Router,
    private _modalService: MgModalService,
    private _dialog: MatDialog,
    private _eventContent: EventContentService,
    private _auth: AuthService,
    private _contentCleanup: ContentCleanup,
    private _contentBuilder: ContentBuilderService,
    private _contentMetadata: ContentMetadataService,
    private _mingaContent: MingaContent,
    private _streamManager: StreamManager,
    private _reportService: ReportService,
    private _authService: AuthInfoService,
  ) {
    this._contentContextState = new Map();
    this._likesPeopleContentMap = new Map();

    this._contentEvents.onContextMenuOpen.subscribe(ev => {
      this._onContextMenuOpen(ev);
    });
    this._eventContent.onSeeEventPeopleGoing.subscribe(ev => {
      this._onSeePeopleGoing(ev);
    });
    this._contentEvents.onContentEventCodeCheckIn.subscribe(ev => {
      this._openEventCodeCheckInDialog(
        ev.contentContextHash,
        ev.contentTitle,
        ev.date,
        ev.eventReasonId,
      );
    });
    this._contentEvents.onContentOpen.subscribe(ev => {
      this._onContentOpen(ev);
    });
    this._contentEvents.onSeePeopleLikes.subscribe(ev => {
      this._onSeePeopleLikes(ev);
    });
    this._contentEvents.onBlockedContextMenuOpen.subscribe(ev => {
      this._onBlockedContextMenuOpen(ev);
    });
    this._contentEvents.onGalleryPhotoOpenEvent.subscribe(ev =>
      this._onGalleryPhotoOpenEvent(ev),
    );
  }

  private _onContentOpen(ev: IContentOpenEvent) {
    this._router.navigate([
      '',
      { outlets: { o: ['view', ev.contentContextHash] } },
    ]);
  }

  private _onGalleryPhotoOpenEvent(ev: IGalleryPhotoOpenEvent) {
    this._router.navigate(['/gallery/' + ev.galleryPhotoUuid]);
  }

  private _onSeePeopleGoing(ev: IEventContentPeopleEvent) {
    // store content to retrieve when the overlay is opened
    this._eventContent.setGoingPeopleContent(
      ev.contextHash,
      ev.content,
      ev.initialPeopleStatusFilter,
    );

    // open the overlay
    if (this._router) {
      this._router.navigate([
        '',
        { outlets: { o: ['view', 'event~people', 'going', ev.contextHash] } },
      ]);
    }
  }

  clearContentForPeopleLikes(contextHash: string = null) {
    if (contextHash) {
      this._likesPeopleContentMap.delete(contextHash);
    } else {
      this._likesPeopleContentMap.clear();
    }
  }

  getContentForPeopleLikes(contextHash: string) {
    return this._likesPeopleContentMap.get(contextHash);
  }

  private _onSeePeopleLikes(ev: ILikesPeopleEvent) {
    if (ev.content && ev.contextHash) {
      this._likesPeopleContentMap.set(ev.contextHash, ev.content);
    }

    // open the overlay
    if (this._router) {
      this._router.navigate([
        '',
        { outlets: { o: ['view', 'post~people', 'likes', ev.contextHash] } },
      ]);
    }
  }

  private _openEventCodeCheckInDialog(
    contextHash: string,
    eventTitle: string = '',
    date: Date = null,
    eventReasonId: number,
  ) {
    const options = {
      data: {
        eventReasonId,
        eventTitle,
      },
      autoFocus: false,
    };
    const dialog = this._dialog.open(EventCodeCheckInDialogComponent, options);

    dialog
      .afterClosed()
      .subscribe(async (result: IEventCodeCheckInDialogResponse) => {
        if (result && !result.canceled && !result.correctCode) {
          const initialValue = this._eventContent.getCheckIn(contextHash);
          this._eventContent.setCheckIn(contextHash, initialValue);

          this._contentEvents.emitContentEventGoingError({
            contentContextHash: contextHash,
            going: true,
            checkedIn: false,
            status: initialValue,
          });
          return;
        } else if (result && result.correctCode) {
          this._eventContent.setCheckIn(contextHash, EventStatus.CHECKED_IN);

          if (result.successOverlay) {
            const dialogRef = this._modalService.open(result.successOverlay, {
              full: true,
              animation: 'fade',
            });
            setTimeout(() => dialogRef.close(), 2000);
            this._contentEvents.emitContentEventCodeCheckInSuccess({
              contentContextHash: contextHash,
              date,
            });
            // Wait for the animation to start a tiny bit
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
      });
  }

  private async _onContextMenuOpen(ev: IContextMenuOpenEvent) {
    const deletedReasons = this._reportService.getDeletedReasons(
      ev.contentContextHash,
    );
    if (!!deletedReasons) {
      const blockedEv: IBlockedContextMenuOpenEvent = _.merge(
        {},
        { blockedReasons: deletedReasons.reasons },
        ev,
      );
      await this._onBlockedContextMenuOpen(blockedEv);
    } else {
      await this._openContextMenu(ev);
    }
  }

  private async _openContextMenu(ev: IContextMenuOpenEvent) {
    const menuItems: any[] = [];

    const contentMetadata = await this._contentMetadata.getContentMetadata({
      contentContextHash: ev.contentContextHash,
    });
    const isAuthor =
      contentMetadata.authorPersonHash === this._authService.authPersonHash;
    const isStudioContent = contentMetadata.mingaDesignerContent;

    const typeStringToPerm = (typeString: string) => {
      switch (typeString) {
        case 'post':
          return MingaPermission.CONTENT_POST_CREATE;
        case 'video':
          return MingaPermission.CONTENT_VIDEO_CREATE;
        case 'poll':
          return MingaPermission.CONTENT_POLL_CREATE;
        case 'fullGraphic':
          return MingaPermission.CONTENT_FULL_GRAPHIC_CREATE;
        case 'event':
          return MingaPermission.CONTENT_EVENT_CREATE;
        case 'challenge':
          return MingaPermission.CONTENT_CHALLENGE_MANAGE;
      }

      return MingaPermission.SUPERADMIN;
    };

    /** Check if content type can be edited */
    const canUpdateContentPerm = (typeString: string) => {
      if (typeString === 'file') return false;
      if (typeString === 'sms_message') return false;

      const perm = typeStringToPerm(typeString);
      return this._permissions.hasPermission(perm);
    };

    const canUpdateContent = canUpdateContentPerm(contentMetadata.typeString);
    const isSuperAdmin = this._permissions.hasPermission(
      MingaPermission.SUPERADMIN,
    );
    const canDeleteOwn = true;
    const canDeleteOthers = this._permissions.hasPermission(
      MingaPermission.CONTENT_DELETE_OTHERS,
    );

    const canUnpin = this._permissions.hasPermission(
      MingaPermission.MINGA_CONTENT_PIN,
    );
    const canReport = this._permissions.hasPermission(
      MingaPermission.CONTENT_REPORT_CREATE,
    );
    const canResolve = this._permissions.hasPermission(
      MingaPermission.CONTENT_REPORTS_MANAGE,
    );
    const canUpdateOthers = this._permissions.hasPermission(
      MingaPermission.MINGA_CONTENT_UPDATE_OTHERS,
    );

    const isRecurringEventParent =
      contentMetadata.recurringEventContentHash &&
      contentMetadata.recurringEventContextHash === ev.contentContextHash;

    if (isSuperAdmin) {
      menuItems.push({
        name: 'Debug',
        click: e => this.openDebug(ev.contentContextHash),
      });
    }

    if (canUnpin && contentMetadata.pinEndTimestamp) {
      menuItems.push({
        name: 'Unpin',
        click: e =>
          this._rootService.addLoadingPromise(
            this.unpinContent(ev.contentContextHash),
          ),
      });
    }

    if (!ev.disableDelete) {
      if (
        canResolve &&
        canDeleteOthers &&
        this._reportService.isReported({ contextHash: ev.contentContextHash })
      ) {
        menuItems.push({
          name: 'Delete & Resolve',
          click: e => this.openDelete(ev.contentContextHash),
        });
      } else if (canDeleteOthers && !isRecurringEventParent) {
        // can delete if not program content, or if it is must be a super admin
        if (
          !contentMetadata.programContextHash ||
          (isSuperAdmin && contentMetadata.programContextHash)
        ) {
          menuItems.push({
            name: 'Delete',
            click: e => this.openDelete(ev.contentContextHash),
          });
        }
      } else if (canDeleteOwn && !isRecurringEventParent) {
        if (isAuthor) {
          menuItems.push({
            name: 'Delete',
            click: e => this.openDelete(ev.contentContextHash),
          });
        }
      }

      if (
        contentMetadata.recurringEventContentHash &&
        contentMetadata.recurringEventContextHash
      ) {
        if (canDeleteOthers || (canDeleteOwn && isAuthor)) {
          menuItems.push({
            name: 'Delete Recurring Event',
            click: e =>
              this.openDelete(contentMetadata.recurringEventContextHash),
          });
        }
      }
    }

    // update others permission or only edit your own content
    if (canUpdateContent && (canUpdateOthers || isAuthor)) {
      // add edit if not program content, or if it is only for super admins
      // disable edit for minga designer as it is getting depracated
      if (
        (!contentMetadata.mingaDesignerContent &&
          !contentMetadata.programContextHash) ||
        (isSuperAdmin && contentMetadata.programContextHash)
      ) {
        menuItems.push({
          name: 'Edit',
          click: e => this.openEdit(ev.contentHash, ev.contentContextHash),
        });
      }
    }

    if (
      canReport &&
      !this._reportService.isReported({ contextHash: ev.contentContextHash })
    ) {
      menuItems.push({
        name: 'Report',
        click: e => this.openReport(ev.contentContextHash),
      });
    }

    if (
      canResolve &&
      this._reportService.isReported({ contextHash: ev.contentContextHash })
    ) {
      menuItems.push({
        name: 'Keep & Resolve',
        click: e => this.resolveReport(ev.contentContextHash),
      });
    }

    if (canUpdateContent && (canUpdateOthers || isAuthor)) {
      if (
        contentMetadata.recurringEventContentHash &&
        contentMetadata.recurringEventContextHash
      ) {
        menuItems.push({
          name: 'Edit Recurring Event',
          click: e =>
            this.openEdit(
              contentMetadata.recurringEventContentHash,
              contentMetadata.recurringEventContextHash,
            ),
        });
      }
    }

    if (canUpdateContent && (canUpdateOthers || isAuthor)) {
      if (contentMetadata.typeString === 'event') {
        menuItems.push({
          name: 'Cancel Event',
          click: e => this.cancelEvent(contentMetadata.contentHash),
        });
      }
    }

    await this._rootService.openGlobalMenu({
      x: ev.x,
      y: ev.y,
      items: menuItems,
    });
  }

  private async _onBlockedContextMenuOpen(ev: IBlockedContextMenuOpenEvent) {
    const menuItems: any[] = [];

    const contentMetadata = await this._contentMetadata.getContentMetadata({
      contentHash: ev.contentHash,
      contentContextHash: ev.contentContextHash,
    });

    const type = contentMetadata.typeString;
    const canOverride = this._permissions.hasPermission(
      MingaPermission.CONTENT_MODERATION_OVERRIDE,
    );

    if (canOverride) {
      menuItems.push({
        name: 'Publish',
        click: e =>
          this.overrideAI(
            ev.contentHash,
            ev.contentContextHash,
            ev.blockedReasons,
            contentMetadata,
          ),
      });
    }

    await this._rootService.openGlobalMenu({
      x: ev.x,
      y: ev.y,
      items: menuItems,
    });
  }

  async unpinContent(contentContextHash: string) {
    const request = new MingaUnpinContent();
    request.setContentContextHash(contentContextHash);
    await this._mingaContent.unpinContent(request);
    this._contentEvents.emitContentUnpin({ contentContextHash });
    this._contentMetadata.clearContentMetadata(contentContextHash);

    // This is pretty overkill. It was a business requirement though.
    await this._streamManager.restartStreamIfAvailable('HomeFeed');
  }

  async openEdit(contentHash: string, contentContextHash: string = '') {
    const contentMetadata = await this._contentMetadata.getContentMetadata({
      contentHash,
      contentContextHash,
    });

    let contextInfo: IContentInfoDraftContext;

    if (contentMetadata.ownerGroupHash && contentContextHash) {
      contextInfo = {
        hash: contentContextHash,
        type: 'group',
        ownerGroupHash: contentMetadata.ownerGroupHash,
      };
    } else if (contentMetadata.programContextHash) {
      contextInfo = {
        hash: contentMetadata.programContextHash,
        type: 'program',
      };
    } else if (contentContextHash) {
      contextInfo = {
        hash: contentContextHash,
        type: 'minga',
      };
    }
    const isChallenge = contentMetadata.typeString === 'challenge';
    if (isChallenge) {
      await this._contentBuilder.challengeNavEdit(
        contentMetadata.contentHash,
        contextInfo,
      );
    } else {
      await this._contentBuilder.navEdit(
        contentMetadata.typeString,
        contentMetadata.contentHash,
        contextInfo,
        contentMetadata.mingaDesignerContent,
      );
    }
  }

  async openDebug(contentContextHash: string) {
    const contentMetadata = await this._contentMetadata.getContentMetadata({
      contentHash: '',
      contentContextHash,
    });

    const dialogRef = this._dialog.open(DebugContentDialogComponent, {
      data: { contentMetadata, contentContextHash },
    });
  }

  private async _deleteContext(contentContext: string) {
    const contentInfoQuery = new ContentInfoQuery();
    contentInfoQuery.setContentContextHash(contentContext);
    const response = await this._contentCleanup.delete(contentInfoQuery);
    this._contentEvents.emitContentDeleted({
      contentContextHash: contentContext,
    });

    return response;
  }

  async openDelete(contentContextHash: string) {
    const options = {
      data: {
        text: 'dialog.deleteContentConfirm',
        saveButtonLocale: 'button.delete',
      },
    };

    const dialog = this._dialog.open(SaveCancelDialog, options);
    dialog.afterClosed().subscribe(async result => {
      if (result === true) {
        const response = await this._rootService.addLoadingPromise(
          this._deleteContext(contentContextHash),
        );

        if (response) {
          this._dialog.open(SuccessDialog, {
            data: { text: `Deleted successfully.` },
          });
          await this._router.navigate(['', { outlets: { o: null } }]);
        }
      }
    });
  }

  async onEventCancelClicked(contentHash: string) {
    const options = {
      data: {
        title: 'Cancel Event',
        text: 'Are you sure you want to cancel this event? The event will remain visible but will be shown as "Cancelled" in all feeds.',
      },
    };

    const dialogRef = this._dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelEvent(contentHash);
      }
    });
  }

  async cancelEvent(contentHash: string) {
    await this._eventContent.cancelEvent(contentHash);

    const dialog = this._dialog.open(SuccessDialog, {
      data: { text: `This event has been cancelled.` },
    });

    dialog.afterClosed().subscribe(result => {
      this._streamManager.restartStreamIfAvailable('HomeFeed');
      this._router.navigate(['/']);
    });
  }

  async openReport(contextHash: string = '', galleryPhotoUuid: string = '') {
    const reportNav = {
      outlets: { o: ['report', contextHash, galleryPhotoUuid] },
    };
    await this._router.navigate(['', reportNav]);
  }

  async openGroup(groupHash: string) {
    await this._router.navigate(['/groups', 'view', groupHash]);
  }

  async resolveReport(contextHash: string = '') {
    const success = await this._reportService.resolve({ contextHash });
    if (success) {
      await this._router.navigate(['', { outlets: { o: null } }]);
    }
  }

  async overrideAI(
    contentHash: string = '',
    contextHash: string = '',
    reasonList: string[] = [],
    contentMetadata?: ContentMetadata.AsObject,
  ) {
    const success = await this._reportService.overrideAI({
      contentHash,
      contextHash,
      reasonList,
    });
    if (success) {
      if (contentMetadata) {
        this._overrideAiSubject.next(contentMetadata);
      }
      await this._router.navigate(['', { outlets: { o: null } }]);
    }
  }

  private _getContentContextState(contentContext) {
    let contentContextState: IContentContextState = {};

    if (this._contentContextState.has(contentContext)) {
      contentContextState = this._contentContextState.get(contentContext);
    } else {
      this._contentContextState.set(contentContext, contentContextState);
    }

    return contentContextState;
  }

  wants(contentContext: string, key: keyof IContentContextState) {
    const state = this._getContentContextState(contentContext);
    if (
      key !== 'imageFocusIndex' &&
      key !== 'responseContextHash' &&
      key !== 'responseStreamState'
    ) {
      state[key] = true;
    } else {
      console.error(`Can't want non boolean ${key}`);
    }
  }

  consume(contentContext: string, key: keyof IContentContextState) {
    const state = this._getContentContextState(contentContext);
    const hadKey = key in state;
    delete state[key];

    const keys = Object.keys(state);

    if (keys.length === 0) {
      this._contentContextState.delete(contentContext);
    }

    return hadKey;
  }

  setStateValue<K extends keyof IContentContextState>(
    contentContext: string,
    key: K,
    value: IContentContextState[K],
  ) {
    const state = this._getContentContextState(contentContext);
    state[key] = value;
  }

  getStateValue(contentContext: string, key: keyof IContentContextState) {
    const state = this._getContentContextState(contentContext);
    const value = state[key];
    delete state[key];

    const keys = Object.keys(state);

    if (keys.length === 0) {
      this._contentContextState.delete(contentContext);
    }

    return value;
  }

  wantsReply(contentContext: string, replyCommentContext: string) {}

  consumeReply(contentContext: string) {}

  /** @deprecated use wants(contentContext, 'commentsIntoView') */
  wantsCommentScrollIntoView(contentContext: string) {
    return this.wants(contentContext, 'commentsIntoView');
  }

  /** @deprecated use consume(contentContext, 'commentsIntoView') */
  consumeCommentScrollIntoView(contentContext: string): boolean {
    return !!this.consume(contentContext, 'commentsIntoView');
  }
}
