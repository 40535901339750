import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IdSettings, IdStudentData, IdTemplate } from '../../types';

@Component({
  selector: 'mg-view-id-body',
  templateUrl: './view-id-body.component.html',
  styleUrls: ['./view-id-body.component.scss'],
})
export class ViewIdBodyComponent {
  // Inputs

  @Input() template: IdTemplate;
  @Input() studentData: IdStudentData;
  @Input() settings: IdSettings;
  @Input() readonly: boolean;

  // Outputs

  @Output() changePhoto = new EventEmitter<IdStudentData>();

  // Constructor

  constructor() {}
}
