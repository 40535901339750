<mg-card
  *ngIf="person"
  class="header"
  [ngClass]="{
    'has-active-pass': activeHallPass$ | async,
    'no-actions': !onOwnProfile
  }"
  paddingX="4"
  paddingY="4">
  <div class="header-avatar">
    <div
      class="avatar-wrap"
      [attr.data-analytics]="'tools-dashboard-click-view-id'"
      [attr.data-test]="'tools-dashboard-click-view-id'"
      [routerLink]="[
        '',
        {
          outlets: {
            o: [viewId.getIdOverlayRoute(), person.hash]
          }
        }
      ]">
      <button class="avatar-overlay">
        <mg-text
          variant="body-md"
          color="white">
          View ID
        </mg-text>
      </button>
      <mg-avatar [src]="person.profileImageUrl"></mg-avatar>
      <mg-icon iconName="mg-id-menu"></mg-icon>
    </div>
  </div>
  <div class="header-user-info">
    <mg-text
      class="name"
      variant="header-lg-secondary"
      [numberOfLines]="2">
      {{ person.firstName }} {{ person.lastName }}
    </mg-text>
    <div class="header-user-info-details">
      <div class="item">
        <mg-icon iconName="mg-people-menu"></mg-icon>
        <mg-text
          variant="body-sm"
          color="surface-alt"
          [numberOfLines]="1">
          {{ person.badgeRoleName }}
        </mg-text>
      </div>
      <div
        *ngIf="person.studentId"
        class="item">
        <mg-icon iconName="mg-id-menu"></mg-icon>
        <mg-text
          variant="body-sm"
          color="surface-alt"
          [numberOfLines]="1">
          {{ person.studentId }}
        </mg-text>
      </div>
      <div
        *ngIf="person.grade"
        class="item">
        <mg-icon iconName="mg-student-filled"></mg-icon>
        <mg-text
          variant="body-sm"
          color="surface-alt"
          [numberOfLines]="1">
          Grade {{ person.grade }}
        </mg-text>
      </div>
    </div>
  </div>
  <div class="header-actions">
    <div class="header-actions-wrap">
      <ng-content></ng-content>
    </div>
  </div>
  <div
    class="header-hall-pass"
    *ngIf="activeHallPass$ | async as activeHallPass">
    <ng-container
      *ngIf="
        $any(activeHallPass)
          | countdown
            : (recentlyEndedSetting$ | async)
            : (manuallyEndedSetting$ | async)
            : timer$
            : activeHallPass.hallPassType?.manuallyEndPass
          | async as countdown
      ">
      <button
        *ngIf="countdown !== 'DISABLED'"
        class="button-wrapper"
        [attr.data-analytics]="'tools-dashboard-click-hallpass'"
        [attr.data-test]="'tools-dashboard-click-hallpass'"
        (click)="openId()">
        <div
          class="header-hall-pass-wrap"
          [class.overdue]="
            activeHallPass.status.state === HALL_PASS_STATUS_ENUM.OVERDUE
          ">
          <mg-icon
            class="pass-type-icon"
            matTooltipClass="mg-tooltip"
            [matTooltip]="activeHallPass.passName"
            [iconName]="activeHallPass.passIcon || 'hallpass'"
            [style.color]="activeHallPass.passColor">
          </mg-icon>
          <mg-text
            variant="body-sm"
            [numberOfLines]="2">
            {{ activeHallPass.passName }}
          </mg-text>

          <mg-text
            class="countdown"
            variant="body-sm-secondary"
            [color]="
              activeHallPass.status.state === HALL_PASS_STATUS_ENUM.OVERDUE
                ? 'error'
                : 'surface'
            ">
            {{ countdown }}
          </mg-text>
        </div>
      </button>
    </ng-container>
  </div>
</mg-card>
