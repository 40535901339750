import { Injectable, OnInit } from '@angular/core';

import * as day from 'dayjs';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IMingaFeatureToggleKeys } from 'minga/domain/featureToggle';
import { IHallPass } from 'minga/domain/hallPass';
import { MingaPermission } from 'minga/domain/permissions';
import { mingaSettingTypes } from 'minga/libraries/util';
import { AppConfigService } from 'src/app/minimal/services/AppConfig';
import { PermissionsService } from 'src/app/permissions';
import { HallPassService } from 'src/app/services/HallPass';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { BellSchedulePermissionsService } from '@modules/minga-manager/components/mm-bell-schedule/services/bell-schedule-permissions.service';
import { StudentToolsFlexTimeService } from '@modules/tools/student-tools/services';

import { ScheduleCard } from '@shared/components/student-schedule-widget/student-schedule-widget.types';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { StudentScheduleService } from '@shared/services/student-schedule';

@Injectable()
export abstract class StudentDashboardAbstract implements OnInit {
  protected _activeHallPass = new BehaviorSubject<IHallPass>(null);
  public readonly activeHallPass$ = this._activeHallPass.asObservable();

  protected _loadingScheduleSubject = new BehaviorSubject<boolean>(true);
  public loadingSchedule$ = this._loadingScheduleSubject.asObservable();

  protected _scheduleItemsSubject = new BehaviorSubject<ScheduleCard[]>([]);
  public scheduleItems$ = this._scheduleItemsSubject.asObservable().pipe(
    map(items =>
      items.sort((a, b) => {
        return a.startTime < b.startTime ? -1 : 1;
      }),
    ),
  );

  public isBellScheduleEnabledForCurrentUser$: Observable<boolean> = from(
    this._bellSchedulePermissions.isBellScheduleEnabledForCurrentUser(),
  );

  public isScheduleEnabled$: Observable<boolean> = combineLatest([
    this._mingaSettings.isFlexTimeModuleEnabled(),
    this._permissions.observePermission(MingaPermission.STUDENT_SCHEDULE_VIEW),
    this._mingaSettings.getSettingValueObs(
      mingaSettingTypes.STUDENT_SCHEDULE_ENABLE,
    ),
  ]).pipe(
    map(
      ([flexEnabled, canViewStudentSchedule, studentScheduleEnabled]) =>
        (flexEnabled ||
          (canViewStudentSchedule &&
            this._appConfig.getValue('studentScheduleEnabled'))) &&
        studentScheduleEnabled,
    ),
  );

  constructor(
    private _hallPassService: HallPassService,
    private _studentScheduleService: StudentScheduleService,
    private _studentFlexTime: StudentToolsFlexTimeService,
    private _mingaSettings: MingaSettingsService,
    private _snackBarService: SystemAlertSnackBarService,
    private _appConfig: AppConfigService,
    private _permissions: PermissionsService,
    private _bellSchedulePermissions: BellSchedulePermissionsService,
  ) {}

  ngOnInit(): void {
    this.onInit();
  }

  protected abstract onInit(): void;

  protected _fetchStudentData(userHash: string): void {
    this._fetchActiveHallPass(userHash);

    if (this._appConfig.getValue('studentScheduleEnabled')) {
      this._fetchMySchedule(userHash);
    }
  }

  private async _fetchActiveHallPass(userHash: string): Promise<void> {
    const hallPasses = await this._hallPassService.getHallPassesForRecipient(
      userHash,
    );
    const activePass = hallPasses.find(pass => pass.endDate > new Date());
    if (activePass) {
      this._activeHallPass.next(activePass);
    }
  }

  private async _fetchMySchedule(userHash: string): Promise<void> {
    try {
      this._loadingScheduleSubject.next(true);
      const start = day();
      const end = day();
      await Promise.all([
        this._fetchStudentSchedule(userHash, start, end),
        this._fetchFlexTimeSchedule(userHash, start, end),
      ]);
    } catch (e) {
      this._snackBarService.error('There was a problem fetching schedule');
    } finally {
      this._loadingScheduleSubject.next(false);
    }
  }

  private async _fetchStudentSchedule(
    userHash: string,
    start: day.Dayjs,
    end: day.Dayjs,
  ): Promise<void> {
    const isBellScheduleEnabledForCurrentUser: boolean =
      await this._bellSchedulePermissions.isBellScheduleEnabledForCurrentUser();

    if (!isBellScheduleEnabledForCurrentUser) return;

    const studentSchedule =
      await this._studentScheduleService.getStudentSchedule(
        userHash,
        start,
        end,
      );

    const cards = studentSchedule.map(schedule => ({
      period: schedule.period,
      course: schedule.name,
      isFlexActivity: false,
      room: schedule.location,
      startTime: schedule.startTime,
      endTime: schedule.endTime,
    }));
    const existing = this._scheduleItemsSubject.value;
    this._scheduleItemsSubject.next([...existing, ...cards]);
  }

  private async _fetchFlexTimeSchedule(
    userHash: string,
    start: day.Dayjs,
    end: day.Dayjs,
  ): Promise<void> {
    const isFlexEnabled = await this._mingaSettings.getModuleEnabled(
      IMingaFeatureToggleKeys.FLEX_TIME_ENABLED,
    );
    if (!isFlexEnabled) return;

    const registrations =
      await this._studentFlexTime.fetchMyActivityRegistrations(
        start,
        end,
        userHash,
      );

    const cards = registrations.map(r => ({
      period: r.registration?.flexTimePeriod?.title,
      course: r.registration?.flexTimeActivity.name,
      room: r.registration?.flexTimeActivity.location,
      startTime: r.registration?.flexTimePeriod.startTime,
      endTime: r.registration?.flexTimePeriod.endTime,
      isFlexActivity: true,
    }));

    const existing = this._scheduleItemsSubject.value;
    this._scheduleItemsSubject.next([...existing, ...cards]);
  }
}
