<div class="manager-overlay-subpage-header">
  <div
    class="header-container"
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="end center"
    fxLayoutAlign.lt-sm="start stretch">
    <mg-btn
      variant="filled"
      [id]="'pbis-behaviors-click-create'"
      [responsive]="true"
      (pressed)="typesBehavior.openEditModal()">
      {{ MESSAGES.BUTTON_CREATE }}
    </mg-btn>
  </div>
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</div>

<!-- Loading -->
<div
  *ngIf="isLoading$ | async"
  fxLayout="row"
  fxLayoutAlign="center center"
  class="loading-container">
  <mg-spinner
    [diameter]="40"
    [thickness]="3">
  </mg-spinner>
</div>

<!-- Main content -->
<ng-template #mainContent>
  <div
    *ngIf="(isLoading$ | async) === false"
    fxLayout="column"
    fxLayoutGap="24px"
    fxLayoutAlign="start stretch"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start stretch">
    <div fxFlex="50%">
      <div
        fxLayout="row"
        fxLayoutAlign="start center">
        <mg-icon
          icon-namespace="minga.behaviors"
          [style.color]="defaultPraiseIcon.color">
          {{ defaultPraiseIcon.icon }}
        </mg-icon>
        <mg-text variant="header-lg-secondary">
          {{ MESSAGES.PRAISE_TITLE }}
        </mg-text>
      </div>

      <ng-container *ngIf="praises$ | async as praises">
        <div class="table-container">
          <mg-bm-types-list
            *ngIf="praises?.length > 0"
            [fallbackIcon]="defaultPraiseIcon"
            [items]="praises"
            (toggleActive)="
              typesBehavior.changeStatus($event.item, $event.active)
            "
            (edit)="typesBehavior.openEditModal($event.id)">
          </mg-bm-types-list>

          <mg-empty-state
            *ngIf="praises?.length === 0"
            class="no-results"
            [title]="MESSAGES.EMPTY_PRAISE_TITLE"
            [subtitle]="MESSAGES.EMPTY_PRAISE_DESC">
          </mg-empty-state>
        </div>
      </ng-container>
    </div>
    <div fxFlex="50%">
      <div
        fxLayout="row"
        fxLayoutAlign="start center">
        <mg-icon
          icon-namespace="minga.behaviors"
          [style.color]="defaultGuidanceIcon.color"
          >{{ defaultGuidanceIcon.icon }}</mg-icon
        >
        <mg-text variant="header-lg-secondary">{{
          MESSAGES.GUIDANCE_TITLE
        }}</mg-text>
      </div>

      <ng-container *ngIf="guidances$ | async as guidances">
        <div class="table-container">
          <mg-bm-types-list
            *ngIf="guidances?.length > 0"
            [fallbackIcon]="defaultGuidanceIcon"
            [items]="guidances"
            (toggleActive)="
              typesBehavior.changeStatus($event.item, $event.active)
            "
            (edit)="typesBehavior.openEditModal($event.id)">
          </mg-bm-types-list>

          <mg-empty-state
            *ngIf="guidances?.length === 0"
            class="no-results"
            [title]="MESSAGES.EMPTY_GUIDANCE_TITLE"
            [subtitle]="MESSAGES.EMPTY_GUIDANCE_DESC">
          </mg-empty-state>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
