import { NgModule } from '@angular/core';

import { CommonUIModules } from '@shared/modules';

import { TilesCarouselModule } from '../tiles-carousel';
import { ViewIdBodyComponent } from './components/view-id-body/view-id-body.component';
import { ViewIdCodeComponent } from './components/view-id-code/view-id-code.component';
import { ViewIdHallpassComponent } from './components/view-id-hallpass/view-id-hallpass.component';
import { ViewIdMingalangeloComponent } from './components/view-id-mingalangelo/view-id-mingalangelo.component';
import { ViewIdMingassoComponent } from './components/view-id-mingasso/view-id-mingasso.component';
import { ViewIdScheduleComponent } from './components/view-id-schedule/view-id-schedule.component';
import { ViewIdStickerComponent } from './components/view-id-stickers/view-id-sticker.component';
import { ViewIdBarcodeDirective } from './directives/view-id-barcode.directive';
import { HallPassTimeFormatPipe } from './pipes/hallPassTimeFormat.pipe';
import { HideIdNumberPipe } from './pipes/hideIdNumber.pipe';
import { SchoolYearFormatPipe } from './pipes/schoolYearFormat.pipe';
import { ViewIdService } from './services/view-id.service';
import { ViewIdComponent } from './view-id.component';

@NgModule({
  imports: [
    // Common
    CommonUIModules,

    // Minga dependencies
    TilesCarouselModule,

    // External dependencies
  ],
  declarations: [
    // Components
    ViewIdComponent,
    ViewIdBodyComponent,
    ViewIdCodeComponent,
    ViewIdMingassoComponent,
    ViewIdMingalangeloComponent,
    ViewIdScheduleComponent,
    ViewIdStickerComponent,
    ViewIdHallpassComponent,

    // Pipes
    HallPassTimeFormatPipe,
    SchoolYearFormatPipe,
    HideIdNumberPipe,

    // Directives
    ViewIdBarcodeDirective,
  ],
  exports: [
    ViewIdComponent,
    ViewIdBodyComponent,
    ViewIdMingassoComponent,
    ViewIdMingalangeloComponent,
  ],
  providers: [
    // Services
    ViewIdService,
  ],
})
export class ViewIdModule {}
