<!-- Before Closed Content -->
<ng-container
  *ngIf="beforeCloseSuccess$ | async as beforeCloseSuccess; else modal">
  <div
    class="modal-before-closed-container"
    [style]="{
      '--icon-color': beforeCloseSuccess.iconColor
    }">
    <div class="modal-before-closed-icon">
      <mg-icon [iconName]="beforeCloseSuccess.iconName"></mg-icon>
    </div>
    <div
      *ngIf="beforeCloseSuccess.message"
      class="modal-before-closed-message">
      {{ beforeCloseSuccess.message }}
    </div>
  </div>
</ng-container>

<!-- Modal -->
<ng-template #modal>
  <div
    *ngIf="isLoading"
    class="loading-container">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>
  <div
    *ngIf="!isLoading"
    class="modal-overlay"
    [ngClass]="sizeClass">
    <!-- Modal Title -->

    <ng-container *ngIf="secondaryButton">
      <header class="modal-overlay-header-v3">
        <div class="secondary-action-button">
          <mg-btn
            size="large"
            class="modal-overlay-header-v3-secondary-action"
            variant="icon-secondary"
            iconSet="minga"
            [icon]="secondaryButtonIcon"
            (pressed)="handleOnClickSecondaryAction()"></mg-btn>
        </div>
        <div
          class="title"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="8px"
          [style.transform]="'scale(' + overlayTitleScale + ')'">
          <!-- Title icon -->
          <mg-icon
            class="title-icon"
            *ngIf="titleIcon"
            [iconName]="titleIcon">
          </mg-icon>
          <!-- Title -->
          <mg-text variant="header-lg-secondary">{{ overlayTitle }}</mg-text>
        </div>
        <div class="close-action-button">
          <mg-btn
            size="large"
            class="modal-overlay-header-v3-close"
            variant="icon-secondary"
            iconSet="minga"
            icon="mg-cross"
            (pressed)="handleOnClickClose()"></mg-btn>
        </div>
      </header>
    </ng-container>

    <header
      *ngIf="!secondaryButton"
      class="modal-overlay-header-v2">
      <ng-container *ngIf="promotionalIcon">
        <mg-icon
          [ngClass]="'icon-' + promotionalColor"
          [iconName]="promotionalIcon">
        </mg-icon>
      </ng-container>
      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="8px">
        <!-- Title icon -->
        <mg-icon
          class="title-icon"
          *ngIf="titleIcon"
          [iconName]="titleIcon">
        </mg-icon>
        <!-- Title -->
        <mg-text
          *ngIf="overlayTitle"
          [variant]="
            size === 'full' ? 'header-xl-secondary' : 'header-lg-secondary'
          ">
          {{ overlayTitle }}
        </mg-text>
        <mg-tag
          *ngIf="betaTag"
          color="green"
          size="small">
          Beta
        </mg-tag>
      </div>
      <mg-btn
        size="large"
        class="modal-overlay-header-v2-close"
        variant="icon-secondary"
        iconSet="minga"
        icon="mg-cross"
        (pressed)="handleOnClickClose()"></mg-btn>
    </header>

    <!-- Modal Header -->
    <header
      *ngIf="modalHeader"
      class="modal-overlay-header"
      [ngClass]="promotionalColor">
      <ng-container *ngTemplateOutlet="modalHeader"></ng-container>
    </header>

    <!-- Modal Body -->
    <div
      #modalOverlayBody
      class="modal-overlay-body"
      [ngClass]="{ 'no-padding': bodyNoPadding }">
      <ng-content></ng-content>
    </div>

    <!-- Modal Footer -->
    <div
      *ngIf="overlayFooter"
      class="modal-overlay-footer">
      <ng-container *ngTemplateOutlet="overlayFooter"></ng-container>
    </div>
  </div>
</ng-template>
