import { Injectable, Optional, SkipSelf } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Group } from 'minga/app/src/app/groups/models';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services/GroupsFacade/';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { RouterDerivedStateService } from 'minga/app/src/app/state/services';
import { MingaStoreFacadeService } from 'minga/app/src/app/store/Minga/services/MingaStoreFacade.service';
import { MingaPermission } from 'minga/domain/permissions';

export enum IconNavHoverType {
  ACTIVE = 'active',
  BACKGROUND = 'background',
}
@Injectable({ providedIn: 'root' })
export class RootNavService {
  private _showNavOnTop: boolean = false;

  currentGroup$: Observable<Group | null>;
  isOwner$: Observable<boolean>;
  currentGroupHash$: Observable<string | null>;

  get showNavOnTop(): boolean {
    return this._showNavOnTop;
  }
  set showNavOnTop(bool: boolean) {
    this._showNavOnTop = bool;
  }

  constructor(
    @Optional() @SkipSelf() parentModule: RootNavService,
    private permissions: PermissionsService,
    private mingaStore: MingaStoreFacadeService,
    private routerDerivedState: RouterDerivedStateService,
    private groupsFacade: GroupsFacadeService,
  ) {
    // if(parentModule) {
    //   throw new Error('RootNavService already loaded. Import it in root
    //   module only');
    // }

    this.currentGroup$ = this.routerDerivedState.currentGroup$.pipe(
      distinctUntilChanged(),
    );
    this.currentGroupHash$ = this.routerDerivedState.currentGroupHash$.pipe(
      distinctUntilChanged(),
    );

    this.isOwner$ = this.currentGroup$.pipe(map(group => Group.isOwner(group)));
  }

  canPostContent(): Observable<boolean> {
    return combineLatest(
      this.currentGroup$,
      this.permissions.observePermission(
        MingaPermission.MINGA_CONTENT_MAIN_FEED,
      ),
    ).pipe(
      map(([group, hasPerm]) => {
        if (group) {
          return this.groupsFacade.canPostContentToGroup(group);
        }
        return hasPerm;
      }),
    );
  }

  inParentGroup(): Observable<boolean> {
    return this.currentGroup$.pipe(
      map((group: Group | null) => {
        if (group?.isParent) {
          return true;
        }
        return false;
      }),
    );
  }
}
