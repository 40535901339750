import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { StudentSCheduleWidgetMessage } from './student-schedule-widget.constants';
import { ScheduleCard } from './student-schedule-widget.types';

@Component({
  selector: 'mg-student-schedule-widget',
  templateUrl: './student-schedule-widget.component.html',
  styleUrls: ['./student-schedule-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentScheduleWidgetComponent {
  /** Constants */
  public readonly MESSAGES = StudentSCheduleWidgetMessage;

  @Input() scheduleItems: ScheduleCard[];
  @Input() bellScheduleEnabledForCurrentUser: boolean = false;
  @Output() public viewAllPressed = new EventEmitter<{
    isFlexActivity: boolean;
    schedule?: ScheduleCard;
  }>();

  constructor() {}

  public goToSchedule(schedule?: ScheduleCard) {
    this.viewAllPressed.emit({
      isFlexActivity: schedule?.isFlexActivity,
      schedule,
    });
  }
}
