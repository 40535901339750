import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EditablePbisBehavior, IPbisBehavior } from 'minga/domain/pbis';
import { MingaPermission } from 'minga/domain/permissions';
import {
  BehaviorReportColumns,
  ColumnInfo,
  TemplateColumnKeys,
} from 'minga/libraries/shared/reports_columns';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { PermissionsService } from 'src/app/permissions';

import { BellSchedulePermissionsService } from '@modules/minga-manager/components/mm-bell-schedule/services/bell-schedule-permissions.service';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { BmReportsHistoryEditComponent } from '../components/bm-reports-history/bm-reports-history-edit.component';
import { BmReportsMessages } from '../constants';
import { BmReportsService } from './bm-reports.service';

@Injectable()
export class BmReportsHistoryDatasourceService extends ReportDatasourceService<EditablePbisBehavior> {
  protected _archiveMsg: string = BmReportsMessages.BEHAVIOR_ARCHIVE_MESSAGE;
  protected _editForm = BmReportsHistoryEditComponent;
  protected _displayCol: ColumnInfo[] = [];

  constructor(
    _router: Router,
    private _pbisReportService: BmReportsService,
    _alertModal: SystemAlertModalService,
    private _permissions: PermissionsService,
    _snackBar: SystemAlertSnackBarService,
    _modalOverlay: ModalOverlayService,
    private _bellSchedulePermissions: BellSchedulePermissionsService,
  ) {
    super(
      _router,
      _pbisReportService,
      BmReportsHistoryDatasourceService.name,
      _alertModal,
      _snackBar,
      _modalOverlay,
    );
    this._archiveFn = this._pbisReportService.deleteBehavior.bind(
      this._pbisReportService,
    );
    let displayCol: ColumnInfo[] = [
      { key: TemplateColumnKeys.EDIT, header: '', columnClasses: ['w-16'] },
      {
        header: '',
        key: TemplateColumnKeys.ARCHIVE,
        columnClasses: ['w-16'],
      },
    ];
    if (!this._permissions.hasPermission(MingaPermission.PBIS_TYPE_MANAGE))
      displayCol = [];

    this._displayCol = displayCol;

    super.setDisplayColumns(BehaviorReportColumns.HISTORY, displayCol);
  }

  async fetch(offset: number, limit: number) {
    // we need to check if current user has bell schedule permission to show/hide
    // current class column, we can do this asynchronously
    this._bellSchedulePermissions
      .isBellScheduleEnabledForCurrentUser()
      .then(isBellScheduleEnabledForCurrentUser => {
        if (!isBellScheduleEnabledForCurrentUser) {
          super.setDisplayColumns(
            BehaviorReportColumns.HISTORY.filter(
              item => item.key !== 'currentClass',
            ),
            this._displayCol,
          );
        }
      });

    return await this._pbisReportService.getBehaviorHistory(
      offset,
      limit,
      this._currentSort,
    );
  }
}
