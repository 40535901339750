<ng-container [ngSwitch]="template">
  <mg-view-id-mingasso
    *ngSwitchCase="'mingasso'"
    [studentData]="studentData"
    [settings]="settings"
    [readonly]="readonly"
    (changePhoto)="changePhoto.emit(studentData)">
  </mg-view-id-mingasso>
  <mg-view-id-mingalangelo
    *ngSwitchCase="'mingalangelo'"
    [studentData]="studentData"
    [settings]="settings"
    [readonly]="readonly"
    (changePhoto)="changePhoto.emit(studentData)">
  </mg-view-id-mingalangelo>
  <div *ngSwitchDefault>loading</div>
</ng-container>
