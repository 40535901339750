import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { RolesService } from 'minga/app/src/app/roles/services';
import { DisplayNameFormat } from 'minga/libraries/util';

class SelectErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;

    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

const MG_ROLE_SELECT_VALUE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RoleSelectComponent),
  multi: true,
};

export const MG_ROLE_SELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RoleSelectComponent),
  multi: true,
};

export interface IRoleSelectItem {
  roleType: string;
  name: string;
  roleDisplayNameFormat: DisplayNameFormat;
  disabled: boolean;
}

@Component({
  selector: 'mg-role-select',
  providers: [MG_ROLE_SELECT_VALUE_ACCESSOR, MG_ROLE_SELECT_VALUE_VALIDATOR],
  templateUrl: './RoleSelect.component.html',
  styleUrls: ['./RoleSelect.component.scss'],
})
export class RoleSelectComponent
  implements ControlValueAccessor, OnInit, Validator, OnDestroy
{
  selectedRoleType$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _sub = new Subscription();

  onChange?: Function;
  onTouched?: Function;

  /**
   * Need custom validation matcher here b/c marking all the fields as touched
   * `markAllAsTouched` on form doesn't trigger invalid field if it's invalid
   */
  matcher = new SelectErrorMatcher();

  @Input()
  disabled: boolean = false;

  @Input()
  prefix: string = '';

  @Input()
  hint: string = '';

  @Input()
  appearance: string = 'outline';

  @Input()
  mgHasHint: boolean = false;

  @Input()
  required: boolean = false;

  @Input()
  disallowNone: boolean = true;

  @Input()
  placeholder: string = 'Select Role';

  @Output()
  displayNameFormat = new EventEmitter<DisplayNameFormat>();

  @Output()
  roleName = new EventEmitter<string>();

  roleSelectItems$: Observable<IRoleSelectItem[]>;
  selectedRoleSelectItem$: Observable<IRoleSelectItem | null>;

  constructor(
    private rolesService: RolesService,
    private _cdr: ChangeDetectorRef,
  ) {
    this.roleSelectItems$ = this.rolesService.roles$.pipe(
      map(roles =>
        roles.map(role => {
          const selectItem: IRoleSelectItem = {
            disabled: false,
            name: role.name,
            roleType: role.roleType,
            roleDisplayNameFormat: role.roleDisplayNameFormat,
          };

          return selectItem;
        }),
      ),
    );

    this.selectedRoleSelectItem$ = combineLatest(
      this.selectedRoleType$,
      this.roleSelectItems$,
    ).pipe(
      map(
        ([selectedRoleType, items]) =>
          items.find(item => item.roleType === selectedRoleType) || null,
      ),
    );
  }

  private _updateDisplayNameFormat(selectedRoleInfo: IRoleSelectItem | null) {
    if (selectedRoleInfo) {
      this.displayNameFormat.emit(selectedRoleInfo.roleDisplayNameFormat);
      this.roleName.emit(selectedRoleInfo.name);
    } else {
      this.displayNameFormat.emit(DisplayNameFormat.FIRST_L);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.required && !this.selectedRoleType$.getValue()) {
      return { required: true };
    }

    return null;
  }

  private async _initRoles() {
    await this.rolesService.fetchIfNeeded();
  }

  ngOnInit() {
    const sub = this.selectedRoleSelectItem$.subscribe(roleItem => {
      this._updateDisplayNameFormat(roleItem);
      if (this.onChange) {
        this.onChange(roleItem?.roleType || '');
      }

      // @HACK: This is a workaround. At the time of writing this i couldn't get
      // [ngModel]="selectedRoleType$ | async" to work correctly at the right
      // timing
      this._cdr.markForCheck();
    });
    this._sub.add(sub);
    this._initRoles();
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value === null) {
      this.selectedRoleType$.next('');
      return;
    } else if (typeof value === 'string') {
      this.selectedRoleType$.next(value);
      return;
    } else if (value && typeof value === 'object') {
      if ('roleType' in value) {
        this.writeValue(value.roleType);
        return;
      }
    } else if (typeof value === 'number') {
      throw new Error('mg-role-select does NOT accept number as a write value');
    }

    console.warn('<mg-role-select> invalid value:', value);
  }
}
