import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { ViewIdComponent } from './view-id.component';
import { ViewIdModule } from './view-id.module';

export const VIEW_ID_ROUTES: Route[] = [
  {
    path: '',
    component: ViewIdComponent,
  },
  {
    path: ':hash',
    component: ViewIdComponent,
  },
  {
    path: ':hash/:print',
    component: ViewIdComponent,
  },
];

@NgModule({
  imports: [ViewIdModule, RouterModule.forChild(VIEW_ID_ROUTES)],
  exports: [RouterModule],
})
export class ViewIdRoutingModule {}
