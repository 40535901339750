<!-- Term Selector -->
<div class="tw-flex tw-flex-row tw-gap-4 tw-items-center tw-py-2 tw-mb-2.5">
  <mg-text variant="header-md-secondary">{{ MESSAGES.CALENDAR_LABEL }}</mg-text>
  <div
    *ngIf="(isLoading$ | async) === false && hasData()"
    class="w-52 terms-selector">
    <mg-form-select
      [matTooltip]="(currentTerm$ | async)?.title"
      matTooltipClass="mg-tooltip"
      appearance="primary"
      [placeholder]="MESSAGES.PLACEHOLDER_TERM"
      [multiple]="false"
      [searchable]="false"
      [condensed]="true"
      [isFullWidth]="true"
      [floatingLabel]="false"
      [value]="(currentTerm$ | async)?.id"
      [options]="termsOptions$ | async"
      (selectionChanged)="changedSelectedTerm($event)">
    </mg-form-select>
  </div>
</div>

<!-- Schedule errors -->
<div
  *ngIf="(isLoading$ | async) === false && hasErrors()"
  class="tw-mb-4">
  <mg-system-alert
    type="error"
    [message]="MESSAGES.SCHEDULE_ERRORS"
    [buttonText]="MESSAGES.SCHEDULE_ERRORS_BUTTON"
    [isAlert]="true"
    [dismissable]="false">
  </mg-system-alert>
</div>

<!-- Missing data -->
<div
  *ngIf="
    (isLoading$ | async) === false &&
    (!hasData() || tableDataSource.data?.length === 0)
  "
  class="tw-mb-4">
  <mg-system-alert
    type="default"
    [message]="!hasData() ? MESSAGES.NO_DATA : MESSAGES.MISSING_DATA"
    [isAlert]="true"
    [dismissable]="false"
    [routerLink]="'/minga-manager/bell-schedule/schedules'">
  </mg-system-alert>
</div>

<!-- Calendar Data -->
<ng-container *ngIf="(isLoading$ | async) === false; else loadingTemplate">
  <div
    *ngIf="tableDataSource.data?.length > 0 && hasData()"
    class="table-wrapper tw-mb-6">
    <mat-table
      *ngIf="scheduleOptions$ | async as scheduleOptions"
      class="calendar-table"
      [dataSource]="tableDataSource"
      [trackBy]="trackByFn">
      <!-- Date Column -->
      <ng-container [matColumnDef]="TABLE_COLUMN.DATE">
        <mat-header-cell
          *matHeaderCellDef
          class="tw-min-w-40 tw-w-1/12 tw-max-w-60">
          <mg-text variant="header-xs">{{ TABLE_COLUMN_LABEL.DATE }}</mg-text>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let schedule"
          class="tw-min-w-40 tw-w-1/12 tw-max-w-60">
          <mg-text
            variant="body-sm"
            class="date-text">
            {{ schedule.date | date: 'EEE, MMM d YYYY' }}
          </mg-text>
        </mat-cell>
      </ng-container>

      <!-- Schedule Column -->
      <ng-container [matColumnDef]="TABLE_COLUMN.SCHEDULE">
        <mat-header-cell
          *matHeaderCellDef
          class="w-md-flex">
          <mg-text variant="header-xs">
            {{ TABLE_COLUMN_LABEL.SCHEDULE }}
          </mg-text>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let schedule"
          class="w-md-flex">
          <mg-form-select
            *ngIf="scheduleOptions | overrideOptions: schedule as options"
            matTooltipClass="mg-tooltip"
            [matTooltip]="
              (
                options
                | find: 'value':schedule.overrideBellId || schedule.bellId
              )?.label
            "
            appearance="primary"
            class="tw-w-full"
            [placeholder]="MESSAGES.PLACEHOLDER_NO_SCHEDULE"
            [floatingLabel]="false"
            [closeOnSelect]="true"
            [multiple]="false"
            [searchable]="false"
            [condensed]="true"
            [isFullWidth]="true"
            [value]="schedule.overrideBellId || schedule.bellId"
            [options]="options"
            (selectionChanged)="changedBellSchedule($event, schedule)">
          </mg-form-select>
        </mat-cell>
      </ng-container>

      <!-- Default Schedule Column -->
      <ng-container [matColumnDef]="TABLE_COLUMN.STATE">
        <mat-header-cell
          *matHeaderCellDef
          class="tw-min-w-40 tw-max-w-40">
          <mg-text variant="header-xs">{{ TABLE_COLUMN_LABEL.STATE }}</mg-text>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let schedule"
          class="tw-min-w-40 tw-max-w-40 tw-justify-center">
          <mg-icon
            matTooltipClass="mg-tooltip"
            [iconName]="iconForState(schedule.state)"
            [matTooltip]="tooltipForState(schedule.state)"
            [ngClass]="schedule.state">
          </mg-icon>
        </mat-cell>
      </ng-container>

      <!-- Header columns -->
      <mat-header-row *matHeaderRowDef="tableColumns; sticky: true">
      </mat-header-row>

      <!-- Main rows -->
      <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
  </div>
</ng-container>

<!-- Loading indicator -->
<ng-template #loadingTemplate>
  <div class="tw-py-4">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>
</ng-template>
