import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { BehaviorSubject, from, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PeopleRoute } from '@modules/people/types';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import {
  BellSchedulesInterface,
  BellSchedulesService,
} from '@shared/services/bell-schedule/bs-schedules.interface';
import {
  BellScheduleTermsInterface,
  BellScheduleTermsService,
} from '@shared/services/bell-schedule/bs-terms.interface';
import { MediaService } from '@shared/services/media';

import {
  BS_TERMS_COLUMN_LABELS,
  BS_TERMS_COLUMNS,
  BSTermColumns,
  TERMS_MESSAGES,
} from '../../constants/mm-bs-terms.constants';
import { BsTermEditData, ClientTerm } from '../../types/mm-bell-schedule.types';
import { MmBsTermsEditComponent } from '../mm-bs-terms-edit/mm-bs-terms-edit.component';

/** Local convenience types for the table data. */
type TableTerms = {
  id: number;
  shortCode: string;
  title: string;
  startDate: Date;
  endDate: Date;
  bellSchedules: string[];
};

@Component({
  selector: 'mg-mm-bs-terms',
  templateUrl: './mm-bs-terms.component.html',
  styleUrls: ['./mm-bs-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

//
export class MmBsTermsComponent implements OnInit, OnDestroy {
  /** Constants */
  public MESSAGES = TERMS_MESSAGES;

  /** Table */
  public readonly TABLE_COLUMN = BS_TERMS_COLUMNS;
  public readonly TABLE_COLUMN_LABEL = BS_TERMS_COLUMN_LABELS;
  public readonly tableDataSource = new MatTableDataSource<TableTerms>([]);
  public readonly tableColumns = BSTermColumns;

  /** Subjects */
  private _destroyedSubject = new ReplaySubject<void>(1);
  private _loadingSubject = new BehaviorSubject<boolean>(false);
  private _termsSubject = new BehaviorSubject<ClientTerm[]>([]);
  public terms$ = this._termsSubject.asObservable();

  /** Observables */
  public isLoading$ = this._loadingSubject.asObservable();

  public hasSchedules$ = from(this._scheduleService.fetchAll()).pipe(
    map(schedules => schedules.length > 0),
  );

  public readonly displayedColumns$ = this.media.breakpoint$.pipe(
    takeUntil(this._destroyedSubject),
    map(bp => {
      if (['xsmall', 'small'].includes(bp)) return [BS_TERMS_COLUMNS.MOBILE];
      else return BSTermColumns;
    }),
  );

  // Terms Service
  constructor(
    private _modalService: ModalOverlayService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    @Inject(BellScheduleTermsInterface)
    private _termsService: BellScheduleTermsService,
    @Inject(BellSchedulesInterface)
    private _scheduleService: BellSchedulesService,
    private _router: Router,
    public media: MediaService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._termsSubject
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(terms => {
        this.tableDataSource.data = this._makeViewData(terms);
        this.tableDataSource.data.sort(
          (a, b) =>
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
        );
        this._cdr.markForCheck();
      });

    this._loadInitialData();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  private async _loadInitialData(): Promise<void> {
    try {
      this._loadingSubject.next(true);
      const terms = await this._termsService.fetchAll();
      this._termsSubject.next(terms);
    } catch (error) {
      this._systemAlertSnackBar.error(TERMS_MESSAGES.ERROR_LOADING_DATA);
    } finally {
      this._loadingSubject.next(false);
    }
  }

  public async openEditModal(id?: number) {
    const modalRef = this._modalService.open<BsTermEditData>(
      MmBsTermsEditComponent,
      {
        data: { id },
      },
    );

    const response = await modalRef.afterClosed.toPromise();
    const { type, data } = response;

    let message = '';
    let cacheData;

    if (type === ModalOverlayServiceCloseEventType.CREATE) {
      cacheData = {
        action: 'create',
        data: data.created,
      };
      message = TERMS_MESSAGES.SNACKBAR_CREATE_SUCCESS;
    }

    if (type === ModalOverlayServiceCloseEventType.SUBMIT) {
      cacheData = {
        action: 'update',
        data: data.updated,
      };
      message = TERMS_MESSAGES.SNACKBAR_UPDATE_SUCCESS;
    }

    if (type === ModalOverlayServiceCloseEventType.DELETE) {
      cacheData = {
        action: 'delete',
        data: data.deleted,
      };
      message = TERMS_MESSAGES.SNACKBAR_DELETE_SUCCESS;
    }

    if (message) {
      this._systemAlertSnackBar.open({
        type: 'success',
        message,
      });
    }

    if (cacheData) {
      const updated = await this._termsService.updateListCache(cacheData);
      this._termsSubject.next(updated);
      // need to revalidate any schedules since the associations could have changed
      this._scheduleService.fetchAll({ revalidate: true });
    }
  }

  public navigateToSis() {
    this._router.navigate([PeopleRoute.ROOT, PeopleRoute.ROSTERING]);
  }

  private _makeViewData(terms: ClientTerm[]): TableTerms[] {
    return terms.map(term => {
      const bellSchedules =
        term.bellSchedules?.map(schedule => schedule.name) ?? [];

      return {
        id: term.id,
        shortCode: term.sourcedId,
        title: term.title,
        startDate: term.startDate,
        endDate: term.endDate,
        bellSchedules,
      } as TableTerms;
    });
  }
}
