import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { BadgeDialogService } from 'minga/app/src/app/dialog/BadgeDialog';
import { isBadgeNotification } from 'minga/libraries/shared/notification/util';
import { Notification } from 'minga/proto/gateway/notification_pb';
import { ImageInfo } from 'minga/proto/image/image_pb';

import { NotificationActions } from '../../store/Notifications/actions/notifications.actions';
import { NotificationStoreModel } from '../../store/Notifications/model/notification.model';

/**
 * Handle activating a badge notification.
 */
@Injectable({ providedIn: 'root' })
export class BadgeNotificationHandlerService {
  constructor(
    private _store: Store<any>,
    private _badgeDialog: BadgeDialogService,
  ) {}

  async handleDisplayBadgeNotification(msg: Notification.AsObject) {
    const notificationType = msg.notificationType;
    if (notificationType && isBadgeNotification(notificationType)) {
      setTimeout(
        async () =>
          await this.showBadgeDialogForNotification(
            msg.title,
            msg.body,
            msg.notificationId,
            msg.image,
          ),
        3000,
      );
    }
  }

  async showBadgeDialogForNotification(
    title: string,
    body: string,
    notificationId: string,
    image?: ImageInfo.AsObject,
  ) {
    // we have to track whether the dialog is finished or not.
    // we can't use dialogRef.afterClose() because this dialog closes
    // immediately, and then shows an animation that lasts a bit.
    const tracker$ = new BehaviorSubject<boolean>(false);

    const dialogRef = this._badgeDialog.open({
      data: {
        text: title,
        body,
        image,
        showConfetti: true,
        finished$: tracker$,
      },
    });
    await dialogRef.afterClosed().toPromise();
    const notification: NotificationStoreModel = { id: notificationId };
    this._store.dispatch(
      new NotificationActions.MarkNotificationAsReadAction(notification),
    );
    return await tracker$.toPromise();
  }

  isBadgeNotification(type: string): boolean {
    return isBadgeNotification(type);
  }
}
