import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { mingaSettingTypes } from 'minga/libraries/util';
import { HallPassWithType } from 'minga/proto/hall_pass/hall_pass_pb';
import { BarcodeScanner } from 'src/app/barcodeScanner';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { HallPassEvents } from '@shared/services/hall-pass-events';
import { CreateHallpassService } from '@shared/services/hall-pass/create-hallpass.service';
import { HallPassActionsService } from '@shared/services/hall-pass/hallpass-actions.service';

import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsHallPassService extends PeopleSelectorFormService<'Hall Pass'> {
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _hallPassEvents: HallPassEvents,
    private _auth: AuthInfoService,
    private _createHpService: CreateHallpassService,
    private _hallPassActions: HallPassActionsService,
    private _settingService: MingaSettingsService,
    private _location: Location,
  ) {
    super(
      {
        name: 'Hall Pass',
        pageDefinitions: {
          assign: {
            submitFn: async () => this._submitAssign(),
            searchFn: async (type, filters) =>
              this._psCollectionSearch.collectionSearch(type, filters),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );
  }

  private async _submitAssign(): Promise<void> {
    const {
      isSelfGranted,
      typeId,
      duration,
      startDate,
      teacherAssignedHash,
      note,
      requireTeacherApproval,
    } = this.data;
    const selectedHashes = isSelfGranted
      ? [this._auth.authPerson.hash]
      : this.selection.getSelectionHashes();
    const { personHashes, passIds, passes } =
      await this._createHpService.createHallPass(
        isSelfGranted,
        typeId,
        selectedHashes,
        duration,
        startDate,
        teacherAssignedHash,
        note,
      );
    if (personHashes.length) {
      if (
        teacherAssignedHash &&
        teacherAssignedHash !== this._auth.authPerson.hash
      ) {
        const timeoutMins = parseInt(
          await this._settingService.getSettingValue(
            mingaSettingTypes.PASS_APPROVAL_REQUEST_TIMEOUT_DURATION_STAFF,
          ),
          10,
        );

        const timeInSecs = timeoutMins * 60;

        const { typeName, teacherAssignedName } = this.data;
        const recipients = personHashes.map((hash, index) => {
          const name = this.selection.getSelected(hash).displayName;
          const passId = passIds[index];
          return {
            passId,
            name,
          };
        });
        let closeEvents = 0;

        await this._hallPassActions.showPendingCancellationDialog(
          recipients,
          typeName,
          teacherAssignedName,
          timeInSecs,
          {
            onClose: () => {
              closeEvents++;
              // there's a small use case where if multiple approval passes are assigned we need
              // to wait till the last one is closed before navigating back
              if (closeEvents === recipients.length) {
                this._location.back();
              }
            },
          },
        );
      } else {
        await this._handleSuccess(personHashes, selectedHashes, passes);
      }
    }
  }

  private async _handleSuccess(
    createdPasses: string[],
    selectionHashes: string[],
    passes: HallPassWithType.AsObject[],
  ): Promise<void> {
    const passCount = createdPasses.length;
    const hasManyPeople = selectionHashes.length > 1;
    const { typeName } = this.data;
    this._hallPassEvents.emitHallPassCreateSuccess(passes);
    await this.peopleSelector.openDialog({
      type: 'success',
      audioAlerts: false,
      title: hasManyPeople
        ? undefined
        : this.selection.getSelected(selectionHashes[0]).displayName,
      subTitle: 'Hall pass created',
      message: typeName,
      extendedMessage: hasManyPeople
        ? `${passCount} hall pass${passCount > 1 ? 'es' : ''} created`
        : undefined,
    });
  }
}
