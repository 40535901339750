import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { CarouselStylePresets } from '@shared/components/tiles-carousel';

@Component({
  selector: 'mg-view-id-sticker',
  templateUrl: './view-id-sticker.component.html',
  styleUrls: ['./view-id-sticker.component.scss'],
})
export class ViewIdStickerComponent implements OnInit {
  // Constants

  public readonly CAROUSEL_PRESETS = CarouselStylePresets;

  // Inputs

  @Input() stickerList?: string[];
  @Input() noAccess = ''; // sticker image url

  public sanitizedStickerList = [];

  // Constructor

  constructor(
    private _sanitizer: DomSanitizer,
    private _cdr: ChangeDetectorRef,
  ) {}

  // Lifecycle Hooks

  public ngOnInit(): void {
    this._setStickers();
  }

  // Private Methods

  private _setStickers() {
    const sanitizedList = (this.stickerList || []).map(url => {
      return this._sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this._sanitizer.bypassSecurityTrustResourceUrl(url),
      );
    });

    this.sanitizedStickerList = sanitizedList;

    this._cdr.markForCheck();
  }
}
