import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import Color from 'color';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

import {
  IMingaFeatureToggle,
  IMingaFeatureToggleKeys,
} from 'minga/domain/featureToggle';
import { MingaPermission } from 'minga/domain/permissions';
import { MingaFeatureToggleMapper } from 'minga/shared-grpc/feature_toggle';
import { PermissionsService } from 'src/app/permissions';
import { MingaManagerService } from 'src/app/services/MingaManager';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

const DEFAULT_MINGA_FEATURE_TOGGLE: IMingaFeatureToggle = {
  [IMingaFeatureToggleKeys.TRACKING_ENABLED]: false,
  [IMingaFeatureToggleKeys.CHECKIN_ENABLED]: false,
  [IMingaFeatureToggleKeys.HALLPASS_ENABLED]: false,
  [IMingaFeatureToggleKeys.STUDENT_ID_ENABLED]: false,
  [IMingaFeatureToggleKeys.COMMUNITY_ENABLED]: true,
  [IMingaFeatureToggleKeys.PHOTO_GALLERY_ENABLED]: false,
  [IMingaFeatureToggleKeys.DM_ENABLED]: false,
  [IMingaFeatureToggleKeys.FLEX_TIME_ENABLED]: false,
};

@Injectable()
export class MmDashboardFeatureToggleService implements OnDestroy {
  private _featureToggles: IMingaFeatureToggle = {
    ...DEFAULT_MINGA_FEATURE_TOGGLE,
  };

  private _featureStates$ = new BehaviorSubject<IMingaFeatureToggle>(
    this._featureToggles,
  );
  public featureStates$ = this._featureStates$
    .asObservable()
    .pipe(shareReplay(1));

  private _isLoadingSubj = new BehaviorSubject<boolean>(true);
  public isLoading$ = this._isLoadingSubj.asObservable();

  private _destroyed$ = new ReplaySubject<void>(1);

  private _isSuperAdmin = false;
  private _mingaHash = '';

  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _permissions: PermissionsService,
    private _sanitizer: DomSanitizer,
    private _mingaManagerService: MingaManagerService,
  ) {
    this._isSuperAdmin = this._permissions.hasPermission(
      MingaPermission.SUPERADMIN,
    );

    this._mingaManagerService.onMingasUpdate
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this.postSubmit();
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public async initFeatureToggleService(mingaHash?: string) {
    this._mingaHash = mingaHash;
    if (!this._mingaHash) {
      this._isLoadingSubj.next(false);
      return;
    }
    try {
      const currentMingaFeaturesMsg = await (
        await this._mingaManagerService.getMingaFeatureToggles(this._mingaHash)
      ).getFeatureToggle();
      const currentMingaFeatures = MingaFeatureToggleMapper.fromProto(
        currentMingaFeaturesMsg,
      );
      this._initCurrentMingaFeatures(currentMingaFeatures);
    } catch (e) {
      this._systemAlertSnackBar.error('Failed to load Minga module states');
      this._isLoadingSubj.next(false);
    }
  }

  public resetModuleForm() {
    this._featureToggles = { ...DEFAULT_MINGA_FEATURE_TOGGLE };
    this._featureStates$.next(this._featureToggles);
  }

  public onStudentIdsExpansionPackChange(enabled: boolean) {
    if (this._isSuperAdmin) {
      this._updateFeatureToggle(
        IMingaFeatureToggleKeys.STUDENT_ID_ENABLED,
        enabled,
      );

      if (!enabled && this._featureToggles.hallPassEnabled) {
        this.onHallPassExpansionPackChange(false);
      }
    }
  }

  public onHallPassExpansionPackChange(enabled: boolean) {
    if (enabled && !this._featureToggles.studentIdEnabled) {
      this._systemAlertSnackBar.warning(
        'Student ID Expansion Pack must be enabled first',
      );
      return;
    }

    if (this._isSuperAdmin) {
      this._updateFeatureToggle(
        IMingaFeatureToggleKeys.HALLPASS_ENABLED,
        enabled,
      );
    }
  }

  public onCommunityModuleChange(enabled: boolean) {
    if (this._isSuperAdmin) {
      this._updateFeatureToggle(
        IMingaFeatureToggleKeys.PHOTO_GALLERY_ENABLED,
        false,
      );
      this._updateFeatureToggle(
        IMingaFeatureToggleKeys.COMMUNITY_ENABLED,
        enabled,
      );

      if (!enabled && this._featureToggles.checkinEnabled) {
        this.onCheckInExpansionPackChange(false);
      }
      if (!enabled && this._featureToggles.directMessagingEnabled) {
        this._updateFeatureToggle(IMingaFeatureToggleKeys.DM_ENABLED, false);
      }
    }
  }

  public onTrackingExpansionPackChange(enabled: boolean) {
    if (this._isSuperAdmin) {
      this._updateFeatureToggle(
        IMingaFeatureToggleKeys.TRACKING_ENABLED,
        enabled,
      );
    }
  }

  public onCheckInExpansionPackChange(enabled: boolean) {
    if (enabled && !this._featureToggles.communityEnabled) {
      this._systemAlertSnackBar.warning(
        'Community & Communication Expansion Pack must be enabled first',
      );
      return;
    }

    if (this._isSuperAdmin) {
      this._updateFeatureToggle(
        IMingaFeatureToggleKeys.CHECKIN_ENABLED,
        enabled,
      );
    }
  }

  public onFlexTimeExpansionPackChange(enabled: boolean) {
    if (this._isSuperAdmin) {
      this._updateFeatureToggle(
        IMingaFeatureToggleKeys.FLEX_TIME_ENABLED,
        enabled,
      );
    }
  }

  public async postSubmit() {
    this.resetModuleForm();
  }

  private _getMemberBarStyle(roleColor: string, width: string) {
    return this._sanitizer.bypassSecurityTrustStyle(
      `${this._getMemberBarGradient(roleColor)} width: ${width};`,
    );
  }

  private _getMemberBarGradient(_color: string) {
    // default to color in design specs
    const color = _color || '#70B2D7';
    const darkenedColor = Color(color).darken(0.5);

    return `--member-bar-progress-color1: ${darkenedColor}; --member-bar-progress-color2: ${color};`;
  }

  private async _updateFeatureToggle(
    settingName: IMingaFeatureToggleKeys,
    value: boolean,
  ) {
    this._featureToggles[settingName] = value;
    await this._mingaManagerService.updateMingaFeatureToggles(
      this._mingaHash,
      this._featureToggles,
    );
    this._featureStates$.next(this._featureToggles);
  }

  private _initCurrentMingaFeatures(currentMingaFeatures: IMingaFeatureToggle) {
    this._featureToggles = { ...currentMingaFeatures };
    this._featureStates$.next(this._featureToggles);
    this._isLoadingSubj.next(false);
  }
}
