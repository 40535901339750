import { Location } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';

import { from, Observable, of } from 'rxjs';

//import * as packageJsonInfo from 'minga/package-json-info';
import { FirebaseMessaging } from 'minga/app/src/app/firebase/messaging';
import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';
import { OnOverlayClose } from 'minga/app/src/app/misc/overlay';
import { LinkOpenerService } from 'minga/app/src/app/services/LinkOpener';

export interface ILicenseInfo {
  readonly name: string;
  readonly license: string;
  readonly publisher?: string;
  readonly url?: string;
}

@Component({
  selector: 'mg-about-us',
  templateUrl: './AboutUs.component.html',
  styleUrls: ['./AboutUs.component.scss'],
})
export class AboutUsComponent implements OnInit, OnOverlayClose {
  /*
  licenses: ILicenseInfo[] = Object.keys(packageJsonInfo.licenses).map(name => {
    const info = (packageJsonInfo.licenses as any)[name];
    return <ILicenseInfo>{
      get name() {
        return name;
      },

      get license() {
        return info.licenses;
      },

      get publisher() {
        return info.publisher;
      },

      get url() {
        return info.url;
      },
    };
  });
  */
  licenses: ILicenseInfo[] = [];

  get version() {
    return this.appConfig.getAppVersion();
  }

  get platform() {
    return window.MINGA_APP_PLATFORM;
  }

  get messagingStrategy() {
    return this.messaging.name;
  }

  get messagingPermission() {
    return this.messaging.getPermissionStatus();
  }

  readonly messagingPermissionStatus$: Observable<string>;

  constructor(
    private _elementRef: ElementRef,
    private _location: Location,
    private linkOpener: LinkOpenerService,
    private messaging: FirebaseMessaging,
    public appConfig: AppConfigService,
  ) {
    this.messagingPermissionStatus$ = from(
      this.messaging.getPermissionStatus(),
    );
  }

  ngOnInit() {
    this._elementRef.nativeElement.parentElement.style.overflow = 'visible';

    const anchorEls = this._elementRef.nativeElement.getElementsByTagName('a');
    if (anchorEls && !window.MINGA_APP_BROWSER) {
      for (let i = 0; i < anchorEls.length; ++i) {
        const anchorElement: HTMLAnchorElement = <HTMLAnchorElement>(
          anchorEls[i]
        );
        if (
          !anchorElement.href.includes('mailto:') ||
          anchorElement.href.includes('tel:')
        ) {
          anchorElement.addEventListener(
            'click',
            this.onAnchorClick.bind(this),
          );
        }
      }
    }
  }

  onAnchorClick(e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    this.linkOpener.open(e.target.href);
  }

  onOverlayClose() {
    this._elementRef.nativeElement.parentElement.style.overflow = 'hidden';
    this._location.back();

    return true;
  }
}
