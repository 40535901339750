import { Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Userpilot } from 'userpilot';

import { MingaRoleType } from 'minga/domain/permissions';
import { badgeRoleNameToMingaRoleType } from 'minga/libraries/shared/auth/util';
import { AppConfigService } from 'src/app/minimal/services/AppConfig';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { UserPilotMingaInfoOptions } from '@shared/types/UserPilotMingaInfo';

export enum UserpilotEventType {
  VISITED_SIS = 'visited sis page',
}

@Injectable({
  providedIn: 'root',
})
export class UserpilotService {
  private _isDisabled = isDevMode();
  constructor(
    private _router: Router,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _authInfoService: AuthInfoService,
    private _mingaStore: MingaStoreFacadeService,
    private _appConfig: AppConfigService,
  ) {
    // reload userpilot
    this._router.events.subscribe(event => {
      try {
        if (event instanceof NavigationEnd) {
          if (!this._isDisabled) {
            const origin = this._appConfig.getAppOrigin();
            Userpilot.reload(origin + event.url);
          }
        }
      } catch (err) {
        console.error(err);
      }
    });
  }

  public initialize() {
    if (this._isDisabled) return;
    Userpilot.initialize('NX-124c0295');
  }

  public async identify(options?: UserPilotMingaInfoOptions) {
    if (this._isDisabled) return;

    const person = this._authInfoService.authPerson;
    const roleType = badgeRoleNameToMingaRoleType(person.badgeRoleName);
    const mingaInfo = await this._mingaStore.getMingaAsPromise();
    const sisSettings = mingaInfo.sisSettings;

    let userId = person.hash;
    const identityInfo: any = {
      role: person.badgeRoleName,
      company: {
        id: options?.hash || mingaInfo.hash,
        name: options?.name || mingaInfo.name,
        hubspotId: mingaInfo.hubspotId || '',
        districtId: mingaInfo.districtId || '',
        // @TODO: remove sis_type because it's redundant
        sisType: options?.sisType || mingaInfo.sisType,
        ...(options?.featureToggle || mingaInfo.featureToggle),
      },
      login_count: person.loginCount,
      created_at: person.dateCreated,
    };

    if (
      roleType != MingaRoleType.STUDENT &&
      roleType != MingaRoleType.STUDENT_LEADER &&
      roleType != MingaRoleType.PARENT
    ) {
      identityInfo.first_name = person.firstName;
      identityInfo.last_name = person.lastName;
      identityInfo.email = person.email;
      if (roleType === MingaRoleType.OWNER) {
        identityInfo.company.sisUpdateExistingUsers =
          options?.sisUsersExisting || sisSettings?.updateExisting;
        identityInfo.company.sisArchiveMissingUsers =
          options?.sisUsersMissing || sisSettings?.archiveMissing;
      }
    } else {
      // create a separate user for each role per minga
      const prefix = mingaInfo.hash.substring(0, 7);
      userId = prefix + '-' + identityInfo.role;
    }

    try {
      Userpilot.identify(userId, identityInfo);
    } catch (e) {
      console.error('Problem identifying user for Userpilot');
      this._systemAlertSnackBar.open({
        type: 'error',
        message: `Userpilot failed to initialize: ${e}`,
      });
    }
  }

  public openResourceCenter() {
    Userpilot.trigger('resource_center:qDxBnNAgOc');
  }

  public track(event: UserpilotEventType) {}
}
