import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';

import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs';
import {
  debounceTime,
  shareReplay,
  distinctUntilChanged,
  takeUntil,
  switchMap,
  withLatestFrom,
  map,
  take,
} from 'rxjs/operators';
import scrollIntoView from 'scroll-into-view';

import { ConversationNavigator } from 'minga/app/src/app/modules/direct-message/services';
import { MessagingFacade } from 'minga/app/src/app/modules/direct-message/store';
import { MingaPermission } from 'minga/domain/permissions';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { RootService } from 'src/app/minimal/services/RootService';
import {
  OnOverlayClose,
  IOverlayStepper,
  IOverlayConfigurable,
  IOverlayConfig,
} from 'src/app/misc/overlay';
import { Person, PeopleFacadeService } from 'src/app/people';
import { PermissionsService } from 'src/app/permissions';
import { closeCurrentOverlay } from 'src/app/util/overlay';

@Component({
  selector: 'mg-dm-conversation-create',
  templateUrl: './dm-conversation-create.component.html',
  styleUrls: ['./dm-conversation-create.component.scss'],
})
export class DmConversationCreateComponent
  implements
    OnOverlayClose,
    IOverlayStepper,
    OnInit,
    OnDestroy,
    IOverlayConfigurable
{
  searchText = '';
  openPersonNameList = false;
  newPeopleToAdd: Person[] = [];

  readonly searchText$: Observable<string>;
  readonly searchTextDebounced$: Observable<string>;
  private _searchText$ = new BehaviorSubject<string>('');

  private _destroyed$ = new ReplaySubject<void>(1);
  private _skipBlurEvent = false;

  results$: Observable<Person[]> = of([]);
  public isStaff$ = this.permissions.observePermission(
    MingaPermission.DM_CAN_MESSAGE,
  );

  private _onOverlayNextDisabled?: (disabled: boolean) => void;
  private _onOverlayPreviousDisabled?: (disabled: boolean) => void;
  private _onOverlayConfig?: (config: IOverlayConfig) => void;

  @ViewChild('chipList', { static: true, read: ElementRef })
  chipList?: ElementRef;

  constructor(
    public permissions: PermissionsService,
    private _router: Router,
    private _media: MediaObserver,
    private _rootService: RootService,
    private _messagingFacade: MessagingFacade,
    private _conversationNavigator: ConversationNavigator,
    private _peopleFacade: PeopleFacadeService,
    private _authInfoService: AuthInfoService,
  ) {
    this.searchText$ = this._searchText$.asObservable();
    this.searchTextDebounced$ = this.searchText$.pipe(
      debounceTime(300),
      shareReplay(1),
      distinctUntilChanged(),
    );
  }

  registerOnOverlayConfig(fn: (config: IOverlayConfig) => void) {
    this._onOverlayConfig = fn;

    this._onOverlayConfig({
      disableOverlayPulldown: true,
    });
  }

  ngOnInit() {
    if (window.MINGA_DEVICE_IOS) {
      this._media
        .asObservable()
        .pipe(takeUntil(this._destroyed$))
        .subscribe(mediaChanges => {
          const mediaChange = mediaChanges[0];

          // if iPhone, allow blur event for them to
          // close their keyboard
          this._skipBlurEvent = mediaChange.mqAlias === 'xs';
        });
    }

    this.results$ = this.searchTextDebounced$.pipe(
      switchMap(search => {
        return this._peopleFacade.searchPeople(search).pipe(
          withLatestFrom(this._authInfoService.authPerson$),
          map(([people, authPerson]) => {
            // filter out current user
            return people.filter(person => person.hash !== authPerson?.hash);
          }),
        );
      }),
      shareReplay(1),
    );

    this._checkNextDisabled();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public isDisabled(person: Person) {
    return (
      !person.receivingDirectMessages ||
      person.directMessagesPersonallyDisabled ||
      person.directMessagesMingaDisabled
    );
  }

  isUserHashAdded(userHash: string) {
    const personHashes = this.newPeopleToAdd.map(person => person.hash);
    return personHashes.includes(userHash);
  }

  registerOnOverlayNextDisabled(fn: (disabled: boolean) => void) {
    this._onOverlayNextDisabled = fn;
    this._checkNextDisabled();
  }

  registerOnOverlayPreviousDisabled(fn: (disabled: boolean) => void) {
    this._onOverlayPreviousDisabled = fn;
  }

  onChipKeydown(ev: KeyboardEvent, person: Person, index: number) {
    if (ev.key === 'Backspace') {
      this.onRemovePerson(person, index);
    }
  }

  async onSearchChange(text: string) {
    this._searchText$.next(text);
  }

  closePersonList() {
    this.searchText = '';
    this.openPersonNameList = false;
    this.onSearchChange('');
  }

  onSearchInputFocus(ev: any) {
    this.openPersonNameList = true;
  }

  onSearchInputBlur(ev: any) {
    if (!this._skipBlurEvent) {
      this.closePersonList();
    }
  }

  private _scrollMatChipList() {
    setTimeout(() => {
      if (
        this.chipList?.nativeElement &&
        this.chipList?.nativeElement.firstChild?.children.length
      ) {
        const lastChild =
          this.chipList.nativeElement.firstChild.children[
            this.chipList.nativeElement.firstChild.children.length - 1
          ];

        if (lastChild) {
          scrollIntoView(lastChild, {
            time: 0,
          });
        }
      }
    });
  }

  selectPersonClick(ev: MouseEvent, personToAdd: Person) {
    const disabled =
      !personToAdd.receivingDirectMessages ||
      personToAdd.directMessagesPersonallyDisabled ||
      personToAdd.directMessagesMingaDisabled;
    const isStaff = this.permissions.hasPermission(
      MingaPermission.DM_CAN_MESSAGE,
    );

    // don't add if user is 'disabled'
    if (disabled && !isStaff) {
      return;
    }

    const currentIndex = this.newPeopleToAdd.findIndex(
      m => m.hash === personToAdd.hash,
    );

    // check if already added
    if (currentIndex === -1) {
      this.newPeopleToAdd.push(personToAdd);
      this._scrollMatChipList();
      this._checkNextDisabled();
    } else {
      this.onRemovePerson(ev, currentIndex);
    }

    // reset search
    this.closePersonList();
  }

  onRemovePerson(ev: any, index: number) {
    this.newPeopleToAdd.splice(index, 1);

    this._checkNextDisabled();
  }

  async onOverlayClose() {
    await closeCurrentOverlay(this._router);
    return true;
  }

  get overlayPrevious() {
    return {
      icon: 'close',
      text: '',
    };
  }

  hasOverlayNext() {
    return {
      text: 'Add these members',
      icon: 'publish-o',
    };
  }

  private _checkNextDisabled() {
    if (this._onOverlayNextDisabled) {
      this._onOverlayNextDisabled(!this.newPeopleToAdd.length);
    }
  }

  async onOverlayNext() {
    if (this._onOverlayNextDisabled) {
      this._onOverlayNextDisabled(true);
    }

    await this.submit();

    if (this._onOverlayNextDisabled) {
      this._onOverlayNextDisabled(false);
    }
  }

  hasOverlayPrevious() {
    return this.overlayPrevious;
  }

  async onOverlayPrevious() {
    await this.onOverlayClose();
  }

  private async _startConversation() {
    const personHashes = this.newPeopleToAdd.map(person => person.hash);
    const conv = await this._messagingFacade
      .startConversation(personHashes)
      .pipe(take(1))
      .toPromise();
    await this._conversationNavigator.navigateByConversationId(conv.id);
  }

  async submit() {
    await this._rootService.addLoadingPromise(this._startConversation());
  }
}
