import { InjectionToken } from '@angular/core';

import * as day from 'dayjs';

import { IBellSchedule } from 'minga/domain/bellSchedule';

import { ScheduleId } from '@modules/minga-manager/components/mm-bell-schedule/types/mm-bell-schedule.types';

import { UpdateListCacheParams } from '../cache/cache.utils';

/** Define the interface token for the dependdency injection provider. */
export const BellSchedulesInterface = new InjectionToken<BellSchedulesService>(
  'BellSchedulesService',
);

/**
 * Bell Schedules
 *
 * CRUD methods for bell schedules.
 */
export interface BellSchedulesService {
  /**
   * Fetch all schedules
   */
  fetchAll(opts?: { revalidate?: boolean }): Promise<IBellSchedule[]>;

  /**
   * Get schedule with the specified id. It's possible the schedule does not
   * exist for the specified id.
   *
   * @throws TODO: exception type
   */
  get(id: ScheduleId): Promise<IBellSchedule>;

  /** Upsert a schedule */
  upsert(schedule: Partial<IBellSchedule>): Promise<IBellSchedule>;

  duplicate(schedule: IBellSchedule): Promise<IBellSchedule>;

  overrideScheduleForDate(
    id: ScheduleId | null,
    date: day.Dayjs,
    termId: number,
  ): Promise<void>;

  /**
   * Delete the specified schedule with id. It's possible the schedule does not
   * exist for the specified id.
   *
   * @throws TODO: exception type
   */
  delete(id: ScheduleId): Promise<void>;

  /** Update the cache. */
  updateListCache(
    opts: UpdateListCacheParams<IBellSchedule>,
  ): Promise<IBellSchedule[]>;
}
