<mat-table
  matSort
  multiTemplateDataRows
  [dataSource]="dataSource"
  [trackBy]="trackById">
  <!-- INDICATOR -->
  <ng-container [matColumnDef]="COLUMN_DEF.INDICATOR">
    <mat-header-cell *matHeaderCellDef>
      {{ MESSAGES.COLUMN_LABEL_INDICATOR }}
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <div
        class="indicator"
        [ngClass]="'indicator-' + STATUS_COLOR_MAP[pass.status.state]"></div>
    </mat-cell>
  </ng-container>
  <!-- STATUS -->
  <ng-container [matColumnDef]="COLUMN_DEF.STATUS">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        class="column-header"
        textAlign="left"
        variant="body-xs-secondary">
        {{ MESSAGES.COLUMN_LABEL_STATUS }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <mg-tag [color]="STATUS_COLOR_MAP[pass.status.state]">
        {{ pass.status.state }}
      </mg-tag>
    </mat-cell>
  </ng-container>
  <!-- TIMER -->
  <ng-container [matColumnDef]="COLUMN_DEF.TIMER">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        class="column-header"
        textAlign="left"
        variant="body-xs-secondary">
        {{ MESSAGES.COLUMN_LABEL_TIMER }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <ng-container
        [ngTemplateOutlet]="passTimerTemplate"
        [ngTemplateOutletContext]="{ $implicit: pass }">
      </ng-container>
    </mat-cell>
  </ng-container>
  <!-- RECIPIENT_PICTURE -->
  <ng-container [matColumnDef]="COLUMN_DEF.RECIPIENT_PICTURE">
    <mat-header-cell *matHeaderCellDef>
      {{ MESSAGES.COLUMN_LABEL_RECIPIENT_PICTURE }}
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <div
        class="picture-container"
        [mgStudentPicture]="pass.recipientStudentIdFilename"></div>
    </mat-cell>
  </ng-container>
  <!-- RECIPIENT_NAME -->
  <ng-container [matColumnDef]="COLUMN_DEF.RECIPIENT_NAME">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        class="column-header"
        textAlign="left"
        variant="body-xs-secondary">
        {{ MESSAGES.COLUMN_LABEL_RECIPIENT_NAME }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <mg-text
        variant="body-md"
        textAlign="left"
        [numberOfLines]="2">
        {{ pass.recipientPersonView?.displayName }}
      </mg-text>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="COLUMN_DEF.CURRENT_CLASS">
    <mat-header-cell *matHeaderCellDef>
      <mg-text
        class="column-header"
        textAlign="left"
        variant="body-xs-secondary">
        {{ MESSAGES.COLUMN_LABEL_CURRENT_CLASS }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <div
        *ngIf="
          getPassCurrentClass(pass) as currentClass;
          else emptyValueTemplate
        ">
        <mg-text
          variant="body-md"
          textAlign="left"
          [numberOfLines]="2">
          {{ currentClass }}
        </mg-text>
      </div>
    </mat-cell>
  </ng-container>
  <!-- PASS_TYPE -->
  <ng-container [matColumnDef]="COLUMN_DEF.PASS_TYPE">
    <mat-header-cell *matHeaderCellDef>
      <mg-text
        class="column-header full-width"
        textAlign="left"
        variant="body-xs-secondary"
        textAlign="center">
        {{ MESSAGES.COLUMN_LABEL_PASS_TYPE }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <ng-container *ngIf="(typesData$ | async)[pass.typeId] as type">
        <div class="pass-type">
          <mg-icon
            class="pass-type-icon"
            matTooltipClass="mg-tooltip"
            [iconName]="type.bannerHash || 'hallpass'"
            [matTooltip]="type.name"
            [style.color]="type.color">
          </mg-icon>
        </div>
      </ng-container>
    </mat-cell>
  </ng-container>
  <!-- NOTES -->
  <ng-container [matColumnDef]="COLUMN_DEF.NOTES">
    <mat-header-cell *matHeaderCellDef>
      <mg-text
        class="column-header full-width"
        variant="body-xs-secondary"
        textAlign="center">
        {{ MESSAGES.COLUMN_LABEL_NOTES }}
      </mg-text>
    </mat-header-cell>
    <mat-cell
      *matCellDef="let pass"
      column-def="notes">
      <ng-container
        *ngIf="pass.note || pass.noteToRequester; else emptyValueTemplate">
        <mg-tooltip
          class="note-icon"
          icon="mg-description"
          size="medium"
          [label]="getNote(pass.note, pass.noteToRequester)">
        </mg-tooltip>
      </ng-container>
    </mat-cell>
  </ng-container>
  <!-- START_TIME -->
  <ng-container [matColumnDef]="COLUMN_DEF.START_TIME">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        class="column-header"
        textAlign="left"
        variant="body-xs-secondary"
        [numberOfLines]="1">
        {{ MESSAGES.COLUMN_LABEL_START_TIME }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <div
        *ngIf="
          pass.status.start | date: 'h:mm aaaaa\'m\'' as time;
          else emptyValueTemplate
        ">
        <mg-text
          variant="body-md"
          textAlign="left"
          [numberOfLines]="1">
          {{ time }}
        </mg-text>
      </div>
    </mat-cell>
  </ng-container>
  <!-- END_TIME -->
  <ng-container [matColumnDef]="COLUMN_DEF.END_TIME">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        class="column-header"
        textAlign="left"
        variant="body-xs-secondary"
        [numberOfLines]="1">
        {{ MESSAGES.COLUMN_LABEL_END_TIME }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <div
        *ngIf="
          pass.status.state !== PASS_STATUS.ACTIVE &&
            pass.status.state !== PASS_STATUS.OVERDUE;
          else emptyValueTemplate
        ">
        <mg-text
          variant="body-md"
          textAlign="left"
          [numberOfLines]="1">
          {{ pass | hpmEndTime | date: "h:mm aaaaa'm'" }}
        </mg-text>
      </div>
    </mat-cell>
  </ng-container>
  <!-- AUTHOR_NAME -->
  <ng-container [matColumnDef]="COLUMN_DEF.AUTHOR_NAME">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        class="column-header"
        textAlign="left"
        variant="body-xs-secondary">
        {{ MESSAGES.COLUMN_LABEL_AUTHOR_NAME }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <mg-text
        variant="body-md"
        textAlign="left"
        [numberOfLines]="2">
        <ng-container *ngIf="pass?.approvalDate; else emptyValueTemplate">
          {{
            pass?.approvedByPersonView?.displayName ||
              pass?.authorPersonView?.displayName
          }}
        </ng-container>
      </mg-text>
    </mat-cell>
  </ng-container>
  <!-- ENDED_BY_NAME -->
  <ng-container [matColumnDef]="COLUMN_DEF.ENDED_BY_NAME">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        class="column-header"
        textAlign="left"
        variant="body-xs-secondary">
        {{ MESSAGES.COLUMN_LABEL_ENDED_BY }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <mg-text
        variant="body-md"
        textAlign="left"
        [numberOfLines]="2">
        <ng-container
          *ngIf="
            pass.status.state === PASS_STATUS.ENDED;
            else emptyValueTemplate
          ">
          {{
            pass.endedByPersonView?.displayName
              ? pass.endedByPersonView.displayName
              : MESSAGES.COLUMN_LABEL_END_AUTOMATIC
          }}
        </ng-container>
      </mg-text>
    </mat-cell>
  </ng-container>
  <!-- ACTIONS -->
  <ng-container [matColumnDef]="COLUMN_DEF.ACTIONS">
    <mat-header-cell *matHeaderCellDef>
      {{ MESSAGES.COLUMN_LABEL_ACTIONS }}
    </mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <ng-container
        [ngTemplateOutlet]="passActionsTemplate"
        [ngTemplateOutletContext]="{ $implicit: pass }">
      </ng-container>
    </mat-cell>
  </ng-container>
  <!-- MOBILE -->
  <ng-container [matColumnDef]="COLUMN_DEF.MOBILE">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let pass">
      <ng-container
        [ngTemplateOutlet]="mobilePanelTemplate"
        [ngTemplateOutletContext]="{ $implicit: pass }">
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Mat Row -->
  <mat-header-row
    *matHeaderRowDef="displayedColumns$ | async"
    [fxHide]="!(filteredData$ | async)?.length">
  </mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns$ | async"
    class="row-container"
    [class.is-overdue]="row.status.state === PASS_STATUS.OVERDUE"
    [class.mobile-card-container]="(media$ | async) === 'xs'"
    [fxHide]="hpmDashboard.isLoading$ | async">
  </mat-row>
</mat-table>

<!-- Empty State -->
<div
  *ngIf="
    !(filteredData$ | async)?.length &&
    (hpmDashboard.isLoading$ | async) === false
  "
  class="empty-state-container">
  <mg-empty-state
    [title]="MESSAGES.EMPTY_STATE_TITLE"
    [subtitle]="MESSAGES.EMPTY_STATE_SUBTITLE">
  </mg-empty-state>
</div>

<!-- Empty Value Template -->
<ng-template #emptyValueTemplate>
  <div class="empty-value"></div>
</ng-template>

<!-- Loading -->
<div
  *ngIf="
    (hpmDashboard.isLoading$ | async) && !(hpmDashboard.passes$ | async)?.length
  "
  class="loading-container">
  <mg-spinner
    [diameter]="40"
    [thickness]="3">
  </mg-spinner>
</div>

<!-- Pagination -->
<mat-paginator
  [pageSizeOptions]="[100, 50, 20]"
  showFirstLastButtons>
</mat-paginator>

<!-- Pass Actions Template -->
<ng-template
  #passActionsTemplate
  let-pass>
  <div
    *ngIf="'HALL_PASS_MANAGE' | hasPermission"
    class="pass-actions-container">
    <ng-container *ngIf="pass.status.state === PASS_STATUS.SCHEDULED">
      <mg-btn
        variant="outlined"
        size="small"
        [id]="'hallpass-dashboard-click-cancel'"
        (click)="passActions.cancel(pass.id)">
        {{ MESSAGES.BUTTON_CANCEL }}
      </mg-btn>
      <mg-btn
        variant="filled"
        size="small"
        [id]="'hallpass-dashboard-click-start'"
        (click)="passActions.start(pass.id)">
        {{ MESSAGES.BUTTON_START }}
      </mg-btn>
    </ng-container>
    <ng-container *ngIf="(typesData$ | async)[pass.typeId] as type">
      <mg-btn
        *ngIf="
          pass.status.state === PASS_STATUS.ACTIVE ||
          pass.status.state === PASS_STATUS.OVERDUE
        "
        variant="outlined"
        size="small"
        [id]="'hallpass-dashboard-click-end'"
        (click)="passActions.end(pass, type)">
        {{ MESSAGES.BUTTON_END }}
      </mg-btn>
    </ng-container>

    <ng-container
      *ngIf="
        pass.status.state === PASS_STATUS.PENDING_APPROVAL &&
        ((isHallPassAdmin$ | async) ||
          (authHash$ | async) === pass.authorPersonView?.personHash)
      ">
      <ng-container *ngIf="(typesData$ | async)[pass.typeId] as type">
        <mg-btn
          variant="outlined"
          size="small"
          [id]="'hallpass-dashboard-click-deny'"
          (click)="passActions.showPendingApprovalDialog(pass, type)">
          {{ MESSAGES.BUTTON_DENY }}
        </mg-btn>
        <mg-btn
          variant="filled"
          size="small"
          [id]="'hallpass-dashboard-click-approve'"
          (click)="passActions.showPendingApprovalDialog(pass, type)">
          {{ MESSAGES.BUTTON_APPROVE }}
        </mg-btn>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- Inline Loading Spinner -->
<ng-template #inlineLoadingSpinnerTemplate>
  <div class="inline-spinner">
    <mat-spinner
      diameter="20"
      strokeWidth="2">
    </mat-spinner>
  </div>
</ng-template>

<!-- Pass Timer Template -->
<ng-template
  #passTimerTemplate
  let-pass>
  <ng-container *ngIf="(typesData$ | async)[pass.typeId] as type">
    <mg-icon
      *ngIf="showIcon(type) && pass.status.state == PASS_STATUS.ACTIVE"
      matTooltip="Automatically ended"
      matTooltipClass="mg-tooltip"
      class="auto-end-icon"
      iconName="mg-auto-end">
    </mg-icon>
    <ng-container
      *ngIf="
        pass
          | countdown
            : (recentlyEndedSetting$ | async)
            : (manuallyEndedPassesSetting$ | async)
            : hpmDashboard.timeTicker$
            : type.manuallyEndPass
          | async as countdown;
        else inlineLoadingSpinnerTemplate
      ">
      <mg-text
        *ngIf="pass.status.state === PASS_STATUS.SCHEDULED"
        textAlign="left"
        [variant]="
          MOBILE_BREAKPOINTS.includes(media$ | async) ? 'body-xs' : 'body-sm'
        "
        [numberOfLines]="1">
        {{ pass.status.start | date: 'MM/dd' }}
      </mg-text>
      <mg-text
        *ngIf="countdown !== 'DISABLED'"
        textAlign="left"
        [numberOfLines]="1"
        [variant]="
          MOBILE_BREAKPOINTS.includes(media$ | async) ? 'body-xs' : 'body-sm'
        ">
        {{ countdown }}
      </mg-text>
    </ng-container>
  </ng-container>
</ng-template>

<!-- Mobile Panel Template -->
<ng-template
  #mobilePanelTemplate
  let-pass>
  <mat-expansion-panel
    hideToggle
    class="mobile-panel"
    [class.is-overdue]="pass.status.state === PASS_STATUS.OVERDUE"
    (opened)="mobilePanelState.next('opened')"
    (closed)="mobilePanelState.next('closed')">
    <mat-expansion-panel-header class="mobile-header">
      <div
        class="indicator"
        [ngClass]="'indicator-' + STATUS_COLOR_MAP[pass.status.state]"></div>
      <mat-panel-title>
        <div
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="4px"
          [style.width.%]="100">
          <div
            fxLayout="row"
            fxLayoutAlign="start start"
            fxLayoutGap="8px"
            [style.width.%]="100">
            <div
              fxFlex="none"
              class="picture-container"
              [mgStudentPicture]="pass.recipientStudentIdFilename"></div>
            <div
              fxFlex="noshrink"
              fxLayout="column"
              fxLayoutAlign="start start">
              <mg-text
                variant="header-xs-secondary"
                spacing="1">
                {{ pass.recipientPersonView?.displayName }}
              </mg-text>
              <div
                *ngIf="(typesData$ | async)[pass.typeId] as type"
                fxLayout="row"
                fxLayoutAlign="start center">
                <mg-icon
                  class="pass-type-icon"
                  [iconName]="type.bannerHash || 'hallpass'"
                  [style.color]="type.color">
                </mg-icon>
                <mg-text
                  variant="label-md"
                  textAlign="left"
                  [numberOfLines]="1">
                  {{ type.name }}
                </mg-text>
              </div>
            </div>
            <div
              fxFlex="none"
              fxLayout="column"
              fxLayoutAlign="start end"
              fxLayoutGap="4px">
              <mg-tag [color]="STATUS_COLOR_MAP[pass.status.state]">
                {{ pass.status.state }}
              </mg-tag>
              <ng-container
                [ngTemplateOutlet]="passTimerTemplate"
                [ngTemplateOutletContext]="{ $implicit: pass }">
              </ng-container>
            </div>
          </div>
          <!-- Actions -->
          <ng-container
            [ngTemplateOutlet]="passActionsTemplate"
            [ngTemplateOutletContext]="{ $implicit: pass }">
          </ng-container>
        </div>
      </mat-panel-title>
      <div
        class="mobile-toggle"
        [class.is-open]="(mobilePanelState | async) === 'opened'">
        <mg-icon>mg-toggle-up</mg-icon>
      </div>
    </mat-expansion-panel-header>
    <ng-container
      [ngTemplateOutlet]="mobileBodyTemplate"
      [ngTemplateOutletContext]="{ $implicit: pass }">
    </ng-container>
  </mat-expansion-panel>
</ng-template>

<!-- Mobile Expanded Panel Template -->
<ng-template
  #mobileBodyTemplate
  let-pass>
  <div
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="4px"
    class="mobile-body">
    <!-- Start Time -->
    <ng-container
      *ngIf="['Overdue', 'Active', 'Scheduled'].includes(pass.status.state)"
      [ngTemplateOutlet]="mobileTimeTemplate"
      [ngTemplateOutletContext]="{
        $implicit: 'Start time',
        time: pass.status.start
      }"></ng-container>
    <!-- End Time -->
    <ng-container
      *ngIf="pass.status.state === 'Ended'"
      [ngTemplateOutlet]="mobileTimeTemplate"
      [ngTemplateOutletContext]="{
        $implicit: 'End time',
        time: pass.status.end
      }"></ng-container>
    <!-- Approved By -->
    <ng-container
      *ngIf="
        pass.status.state !== 'Pending Approval' &&
        pass.authorPersonView?.displayName as approver
      "
      [ngTemplateOutlet]="mobilePersonTemplate"
      [ngTemplateOutletContext]="{
        $implicit: 'Approved by',
        name: approver
      }"></ng-container>
    <!-- Ended By -->
    <ng-container
      *ngIf="pass.status.state === 'Ended'"
      [ngTemplateOutlet]="mobilePersonTemplate"
      [ngTemplateOutletContext]="{
        $implicit: 'Ended by',
        name: pass.endedByPersonView?.displayName
      }"></ng-container>
    <!-- Note -->
    <ng-container *ngIf="pass.note as note">
      <div
        [style.width.%]="100"
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <mg-text
          class="surface-label"
          variant="body-xs"
          [numberOfLines]="1">
          Note
        </mg-text>
        <mg-btn
          icon="description"
          variant="icon"
          size="small"
          [matTooltip]="note">
        </mg-btn>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- Mobile Time Template -->
<ng-template
  #mobileTimeTemplate
  let-title
  let-time="time">
  <div
    class="mobile-body-spacing"
    fxLayout="row"
    fxLayoutAlign="space-between"
    [style.width.%]="100">
    <mg-text
      class="surface-label"
      variant="body-xs"
      [numberOfLines]="1">
      {{ title }}
    </mg-text>
    <mg-text
      variant="body-xs"
      [numberOfLines]="1">
      {{ time | date: "h:mm aaaaa'm'" }}
    </mg-text>
  </div>
</ng-template>

<!-- Mobile Person Template -->
<ng-template
  #mobilePersonTemplate
  let-title
  let-name="name">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between"
    [style.width.%]="100">
    <mg-text
      class="surface-label"
      variant="body-xs"
      [numberOfLines]="1">
      {{ title }}
    </mg-text>
    <mg-text
      variant="body-xs"
      [numberOfLines]="1">
      {{ name }}
    </mg-text>
  </div>
</ng-template>
