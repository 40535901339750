<!-- Subpage Header -->
<div
  *ngIf="!loading"
  class="subpage-header white-bg">
  <ng-container *ngIf="!subpageHeader; else customHeader">
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="start start"
      fxLayoutAlign.lt-sm="start stretch"
      fxLayoutGap="15px">
      <div
        *ngIf="headerLeft"
        fxFlex="grow">
        <ng-container *ngTemplateOutlet="headerLeft"></ng-container>
      </div>
      <div
        *ngIf="headerRight"
        fxFlex.gt-sm="noshrink"
        fxFlex.lt-sm="grow"
        [fxFlexAlign.gt-xs]="headerRightAlign">
        <ng-container *ngTemplateOutlet="headerRight"></ng-container>
      </div>
    </div>
    <div *ngIf="headerBottom">
      <ng-container *ngTemplateOutlet="headerBottom"></ng-container>
    </div>
  </ng-container>
  <ng-template #customHeader>
    <div>
      <ng-container *ngTemplateOutlet="subpageHeader"></ng-container>
    </div>
  </ng-template>
</div>

<!-- Subpage Content -->
<div
  class="subpage-content"
  [class.no-bottom-padding]="noBottomPadding"
  [class.center-content]="loading">
  <ng-container *ngIf="loading; else subpageContentTemplate">
    <div class="loading-container">
      <mg-spinner
        [diameter]="60"
        [thickness]="3">
      </mg-spinner>
    </div>
  </ng-container>
  <ng-template #subpageContentTemplate>
    <ng-content></ng-content>
  </ng-template>
</div>
