import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { $enum } from 'ts-enum-util';

import { MingaPermission } from 'minga/domain/permissions';
import { PermissionsService } from 'src/app/permissions';

@Injectable()
export class PermissionsGuard implements CanActivate {
  constructor(
    private _permissions: PermissionsService,
    private _router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): boolean | UrlTree {
    if (!route.data || !route.data.permissions) {
      return true;
    }

    const permissions = route.data.permissions;

    if (permissions.only) {
      if (this._permissions.hasPermission(permissions.only)) {
        return true;
      }
    }

    if (permissions.except) {
      if (this._permissions.hasPermission(permissions.except)) {
        return false;
      }
    }

    if (Array.isArray(permissions.atLeastOne)) {
      const perms = permissions.atLeastOne.map((p: any) =>
        $enum(MingaPermission).asValueOrThrow(p),
      );
      let hasAtleastOnePerm = true;

      for (const perm of perms) {
        hasAtleastOnePerm = this._permissions.hasPermission(perm);

        if (hasAtleastOnePerm) break;
      }
      return hasAtleastOnePerm;
    }

    if (permissions.redirect) {
      const redirect =
        typeof permissions.redirect === 'string'
          ? [permissions.redirect]
          : permissions.redirect;
      return this._router.createUrlTree(redirect);
    }

    return false;
  }
}
