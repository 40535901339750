import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Store } from '@ngrx/store';

import { setPreAuthRoute } from 'minga/app/src/app/store/AuthStore/actions';
import { MingaRoleType } from 'minga/domain/permissions';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

import { KioskRoute } from '@modules/kiosk';

/**
 * Guard to ensure that the user is authenticated and has a token.
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authInfo: AuthInfoService,
    private _store$: Store<any>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    if (!this._authInfo.authInfo) {
      this._store$.dispatch(setPreAuthRoute({ preAuthUrl: state.url }));
      return this._router.createUrlTree(['/landing']);
    }
    if ((this._authInfo.authInfo as any)?.role === MingaRoleType.KIOSK) {
      const urlSegments = route.url.map(segment => segment.path);
      if (urlSegments[0] !== KioskRoute.ROOT)
        return this._router.createUrlTree(['/', KioskRoute.ROOT]);
    }
    return true;
  }
}
