import { Validators } from '@angular/forms';

import * as day from 'dayjs';

import { Term } from 'minga/domain/term/Term.domain';

import * as formUtils from '@shared/components/crud-form-base/crud-form-base.utils';
import { FormConfig } from '@shared/components/crud-form-base/crud-form-base.types';

export enum TERMS_MESSAGES {
  HEADER = 'Terms',
  ADD_TERM = 'Create term',
  NO_RESULTS_TITLE = 'No term found',
  NO_RESULTS_SUBTITLE = 'Your terms will be displayed here',
  NO_SCHEDULES = 'Create your first bell schedule on the schedule page',
  NO_SCHEDULES_BUTTON = 'Go to schedule',
  SCHEDULE_ERRORS = 'We detected some errors with your bell schedules.',
  SCHEDULE_ERRORS_BUTTON = 'View Errors',
  MISSING_TERMS = 'Terms could not be imported from your SIS. Please check your rostering settings',
  MISSING_TERMS_BTN = 'Fix issue',
  TABLE_SHORT_CODE_HINT = 'Must match your SIS. This can be named differently in your SIS (ID)',

  MODAL_TITLE_ADD = 'Add term',
  MODAL_TITLE_EDIT = 'Edit term',
  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_CREATING = 'Creating',
  BUTTON_LABEL_UPDATE = 'Save',
  BUTTON_LABEL_UPDATING = 'Saving',

  FIELD_LABEL_SHORT_CODE = 'Short code',
  FIELD_LABEL_NAME = 'Term name',
  FIELD_LABEL_SCHEDULE = 'Bell schedule',
  FIELD_LABEL_SHORT_CODE_HINT = 'Must match SIS',

  FIELDSET_SCHEDULE_TITLE = 'Bell schedule',
  FIELDSET_SCHEDULE_DESC = 'Select schedules to apply to this term',
  // FIELDSET_SCHEDULE_TOOLTIP = 'Select which term(s) will be using this schedule',

  ERROR_GENERIC = 'Please complete the missing information',

  UPDATE_ERROR = 'There was an error updating the term',
  CREATE_ERROR = 'There was an error creating the term',
  DELETE_ERROR = 'There was an error deleting the term',
  GET_ERROR = 'There was an problem fetching the term',
  ERROR_LOADING_DATA = 'There was an error fetching terms',

  ERROR_NO_OVERLAPPING_TERMS_DATE_RANGE_ERROR = 'Date cannot overlap with another term',
  ERROR_NO_OVERLAPPING_TERMS_SCHEDULE_ERROR = "Two schedules can't use the same days",

  DELETE_CONFIRM_TITLE = "Are you sure you want to delete this term, this can't be undone!",
  DELETE_CONFIRM_DISCARD_TITLE = 'Are you sure you want to discard this term?',
  DELETE_CONFIRM_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_DISCARD_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_CANCEL_BTN = 'Cancel',
  DELETE_CONFIRM_DELETE_BTN = 'Delete',
  DELETE_CONFIRM_DISCARD_BTN = 'Discard',

  SNACKBAR_CREATE_SUCCESS = 'Term created',
  SNACKBAR_UPDATE_SUCCESS = 'Term updated',
  SNACKBAR_DELETE_SUCCESS = 'Term deleted',
}

/** Terms Table */
export enum BS_TERMS_COLUMNS {
  CODE = 'code',
  TERM = 'term',
  START = 'start',
  END = 'end',
  BELL = 'bell',
  EDIT = 'edit',
  MOBILE = 'mobile',
}

export enum BS_TERMS_COLUMN_LABELS {
  CODE = 'Short Code',
  TERM = 'Term Name',
  START = 'Start Date',
  END = 'End Date',
  BELL = 'Bell Schedules',
  EDIT = '',
  MOBILE = '',
}

export const BSTermColumns = [
  BS_TERMS_COLUMNS.CODE,
  BS_TERMS_COLUMNS.TERM,
  BS_TERMS_COLUMNS.START,
  BS_TERMS_COLUMNS.END,
  BS_TERMS_COLUMNS.BELL,
  BS_TERMS_COLUMNS.EDIT,
];

export enum TERM_FORM_FIELDS {
  SHORT_CODE = 'sourcedId',
  TITLE = 'title',
  DATE_RANGE = 'dateRange',
  BELL_SCHEDULE_IDS = 'bellScheduleIds',
}

// form helpers dont have support for nested form arrays yet
const FORM: FormConfig<TERM_FORM_FIELDS, Term> = {
  [TERM_FORM_FIELDS.SHORT_CODE]: [null, []],
  [TERM_FORM_FIELDS.TITLE]: [null, [Validators.required]],
  [TERM_FORM_FIELDS.BELL_SCHEDULE_IDS]: {
    key: TERM_FORM_FIELDS.BELL_SCHEDULE_IDS,
    formControl: [null],
    set: data => {
      return (data.bellSchedules || []).map(s => s.id);
    },
  },
  [TERM_FORM_FIELDS.DATE_RANGE]: {
    start: {
      key: 'start',
      formControl: [null, [Validators.required]],
      set: data => {
        return data.startDate ? day(data.startDate) : null;
      },
      get: data => {
        return data.dateRange.start?.toDate() || null;
      },
    },
    end: {
      key: 'end',
      formControl: [null, [Validators.required]],
      set: data => {
        return data.endDate ? day(data.endDate) : null;
      },
      get: data => {
        return data.dateRange.end?.toDate() || null;
      },
    },
  },
};

export const createForm = formUtils.createForm(FORM);
export const setForm = formUtils.setForm(FORM);
export const getData = formUtils.getData(FORM);
