import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IConsequenceSummaryResult } from 'minga/domain/pbis';
import {
  ColumnInfo,
  TemplateColumnKeys,
  BehaviorReportColumns,
} from 'minga/libraries/shared/reports_columns';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';

import { BHConsequenceTypeHistoryInfo } from '../constants';
import { BmReportsService } from './bm-reports.service';

@Injectable()
export class BmReportsConsequenceTypesDatasourceService extends ReportDatasourceService<IConsequenceSummaryResult> {
  historyInfo = BHConsequenceTypeHistoryInfo;

  constructor(_router: Router, private _pbisReportsService: BmReportsService) {
    super(
      _router,
      _pbisReportsService,
      BmReportsConsequenceTypesDatasourceService.name,
    );
    const displayCol: ColumnInfo[] = [
      {
        key: TemplateColumnKeys.HISTORY,
        header: 'History',
        columnClasses: ['w-16'],
      },
    ];
    super.setDisplayColumns(
      BehaviorReportColumns.CONSEQUENCE_TYPES,
      displayCol,
    );
  }

  async fetch(offset: number, limit: number) {
    return await this._pbisReportsService.getConsequenceTypes(
      offset,
      limit,
      this._currentSort,
    );
  }
}
