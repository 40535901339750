import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { MingaPermission } from 'minga/domain/permissions';
import { PermissionsService } from 'src/app/permissions';

import { MingaManagerRoutes } from '../constants';

@Injectable()
export class MingaManagerRedirectGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _permissionsService: PermissionsService,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const hasFullAccess = this._permissionsService.hasPermission(
      MingaPermission.MINGA_ACCOUNT_MANAGE,
    );

    if (!hasFullAccess) {
      // Redirect to subscription page
      this._router.navigate([
        MingaManagerRoutes.ROOT,
        MingaManagerRoutes.SUBSCRIPTION,
      ]);
    }

    return hasFullAccess;
  }
}
