<ng-container *ngIf="tags?.length > 0">
  <!-- we use this invisible container to calculate the width of the tags -->
  <div
    class="tag-wrap invisible"
    #container>
    <mg-tag
      *ngFor="let tag of tags"
      [type]="type"
      [color]="color"
      [size]="size"
      >{{ tag }}</mg-tag
    >
  </div>

  <!-- this is the actual visible rendered tags -->
  <div
    class="tag-wrap"
    [style.gap.px]="FLEX_GAP"
    [ngClass]="{ 'no-wrap': maxLines === 1 }"
    #visibleContainer>
    <mg-tag
      *ngFor="let tag of visibleTags$ | async"
      [type]="type"
      [color]="color"
      [size]="size"
      >{{ tag }}</mg-tag
    >

    <mg-tag
      *ngIf="
        (hiddenTags$ | async).length > 0 || (visibleTags$ | async).length === 0
      "
      [type]="type"
      [color]="color"
      [size]="size"
      matTooltipClass="mg-tooltip"
      [matTooltip]="(hiddenTags$ | async).join(', ')"
      >{{ (hiddenTags$ | async).length }}
      {{
        (visibleTags$ | async).length === 0
          ? SHOW_MORE_ITEMS
          : SHOW_MORE_TRUNCATED
      }}
    </mg-tag>
  </div>
</ng-container>
