import { Pipe, PipeTransform } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { MingaPermission } from 'minga/domain/permissions';
import { PermissionsService } from 'src/app/permissions';

import {
  HALLPASS_MENU_OPTIONS,
  ITeacherToolsMenu,
} from '@modules/teacher-tools/teacher-tools.types';

const PERMISSIONS_BY_TYPE: Record<HALLPASS_MENU_OPTIONS, (perms) => boolean> = {
  [HALLPASS_MENU_OPTIONS.ASSIGN]: perms => perms.canCreate,
  [HALLPASS_MENU_OPTIONS.VIEW]: perms => perms.canManage,
};

@Pipe({
  name: 'filterHpOptions',
})
export class FilterHpOptionsPipe implements PipeTransform {
  constructor(private _permissions: PermissionsService) {}

  transform(
    options: ITeacherToolsMenu[],
    hallPassEnabled: Observable<boolean>,
  ): Observable<ITeacherToolsMenu[]> {
    return combineLatest([
      hallPassEnabled,
      this._permissions.observePermission(MingaPermission.HALL_PASS_CREATE),
      this._permissions.observePermission(MingaPermission.HALL_PASS_MANAGE),
    ]).pipe(
      map(([hpEnabled, canCreate, canManage]) => {
        if (!hpEnabled) return [];
        return options
          .filter(o => PERMISSIONS_BY_TYPE[o.type])
          .filter(o => {
            const { type } = o;

            const hasPermission = PERMISSIONS_BY_TYPE[type];
            return hasPermission({
              canCreate,
              canManage,
            });
          })
          .filter(o => o.label !== 'View Pass Dashboard');
      }),
    );
  }
}
