import { Inject, Injectable, InjectionToken } from '@angular/core';

import { IStorageInstance } from 'minga/app/src/app/util/storage';
import { day } from 'minga/libraries/day';
import { MetadataScraper } from 'minga/proto/gateway/scraper_ng_grpc_pb';
import { MetadataScraperGetRequest } from 'minga/proto/gateway/scraper_pb';

export interface IUrlMetadata {
  /**
   * A audio URL that best represents the article.
   */
  audio: string;

  /**
   * A human-readable representation of the author's name.
   */
  author: string;

  /**
   * An ISO 8601 representation of the date the article was published.
   */
  date: string;

  /**
   * The publisher's chosen description of the article.
   */
  description: string;

  /**
   * A video URL that best represents the article.
   */
  video: string;

  /**
   * An image URL that best represents the article.
   */
  image: string;

  /**
   * An ISO 639-1 representation of the url content language.
   */
  lang: string;

  /**
   * An image URL that best represents the publisher brand.
   */
  logo: string;

  /**
   * A human-readable representation of the publisher's name.
   */
  publisher: string;

  /**
   * The publisher's chosen title of the article.
   */
  title: string;

  /**
   * The URL of the article.
   */
  url: string;

  /**
   * Whether or not this metadata should be hidden and not displayed
   */
  hidden: boolean;
}

export const URL_METADATA_STORAGE = new InjectionToken<
  IStorageInstance<IStoredUrlMetadata>
>('URL_METADATA_STORAGE');

interface IStoredUrlMetadata {
  storedDate: number;
  urlMetadata: IUrlMetadata;
}

@Injectable({ providedIn: 'root' })
export class UrlMetadataService {
  constructor(
    private metadataScraperProto: MetadataScraper,
    @Inject(URL_METADATA_STORAGE)
    private storage: IStorageInstance<IStoredUrlMetadata>,
  ) {}

  async get(url: string): Promise<IUrlMetadata> {
    const storedUrlMetadata = await this.storage.getItem(url);

    if (storedUrlMetadata) {
      const storedDate = day(storedUrlMetadata.storedDate);

      if (storedDate.isAfter(1, 'day')) {
        return storedUrlMetadata.urlMetadata;
      }
    }

    const urlMetadata = await this.fetchUrlMetadata(url);

    await this.storage.setItem(url, {
      storedDate: Date.now(),
      urlMetadata,
    });

    return urlMetadata;
  }

  private async fetchUrlMetadata(url: string): Promise<IUrlMetadata> {
    const request = new MetadataScraperGetRequest();
    request.setUrl(url);
    const response = await this.metadataScraperProto.get(request);

    const hidden = response.getHidden();
    const siteMetadata = response.getSiteMetadata();
    if (!siteMetadata) {
      throw new Error('Server responded without site metadata');
    }

    return {
      audio: siteMetadata.getAudio(),
      author: siteMetadata.getAuthor(),
      date: siteMetadata.getDate(),
      description: siteMetadata.getDescription(),
      image: siteMetadata.getImage(),
      lang: siteMetadata.getLang(),
      logo: siteMetadata.getLogo(),
      publisher: siteMetadata.getPublisher(),
      title: siteMetadata.getTitle(),
      url: siteMetadata.getUrl(),
      video: siteMetadata.getVideo(),
      hidden,
    };
  }
}
