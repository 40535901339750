import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';

import { FileDownloaderService } from 'minga/app/src/app/file';
import { IFileDownloadStatus } from 'minga/app/src/app/file/public_interfaces';
import { Group } from 'minga/app/src/app/groups/models/Group';
import { GroupFiles } from 'minga/app/src/app/groups/models/GroupFiles';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services/GroupsFacade';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { LinkOpenerService } from 'minga/app/src/app/services/LinkOpener';
import 'minga/app/src/globals';
import { day } from 'minga/libraries/day';
import { ShortFileCardView } from 'minga/proto/gateway/content_views_pb';

// import {GroupsFacadeService} from 'minga/app/src/app/groups/services';

@Component({
  selector: 'mg-file-mini-card',
  templateUrl: './MgFileMiniCard.element.html',
  styleUrls: [
    // '../MgMiniCardBaseElement.scss',
    './MgFileMiniCard.element.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgFileMiniCardElement implements OnInit, OnChanges {
  @Input()
  content?: ShortFileCardView.AsObject;

  @Input()
  context: string = '';

  @Input()
  filestatus?: IFileDownloadStatus;

  @Input()
  downloadProgress: number = 0;

  @Input()
  downloadCanceled: boolean = false;

  @Input()
  downloadComplete: boolean = false;

  @Input()
  isDownloadingFile: boolean = false;

  @Input()
  extBoxClass: string = '';

  fileDownloadSubscription: Subscription;

  ownerGroup$?: Observable<Group | null>;
  currentGroup$?: Observable<string>;

  get ownerGroupHash(): string {
    if (!this.content) return '';
    return this.content.ownerGroupHash;
  }

  get timestampString(): string {
    const timestamp = this.content.timestamp;
    if (!timestamp) {
      return '';
    }

    return day(timestamp).format('dddd, MMM D, YYYY [at] h:mm a');
  }

  constructor(
    public contentstate: ContentState,
    public filedownloader: FileDownloaderService,
    public linkopener: LinkOpenerService,
    private groupsFacade: GroupsFacadeService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.currentGroup$ = this.groupsFacade.getCurrentGroupHashObservable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content) {
      this.ownerGroup$ = this.groupsFacade.getGroup(this.ownerGroupHash);
    }
  }

  shouldShowGroupLinkSlot(currentGroup?: string) {
    // don't show if we are in the group context.
    if (currentGroup) {
      return false;
    }
    return !!this.ownerGroupHash;
  }

  @HostListener('click', ['$event'])
  _onClick(e) {
    this._noClick(e);
    if (!this.isDownloadingFile && !this.downloadComplete) {
      this._downloadFile();
    }
  }

  @HostListener('mouseover', ['$event'])
  _onHover(e) {
    this.extBoxClass = 'inverse';
  }

  @HostListener('mouseout', ['$event'])
  _onBlur(e) {
    this.extBoxClass = '';
  }

  @HostBinding('class.mg-cancel-content-link')
  readonly cancelContentLink: boolean = true;

  private _downloadFile() {
    let filePath = this.content.filePath;
    if (this.content.image && !_.isEmpty(this.content.image)) {
      const sizeMap = this.content.image.sizeMap;
      sizeMap.forEach(entry => {
        sizeMap[entry[0]] = entry[1];
      });

      const sizeInfo =
        sizeMap['longcardbanner'] || sizeMap['cardbanner'] || sizeMap['raw'];
      filePath = window.MINGA_ASSET_URL_PREFIX + sizeInfo.path;
    }

    if (this.content.url) {
      if (!this.linkopener) {
        console.error(
          `[mg-file-mini-card] cannot open link, missing linkopener service!`,
        );
      }
      this.linkopener.open(this.content.url);
    } else if (!this.filedownloader) {
      console.error(`[mg-file-mini-card] missing filedownloader service!`);
    } else if (!filePath) {
      console.error(`content is missing file path to open file!`, this.content);
    } else {
      // use complete file name with extension
      const fileName = GroupFiles.getStatusKey(this.content);
      this.filestatus = { fileKey: fileName };

      this.fileDownloadSubscription =
        this.filedownloader.onDownloadStatusUpdate.subscribe(ev => {
          this.onFileDownloadUpdate(ev);
        });

      // download the file
      this.filedownloader.downloadFromUrl(
        filePath,
        fileName,
        this.content.mimeType,
      );
    }
  }

  private _noClick(e: MouseEvent) {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    }
  }

  groupLinkClick(e) {
    this._noClick(e);
    // open group page
    this.contentstate.openGroup(this.ownerGroupHash);
  }

  onFileDownloadUpdate(ev: IFileDownloadStatus) {
    if (this.filestatus && this.filestatus.fileKey == ev.fileKey) {
      this.filestatus = ev;
      this.downloadProgress = ev.progress;
      this.downloadCanceled = ev.canceled;
      this.downloadComplete = ev.complete;

      if (ev.error) {
        this.isDownloadingFile = false;
      } else if (ev.progress < 100 && !ev.complete) {
        this.isDownloadingFile = true;
      } else {
        this.isDownloadingFile = false;
      }
      this.cdr.detectChanges();
    }
  }

  onFileIndexDownloadAbort(e) {
    this.filedownloader.abortDownloads();
    const currentStatus: IFileDownloadStatus = {
      canceled: true,
      progress: 0,
      complete: false,
      error: false,
      fileKey: '',
    };
    // update file status
    this.filestatus = currentStatus;
    this.downloadProgress = currentStatus.progress;
    this.downloadCanceled = currentStatus.canceled;
    this.downloadComplete = currentStatus.complete;

    // reset state
    this.isDownloadingFile = false;

    this.cdr.detectChanges();
  }

  onFileOpen(e) {
    this._noClick(e);
    const fileStatus = this.filestatus;
    const fileCompleted = this.downloadComplete;

    if (!this.filedownloader) {
      console.error(
        `[mg-file-mini-card] onFileOpen() missing filedownloader service!`,
      );
    } else if (!fileCompleted) {
      console.error(
        `[mg-file-mini-card] onFileOpen() file download not completed!`,
      );
    }

    if (fileStatus && fileStatus.fileKey) {
      this.filedownloader.openDownload(fileStatus.fileKey);
    }
  }

  onAnchorClick(event) {
    this._noClick(event);
    this._onClick(event);
  }

  getHrefValue() {
    const noHref = 'javascript:;';
    if (!window.MINGA_APP_BROWSER) {
      return noHref;
    }
    if (this.content.url) {
      return this.content.url;
    } else if (this.content.filePath) {
      return this.content.filePath;
    } else {
      return noHref;
    }
  }

  getTitleValue() {
    const href = this.getHrefValue();

    if (href == 'javascript:;') {
      return this.content.name;
    } else {
      return href;
    }
  }
}
