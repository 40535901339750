import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TrackByFunction,
} from '@angular/core';

import * as day from 'dayjs';
import { Subject } from 'rxjs';

import { EventCalendarService } from 'minga/app/src/app/services/EventCalendar';

import {
  CalendarScheduleEventDate,
  CalendarScheduleView,
  ICalendarScheduleEvent,
} from './types';

/** @internal */
interface ICalendarScheduleViewItem {
  /** 1 - 12 */
  month: number;
  /** 1 - 31 */
  day: number;
  year: number;
  events: Required<ICalendarScheduleEvent>[];
}

const defaultColors = [
  '#ce4d4d',
  '#4d57ce',
  '#4dc4ce',
  '#ce4d92',
  '#8ece4d',
  '#ceb54d',
];

function getDefaultColor(ev: ICalendarScheduleEvent, index: number) {
  const defaultColorIndex = index + day(ev.date.start).day();
  return defaultColors[defaultColorIndex % defaultColors.length];
}

function toRequiredCalendarScheduleEvent(
  ev: ICalendarScheduleEvent,
  index: number,
): Required<ICalendarScheduleEvent> {
  const defaultDescription = !ev.title && !ev.description ? '(no text)' : '';
  return {
    color: ev.color || getDefaultColor(ev, index),
    date: ev.date,
    description: ev.description || defaultDescription,
    key: ev.key || '',
    title: ev.title || '',
  };
}

/**
 * @param month number between 1-12
 * @returns number weeks in month
 */
function getWeekCount(year: number, month: number) {
  var firstOfMonth = new Date(year, month - 1, 1);
  var lastOfMonth = new Date(year, month, 0);

  var used = firstOfMonth.getDay() + lastOfMonth.getDate();

  return Math.ceil(used / 7);
}

@Component({
  selector: 'mg-calendar-schedule',
  templateUrl: './CalendarSchedule.component.html',
  styleUrls: ['./CalendarSchedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarScheduleComponent implements OnInit, OnDestroy {
  /** @internal */
  private _initHappened = false;
  /** @internal */
  private _calendarView: CalendarScheduleView = 'month';
  /** @internal */
  private _calendarEvents: ICalendarScheduleEvent[] = [];
  /** @internal */
  private _excludeWeekend: boolean = false;
  /** @internal */
  private _activeDate: Date = new Date();

  // For passing date changes to child components
  readonly dateChangeSubject: Subject<Date> = new Subject<Date>();

  _activeDateTitle: string = '';
  _viewItems: ICalendarScheduleViewItem[][] = [[]];

  @Input()
  get calendarEvents(): ICalendarScheduleEvent[] {
    return this._calendarEvents;
  }
  set calendarEvents(value: ICalendarScheduleEvent[]) {
    this._calendarEvents = value || [];
    if (this._initHappened) this._updateViewItems();
  }

  @Input()
  get calendarView(): CalendarScheduleView {
    return this._calendarView;
  }
  set calendarView(value: CalendarScheduleView) {
    this._calendarView = value;
    if (this._initHappened) {
      this.calendarService.setActiveDate(this._activeDate, this._calendarView);
      this._updateViewMode();
    }
  }

  @Input()
  get excludeWeekend(): boolean {
    return this._excludeWeekend;
  }
  set excludeWeekend(value: boolean) {
    this._excludeWeekend = value;
    if (this._initHappened) this._updateViewItems();
  }

  @Output()
  readonly calendarViewChange: EventEmitter<CalendarScheduleView>;

  @Input()
  get activeDate(): Date {
    return this._activeDate;
  }
  set activeDate(value: Date) {
    this._activeDate = value;
    if (this._initHappened) {
      this.calendarService.setActiveDate(value, this._calendarView);
      this._updateViewItems();
    }
  }

  @Output()
  readonly activeDateChange: EventEmitter<Date>;

  readonly _eventTrackBy: TrackByFunction<Required<ICalendarScheduleEvent>>;

  @Output()
  readonly clickCalendarEventItem: EventEmitter<ICalendarScheduleEvent>;

  constructor(
    private _cdr: ChangeDetectorRef,
    private calendarService: EventCalendarService,
  ) {
    this.calendarViewChange = new EventEmitter();
    this.activeDateChange = new EventEmitter();
    this.clickCalendarEventItem = new EventEmitter();

    this._eventTrackBy = (index, ev: Required<ICalendarScheduleEvent>) => {
      return ev.key;
    };

    this.calendarService.setUseCalendarSettings(true);
  }

  ngOnInit() {
    this._initHappened = true;
    this._updateViewItems();
  }

  ngOnDestroy() {}

  isNow(viewItem: ICalendarScheduleViewItem) {
    const now = new Date();
    const viewItemDate = new Date(this._activeDate);
    viewItemDate.setMonth(viewItem.month - 1);
    viewItemDate.setDate(viewItem.day);

    return (
      now.getFullYear() == viewItemDate.getFullYear() &&
      now.getMonth() == viewItemDate.getMonth() &&
      now.getDate() == viewItemDate.getDate()
    );
  }

  isWithinMonth(viewItem: ICalendarScheduleViewItem) {
    return viewItem.month - 1 == this._activeDate.getMonth();
  }

  changeActiveDate(newDate: Date) {
    this._activeDate = newDate;
    this._updateViewItems();
    this.activeDateChange.emit(this._activeDate);
    this._cdr.markForCheck();
  }

  changeCalendarView(newView: CalendarScheduleView) {
    this._calendarView = newView;
    this._updateViewItems();
    this.calendarViewChange.emit(this._calendarView);
    this._cdr.markForCheck();
  }

  getWeekdayDisplay(data: ICalendarScheduleViewItem) {
    return day(new Date(data.year, data.month - 1, data.day)).format('ddd');
  }

  getEventDisplayTime(date: CalendarScheduleEventDate) {
    if (date.allDay) {
      return 'All Day';
    } else {
      const timeFormat = 'h:mm a';
      let displayTime = '';
      displayTime += day(date.start).format(timeFormat);
      if ('end' in date && date.end) {
        displayTime += ' - ';
        displayTime += day(date.end).format(timeFormat);
      }
      return displayTime;
    }
  }

  activeDateNavToday() {
    const currentDate = new Date();
    this.changeActiveDate(currentDate);
    this.dateChangeSubject.next(currentDate);
  }

  /** @internal */
  private _updateViewMode() {
    // @NOTE: This could be optimized to just remove some view items instead of
    // re-calculating them.
    if (this._calendarView === 'week' && this._viewItems.length > 0) {
      // @TODO: Only grab this active week from the month view
      this._updateViewItems();
    } else if (this._calendarView === 'day' && this._viewItems.length > 0) {
      // @TODO: Only grab active date cell from the month/week view
      this._updateViewItems();
    } else {
      this._updateViewItems();
    }
  }

  /** @internal */
  private _updateViewItems() {
    const startTime = new Date().getTime();
    const activeDate = day(this._activeDate);
    let activeDateStart: day.Dayjs;

    switch (this._calendarView) {
      case 'month':
        activeDateStart = day(activeDate).startOf('month').startOf('week');
        break;
      case 'week':
        activeDateStart = day(activeDate).startOf('week');
        break;
      case 'day':
        activeDateStart = day(activeDate);
        break;
    }

    this._viewItems = [];

    const getEventsForDate = (date: day.Dayjs) => {
      /**
       * TODO: this is pretty inefficient, going through the whole list of
       * events...should just pre-sort them into a map by date
       */
      const filterEvents = (v: ICalendarScheduleEvent) => {
        const startDate = day(v.date.start);

        return (
          startDate.year() == date.year() &&
          startDate.month() == date.month() &&
          startDate.date() == date.date()
        );
      };
      const sortEvents = (
        a: ICalendarScheduleEvent,
        b: ICalendarScheduleEvent,
      ) => {
        const aTime = a.date.start.getTime();
        const bTime = b.date.start.getTime();

        if (aTime > bTime) {
          return 1;
        } else if (aTime < bTime) {
          return -1;
        } else {
          return 0;
        }
      };
      const events = this._calendarEvents.filter(filterEvents).sort(sortEvents);

      return events.map(toRequiredCalendarScheduleEvent);
    };

    let startDay = 0;
    let endDay = 7;
    if (this._calendarView === 'day') {
      startDay = activeDate.day();
      endDay = startDay + 1;
    } else if (this._excludeWeekend) {
      startDay = 1;
      endDay = 6;
    }

    if (this._calendarView === 'month' || this._calendarView === 'week') {
      const weekCount =
        this._calendarView === 'week'
          ? 1
          : getWeekCount(activeDate.year(), activeDate.month() + 1);
      for (let week = 0; weekCount > week; ++week) {
        const rowItems: ICalendarScheduleViewItem[] = [];
        let allOutOfMonth = true;
        for (let i = startDay; endDay > i; ++i) {
          const date = day(activeDateStart).add(i + 7 * week, 'd');
          rowItems.push({
            day: date.date(),
            month: date.month() + 1,
            events: getEventsForDate(date),
            year: date.year(),
          });
          if (allOutOfMonth && activeDate.month() == date.month()) {
            allOutOfMonth = false;
          }
        }

        // If we're excluding the weekend there may be weeks that contain zero
        // days within the month.
        if (this._excludeWeekend && allOutOfMonth) {
          continue;
        }

        this._viewItems.push(rowItems);
      }
    } else if (this._calendarView === 'day') {
      this._viewItems.push([
        {
          day: activeDate.date(),
          month: activeDate.month() + 1,
          events: getEventsForDate(activeDate),
          year: activeDate.year(),
        },
      ]);
    }

    // There must always be at least 1 item in view items
    if (this._viewItems.length === 0) {
      this._viewItems.push([]);
    }
  }

  onActiveDateChange(value: Date) {
    this.activeDate = value;
  }
}
