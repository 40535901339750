import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  from,
} from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { Person } from 'minga/app/src/app/people';
import { MingaRoleType } from 'minga/domain/permissions';
import { CheckinManagerReportFilters } from 'minga/domain/reportFilters';
import { RolesService } from 'src/app/roles/services';
import { GradesService } from 'src/app/services/Grades';

import {
  ScheduledReportEditFormFields,
  getFilterGroupControlKey,
} from '@modules/minga-manager/components/mm-scheduled-reports/constants';
import {
  ClientSupportedScheduledReportFilter,
  ScheduledReportType,
} from '@modules/minga-manager/components/mm-scheduled-reports/types';

import { UserListCategory } from '@shared/components/user-list-filter/user-list.types';
import { CheckinService } from '@shared/services/checkin';

import { setIssuedOnPeopleSelect } from '../../utils/mm-sr-reports-edit.utils';
import {
  CheckinReportFields,
  CheckinReportMessages,
} from './sr-checkin-filters.constants';

@Component({
  selector: 'mg-sr-checkin-filters',
  templateUrl: './sr-checkin-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SrCheckinFiltersComponent implements OnInit, OnDestroy {
  private _defaultIssuedHashesSubject = new BehaviorSubject<string[]>([]);
  public defaultIssuedHashes$ = this._defaultIssuedHashesSubject.asObservable();
  private _destroyed$ = new ReplaySubject<void>(1);
  private _filtersSubject = new BehaviorSubject<
    Record<CheckinReportFields, any>
  >(null);
  public filters$ = this._filtersSubject.asObservable();
  public MESSAGES = CheckinReportMessages;
  public FIELDS = CheckinReportFields;
  public PARENT_FIELDS = ScheduledReportEditFormFields;
  public reasonOptions$ = this._checkinService.getReasonsObs().pipe(
    map(reasons =>
      reasons.map(reason => ({
        label: reason.name,
        value: reason.id,
      })),
    ),
  );
  public roleOptions$ = this._rolesService.roles$.pipe(
    tap(() => this._rolesService.fetchIfNeeded()),
    map(roles =>
      roles
        .filter(
          role =>
            ![MingaRoleType.SUPERADMIN].includes(
              role.roleType as MingaRoleType,
            ),
        )
        .map(role => ({
          value: role.roleType as MingaRoleType,
          label: role.name,
        })),
    ),
  );
  public gradeOptions$ = this._gradesService.grades$.pipe(
    tap(() => this._gradesService.fetchIfNeeded()),
    map(grades => {
      return grades.map(grade => ({ label: grade, value: grade }));
    }),
  );

  public UserListCategory = UserListCategory;

  public getFilterGroupControlKey = getFilterGroupControlKey;
  @Input() public form: FormGroup;
  @Input() defaultValues$: Observable<ClientSupportedScheduledReportFilter>;

  constructor(
    private _checkinService: CheckinService,
    private _rolesService: RolesService,
    private _gradesService: GradesService,
  ) {}

  ngOnInit(): void {
    // wait till we have all the dropdown data before setting values on it
    combineLatest([
      this.defaultValues$,
      this.gradeOptions$,
      this.roleOptions$,
      this.reasonOptions$,
    ])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([defaultValues]) => {
        this._onReady(defaultValues);
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onPersonSelected(people: Partial<Person>[]) {
    setIssuedOnPeopleSelect(people, this.form, {
      reportType: ScheduledReportType.CHECKIN_HISTORY,
      issuedToKey: this.FIELDS.ISSUED_TO,
      issuedByKey: this.FIELDS.ISSUED_BY,
    });
  }

  private _onReady(defaultValues: ClientSupportedScheduledReportFilter) {
    const {
      issuedTo = [],
      issuedBy = [],
      reason = [],
      role = [],
      grade = [],
      userList = [],
    } = (defaultValues?.filters || {}) as CheckinManagerReportFilters;

    this._defaultIssuedHashesSubject.next([...issuedTo, ...issuedBy]);

    this._filtersSubject.next({
      [CheckinReportFields.ISSUED_TO]: [issuedTo],
      [CheckinReportFields.ISSUED_BY]: [issuedBy],
      [CheckinReportFields.REASON]: [reason],
      [CheckinReportFields.ROLE]: [role],
      [CheckinReportFields.GRADE]: [grade],
      [CheckinReportFields.USER_LIST]: [userList],
    });
  }
}
