import { mingaSettingTypes } from 'minga/libraries/util';

import { ICheckinToggleSettings } from './checkin-manager-settings.types';

const settingTypes = mingaSettingTypes;

export enum CheckinToggleSettingMsgs {
  SUBHEADING_CHECK_IN_TITLE = 'Check in Settings',
  CHECKIN_ENABLE_TEACHER_TOOLS = 'Enable Check in tools for teachers',
  CHECKIN_ENABLE_STAFF_TOOLS = 'Enable Check in tools for staff',
  CHECKIN_ENABLE_KIOSK = 'Enable Check in kiosk',
}

export const defaultCheckinToggleSettings: ICheckinToggleSettings[] = [
  {
    name: CheckinToggleSettingMsgs.CHECKIN_ENABLE_TEACHER_TOOLS,
    settingName: settingTypes.CHECKIN_TRACKING_TOOLS_TEACHERS,
    value: false,
    id: 'checkin-settings-click-teacher-assign',
  },
  {
    name: CheckinToggleSettingMsgs.CHECKIN_ENABLE_STAFF_TOOLS,
    settingName: settingTypes.CHECKIN_TRACKING_TOOLS_STAFF,
    value: false,
    id: 'checkin-settings-click-staff-assign',
  },
  {
    name: CheckinToggleSettingMsgs.CHECKIN_ENABLE_KIOSK,
    settingName: settingTypes.CHECKIN_KIOSK,
    value: false,
    id: 'checkin-settings-click-kiosk',
  },
];
