import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { setStatusBarAppearance } from '@lib/cordova/status-bar';
import { ReplaySubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { splashScreen } from 'src/splashscreen';

import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

import {
  PortalLayoutSection,
  PortalLayoutService,
} from '@shared/components/portal-layout';
import { KeyboardEventsService } from '@shared/services/app-events/keyboard';
import { MediaService } from '@shared/services/media';
import { NavigationService } from '@shared/services/navigation/navigation.service';

import { LayoutService } from './services';

type LayoutMode = 'default' | 'unauthenticated' | 'kiosk';

@Component({
  selector: 'mg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  // Constants
  public readonly PORTAL_LAYOUT = PortalLayoutSection;
  // Children

  @ViewChild(MatDrawer, { static: false })
  matDrawer: MatDrawer;
  @ViewChild('mainContent') private _mainContentElement: ElementRef;

  // Cleanup
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Inputs
  @Input()
  public readonly overlayIsActive: boolean;

  /** Layout template */
  public readonly layoutTemplate$ = combineLatest([
    this.navigation.ended$,
    this._authInfo.authPersonHash$.pipe(map(hash => !!hash)),
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(([, isLoggedIn]): LayoutMode => {
      const isKiosk = this.router.isActive('/kiosk', false);
      const isLanding = this.router.isActive('/landing', false);
      if (isKiosk) return 'kiosk';
      if (isLanding || !isLoggedIn) return 'unauthenticated';
      return 'default';
    }),
    distinctUntilChanged(),
  );

  /** Show authenticated layout */
  public readonly showAuthenticatedLayout$ = combineLatest([
    this.navigation.ended$,
    this._authInfo.authPersonHash$.pipe(map(hash => !!hash)),
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(
      ([, isLoggedIn]) =>
        !this.router.isActive('/landing', false) && isLoggedIn,
    ),
    distinctUntilChanged(),
  );

  // Portals

  public headerPortal: TemplatePortal<unknown> | null = null;
  public footerPortal: TemplatePortal<unknown> | null = null;

  /**
   * if we want to add a unread message counter to the tab title,
   * this is the place to do it.
   *
   * @todo use https://angular.io/api/router/TitleStrategy
   * when we upgrade to angular 14+
   */
  public readonly pageTitle$ = combineLatest([
    this.navigation.primaryTitle$,
    this.navigation.secondaryTitle$,
  ]).pipe(
    map(([primaryTitle, secondaryTitle]) => {
      let title = 'Minga';
      if (primaryTitle || secondaryTitle) {
        if (secondaryTitle) title = `${secondaryTitle} – ${title}`;
        if (primaryTitle) title = `${primaryTitle} · ${title}`;
      }
      return title;
    }),
    distinctUntilChanged(),
  );

  /** Component constructor */
  constructor(
    public media: MediaService,
    public layout: LayoutService,
    public route: ActivatedRoute,
    public router: Router,
    public navigation: NavigationService,
    public title: Title,
    public keyboardEvents: KeyboardEventsService,
    public viewContainerRef: ViewContainerRef,
    public portalLayout: PortalLayoutService,
    private _authInfo: AuthInfoService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._closeNavigationOnNavigationChangeSubscription();
    this._setPageTitleSubscription();
    this._portalLayoutHeaderSubscription();
    this._portalLayoutFooterSubscription();
    // if (window.MINGA_DEVICE) this._setStatusBarColorSubscription();
    // @todo fix this to correctly work on latest android
  }

  ngAfterViewInit(): void {
    splashScreen.hide();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public async toggleDrawer(): Promise<void> {
    await this.matDrawer?.toggle();
  }

  private _closeNavigationOnNavigationChangeSubscription() {
    this.navigation.started$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(async () => {
        await this.matDrawer?.close();
      });
  }

  private _setPageTitleSubscription() {
    return this.pageTitle$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(title => this.title.setTitle(title));
  }

  private _portalLayoutHeaderSubscription() {
    return this.portalLayout.headerContent$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(templateRef => {
        if (!templateRef) {
          this.headerPortal = null;
        } else {
          this.headerPortal = new TemplatePortal<unknown>(
            templateRef,
            this.viewContainerRef,
          );
        }
        this._cdr.markForCheck();
      });
  }

  private _portalLayoutFooterSubscription() {
    return this.portalLayout.footerContent$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(templateRef => {
        if (!templateRef) {
          this.footerPortal = null;
        } else {
          this.footerPortal = new TemplatePortal<unknown>(
            templateRef,
            this.viewContainerRef,
          );
        }
        this._cdr.markForCheck();
      });
  }
}
