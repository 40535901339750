import { Validators } from '@angular/forms';

import { $enum } from 'ts-enum-util';

import { OverdueConsequenceActionType } from 'minga/domain/consequences';
import {
  DEFAULT_BEHAVIOR_SMS_TEMPLATE,
  DefaultBehaviorEmailTemplate,
} from 'minga/libraries/shared/pbis/defaults';
import { MgValidators } from 'src/app/input/validators';

import { setDefaultRestrictionControlValue } from '@shared/components/form-restriction-input/form-restriction-input.constants';
import { getAppColor } from '@shared/constants';

import { BehaviorIconListType, BehaviorMsgCategory } from '../../constants';

export enum ConsequenceEditMessages {
  TABLE_TITLE_CONSEQUENCES = 'Consequences',
  TABLE_TITLE_AUTOMATIONS = 'Automations',

  MODAL_TITLE_NEW = 'Create Consequence',
  MODAL_TITLE_EXISTING = 'Edit Consequence',

  FIELD_LABEL_NAME = 'Consequence name',
  FIELD_LABEL_TYPE = 'Type of consequence',
  FIELD_LABEL_TYPE_REQUIRED_DESCRIPTION = 'This type requires completion when assigned',
  FIELD_LABEL_TYPE_NOT_REQUIRED_DESCRIPTION = 'This type does not require completion when assigned',
  FIELD_LABEL_DESCRIPTION = 'Internal description (optional)',
  FIELD_LABEL_ICON = 'Icon',
  FIELD_LABEL_ICON_COLOR = 'Icon color',
  FIELD_LABEL_POINTS = 'Points awarded or subtracted (optional)',
  FIELD_LABEL_ENABLE_STICKER = 'Assign or remove sticker',
  FIELD_LABEL_RESTRICTION = 'Limit who can assign this consequence manually',
  FIELD_LABEL_STICKER_ADD = 'Added sticker',
  FIELD_LABEL_STICKER_REMOVE = 'Removed sticker',
  FIELD_LABEL_ENABLE_DUE_DATE = 'Enable due date',
  FIELD_LABEL_ENABLE_DUE_DATE_TOOLTIP = `The due date is entered when manually assigning the consequence or in the automation setting using the consequence.\nThis setting is only available with a type of consequence that requires completion`,
  FIELD_LABEL_PARENT_EMAIL_SUBJECT = 'Email subject',
  FIELD_LABEL_PARENT_EMAIL_BODY = 'Email body',

  PARENT_EMAIL_MSG = 'Send parents notification email',
  PARENT_SMS_MSG = 'Send parents notification SMS',
  PARENT_SMS_MSG_TOOLTIP = 'SMS needs to be enabled in your Minga settings',
  ADMIN_EMAIL_MSG = 'Send admin notification email',

  FIELDSET_RESTRICTION_LABEL = 'Consequence restriction',
  FIELDSET_STICKER_LABEL = 'Sticker',
  FIELDSET_OVERDUE_ACTION_LABEL = 'Overdue action',
  FIELD_OVERDUE_LABEL_NOTE = 'Define what happens if a consequence is not completed on time',
  FIELD_LABEL_OVERDUE_TYPE_LABEL = 'Enable action for overdue consequence',
  FIELD_OVERDUE_ACTION_ADDITIONAL_LABEL = 'Additional consequence',
  FIELD_OVERDUE_ACTION_ESCALATE_LABEL = 'Escalate consequence',
  FIELD_OVERDUE_ACTION_ADDITIONAL_NOTE = 'An additional consequence will be added on top of the overdue one',
  FIELD_OVERDUE_ACTION_ESCALATE_NOTE = 'A new consequence will replace the overdue one',
  FIELD_OVERDUE_ACTION_CONSEQUENCE_LABEL = 'Consequence',
  FIELD_OVERDUE_ACTION_ENABLE_DUE_DATE_LABEL = 'Enable due date for overdue escalated / additional consequence',
  FIELD_OVERDUE_ACTION_DAYS_TO_COMPLETE_LABEL = 'Number of days to complete consequence',
  FIELD_OVERDUE_ACTION_DAYS_TO_COMPLETE_TOOLTIP = "Choose 0 to complete a consequence before the end of the day. Weekends don't count towards the number of days to complete the consequence.",

  FIELDSET_NOTIFICATION_LABEL = 'Notification',
  FIELD_LABEL_NOTE = 'Add note to consequence',
  FIELD_LABEL_NOTE_DESC = 'Allow teacher to write a note to the student when assigning the consequence',

  BUTTON_TOGGLE_PRAISE = 'Praise',
  BUTTON_TOGGLE_GUIDANCE = 'Guidance',

  QUILL_EDITOR_INSTRUCTIONS = "${firstName} = Student's first name\n${lastName} = Student's last name\n${typeName} = Name of consequence assigned to student\n${mingaName} = School name\n${note} = Custom note from teacher to student\n${assigner} = The person who assigned the consequence\n${points} = How many points were awarded",
  INVALID_FORM_MESSAGE = 'Please complete the missing information(s)',

  DELETE_CONFIRM_TITLE = 'Are you sure you want to delete this consequence?',
  DELETE_CONFIRM_DISCARD_TITLE = 'Are you sure you want to discard this this consequence?',
  DELETE_CONFIRM_DISCARD_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_CANCEL_BTN = 'Cancel',
  DELETE_CONFIRM_DELETE_BTN = 'Delete',
  DELETE_CONFIRM_DISCARD_BTN = 'Discard',

  DISABLED_CONSEQUENCE_OPTION_LABEL = 'This consequence is currently disabled',
}

export const ICON_CONFIG_DEFAULTS = {
  [BehaviorMsgCategory.PRAISE]: {
    color: getAppColor('behavior-praise'),
    icon: BehaviorIconListType.CONSEQUENCE_PRAISE,
  },
  [BehaviorMsgCategory.GUIDANCE]: {
    color: getAppColor('behavior-consequence'),
    icon: BehaviorIconListType.CONSEQUENCE_GUIDANCE,
  },
};

export enum ConsequenceEditFormFields {
  NAME = 'name',
  ICON = 'icon',
  ICON_COLOR = 'iconColor',
  CATEGORY = 'category',
  RESTRICTION = 'restriction',
  TYPE = 'type',
  DESCRIPTION = 'description',
  POINTS = 'points',
  STICKER_ENABLE = 'stickerEnable',
  STICKER_ADD = 'stickerAdd',
  STICKER_REMOVE = 'stickerRemove',
  DUE_DATE_ENABLE = 'dueDateEnable',
  NOTE_ENABLE = 'noteEnable',
  SEND_PARENT_EMAIL = 'sendParentEmail',
  PARENT_EMAIL_SUBJECT = 'sendParentEmailSubject',
  PARENT_EMAIL_BODY = 'sendParentEmailBody',
  SEND_PARENT_SMS = 'sendParentSms',
  PARENT_SMS_TEMPLATE = 'parentSmsTemplate',
  SEND_ADMIN_EMAIL = 'sendAdminEmail',
  ADMIN_EMAIL = 'adminEmail',
  OVERDUE_ACTION = 'overdueAction',
  OVERDUE_ACTION_TYPE = 'overdueActionType',
  OVERDUE_ACTION_CONSEQUENCE = 'overdueActionConsequence',
  OVERDUE_ACTION_DUE_DATE = 'overdueActionEnableDueDate',
  OVERDUE_ACTION_DAYS_TO_COMPLETE = 'overdueActionDaysToComplete',
}

export const CONSEQUENCE_FORM = {
  [ConsequenceEditFormFields.NAME]: ['', Validators.required],
  [ConsequenceEditFormFields.DESCRIPTION]: [''],
  [ConsequenceEditFormFields.ICON_COLOR]: [
    ICON_CONFIG_DEFAULTS[BehaviorMsgCategory.PRAISE].color,
  ],
  [ConsequenceEditFormFields.ICON]: [
    ICON_CONFIG_DEFAULTS[BehaviorMsgCategory.PRAISE].icon,
  ],
  [ConsequenceEditFormFields.POINTS]: [0],
  [ConsequenceEditFormFields.CATEGORY]: [
    BehaviorMsgCategory.PRAISE,
    Validators.required,
  ],
  [ConsequenceEditFormFields.TYPE]: [null, [Validators.required]],
  [ConsequenceEditFormFields.STICKER_ENABLE]: [false],
  [ConsequenceEditFormFields.STICKER_ADD]: [null],
  [ConsequenceEditFormFields.STICKER_REMOVE]: [null],
  [ConsequenceEditFormFields.DUE_DATE_ENABLE]: [true, [Validators.required]],
  [ConsequenceEditFormFields.NOTE_ENABLE]: [false, [Validators.required]],
  [ConsequenceEditFormFields.SEND_PARENT_EMAIL]: false,
  [ConsequenceEditFormFields.PARENT_EMAIL_BODY]: [
    DefaultBehaviorEmailTemplate.BODY,
    [MgValidators.templateValidator],
  ],
  [ConsequenceEditFormFields.PARENT_EMAIL_SUBJECT]: [
    DefaultBehaviorEmailTemplate.SUBJECT,
    [MgValidators.templateValidator, Validators.maxLength(255)],
  ],
  [ConsequenceEditFormFields.SEND_PARENT_SMS]: false,
  [ConsequenceEditFormFields.PARENT_SMS_TEMPLATE]: [
    DEFAULT_BEHAVIOR_SMS_TEMPLATE,
    [MgValidators.templateValidator, Validators.maxLength(255)],
  ],
  [ConsequenceEditFormFields.SEND_ADMIN_EMAIL]: false,
  [ConsequenceEditFormFields.ADMIN_EMAIL]: [
    [],
    [MgValidators.allValuesAsEmailsValidator],
  ],
  [ConsequenceEditFormFields.RESTRICTION]: setDefaultRestrictionControlValue(),
  [ConsequenceEditFormFields.OVERDUE_ACTION]: false,
  [ConsequenceEditFormFields.OVERDUE_ACTION_TYPE]: [
    OverdueConsequenceActionType.ADDITIONAL,
  ],
  [ConsequenceEditFormFields.OVERDUE_ACTION_CONSEQUENCE]: [null],
  [ConsequenceEditFormFields.OVERDUE_ACTION_DUE_DATE]: [false],
  [ConsequenceEditFormFields.OVERDUE_ACTION_DAYS_TO_COMPLETE]: [null],
};

export const ICON_OPTIONS = $enum(BehaviorIconListType)
  .getValues()
  .filter(
    key =>
      key !== BehaviorIconListType.BEHAVIOR_GUIDANCE &&
      key !== BehaviorIconListType.BEHAVIOR_PRAISE,
  )
  .map(key => ({
    label: key,
    value: key,
  }));
