import { Pipe, PipeTransform } from '@angular/core';

import { IdTemplate } from '../types';

@Pipe({
  name: 'schoolYearFormat',
})
export class SchoolYearFormatPipe implements PipeTransform {
  transform(year: number, template: IdTemplate) {
    switch (template) {
      case 'mingasso':
        return `${year} / ${year + 1}`;
      case 'mingalangelo':
        return `${year}-${(year + 1).toString().slice(-2)}`;
      default:
        return `${year}-${(year + 1).toString().slice(-2)}`;
    }
  }
}
