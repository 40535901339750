import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  SecurityContext,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import textClipperSafe from 'minga/app/src/app/util/text-clipper-safe';

@Component({
  selector: 'mg-clipped-html-head',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClippedHtmlSlot_Head {
  constructor(public element: ElementRef) {}
}

@Component({
  selector: 'mg-clipped-html-tail',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClippedHtmlSlot_Tail {
  constructor(public element: ElementRef) {}
}

/**
 * HTML container that automatically clips it's contents while maintaining html
 * structure.
 *
 * Despite it's name `mg-clipped-html` has more utility than just clipping
 * HTML. It has two child contents for inserting html at the head and tail of
 * the original HTML.
 *
 * @note
 * You must have `<mg-clipped-html-tail>` or `<mg-clipped-html-head>` in the
 * body of the of `<mg-clipped-html>` or else the styles of  `fullHtml` will
 * not inherit the styles defined by the parent component.
 *
 * @example
 * ```html
 * <mg-clipped-html [fullHtml]="someSafeHtmlInput">
 *  <mg-clipped-html-head>
 *    This will go at the head of <strong>someSafeHtmlInput</strong>
 *  </mg-clipped-html-head>
 *  <mg-clipped-html-tail>
 *    This will go at the tail of <strong>someSafeHtmlInput</strong>
 *  </mg-clipped-html-tail>
 * </mg-clipped-html>
 * ```
 */
@Component({
  selector: 'mg-clipped-html',
  templateUrl: './ClippedHtml.component.html',
  styleUrls: ['./ClippedHtml.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClippedHtmlComponent {
  @ViewChild('headTemplate', { static: true })
  headTemplate?: TemplateRef<any>;

  @ViewChild('tailTemplate', { static: true })
  tailTemplate?: TemplateRef<any>;

  @ViewChild('viewContainer', { read: ViewContainerRef, static: true })
  viewContainer?: ViewContainerRef;

  @ContentChild(ClippedHtmlSlot_Head, { static: false })
  headSlot?: ClippedHtmlSlot_Head;

  @ContentChild(ClippedHtmlSlot_Tail, { static: false })
  tailSlot?: ClippedHtmlSlot_Tail;

  private _headEmbededView?: EmbeddedViewRef<any>;
  private _tailEmbededView?: EmbeddedViewRef<any>;
  private _showFullHtml: boolean = false;
  private _viewContainerNodes: any[] = [];
  private _viewEncapsAttrs: string[] = [];

  /**
   * The full unclipped html
   */
  @Input()
  fullHtml: SafeHtml | null = null;

  /**
   * Always show full html. Do not clip. Useful for when you only want to use
   * `<mg-clipped-html-head>` and `<mg-clipped-html-tail>`
   */
  @Input()
  alwaysFullHtml: boolean = false;

  /**
   * Length where the html contents gets clipped. This does **not** include the
   * html tags
   */
  @Input()
  clippedLength: number = 50;

  @Output()
  afterViewContainerSetup: EventEmitter<void> = new EventEmitter();

  get _fullHtmlAsString(): string {
    const fullHtml = this.fullHtml;
    if (!fullHtml) {
      return '';
    }

    if (typeof this.fullHtml === 'string') {
      return this.fullHtml;
    } else {
      return (<any>fullHtml)['changingThisBreaksApplicationSecurity'];
    }
  }

  get shouldShowFullHtml(): boolean {
    return this.alwaysFullHtml || this._showFullHtml;
  }

  get clippedHtmlStr(): string {
    return textClipperSafe(this._fullHtmlAsString, this.clippedLength, {
      indicator: '',
    });
  }

  get clippedHtml(): SafeHtml {
    const strHtml = this._fullHtmlAsString;
    const clippedHtml = textClipperSafe(strHtml, this.clippedLength);

    return this._domSanitizer.bypassSecurityTrustHtml(clippedHtml);
  }

  /** Check if the full html is short enough to be considered clipped */
  get isFullHtmlAlreadyClipped(): boolean {
    const strHtml = this._fullHtmlAsString;
    const clippedHtml = this.clippedHtmlStr;
    return strHtml == clippedHtml;
  }

  /** Check if only part of `fullHtml` is showing */
  get isClippedHtmlShowing(): boolean {
    return !this.shouldShowFullHtml && !this.isFullHtmlAlreadyClipped;
  }

  /** Check if full contents of `fullHtml` is showing */
  get isFullHtmlShowing(): boolean {
    return this.shouldShowFullHtml || this.isFullHtmlAlreadyClipped;
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _domSanitizer: DomSanitizer,
    private _renderer2: Renderer2,
  ) {}

  showFullHtml() {
    const wasHidingFullHtml = !this._showFullHtml;
    this._showFullHtml = true;
    if (wasHidingFullHtml) {
      this.setupViewContainer();
      this._cdr.markForCheck();
    }
  }

  /**
   * Convenience function for calling `showFullHtml()` on click event
   * @param ev MouseEvent to prevent default behaviour/propagation
   */
  showFullHtmlClick(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.stopPropagation();

    this.showFullHtml();
  }

  hideFullHtml() {
    const wasShowingFullHtml = this._showFullHtml;
    this._showFullHtml = false;
    if (wasShowingFullHtml) {
      this.setupViewContainer();
      this._cdr.markForCheck();
    }
  }

  /**
   * Convenience function for calling `hideFullHtml()` on click event
   * @param ev MouseEvent to prevent default behaviour/propagation
   */
  hideFullHtmlClick(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.stopPropagation();

    this.hideFullHtml();
  }

  toggleFullHtml() {
    if (this._showFullHtml) {
      this.hideFullHtml();
    } else {
      this.showFullHtml();
    }
  }

  /**
   * Convenience function for calling `toggleFullHtml()` on click event
   * @param ev MouseEvent to prevent default behaviour/propagation
   */
  toggleFullHtmlClick(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.stopPropagation();

    this.toggleFullHtml();
  }

  private clearViewContainer() {
    if (this._headEmbededView) {
      this._headEmbededView.destroy();
      delete this._headEmbededView;
    }

    if (this._tailEmbededView) {
      this._tailEmbededView.destroy();
      delete this._tailEmbededView;
    }

    if (this.viewContainer) {
      this.viewContainer.clear();
      const viewContainerElement = this.viewContainer.element.nativeElement;
      while (viewContainerElement.firstChild) {
        viewContainerElement.removeChild(viewContainerElement.firstChild);
      }
    }
  }

  /**
   * Get the attributes associated with the view encapsulation. This only works
   * for non-native view encapsulation. @TODO Find a different way of doing this
   */
  private _getViewEncapsulationAttributes(): string[] {
    if (this._viewEncapsAttrs.length > 0) {
      return this._viewEncapsAttrs;
    }

    this._viewEncapsAttrs = [];
    const slotRef = this.headSlot || this.tailSlot;

    if (slotRef && slotRef.element.nativeElement) {
      const slotElement = slotRef.element.nativeElement;

      for (let i = 0; slotElement.attributes.length > i; ++i) {
        const attr = slotElement.attributes[i];
        if (attr.name.startsWith('_ng')) {
          this._viewEncapsAttrs.push(attr.name);
        }
      }
    }

    if (this._viewEncapsAttrs.length == 0) {
      console.warn('<mg-clipped-html> must have a head or tail');
    }

    return this._viewEncapsAttrs;
  }

  private setupViewContainer() {
    if (!this.viewContainer) return;

    this.clearViewContainer();

    const div = this._renderer2.createElement('div');
    div.innerHTML = this.shouldShowFullHtml
      ? this._fullHtmlAsString
      : this.clippedHtmlStr;

    if (this.headTemplate) {
      this._headEmbededView = this.viewContainer.createEmbeddedView(
        this.headTemplate,
      );
    }

    if (this.tailTemplate) {
      this._tailEmbededView = this.viewContainer.createEmbeddedView(
        this.tailTemplate,
      );
    }

    if (this._headEmbededView) {
      let firstChild = div.firstChild;

      if (firstChild) {
        let target = firstChild.firstChild;

        for (const rootNode of this._headEmbededView.rootNodes) {
          if (target) {
            this._renderer2.insertBefore(firstChild, rootNode, target);
          } else {
            this._renderer2.appendChild(firstChild, rootNode);
          }
        }
      }
    }

    if (this._tailEmbededView) {
      let lastChild = div.lastChild;

      if (lastChild) {
        for (const rootNode of this._tailEmbededView.rootNodes) {
          this._renderer2.appendChild(lastChild, rootNode);
        }
      }
    }

    const viewContainerElement = this.viewContainer.element.nativeElement;

    while (div.firstChild) {
      const childNode = div.firstChild;
      this._renderer2.appendChild(viewContainerElement, childNode);
      this._viewContainerNodes.push(childNode);
    }

    this.afterViewContainerSetup.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fullHtml) {
      this._showFullHtml = this.isFullHtmlAlreadyClipped;
    }

    this.setupViewContainer();
  }

  ngAfterViewChecked() {
    const viewEncapAttrs = this._getViewEncapsulationAttributes();
    for (const viewContainerNode of this._viewContainerNodes) {
      for (const viewEncapAttr of viewEncapAttrs) {
        this._renderer2.setAttribute(viewContainerNode, viewEncapAttr, '');
      }
    }
  }
}
