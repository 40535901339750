import 'chartjs-adapter-dayjs';

import { getGroupRangeByDate, GroupType } from 'minga/domain/timeManipulation';
import { day } from 'minga/libraries/day';

export const getUnitsByDateRange = (startDate: Date, endDate: Date) => {
  const groupBy = getGroupRangeByDate(day(startDate), day(endDate));
  switch (groupBy) {
    case GroupType.BY_DAY:
      return 'day';
    case GroupType.BY_MONTH:
      return 'month';
    case GroupType.BY_WEEK:
      return 'week';
    default:
      return 'day';
  }
};
