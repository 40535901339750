import { FtmReportFilters } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { day } from 'minga/libraries/day';

import { SelectFieldsOption } from '@shared/components/select-fields';

export enum FtmReportsMessages {
  BUTTON_APPLY_FILTER = 'Apply filters',
  BUTTON_CLEAR_FILTER = 'Clear filters',
  SELECT_ACTIVITY = 'Activity',
  SELECT_PERIOD = 'Period',
  SELECT_CHECKIN_STATUS = 'Check in status',
  SELECT_LABEL_USER_LIST = 'User list',
  UNREGISTERED_NO_PERIOD_ERROR = 'Need at least one period selected to find unregistered students',

  SHOW_FILTERS = 'Show filters',
  HIDE_FILTERS = 'Hide filters',
}

export enum FtmReportTypes {
  HISTORY = 'FlexTime History Report',
  UNREGISTERED = 'FlexTime Unregistered Report',
}

export const FtmReportPageOptions: SelectFieldsOption<ReportTypes>[] = [
  { value: ReportTypes.FLEX_PERIOD_REGISTERED, label: FtmReportTypes.HISTORY },
  {
    value: ReportTypes.FLEX_PERIOD_UNREGISTERED,
    label: FtmReportTypes.UNREGISTERED,
  },
];

export enum FtmReportFilterType {
  PERIOD = 'PERIOD',
  ACTIVITY = 'ACTIVITY',
  REGISTRANT = 'REGISTRANT',
  CHECKIN_STATUS = 'CHECKIN_STATUS',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  USER_LIST = 'USER_LIST',
}

export const FTM_REPORTS_FILTER_INIT_STATE: FtmReportFilters = {
  periods: [],
  registrant: [],
  activities: [],
  startDate: day().subtract(7, 'd').startOf('day'),
  endDate: day().endOf('day'),
  userList: [],
};

export const FTM_REPORTS_FILTER_NON_ARRAY_TYPES = [
  FtmReportFilterType.CHECKIN_STATUS,
  FtmReportFilterType.START_DATE,
  FtmReportFilterType.END_DATE,
];
