<!-- Page Content -->
<div>
  <ng-container *ngTemplateOutlet="quickActionsTemplate"></ng-container>
  <div
    *ngIf="isLoading$ | async; else mainContent"
    class="loading-container">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>
</div>

<!-- Main Content -->
<ng-template #mainContent>
  <ng-container *ngTemplateOutlet="typesTemplate"></ng-container>
  <ng-container *ngIf="areConsequencesEnabled$ | async">
    <ng-container *ngTemplateOutlet="consequenceTypesTemplate"></ng-container>
  </ng-container>
</ng-template>

<!-- Behavior Types Template -->
<ng-template #typesTemplate>
  <section class="tiles-section">
    <div class="tiles-section-title">
      {{ MESSAGES.SECTION_HEADER_BEHAVIORS }}
    </div>
    <mg-empty-state
      *ngIf="(bmService.types$ | async).length === 0"
      [title]="MESSAGES.EMPTY_STATE_TITLE"
      [subtitle]="MESSAGES.EMPTY_STATE_SUBTITLE_BEHAVIORS">
    </mg-empty-state>
    <mg-tiles-carousel
      *ngIf="(bmService.types$ | async).length > 0"
      [stylePreset]="CAROUSEL_PRESETS.LIST">
      <ng-container
        *ngFor="let tile of activePipes$ | async"
        [ngTemplateOutlet]="tileItem"
        [ngTemplateOutletContext]="{ $implicit: tile, type: 'behaviorType' }">
      </ng-container>
    </mg-tiles-carousel>
  </section>
</ng-template>

<!-- Consequence Types Template -->
<ng-template #consequenceTypesTemplate>
  <section class="tiles-section">
    <div class="tiles-section-title">
      {{ MESSAGES.SECTION_HEADER_CONSEQUENCES }}
    </div>
    <mg-empty-state
      *ngIf="(bmService.consequenceTypes$ | async).length === 0"
      [title]="MESSAGES.EMPTY_STATE_TITLE"
      [subtitle]="MESSAGES.EMPTY_STATE_SUBTITLE_CONSEQUENCES">
    </mg-empty-state>
    <mg-tiles-carousel
      *ngIf="(bmService.consequenceTypes$ | async).length > 0"
      [stylePreset]="CAROUSEL_PRESETS.LIST">
      <ng-container
        *ngFor="let tile of bmService.consequenceTypes$ | async"
        [ngTemplateOutlet]="tileItem"
        [ngTemplateOutletContext]="{ $implicit: tile, type: 'consequence' }">
      </ng-container>
    </mg-tiles-carousel>
  </section>
</ng-template>

<!-- Tile Item Template -->
<ng-template
  #tileItem
  let-item
  let-type="type">
  <div class="tile-item">
    <!-- Tile -->
    <mg-count-tile
      size="large"
      [fixedHeight]="true"
      [count]="
        type === 'behaviorType'
          ? item.dailyBehaviorCount
          : item.dailyConsequenceCount
      "
      [label]="item.name"
      [icon]="
        type === 'behaviorType'
          ? item.categoryId === 0
            ? 'praise'
            : 'guidance'
          : item.categoryId === 0
          ? 'consequence-praise'
          : 'consequence-guidance'
      "
      [color]="item | getTileColor: type"
      [backgroundColor]="'white'"
      (tileClicked)="goToReports(item, type)">
    </mg-count-tile>
  </div>
</ng-template>

<!-- Quick Actions Template -->
<ng-template #quickActionsTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap.lt-md="8px"
    class="quick-action-container">
    <div fxFlex.gt-sm="nogrow">
      <span class="todays-date">{{ todaysDate }}</span>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutAlign.lt-md="space-between center"
      fxLayoutGap="15px">
      <div fxFlex="nogrow">
        <mg-btn
          variant="text"
          size="small"
          iconSet="minga"
          icon="mg-import"
          (pressed)="openImportBehaviorsDialog()">
          {{ MESSAGES.BUTTON_LABEL_IMPORT }}
        </mg-btn>
      </div>
      <div
        fxFlex="nogrow"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="8px"
        fxHide.lt-md>
        <mg-form-checkbox
          [checked]="bmDashboard.pollState$ | async"
          [disabled]="isLoading$ | async"
          (changed)="bmDashboard.togglePollingState()">
        </mg-form-checkbox>
        <mg-text variant="body-xs-secondary">
          {{ MESSAGES.AUTO_REFRESH }}
        </mg-text>
      </div>
      <div fxFlex="nogrow">
        <mg-btn
          variant="text"
          size="small"
          iconSet="material"
          icon="refresh"
          (pressed)="bmDashboard.refreshBmDashboard()">
          {{ MESSAGES.BUTTON_LABEL_REFRESH }}
        </mg-btn>
      </div>
    </div>
  </div>
</ng-template>
