export enum BS_CALENDAR_MESSAGES {
  CALENDAR_LABEL = 'Calendar',
  PLACEHOLDER_TERM = 'Term',
  PLACEHOLDER_NO_SCHEDULE = 'No schedule',
  ASSIGNED_DEFAULT = 'Default schedule assigned',
  ASSIGNED_CUSTOM = 'Custom schedule',
  ASSIGNED_NONE = 'No schedule assigned',
  SCHEDULE_ERRORS = 'We detected some errors with your bell schedules',
  SCHEDULE_ERRORS_BUTTON = 'View Errors',
  NO_DATA = 'To display your calendar you need to create at least one term and one schedule',
  MISSING_DATA = 'Please correct any errors with your terms and schedules to view data.',
  ERROR_LOADING_DATA = 'There was an error loading the calendar data',
  SCHEDULE_CHANGED = 'Successfully changed schedule',
  NO_SCHEDULE_LABEL = 'No schedule',
}

export enum BS_CALENDAR_COLUMNS {
  DATE = 'date',
  SCHEDULE = 'schedule',
  STATE = 'state',
}

export enum BS_CALENDAR_COLUMN_LABELS {
  DATE = 'Date',
  SCHEDULE = 'Schedule',
  STATE = 'Default Schedule',
}

export const BSCalendarColumns = [
  BS_CALENDAR_COLUMNS.DATE,
  BS_CALENDAR_COLUMNS.SCHEDULE,
  BS_CALENDAR_COLUMNS.STATE,
];
