import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MingaPermission } from 'minga/domain/permissions';
import { ReportTypes } from 'minga/domain/reportTypes';
import { MingaAppRoutes } from 'src/app/types';

import { AccessRestrictionsGuard } from '@shared/guards';

import { CheckinManagerComponent } from './checkin-manager.component';
import { CheckinManagerRoutes } from './checkin-manager.constants';
import { CheckinManagerModule } from './checkin-manager.module';
import {
  CheckinManagerDashboardComponent,
  CheckinManagerDashboardModule,
  CheckinManagerReasonsComponent,
  CheckinManagerReasonsDeactivateGuard,
  CheckinManagerReasonsModule,
  CheckinManagerReportHistoryComponent,
  CheckinManagerReportReasonsComponent,
  CheckinManagerReportsComponent,
  CheckinManagerReportsModule,
  CheckinManagerSettingsComponent,
  CheckinManagerSettingsModule,
} from './components';
import { ViewReportsGuard } from './guards';

export const CHECK_IN_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: CheckinManagerComponent,
    data: {
      title: 'Check in',
    },
    children: [
      {
        path: '',
        component: CheckinManagerDashboardComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Dashboard',
          description: 'View and manage check in activities',
          enableAutoRedirect: true,
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.CHECKIN_TYPE_MANAGE,
                MingaPermission.CHECKIN_VIEW_REPORTS,
              ],
            },
          },
        },
      },
      {
        path: CheckinManagerRoutes.REPORTS,
        canActivate: [AccessRestrictionsGuard],
        component: CheckinManagerReportsComponent,
        data: {
          title: 'Reports',
          description: 'View and manage check in reports',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.CHECKIN_VIEW_REPORTS],
            },
          },
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: ReportTypes.CHECKIN_HISTORY,
          },
          {
            path: ReportTypes.CHECKIN_HISTORY,
            canActivate: [AccessRestrictionsGuard],
            component: CheckinManagerReportHistoryComponent,
            data: {
              title: 'History',
              description: 'View and manage check in history',
              navigationVisibility: false,
              accessRestrictions: {
                permissions: {
                  atLeastOne: [MingaPermission.CHECKIN_VIEW_REPORTS],
                },
              },
            },
          },
          {
            path: ReportTypes.CHECKIN,
            canActivate: [AccessRestrictionsGuard],
            component: CheckinManagerReportReasonsComponent,
            data: {
              title: 'Reasons',
              description: 'View and manage check in history',
              navigationVisibility: false,
              accessRestrictions: {
                permissions: {
                  atLeastOne: [MingaPermission.CHECKIN_VIEW_REPORTS],
                },
              },
            },
          },
        ],
      },
      {
        path: CheckinManagerRoutes.REASONS,
        canActivate: [AccessRestrictionsGuard],
        component: CheckinManagerReasonsComponent,
        data: {
          title: 'Reasons',
          description: 'Manage check in reasons',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.CHECKIN_TYPE_MANAGE],
            },
          },
        },
      },
      {
        path: CheckinManagerRoutes.SETTINGS,
        canActivate: [AccessRestrictionsGuard],
        component: CheckinManagerSettingsComponent,
        data: {
          title: 'Settings',
          description: 'Manage check in settings',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.MINGA_ACCOUNT_MANAGE],
            },
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    CheckinManagerModule,
    CheckinManagerDashboardModule,
    CheckinManagerReportsModule,
    CheckinManagerSettingsModule,
    CheckinManagerReasonsModule,

    RouterModule.forChild(CHECK_IN_ROUTES),
  ],
  providers: [CheckinManagerReasonsDeactivateGuard, ViewReportsGuard],
})
export class CheckinRoutesModule {}
