import { Component, Input } from '@angular/core';

import { StudentSection } from 'minga/domain/studentSchedule';

@Component({
  selector: 'mg-view-id-schedule',
  templateUrl: './view-id-schedule.component.html',
  styleUrls: ['./view-id-schedule.component.scss'],
})
export class ViewIdScheduleComponent {
  // Inputs

  @Input()
  data: StudentSection[];

  /** Constructor */
  constructor() {}
}
