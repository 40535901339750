import { Pipe, PipeTransform } from '@angular/core';

import { FormSelectOption } from '@shared/components/form';

import { TableSchedule } from '../components/mm-bs-calendar/mm-bs-calendar.component';
import { BS_CALENDAR_MESSAGES } from '../constants/mm-bs-calendar.constants';

interface OptionItem {
  id: string;
  label: string;
}

@Pipe({
  name: 'overrideOptions',
})
export class OverrideOptionsPipe implements PipeTransform {
  transform(
    options: FormSelectOption[],
    scheduleRow: TableSchedule,
  ): FormSelectOption[] {
    const unsetOption = {
      value: null,
      label: BS_CALENDAR_MESSAGES.NO_SCHEDULE_LABEL,
    };
    // if we've previously set a override we need to let them clear it
    return scheduleRow.overrideBellId && !scheduleRow.bellId
      ? [unsetOption, ...options]
      : options;
  }
}
