import { IFileDownloadStatus } from 'minga/app/src/app/file/public_interfaces';
import { getFileExtension } from 'minga/libraries/util/File';
import { ShortFileCardView } from 'minga/proto/gateway/content_views_pb';
import { FileDetails } from 'minga/proto/gateway/file_pb';
import { MingaFilesResponse } from 'minga/proto/gateway/minga_pb';

export interface IMingaFile {
  file: FileDetails.AsObject;
  status?: IFileDownloadStatus;
}

export interface GroupFiles {
  hash: string;
  files: IMingaFile[];
}

export namespace MingaFiles {
  export function fromFilesResponse(msg: MingaFilesResponse): IMingaFile[] {
    const files = msg.getFileList().map(file => {
      const fileObj = file.toObject();
      return {
        file: fileObj,
        status: { fileKey: getStatusKey(fileObj) },
      };
    });

    return files;
  }

  // complete file name with extension
  export function getStatusKey(
    file: FileDetails.AsObject | ShortFileCardView.AsObject,
  ) {
    const fileName =
      file.name && file.fileExtension
        ? file.name + '.' + file.fileExtension
        : file.name + '.' + getFileExtension(file.filePath);
    return fileName;
  }
}
