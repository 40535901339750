import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Observable } from 'rxjs';

import { Group } from 'minga/app/src/app/groups/models';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { PollAnswerStateService } from 'minga/app/src/app/minimal/services/PollAnswerState';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { isPollClosed, pollLayoutName } from 'minga/app/src/app/util/poll';
import { day } from 'minga/libraries/day';
import {
  PollLayout,
  ShortPollCardView,
} from 'minga/proto/gateway/content_views_pb';

export interface IMgPollMiniCardElementProperties {
  content?: ShortPollCardView.AsObject;
  context: string;
}

@Component({
  selector: 'mg-poll-mini-card',
  templateUrl: './MgPollMiniCard.element.html',
  styleUrls: ['./MgPollMiniCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgPollMiniCardElement implements OnChanges {
  @Input()
  context: string = '';

  @Input()
  content?: ShortPollCardView.AsObject;

  @HostBinding('class.mg-show-votes')
  @Input()
  showVotes: boolean = false;

  group$?: Observable<Group>;

  @HostBinding('class.mg-poll-closed')
  get pollClosed(): boolean {
    if (!this.content) return true;
    return isPollClosed(this.content);
  }

  @HostBinding('class.mg-is-published')
  get isPublished(): boolean {
    if (!this.content || !this.content.timestamp) return false;
    return Date.now() >= this.content.timestamp;
  }

  @HostBinding('class.mg-is-unpublished')
  get isUnpublished(): boolean {
    if (!this.content || !this.content.unpublishTimestamp) return false;
    return Date.now() >= this.content.unpublishTimestamp;
  }

  @HostBinding('class.mg-has-answer')
  get hasAnswer(): boolean {
    if (this.context) {
      return this.pollAnswerState.hasAnswer(this.context);
    }

    return false;
  }

  get pollLayoutName(): string {
    if (!this.content) return '';
    return pollLayoutName(this.content.pollLayout || PollLayout.PL_LIST);
  }

  get hasGroup(): boolean {
    if (this.content) {
      return !!this.content.ownerGroupHash;
    }

    return false;
  }

  get ownerGroupHash(): string {
    if (this.content) {
      return this.content.ownerGroupHash;
    }

    return '';
  }

  get groupName() {
    // @TODO: Bazel - get group name for poll mini card
    return '';
  }

  constructor(
    private pollAnswerState: PollAnswerStateService,
    private groupsfacade: GroupsFacadeService,
    private contentState: ContentState,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.ownerGroupHash && changes.content) {
      this.group$ = this.groupsfacade.getGroup(this.ownerGroupHash);
    }
  }

  groupLinkClick(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.stopPropagation();
    this.contentState.openGroup(this.ownerGroupHash);
  }

  _votesText(voteCount: number) {
    return voteCount == 1 ? 'Vote' : 'Votes';
  }

  dateFormat(date: number) {
    if (!date) return '';
    return day(date).format('YYYY/MM/DD');
  }
}
