<div class="floating-icon-container mg-bodycopy">
  <div
    class="floating-icon left"
    (click)="close()">
    <mg-icon>close</mg-icon>&nbsp;Cancel
  </div>
  <div
    class="floating-icon right"
    (click)="onSubmit()">
    <mg-icon>publish-o</mg-icon>&nbsp;Save
  </div>
</div>

<div class="image-container">
  <div
    *ngIf="this.files.length == 0 && !this.existingImageUrl"
    class="file-uploader-container">
    <mg-file-input
      name="uploader-input"
      icon=""
      compactIcon=""
      displayStyle="CAMERA_IMAGE"
      [showFileDetails]="false"
      [customTitleText]="' '"
      [multiple]="false"
      [accept]="accept"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="files"
      (ngModelChange)="onFileSelected()">
    </mg-file-input>
    <div class="divider"></div>
    <div class="image-uploader-title-text">Upload or drag a photo here</div>
  </div>
  <ng-container *ngIf="this.files.length > 0 || this.existingImageUrl">
    <div class="image-cropper-wrapper">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [imageURL]="existingImageUrl"
        [maintainAspectRatio]="false"
        [aspectRatio]="1"
        format="jpeg"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)"
        (cropperReady)="cropperReady()"
        [transform]="transform"
        [canvasRotation]="canvasRotation"
        (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center center">
      <div class="divider divider--less-margin"></div>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        class="button-row">
        <div>
          <mg-button-clear
            class="control-button"
            (click)="rotateRight()">
            <mg-icon>rotate</mg-icon>
          </mg-button-clear>
        </div>
        <div>
          <mg-button-clear
            class="control-button"
            (click)="resetImage()">
            <mg-icon>trash</mg-icon>
          </mg-button-clear>
        </div>
      </div>
    </div>
  </ng-container>

  <div
    class="spinner-container"
    *ngIf="loading">
    <mg-spinner></mg-spinner>
  </div>
</div>
