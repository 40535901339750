import { Pipe, PipeTransform } from '@angular/core';

import { $enum } from 'ts-enum-util';

import { MingaPermission } from 'minga/domain/permissions';

import { PermissionsService } from './Permissions.service';

@Pipe({ name: 'hasPermission', pure: false })
export class HasPermissionPipe implements PipeTransform {
  constructor(private _permissions: PermissionsService) {}

  transform(value: any, options?: any): boolean {
    if (Array.isArray(value)) {
      const perms = value
        .filter($enum(MingaPermission).isKey)
        .map(k => MingaPermission[k]);

      return this._permissions.hasPermission(perms);
    } else if ($enum(MingaPermission).isKey(value)) {
      return this._permissions.hasPermission(MingaPermission[value]);
    }

    console.error('Invalid value for hasPermission pipe:', value);
    return false;
  }
}
