import { Validators } from '@angular/forms';

import { $enum } from 'ts-enum-util';

import {
  DEFAULT_BEHAVIOR_SMS_TEMPLATE,
  DefaultBehaviorEmailTemplate,
} from 'minga/libraries/shared/pbis/defaults';
import { MgValidators } from 'src/app/input/validators';

import { setDefaultRestrictionControlValue } from '@shared/components/form-restriction-input/form-restriction-input.constants';
import { getAppColor } from '@shared/constants';

import { BehaviorIconListType, BehaviorMsgCategory } from '../../constants';

export enum BmTypesBehaviorEditMessages {
  TABLE_TITLE_CONSEQUENCES = 'Consequences',
  TABLE_TITLE_AUTOMATIONS = 'Automations',

  MODAL_TITLE_NEW = 'Create Behavior',
  MODAL_TITLE_EXISTING = 'Edit Behavior',

  BUTTON_TOGGLE_PRAISE = 'Praise',
  BUTTON_TOGGLE_GUIDANCE = 'Guidance',

  INVALID_FORM_MESSAGE = 'Please complete the missing information(s)',

  FIELD_LABEL_NAME = 'Behavior name',
  FIELD_LABEL_ICON = 'Icon',
  FIELD_LABEL_ICON_COLOR = 'Icon color',
  FIELD_LABEL_POINTS = 'Points awarded or subtracted (optional)',
  FIELD_LABEL_DESCRIPTION = 'Description',
  FIELD_LABEL_PARENT_EMAIL_SUBJECT = 'Email subject',
  FIELD_LABEL_PARENT_EMAIL_BODY = 'Email body',
  FIELD_LABEL_ADMIN_EMAIL = 'Admin email',
  FIELD_LABEL_PARENT_SMS = 'SMS message',
  FIELD_LABEL_HALL_PASS = 'Auto assign hall pass (optional)',

  FIELDSET_NOTIFICATION_LABEL = 'Notification',

  PARENT_EMAIL_MSG = 'Send parents notification email',
  PARENT_SMS_MSG = 'Send parents notification SMS',
  PARENT_SMS_MSG_TOOLTIP = 'SMS needs to be enabled in your Minga settings',
  ADMIN_EMAIL_MSG = 'Send admin notification email',

  FIELDSET_RESTRICTION_LABEL = 'Behavior restriction',
  TOGGLE_RESTRICTION_LABEL = 'Limit who can assign this behavior manually',

  QUILL_EDITOR_INSTRUCTIONS = "${firstName} = Student's first name\n${lastName} = Student's last name\n${typeName} = Name of behavior assigned to student\n${mingaName} = School name\n${note} = Custom note from teacher to student\n${assigner} = The person who assigned the behavior\n${points} = How many points were awarded",

  DELETE_CONFIRM_TITLE = 'Are you sure you want to delete this behavior?',
  DELETE_CONFIRM_DISCARD_TITLE = 'Are you sure you want to discard this this behavior?',
  DELETE_CONFIRM_DISCARD_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_CANCEL_BTN = 'Cancel',
  DELETE_CONFIRM_DELETE_BTN = 'Delete',
  DELETE_CONFIRM_DISCARD_BTN = 'Discard',
}

export const ICON_CONFIG_DEFAULTS = {
  [BehaviorMsgCategory.PRAISE]: {
    color: getAppColor('behavior-praise'),
    icon: BehaviorIconListType.BEHAVIOR_PRAISE,
  },
  [BehaviorMsgCategory.GUIDANCE]: {
    color: getAppColor('behavior-consequence'),
    icon: BehaviorIconListType.BEHAVIOR_GUIDANCE,
  },
};

export enum BmTypesBehaviorEditFormFields {
  NAME = 'NAME',
  ICON = 'icon',
  ICON_COLOR = 'iconColor',
  POINTS = 'POINTS',
  PARENT_EMAIL = 'PARENT_EMAIL',
  PARENT_EMAIL_SUBJECT = 'PARENT_EMAIL_SUBJECT',
  PARENT_EMAIL_BODY = 'PARENT_EMAIL_BODY',
  PARENT_SMS = 'PARENT_SMS',
  PARENT_SMS_TEMPLATE = 'PARENT_SMS_TEMPLATE',
  SEND_ADMIN_EMAIL = 'SEND_ADMIN_EMAIL',
  ADMIN_EMAIL = 'ADMIN_EMAIL',
  CATEGORY = 'CATEGORY',
  HALL_PASS_ID = 'HALL_PASS_ID',
  RESTRICTIONS = 'RESTRICTIONS',
}

export const BM_TYPE_FORM = {
  [BmTypesBehaviorEditFormFields.NAME]: [
    '',
    [Validators.required, Validators.minLength(1), Validators.maxLength(25)],
  ],
  [BmTypesBehaviorEditFormFields.ICON_COLOR]: [
    ICON_CONFIG_DEFAULTS[BehaviorMsgCategory.PRAISE].color,
  ],
  [BmTypesBehaviorEditFormFields.ICON]: [
    ICON_CONFIG_DEFAULTS[BehaviorMsgCategory.PRAISE].icon,
  ],
  [BmTypesBehaviorEditFormFields.POINTS]: [0],
  [BmTypesBehaviorEditFormFields.HALL_PASS_ID]: [undefined],
  [BmTypesBehaviorEditFormFields.PARENT_EMAIL]: [false],
  [BmTypesBehaviorEditFormFields.PARENT_EMAIL_BODY]: [
    DefaultBehaviorEmailTemplate.BODY,
    [MgValidators.templateValidator],
  ],
  [BmTypesBehaviorEditFormFields.PARENT_EMAIL_SUBJECT]: [
    DefaultBehaviorEmailTemplate.SUBJECT,
    [MgValidators.templateValidator, Validators.maxLength(255)],
  ],
  [BmTypesBehaviorEditFormFields.PARENT_SMS]: false,
  [BmTypesBehaviorEditFormFields.PARENT_SMS_TEMPLATE]: [
    DEFAULT_BEHAVIOR_SMS_TEMPLATE,
    [MgValidators.templateValidator, Validators.maxLength(255)],
  ],
  [BmTypesBehaviorEditFormFields.SEND_ADMIN_EMAIL]: false,
  [BmTypesBehaviorEditFormFields.ADMIN_EMAIL]: [
    [],
    [MgValidators.allValuesAsEmailsValidator],
  ],
  [BmTypesBehaviorEditFormFields.CATEGORY]: [
    BehaviorMsgCategory.PRAISE,
    Validators.required,
  ],
  [BmTypesBehaviorEditFormFields.RESTRICTIONS]:
    setDefaultRestrictionControlValue(),
};

export const ICON_OPTIONS = $enum(BehaviorIconListType)
  .getValues()
  .filter(
    key =>
      key !== BehaviorIconListType.CONSEQUENCE_GUIDANCE &&
      key !== BehaviorIconListType.CONSEQUENCE_PRAISE,
  )
  .map(key => ({
    label: key,
    value: key,
  }));
