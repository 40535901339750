import { Injectable } from '@angular/core';

import { ISmsRecipientDetails } from 'minga/libraries/shared/sms/types';
import { SmsMinga as SmsMingaProto } from 'minga/proto/sms/sms_ng_grpc_pb';
import { SmsMingaGetDetailsRequest } from 'minga/proto/sms/sms_pb';
import { MingaSmsDetailsMapper } from 'minga/shared-grpc/sms';

@Injectable({ providedIn: 'root' })
export class SmsMinga {
  constructor(private smsMingaProto: SmsMingaProto) {}

  async getDetails(): Promise<ISmsRecipientDetails> {
    const request = new SmsMingaGetDetailsRequest();
    const details = await this.smsMingaProto.getDetails(request);
    return MingaSmsDetailsMapper.toISmsRecipientDetails(details);
  }
}
