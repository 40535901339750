import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { indexOf, sortBy } from 'lodash';
import { Observable, ReplaySubject, combineLatest, from } from 'rxjs';
import {
  concatMap,
  map,
  switchMap,
  take,
  takeUntil,
  toArray,
} from 'rxjs/operators';

import { MingaMinimalModel } from 'minga/domain';
import { MingaRoleType } from 'minga/domain/permissions';
import { badgeRoleNameToMingaRoleType } from 'minga/libraries/shared/auth/util';
import { mingaSettingTypes } from 'minga/libraries/util';
import { AppConfigService } from 'src/app/minimal/services/AppConfig';
import { AuthService } from 'src/app/minimal/services/Auth';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { AboutUsComponent } from 'src/app/overlays/AboutUs';
import { PermissionsService } from 'src/app/permissions';
import { LinkOpenerService } from 'src/app/services/LinkOpener';
import { MingaManagerService } from 'src/app/services/MingaManager';
import {
  MingaSettingsService,
  MingaStoreFacadeService,
} from 'src/app/store/Minga/services';

import {
  BEHAVIOR_CONFIG,
  CHECKIN_CONFIG,
  FLEXTIME_CONFIG,
  HALLPASS_CONFIG,
  PromotionalModalConfig,
} from '@modules/minga-manager/components/mm-subscription';
import { MmSubscriptionFeatureModalComponent } from '@modules/minga-manager/components/mm-subscription/components/mm-subscription-feature-modal/mm-subscription-feature-modal.component';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MediaService } from '@shared/services/media';
import { PrimaryNavigationRoute } from '@shared/services/navigation';
import { NavigationService } from '@shared/services/navigation/navigation.service';
import { UserpilotService } from '@shared/services/userpilot';

import {
  LAYOUT_NAVIGATION_SORT_ORDER,
  LayoutPrimaryNavMessage,
  VIEW_ID_ROUTE,
  LEGACY_VIEW_ID_ROUTE,
} from '../../constants';
import { LayoutService } from '../../services';

@Component({
  selector: 'mg-layout-primary-nav',
  templateUrl: './layout-primary-nav.component.html',
  styleUrls: ['./layout-primary-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutPrimaryNavComponent implements OnDestroy, OnInit {
  // Constants
  public readonly MSG = LayoutPrimaryNavMessage;

  // Cleanup
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Current minga details */
  public readonly minga$ = this._mingaStore.getMingaAsObservable();
  public mingas$: Observable<MingaMinimalModel[]> =
    this._mingaStore.getAllMingas();

  public readonly isStudent$ = this._authInfo.authInfo$.pipe(
    takeUntil(this._destroyedSubject),
    map(info => {
      if (info && info.person) {
        return [MingaRoleType.STUDENT, MingaRoleType.STUDENT_LEADER].includes(
          badgeRoleNameToMingaRoleType(info.person?.badgeRoleName),
        );
      }
      return null;
    }),
  );

  public isHallPassEnabled$ = this._settingService.isHallPassModuleEnabled();
  public canCreateHallPass$ = combineLatest([
    this._settingService.getSettingValueObs(
      mingaSettingTypes.PASS_STUDENTS_GRANT,
    ),
    this._permissions.mingaRoleType$,
  ]).pipe(
    map(([canStudentsSelfGrantHallPasses, mingaRoleType]) => {
      return (
        canStudentsSelfGrantHallPasses &&
        mingaRoleType !== MingaRoleType.READ_ONLY
      );
    }),
  );

  public readonly sortedNavigationItems$ =
    this.navigation.primaryNavigationRoutes$.pipe(
      takeUntil(this._destroyedSubject),
      switchMap(routes => {
        return from(routes).pipe(
          concatMap(async route => {
            return {
              route,
              canAccess: await route.canAccess$.pipe(take(1)).toPromise(),
            };
          }),
          toArray(),
          map(routesWithAccess => {
            const sortedRoutes = sortBy(routesWithAccess, item =>
              indexOf(LAYOUT_NAVIGATION_SORT_ORDER, item.route.title),
            );
            return sortedRoutes
              .sort((a, b) => {
                if (a.canAccess && !b.canAccess) return -1;
                if (!a.canAccess && b.canAccess) return 1;
                return 0;
              })
              .map(({ route }) => route);
          }),
          map(sortedRoutes => {
            const person = this._authInfo.authPerson;
            const role = badgeRoleNameToMingaRoleType(person?.badgeRoleName);
            const shortcuts = this._getShortcuts(role);
            return sortedRoutes.concat(shortcuts);
          }),
        );
      }),
    );

  // Outputs
  @Output() closeDrawer = new EventEmitter<void>();

  // Computed getters
  public get isOnMingaProfile(): boolean {
    return this._router.isActive('/minga-profile', true);
  }

  /** Constructor */
  constructor(
    public media: MediaService,
    public layout: LayoutService,
    public navigation: NavigationService,
    private _authInfo: AuthInfoService,
    private _auth: AuthService,
    private _router: Router,
    private _mingaStore: MingaStoreFacadeService,
    private _userpilotService: UserpilotService,
    private _linkOpener: LinkOpenerService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _mingaManagerService: MingaManagerService,
    private _modalOverlay: ModalOverlayService,
    private _settingService: MingaSettingsService,
    private _permissions: PermissionsService,
    private _appConfig: AppConfigService,
  ) {}

  ngOnInit(): void {
    this.navigation.listPrimaryNavigationRoutes();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public openHelpCenter() {
    if (window.MINGA_APP_IOS) {
      this._linkOpener.open('https://minga.io/helpcenter/');
    } else {
      this._userpilotService.openResourceCenter();
    }
  }

  // We need to make this to target the home page route properly
  public isActive(route: string): boolean {
    return this._router.isActive('/', true) && route === 'home';
  }

  public async openMingaSwitcher() {
    try {
      const minga = await this._mingaManagerService.openSwitchMingaModal();

      this._systemAlertSnackBar.open({
        type: 'success',
        message: `Successfully switched to ${minga.name}`,
      });
      this._router.navigate(['/']);
    } catch (e) {
      // this isn't really an error so lets just suppress it
      // just means they didnt switch mingas
    }
  }

  public async logout(): Promise<void> {
    const logout = await this._auth.logoutDialog();
    if (logout) {
      await this._router.navigateByUrl('/landing', { replaceUrl: true });
      this.layout.resetPrimaryNavigationState();
    }
  }

  public openPromotionalModal(module: string): void {
    let featureConfig: PromotionalModalConfig;
    switch (module) {
      case 'Hall Pass':
        featureConfig = HALLPASS_CONFIG;
        break;
      case 'Behaviors':
      case 'Points':
        featureConfig = BEHAVIOR_CONFIG;
        break;
      case 'Check in':
        featureConfig = CHECKIN_CONFIG;
        break;
      case 'FlexTime':
        featureConfig = FLEXTIME_CONFIG;
        break;
      default:
        featureConfig = null;
    }

    if (!featureConfig) return;

    this._modalOverlay.open<PromotionalModalConfig>(
      MmSubscriptionFeatureModalComponent,
      {
        data: featureConfig,
      },
    );
  }

  public openAbout() {
    this._modalOverlay.open(AboutUsComponent);
  }

  private _getShortcuts(role: MingaRoleType): PrimaryNavigationRoute[] {
    switch (role) {
      case MingaRoleType.STUDENT:
      case MingaRoleType.STUDENT_LEADER:
        return [
          this._appConfig.getValue('idTemplatesEnabled')
            ? VIEW_ID_ROUTE
            : LEGACY_VIEW_ID_ROUTE,
          {
            canAccess$: combineLatest([
              this.isHallPassEnabled$,
              this.canCreateHallPass$,
            ]).pipe(
              map(
                ([isHallPassEnabled, canCreateHallPass]) =>
                  isHallPassEnabled && canCreateHallPass,
              ),
            ),
            path: 'selection-assigner/hall-pass',
            title: 'Create Hall Pass',
            icon: 'mg-hallpass-menu-o',
            iconActive: 'mg-hallpass-menu',
            description: 'Create a Hall Pass',
          },
        ];
      default:
        return [];
    }
  }
}
