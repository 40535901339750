import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import Fuse from 'fuse.js';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { debounceTime, map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { ConversationNavigator } from 'minga/app/src/app/modules/direct-message/services/conversation-navigator.service';
import { IConversationsWithPeople } from 'minga/app/src/app/modules/direct-message/store';
import { MessagingService } from 'minga/app/src/app/modules/direct-message/store/services';
import { IClickConversationPreviewEvent } from 'minga/app/src/app/modules/direct-message/types';
import { addPeopleToConversations } from 'minga/app/src/app/modules/direct-message/utils';
import { PeopleFacadeService, Person } from 'minga/app/src/app/people';
import { inOutAnination } from 'minga/app/src/app/util/animations';
import { IFuseMatchesResult } from 'minga/app/src/app/util/fuse';
import { IConversationWithReadStatus } from 'minga/domain/messaging';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export interface IKeywordMatchedConversations {
  item: IConversationWithReadStatus;
  keyword?: string[];
}

@Component({
  selector: 'mg-moderation-direct-messages',
  templateUrl: './moderation-direct-messages.component.html',
  styleUrls: ['./moderation-direct-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [inOutAnination],
})
export class ModerationDirectMessagesComponent implements OnDestroy {
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  readonly peopleConversations$: Observable<IKeywordMatchedConversations[]>;
  readonly searchText$: BehaviorSubject<string>;
  private _destroyed$ = new ReplaySubject<void>(1);

  private readonly _fuseOptions: Fuse.IFuseOptions<IConversationsWithPeople> = {
    isCaseSensitive: false,
    shouldSort: true,
    keys: ['people.displayName', 'people.firstName', 'people.lastName'],
    includeMatches: true,
    threshold: 0.4,
  };

  readonly trackByFn = (index: number, convo: IConversationWithReadStatus) => {
    return convo.id;
  };

  /** Component Constructor */
  constructor(
    protected router: Router,
    protected systemAlertSnackBar: SystemAlertSnackBarService,
    protected route: ActivatedRoute,
    public messagingService: MessagingService,
    public conversationNavigator: ConversationNavigator,
    public peopleFacade: PeopleFacadeService,
  ) {
    // @NOTE: conversations currently don't support global content, just per
    // minga only

    this.searchText$ = new BehaviorSubject('');

    const allConversations$ = this.messagingService.getAllConversations();

    // grab any people who would come up for this search query
    this.searchText$
      .pipe(takeUntil(this._destroyed$), debounceTime(300))
      .subscribe(searchText => {
        if (searchText) {
          this.peopleFacade.makePeopleSearchRequest(searchText);
        }
      });

    // make a request to grab any people in conversations we don't already have.
    allConversations$
      .pipe(
        withLatestFrom(
          this.peopleFacade.mapPeopleCollectionAsPerson(
            this.peopleFacade.getAllPeopleDetails(),
          ),
        ),
        takeUntil(this._destroyed$),
      )
      .subscribe(([conversations, people]) => {
        const hashesToGet = new Map<string, string>();
        if (conversations) {
          const unused = conversations.map(item => {
            item.participants.forEach(participant => {
              const person = people.find(p => p.hash === participant);
              if (!person) {
                hashesToGet.set(participant, participant);
              }
            });
          });
        }

        const arr = Array.from(hashesToGet.keys());
        this.peopleFacade.requestFetchPeopleFromBackend(arr);
      });

    const peopleConversations$ = addPeopleToConversations(
      this.peopleFacade.mapPeopleCollectionAsPerson(
        this.peopleFacade.getAllPeopleDetails(),
      ),
      allConversations$,
    );

    this.peopleConversations$ = combineLatest([
      peopleConversations$,
      this.searchText$,
    ]).pipe(
      map(([items, searchText]) => {
        if (searchText) {
          const fuse = new Fuse(items, this._fuseOptions);
          const fuseResult = fuse.search(searchText) as any;

          // only return matches with keyword strings
          return fuseResult
            .map((fR: any) => {
              const keywords = this._getHighlightedNameSubstrings(
                fR.matches,
                fR.item.people,
              );
              if (keywords.length) {
                return {
                  item: fR.item.conversation,
                  keywords,
                };
              }
              return null;
            })
            .filter((result: any) => !!result);
        } else {
          const allConvos = items.map(item => {
            return { item: item.conversation };
          });
          return allConvos;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  private _getHighlightedNameSubstrings(
    fuseMatches: IFuseMatchesResult[],
    people: Person[],
  ): string[] {
    const displayNameSubstrings: string[] = [];

    for (const fuseMatch of fuseMatches) {
      const arrayIndex = fuseMatch.refIndex;

      // get substring from display of the correct index from the match data
      if (people[arrayIndex] && fuseMatch.key.includes('displayName')) {
        let maxLength = 0;
        let maxIndex = 0;
        // find greatest length match for highlighting
        for (let i = 0; i < fuseMatch.indices.length; i++) {
          const index = fuseMatch.indices[i];
          if (index.length >= 2) {
            const length = index[1] - index[0];
            if (length > maxLength) {
              maxLength = length;
              maxIndex = i;
            }
          }
        }

        const displayName = people[arrayIndex].displayName;
        const start = fuseMatch.indices[maxIndex][0];
        const end = fuseMatch.indices[maxIndex][1];
        if (displayName.length >= end - 1) {
          const substring = displayName.slice(start, end + 1);
          displayNameSubstrings.push(substring);
        }
      }
    }
    return displayNameSubstrings;
  }

  getResultsHeaderText(conversationsLength: number) {
    const resultString = conversationsLength === 1 ? 'result' : 'results';
    return `Showing ${conversationsLength} ${resultString}`;
  }

  onClickConversationPreview(ev: IClickConversationPreviewEvent) {
    this.conversationNavigator.navigateByConversationId(ev.data.id, true);
  }

  onSearchText(searchText: string) {
    this.searchText$.next(searchText);
  }
}
