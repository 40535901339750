import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import { Subscription } from 'rxjs';

import {
  DeleteConfirmationDialogComponent,
  IDeleteConfirmationOptions,
} from 'minga/app/src/app/dialog';
import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { LikeService } from 'minga/app/src/app/minimal/services/Like';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { ReportService } from 'minga/app/src/app/services/Report';
import { MingaPermission } from 'minga/domain/permissions';
import { ContentCleanup } from 'minga/proto/content/cleanup_ng_grpc_pb';
import { ContentInfoQuery } from 'minga/proto/content/common_pb';
import { CommentContent } from 'minga/proto/gateway/comment_pb';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export interface ICommentDisplay {
  obj: CommentContent.AsObject;
  parentContextHash?: string;
  anim?: string;
}

export const MIN_PAN_THRESHOLD = 35;
export const COMMENT_ROW_WIDTH_OFFSET = -8;

@Component({
  selector: 'mg-comment-view',
  templateUrl: './CommentView.component.html',
  styleUrls: ['./CommentView.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentViewComponent implements OnChanges, OnInit {
  @Input()
  comment?: CommentContent.AsObject;

  @Input()
  commentList?: CommentContent.AsObject[];

  @Input()
  parentContextHash?: string = '';

  @Input()
  hideAvatar?: boolean;

  @Input()
  replyTextOnly?: boolean;

  @Input()
  lightContent: boolean = false;

  @Input()
  isReply: boolean = false;

  @Input()
  disableActions: boolean = false;

  @Input()
  deleted: boolean = false;

  @Output()
  onReplyClick: EventEmitter<ICommentDisplay>;

  @Output()
  onViewRepliesClick: EventEmitter<string>;

  @Output()
  onCommentDeleted: EventEmitter<string> = new EventEmitter();

  @ViewChild('commentContentEl', { static: true })
  commentContentEl: ElementRef;

  @ViewChild('commentRowEl', { static: true })
  commentRowEl: ElementRef;

  @ViewChild('swipeLeftOptions', { static: true })
  swipeLeftOptions: ElementRef;

  @ViewChild('swipeRightOptions', { static: true })
  swipeRightOptions: ElementRef;

  @Input()
  hideReply: boolean = false;

  @ViewChild(MatRipple, { static: true })
  rippleEl: MatRipple;

  loadingComments: boolean = false;
  currentCommentsPage: number = -1;
  comments: ICommentDisplay[] = [];
  showComments: boolean = true;
  canDelete: boolean = false;
  canRipple: boolean = false;
  canResolve: boolean = false;
  canReport: boolean = true;
  deleteContentSub: Subscription;
  _showDeletedStatus: boolean = false;

  optionsPanDistance: number = 0;
  panLock: boolean = false;
  panEndValue: number = 0;
  _panOptionsWidth: number = 0;
  panning: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this.panOptionsWidth;
  }

  get commentBody(): string {
    if (this.comment) {
      return this.comment.text;
    }

    return '';
  }

  constructor(
    public likeservice: LikeService,
    private permissions: PermissionsService,
    private auth: AuthService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private dialog: MatDialog,
    private element: ElementRef,
    private contentCleanup: ContentCleanup,
    private contentEvents: ContentEvents,
    private reportService: ReportService,
    private router: Router,
    private contentState: ContentState,
    private _cdr: ChangeDetectorRef,
    private authInfoService: AuthInfoService,
  ) {
    this.onReplyClick = new EventEmitter();
    this.onViewRepliesClick = new EventEmitter();

    this.canRipple = window.MINGA_DEVICE_ANDROID || window.MINGA_DEVICE_IOS;

    if (this.canRipple) {
      // this.element.nativeElement.addEventListener('contextmenu', (e) => {
      //   this.deleteClick(e);
      // });
    }
  }
  ngOnInit(): void {
    if (this.comment) {
      this.updatePermissions();
    }
    if (this.comment.hasLiked) {
      this.likeservice.setLike(this.comment.commentContextHash);
    }
  }

  get panOptionsWidth() {
    if (this._panOptionsWidth) return this._panOptionsWidth;

    this._panOptionsWidth = this.swipeRightOptions
      ? this.swipeRightOptions.nativeElement.getBoundingClientRect().width -
        COMMENT_ROW_WIDTH_OFFSET
      : 0;
  }

  get showDeletedStatus() {
    // console.log('getter', this._showDeletedStatus, this.comment.text);
    return this._showDeletedStatus;
  }

  readonly _trackByHash = (index: number, item: ICommentDisplay) => {
    return item.obj.commentContextHash;
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.commentList) {
      if (this.commentList) {
        this.comments = [];
        for (let comment of this.commentList) {
          this.comments.push({ anim: 'fade', obj: comment });
        }
      }
    }
  }

  get hasEnabledActions() {
    return (
      !this.disableActions &&
      (this.canReport || this.canResolve || this.canDelete)
    );
  }

  async updateDeletePermissions() {
    if (this.comment.deleted || this.disableActions) {
      this.canDelete = false;
      return;
    }
    if (
      this.comment &&
      this.comment.authorPersonView.personHash ==
        this.authInfoService.authPerson?.hash
    ) {
      this.canDelete = true;
      return;
    }
    this.canDelete = this.permissions.hasPermission(
      MingaPermission.CONTENT_DELETE_OTHERS,
    );
  }

  async updatePermissions() {
    await this.updateDeletePermissions();

    let canResolvPerm = this.permissions.hasPermission(
      MingaPermission.CONTENT_REPORTS_MANAGE,
    );
    let canReportPerm = this.permissions.hasPermission(
      MingaPermission.CONTENT_REPORT_CREATE,
    );
    let overridePerrm = this.permissions.hasPermission(
      MingaPermission.CONTENT_MODERATION_OVERRIDE,
    );

    const hasBeenDeleted = this.reportService.hasBeenDeleted(
      this.comment.commentContextHash,
    );

    this.canResolve =
      canResolvPerm &&
      (this.reportService.isReported({
        contextHash: this.comment.commentContextHash,
      }) ||
        (hasBeenDeleted && overridePerrm)) &&
      !this.disableActions;

    this.canReport =
      canReportPerm &&
      !this.comment.deleted &&
      !this.canResolve &&
      !this.disableActions;

    this._showDeletedStatus =
      overridePerrm && !!this.comment && this.comment.deleted;
    this._cdr.markForCheck();
  }

  replyClick(e?: any) {
    if (e && e.obj.commentContextHash != this.comment.commentContextHash) {
      e.parentContextHash = this.comment.commentContextHash;
    }
    this.onReplyClick.emit(e);
  }

  getResponseObject() {
    let response: ICommentDisplay = {
      obj: this.comment,
    };
    return response;
  }

  humanize(timestamp: number | string) {
    if (typeof timestamp == 'string') {
      timestamp = parseInt(timestamp);
    }

    return day(timestamp).fromNow();
  }

  toggleShowingReplies() {
    this.showComments = !this.showComments;
  }

  async deleteClick(e?: any) {
    if (!this.canDelete) {
      this._systemAlertSnackBar.error(
        `You have insufficient permissions to delete this comment.`,
      );
      return;
    }
    const options: IDeleteConfirmationOptions = {
      showSubTitle: false,
      titleLocaleValue: this.comments.length
        ? "Comment and it's replies"
        : 'Comment',
    };

    let dialog = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: options,
    });
    dialog.afterClosed().subscribe(async result => {
      if (result === true) {
        const contentInfoQuery = new ContentInfoQuery();
        contentInfoQuery.setContentContextHash(this.comment.commentContextHash);
        const response = await this.contentCleanup.delete(contentInfoQuery);

        this.element.nativeElement.classList.add('deleted');
        setTimeout(() => {
          this.element.nativeElement.setAttribute('style', 'display: none');
          this.onCommentDeleted.emit(this.comment?.commentContextHash);
          this.contentEvents.emitContentDeleted({
            contentContextHash: this.comment.commentContextHash,
            typeString: 'COMMENT',
            parentContextHash: this.parentContextHash,
            childCount: this.comment.commentCount,
          });
        }, 100);

        this._systemAlertSnackBar.success(`comment deleted!`);
      }
    });
  }

  async reportClick(e) {
    await this.contentState.openReport(this.comment.commentContextHash);
  }

  async resolveClick(e) {
    if (this.comment.deleted) {
      await this.contentState.overrideAI(null, this.comment.commentContextHash);
    } else {
      await this.contentState.resolveReport(this.comment.commentContextHash);
    }
  }

  _onContentDeleted(ev: string) {
    this.onCommentDeleted.emit(ev);
  }

  onSwipeLeft(e, contextHash: string) {
    let panDistance = e.distance;
    if (this.panEndValue != 0) {
      panDistance = 0;
    }
    this._applyPan(e, contextHash, panDistance, true);
  }

  private _applyPan(
    event,
    contextHash,
    inputDistance: number,
    fromLeft: boolean = true,
  ) {
    const rightOptionsWidth = this.panOptionsWidth;
    let translateDistance =
      inputDistance < rightOptionsWidth ? inputDistance : rightOptionsWidth;
    if (fromLeft) {
      translateDistance *= -1;
    }
    this.optionsPanDistance = translateDistance;
    // const panDistance = this.optionsPanDistance + COMMENT_ROW_WIDTH_OFFSET;

    this.commentRowEl.nativeElement.style.transform = `translateX(${this.optionsPanDistance}px)`;

    this._applyPanClass();
  }

  private _applyPanClass() {
    const swipeClassString = 'swipe-actions-revealed';
    if (this.optionsPanDistance) {
      if (!this.element.nativeElement.classList.contains(swipeClassString)) {
        this.element.nativeElement.classList.add(swipeClassString);
      }
    } else {
      this.element.nativeElement.classList.remove(swipeClassString);
    }
  }

  onSwipeRight(e, contextHash: string) {
    // if panned, then swiping other direction should reset
    if (this.optionsPanDistance) {
      this._resetPan();
      return;
    }
    let panDistance = e.distance;
    // remove this if statement to enable panning right with options on left
    if (this.panEndValue == 0 && panDistance > 0) {
      panDistance = 0;
    } else if (this.panEndValue != 0) {
      panDistance = 0;
    }
    this._applyPan(e, contextHash, panDistance, false);
  }

  private _resetPan() {
    this.commentRowEl.nativeElement.style.transform = `translateX(${COMMENT_ROW_WIDTH_OFFSET}px)`;
    this.panEndValue = this.optionsPanDistance = 0;

    this._applyPanClass();
  }

  onPanReplies(e) {
    this.panLock = true;
    this._resetPan();
  }

  onPanRepliesEnd(e) {
    this.panLock = false;
  }
}
