import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { Person } from 'minga/app/src/app/people';
import {
  HallPassStatusEnum,
  HallPassStudentCreatedFilter,
  IHallPassErrorType,
} from 'minga/domain/hallPass';
import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';
import { MingaPermission, MingaRoleType } from 'minga/domain/permissions';
import { HpmReportsFilter } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { ReportBaseAbstract } from 'src/app/components/manager-report/services/report-base.util';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { PermissionsService } from 'src/app/permissions';
import { HallPassService } from 'src/app/services/HallPass';
import { ListMembershipService } from 'src/app/services/ListMembership';

import { MmScheduledReportsService } from '@modules/minga-manager/components/mm-scheduled-reports/services';
import {
  ScheduledReportType,
  SUPPORTED_SCHEDULE_TYPES,
} from '@modules/minga-manager/components/mm-scheduled-reports/types';

import {
  FiltersFormData,
  FiltersFormService,
} from '@shared/components/filters-form';
import { FormSelectComponent } from '@shared/components/form';
import { FormSelectOption } from '@shared/components/form/types';
import { MediaService } from '@shared/services/media';

import { HpmRoutes } from '../../hpm.constants';
import { HpmRestrictionsBlackoutService } from '../hpm-restrictions/services';
import {
  HPM_CREATED_BY_OPTIONS,
  HPM_REPORT_DENIED_BY_OPTIONS,
  HPM_REPORT_SELECT_OPTIONS,
  HPM_REPORT_STATUS_OPTIONS,
  HPM_REPORTS_FILTER_INITIAL_STATE,
  HpmReportsMessages,
} from './hpm-reports.constants';
import { HpmReportsService } from './services';

// use 'b' and 'p' as arbitrary magic strings to allow for one filter
// without a compareWith fn, since it's possible for duplicate IDs. Only
// used in this component
const parseId = (id: string) => parseInt(id.slice(1), 10);
const makeBlackOut = (id: number) => 'b' + id;
const isBlackOut = (id: string) => id.startsWith('b');
const makeNoParty = (id: number) => 'p' + id;
const isNoParty = (id: string) => id.startsWith('p');

/**
 * Hall Pass Manager Reports Component
 *
 * Refactored Recently, this should be the standard for building
 * and refactoring further manager report modules
 */
@Component({
  selector: 'mg-hpm-reports',
  templateUrl: './hpm-reports.component.html',
  styleUrls: ['./hpm-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpmReportsComponent extends ReportBaseAbstract {
  @ViewChildren(FormSelectComponent)
  private _selectComponents: QueryList<FormSelectComponent>;

  /** Constants */
  public readonly MESSAGES = HpmReportsMessages;
  public readonly CREATED_BY_OPTIONS = HPM_CREATED_BY_OPTIONS;
  public readonly REPORT_TYPE = ReportTypes;

  /** Mobile Device */
  readonly isMobileDevice: boolean =
    window.MINGA_DEVICE_ANDROID || window.MINGA_DEVICE_IOS;

  /** General Observables */
  public readonly media$: Observable<any>;
  public readonly canScheduleReport$ = this._permissions.observePermission(
    MingaPermission.HALL_PASS_TYPE_MANAGE,
  );
  public SUPPPORTED_SCHEDULE_TYPES: ScheduledReportType[] =
    SUPPORTED_SCHEDULE_TYPES;

  /** Table Data Service */
  private _dataServiceSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public readonly dataService$: Observable<any> =
    this._dataServiceSubj.asObservable();

  /** Filters */
  public readonly REPORT_OPTIONS = HPM_REPORT_SELECT_OPTIONS;
  public readonly createdBy$: Observable<HallPassStudentCreatedFilter> =
    this.hpmReports.filter$.pipe(map(state => state.createdBy));

  /** Hall Pass Types Select Options */
  public readonly hallpassTypesSelectOptions$: Observable<
    FormSelectOption<number>[]
  >;

  public readonly STATUS_OPTIONS = HPM_REPORT_STATUS_OPTIONS;
  public readonly DENIED_BY_OPTIONS = HPM_REPORT_DENIED_BY_OPTIONS;
  public datasource$: Observable<ReportDatasourceService<any>>;

  protected readonly _reportTypeSubject = new BehaviorSubject<ReportTypes>(
    ReportTypes.HALL_PASS_SUMMARY,
  );

  private _loadingSubject = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this._loadingSubject.asObservable();

  public filtersFormStructure$ = combineLatest([
    this._reportTypeSubject,
    this._hallpassService.getHallPassTypes(),
    this._permissions.observePermission(MingaPermission.HALL_PASS_TYPE_MANAGE),
    this._blackoutService.schedules$,
    // we still want to emit this observable even if it fails (most likely due to perrmission errors)
    // we hide corresponding filters depending on permissions so sensitive data will be hidden regardless
    from(
      this._listService.getMembershipListByType([MembershipListType.NO_PARTY]),
    ).pipe(
      catchError(() => {
        return of([] as IMembershipList[]);
      }),
    ),
    this.hpmReports.filter$,
  ]).pipe(
    takeUntil(this._destroyed),
    map(
      ([
        reportType,
        types,
        hasHallPassTypeManagePermission,
        blackouts,
        noParty,
        filters,
      ]) => {
        const issuedBy = filters[HpmReportsFilter.ISSUED_BY] || [];
        const issuedTo = filters[HpmReportsFilter.ISSUED_TO] || [];
        const formData: FiltersFormData = {
          person: {
            type: 'people-search',
            label: 'Name, ID or email',
            multiple: true,
            value: [...issuedBy, ...issuedTo],
          },
          [HpmReportsFilter.HALLPASS_TYPE]: {
            type: 'multi-select',
            label: 'Hall pass type',
            id: 'hall-pass-type',
            options: types.map(type => ({
              label: type.name,
              value: type.id,
            })),
            value: filters[HpmReportsFilter.HALLPASS_TYPE],
          },
          [HpmReportsFilter.USER_LIST]: {
            type: 'user-list',
            label: 'User list',
            value: filters[HpmReportsFilter.USER_LIST],
          },
        };
        if (
          reportType === this.REPORT_TYPE.HALL_PASS_HISTORY &&
          hasHallPassTypeManagePermission
        ) {
          formData[HpmReportsFilter.STATUS] = {
            type: 'multi-select',
            label: 'Status',
            id: 'status',
            options: HPM_REPORT_STATUS_OPTIONS,
            value: filters[HpmReportsFilter.STATUS],
          };
          formData[HpmReportsFilter.DENIED_BY] = {
            label: 'Denied by',
            type: 'multi-select',
            options: HPM_REPORT_DENIED_BY_OPTIONS,
            value: filters[HpmReportsFilter.DENIED_BY],
          };
          formData['denial-reason'] = (formState, skipComputation = false) => {
            if (skipComputation) {
              return {
                label: this.MESSAGES.SELECT_LABEL_DENIAL_REASON,
                type: 'multi-select',
                disabled: true,
                options: [],
                value: [],
              };
            }

            const deniedBy = formState[HpmReportsFilter.DENIED_BY];

            let denialReasonOptions: FormSelectOption<string>[] = [];

            const blackoutSelected = deniedBy?.includes(
              IHallPassErrorType.BLACK_OUT_WINDOW,
            );

            const initBlackouts = filters.deniedByBlackout.map(makeBlackOut);

            if (blackoutSelected && blackouts?.length) {
              const blackoutOptions = blackouts.map(blackout => ({
                label: blackout.name,
                value: makeBlackOut(blackout.id),
              }));
              denialReasonOptions = [
                ...denialReasonOptions,
                ...blackoutOptions,
              ];
            }

            const noPartySelected = deniedBy?.includes(
              IHallPassErrorType.NO_PARTY,
            );

            const initNoParty = filters.deniedByNoParty.map(makeNoParty);

            if (noPartySelected && noParty?.length) {
              const noPartyOptions = noParty.map(group => ({
                label: group.name,
                value: makeNoParty(group.id),
              }));
              denialReasonOptions = denialReasonOptions.concat(noPartyOptions);
            }

            return {
              label: this.MESSAGES.SELECT_LABEL_DENIAL_REASON,
              type: 'multi-select',
              disabled: !denialReasonOptions?.length,
              options: denialReasonOptions,
              value: [...initBlackouts, ...initNoParty],
            };
          };
        }
        return this._filtersFormService.create(formData);
      },
    ),
    tap(() => {
      this._loadingSubject.next(false);
    }),
  );

  /** Component Constructor */
  constructor(
    public media: MediaService,
    public mediaObserver: MediaObserver,
    public hpmReports: HpmReportsService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _hallpassService: HallPassService,
    private _analytics: AnalyticsService,
    private _mmScheduledReportService: MmScheduledReportsService,
    private _permissions: PermissionsService,
    private _blackoutService: HpmRestrictionsBlackoutService,
    private _listService: ListMembershipService,
    private _filtersFormService: FiltersFormService,
  ) {
    super(hpmReports);
    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );
    // Get Select Options for Hall Pass Types Select
    this.hallpassTypesSelectOptions$ = this._hallpassService
      .getHallPassTypes()
      .pipe(
        takeUntil(this._destroyed),
        map(types =>
          types.map(type => ({
            label: type.name,
            value: type.id,
          })),
        ),
      );

    if (
      this._permissions.hasPermission(MingaPermission.HALL_PASS_TYPE_MANAGE)
    ) {
      this._blackoutService.initService();
    }

    this._route.url.pipe(takeUntil(this._destroyed)).subscribe(x => {
      if (this._route.children[0]) {
        this._route.children[0].url
          .pipe(takeUntil(this._destroyed))
          .subscribe(url => {
            if (url[0]) {
              const report = url[0].path as ReportTypes;
              const possiblePaths = this.REPORT_OPTIONS.map(type => type.value);
              if (possiblePaths.includes(report)) {
                this._reportTypeSubject.next(report);
                this._cdr.markForCheck();
              }
            }
          });
      }
    });

    this._initializeDates(
      HPM_REPORTS_FILTER_INITIAL_STATE,
      this._route.snapshot?.queryParams,
      this._destroyed,
      (range, fromChangeEvent) => {
        this.hpmReports.setFilter(HpmReportsFilter.START_DATE, range.start);
        this.hpmReports.setFilter(HpmReportsFilter.END_DATE, range.end);

        if (fromChangeEvent) {
          this.hpmReports.applyFilter();
        }
      },
    );
  }

  public async onChangeReportType(value: string): Promise<void> {
    await this._router.navigateByUrl(
      `/${HpmRoutes.ROOT}/${HpmRoutes.REPORTS}/${value}`,
    );
    return;
  }

  public applyFilter(): void {
    this.hpmReports.applyFilter();
    this._analytics.sendManagerReport({
      managerType: 'hallpass',
      reportType: this._reportTypeSubject.value,
    });
  }

  public clearFilter(): void {
    this.hpmReports.clearFilter();

    this._selectComponents.forEach((component, _) => {
      if (component.placeholder === 'Report Type') return;

      component.control.setValue(undefined);
    });

    this.range.patchValue({
      start: HPM_REPORTS_FILTER_INITIAL_STATE.startDate,
      end: HPM_REPORTS_FILTER_INITIAL_STATE.endDate,
    });
  }

  public updateFilters(v: any) {
    this.onPersonSelected(v.person);
    this.onChangeHallpassType(v[HpmReportsFilter.HALLPASS_TYPE]);
    this.onChangeUserList(v[HpmReportsFilter.USER_LIST]);
    if (this.isReportType(ReportTypes.HALL_PASS_HISTORY)) {
      this.onChangeStatus(v[HpmReportsFilter.STATUS]);
      this.onChangeDeniedBy(v[HpmReportsFilter.DENIED_BY]);
      this.onChangeDenialReason(
        v['denial-reason'],
        v[HpmReportsFilter.DENIED_BY] || [],
      );
    }
    this.applyFilter();
  }

  public onChangeHallpassType(value: any): void {
    this.hpmReports.setFilter(HpmReportsFilter.HALLPASS_TYPE, value);
  }

  public onPersonSelected(people: Person[]): void {
    if (!people) people = [];
    let issuedTo: Partial<Person>[] = [];
    let issuedBy: Partial<Person>[] = [];
    if (this.isReportType(ReportTypes.HALL_PASS_STUDENT)) {
      issuedTo = people;
    } else if (this.isReportType(ReportTypes.HALL_PASS_STAFF)) {
      issuedBy = people;
    } else {
      for (const person of people) {
        if (
          person.roleType === MingaRoleType.STUDENT ||
          person.roleType === MingaRoleType.STUDENT_LEADER
        ) {
          issuedTo.push(person);
        } else {
          issuedBy.push(person);
        }
      }
    }
    this.hpmReports.setFilter(HpmReportsFilter.ISSUED_BY, issuedBy);
    this.hpmReports.setFilter(HpmReportsFilter.ISSUED_TO, issuedTo);
  }

  public onChangeCreatedBy(event: MatButtonToggleChange | string): void {
    let value: string;

    if (typeof event === 'string') {
      value = event;
    } else {
      value = event.value;
    }

    if (!value) value = HallPassStudentCreatedFilter.ALL;
    this.hpmReports.setFilter(HpmReportsFilter.CREATED_BY, value);
    this.hpmReports.applyFilter();
  }

  public onChangeUserList(value: number[]): void {
    this.hpmReports.setFilter(HpmReportsFilter.USER_LIST, value);
  }

  public onChangeStatus(value: HallPassStatusEnum[]): void {
    this.hpmReports.setFilter(HpmReportsFilter.STATUS, value);
  }

  public onChangeDeniedBy(value: number[]): void {
    this.hpmReports.setFilter(HpmReportsFilter.DENIED_BY, value);
  }

  public onChangeDenialReason(value: string[], deniedBy: number[]): void {
    if (!value) value = [];
    const doBlackouts = deniedBy.includes(IHallPassErrorType.BLACK_OUT_WINDOW);
    const doNoParty = deniedBy.includes(IHallPassErrorType.NO_PARTY);

    const blackouts: number[] = [];
    const noParty: number[] = [];

    for (const reason of value) {
      if (doBlackouts && isBlackOut(reason)) blackouts.push(parseId(reason));
      else if (doNoParty && isNoParty(reason)) noParty.push(parseId(reason));
    }

    this.hpmReports.setFilter(HpmReportsFilter.DENIED_BY_BLACKOUT, blackouts);
    this.hpmReports.setFilter(HpmReportsFilter.DENIED_BY_NO_PARTY, noParty);
  }

  public onActivate(componentRef: any) {
    this._dataServiceSubj.next(componentRef.ds);
  }

  public async onScheduleReport() {
    const filters = await this.hpmReports.filter$.pipe(take(1)).toPromise();
    await this._mmScheduledReportService.scheduleReport(
      this._reportTypeSubject.value,
      filters,
    );
  }
}
