import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

import JsBarcode from 'jsbarcode';

@Directive({
  selector: '[mgViewIdBarcode]',
})
export class ViewIdBarcodeDirective implements OnChanges {
  // Inputs

  @Input() studentId: string;
  @Input() barcodeFormat: string;
  @Input() displayIdValue: boolean;

  // Constructor

  constructor(private _el: ElementRef) {}

  // Lifecycle Hooks

  public ngOnChanges(): void {
    this._generateBarcode();
  }

  // Private Methods

  private _generateBarcode() {
    if (
      this.studentId &&
      this.barcodeFormat &&
      this.displayIdValue !== undefined
    ) {
      JsBarcode(this._el.nativeElement, this.studentId, {
        height: 100,
        format: this.barcodeFormat,
        displayValue: this.displayIdValue,
      });
    }
  }
}
