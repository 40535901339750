export const getNumberOfElementsToShow = (
  els: Element[],
  opts: {
    containerWidth: number;
    /**
     * gap between elements
     */
    gap?: number;
    /**
     * reserved space needed to show "more items" tag
     */
    reservedSpace?: number;
    /**
     * number of lines the tags can wrap onto
     */
    lines?: number;
  },
): number => {
  const { containerWidth, gap = 0, reservedSpace = 0, lines = 2 } = opts;
  // we need a little extra buffer on width of items to match how flex seems to be calculating it
  const WIDTH_BUFFER = 3;

  if (els.length === 0 || lines <= 0 || containerWidth <= 0) return 0;

  let visibleCount = 0;
  let currentLine = 1;
  let currentLineWidth = 0;

  // loop through each tag and based on it's width lets calculate how many tags we can show
  for (let i = 0; i < els.length; i++) {
    const child = els[i] as HTMLElement;
    // gets the width of the tag
    const childWidth = child.offsetWidth + WIDTH_BUFFER;
    const isLastLine = currentLine === lines;
    const isLastItem = i === els.length - 1;

    // no gap for first item in line
    let gapToAdd = i === 0 ? 0 : gap;

    // we need to take into account the reserved space for the last line and last item (eg potentially show 'more items' tag)
    const availableWidth = isLastLine
      ? containerWidth - (isLastItem ? 0 : reservedSpace)
      : containerWidth;

    // if the tag is wider than available widtth
    if (currentLineWidth + childWidth + gapToAdd > availableWidth) {
      // if we're on the last line we can't do anything else so bail
      if (isLastLine) break;

      // Lets try the tag on the next line
      currentLine++;
      currentLineWidth = 0;

      // Recheck on new line to see if we can fit the tag, if not bail
      if (
        currentLine === lines &&
        childWidth > containerWidth - reservedSpace
      ) {
        break;
      }

      // we hit here this item is getting added and it's first item in line so no gap
      gapToAdd = 0;
    }

    // if we hit here it means we can fit this tag
    currentLineWidth += childWidth + gapToAdd;
    visibleCount++;
  }

  return visibleCount;
};
