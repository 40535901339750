import { Pipe } from '@angular/core';

import textClipperSafe from 'minga/app/src/app/util/text-clipper-safe';

@Pipe({ name: 'mgTextClipper' })
export class MgTextClipperPipe {
  constructor() {}

  transform(value: any, options?: any): string {
    let clipAmount = (options && options.clipAmount) || 50;

    if (typeof value === 'string') {
      return textClipperSafe(value, clipAmount, {
        html: true,
        newlineWeight: 50,
      });
    }

    return '';
  }
}
