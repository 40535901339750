import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
} from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { IMingaFeatureToggle } from 'minga/domain/featureToggle';
import { MingaPermission } from 'minga/domain/permissions';
import { PermissionsService } from 'src/app/permissions';
import { MingaManagerService } from 'src/app/services/MingaManager';
import {
  MingaSettingsService,
  MingaStoreFacadeService,
} from 'src/app/store/Minga/services';

import { NavigationService } from '@shared/services/navigation';

import { MmDashboardFeatureToggleService } from '../../services';
import { MingaManagerDashboardModuleMessages } from './mm-dashboard-modules.constants';

@Component({
  selector: 'mg-mm-dashboard-modules',
  templateUrl: './mm-dashboard-modules.component.html',
  styleUrls: ['./mm-dashboard-modules.component.scss'],
  providers: [MmDashboardFeatureToggleService],
})
export class MmDashboardModulesComponent implements OnInit, OnDestroy {
  public readonly MESSAGES = MingaManagerDashboardModuleMessages;
  public isSuperAdmin = false;

  private _destroyed$ = new ReplaySubject<void>(1);
  private _mingaHash = '';
  private _currentMingaHash = '';

  @Input()
  set mingaHash(hash: string) {
    this._mingaHash = hash;
    this.mmFeatureService.initFeatureToggleService(this._mingaHash);
  }

  @Input()
  canUpdateInstantly = false;

  @Output()
  moduleStatesChanged = new EventEmitter<IMingaFeatureToggle>();

  constructor(
    public mmFeatureService: MmDashboardFeatureToggleService,
    private _permissions: PermissionsService,
    private _mingaManagerService: MingaManagerService,
    private _settingsService: MingaSettingsService,
    private _mingaStore: MingaStoreFacadeService,
    private _navigationService: NavigationService,
  ) {
    this.isSuperAdmin = this._permissions.hasPermission(
      MingaPermission.SUPERADMIN,
    );

    this._mingaStore
      .getMingaAsObservable()
      .pipe(takeUntil(this._destroyed$))
      .pipe(map(mingaInfo => mingaInfo.hash))
      .subscribe(hash => {
        this._currentMingaHash = hash;
      });

    this.mmFeatureService.featureStates$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(featureToggles => {
        if (!this.canUpdateInstantly) {
          this.moduleStatesChanged.emit(featureToggles);
        }
      });
  }

  get isExternalMinga() {
    return this._mingaHash !== this._currentMingaHash;
  }

  ngOnInit(): void {
    this.mmFeatureService.resetModuleForm();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private async _saveFeatureStates(featureToggles: IMingaFeatureToggle) {
    await this._mingaManagerService.updateMingaFeatureToggles(
      this._mingaHash,
      featureToggles,
    );
    if (!this.isExternalMinga) {
      await this._settingsService.updateFeatureToggleStore(featureToggles);
    }
    // reorder layout primary nav items
    this._navigationService.listPrimaryNavigationRoutes();
  }
}
