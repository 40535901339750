<section class="restriction">
  <mat-slide-toggle [formControl]="toggleFormControl">
    <div class="restriction-label">
      <mg-text
        *ngIf="label"
        variant="body-md"
        textAlign="left">
        {{ label }}
      </mg-text>
      <mg-tooltip
        *ngIf="tooltip"
        [label]="tooltip">
      </mg-tooltip>
    </div>
  </mat-slide-toggle>
  <div
    *ngIf="toggleFormControl.value"
    fxLayout="column"
    fxLayoutGap="16px">
    <div class="restriction-selector">
      <mg-form-select
        [id]="id"
        [placeholder]="MESSAGES.INPUT_PLACEHOLDER_RESTRICTION"
        [searchable]="false"
        [isClearable]="true"
        [isFullWidth]="true"
        [multiple]="allowMultiple"
        [options]="tabs"
        [value]="form.get(FORM_RESTRICTION_TABS.Select).value"
        [control]="form.get(FORM_RESTRICTION_TABS.Select)"
        [enableMobileSheet]="true"
        (selectionChanged)="restrictionTabChanged($any($event))">
      </mg-form-select>
    </div>
    <ng-container *ngIf="allowMultiple; else singleRestrictionTemplate">
      <ng-container *ngTemplateOutlet="multipleRestrictionTemplate">
      </ng-container>
    </ng-container>
  </div>
</section>

<!-- Single Restrictions Template -->
<ng-template #singleRestrictionTemplate>
  <ng-container [ngSwitch]="[selectedTabs$ | async]">
    <ng-container
      *ngSwitchCase="tabKeys.Role"
      [ngTemplateOutlet]="roleTemplate">
    </ng-container>
    <ng-container
      *ngSwitchCase="tabKeys.Lists"
      [ngTemplateOutlet]="listTemplate">
    </ng-container>
    <ng-container
      *ngSwitchCase="tabKeys.Stickers"
      [ngTemplateOutlet]="stickerTemplate">
    </ng-container>
    <ng-container
      *ngSwitchCase="tabKeys.Groups"
      [ngTemplateOutlet]="groupTemplate">
    </ng-container>
    <ng-container
      *ngSwitchCase="tabKeys.Grades"
      [ngTemplateOutlet]="gradeTemplate">
    </ng-container>
    <ng-container
      *ngSwitchCase="tabKeys.Reasons"
      [ngTemplateOutlet]="reasonTemplate">
    </ng-container>
    <ng-container
      *ngSwitchCase="tabKeys.MembershipList"
      [ngTemplateOutlet]="peopleTemplate">
    </ng-container>
  </ng-container>
</ng-template>

<!-- Multiple Restrictions Template -->
<ng-template #multipleRestrictionTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="12px">
    <ng-container
      *ngIf="(selectedTabs$ | async).includes(tabKeys.Role)"
      [ngTemplateOutlet]="roleTemplate">
    </ng-container>
    <ng-container
      *ngIf="(selectedTabs$ | async).includes(tabKeys.Lists)"
      [ngTemplateOutlet]="listTemplate">
    </ng-container>
    <ng-container
      *ngIf="(selectedTabs$ | async).includes(tabKeys.Stickers)"
      [ngTemplateOutlet]="stickerTemplate">
    </ng-container>
    <ng-container
      *ngIf="(selectedTabs$ | async).includes(tabKeys.Groups)"
      [ngTemplateOutlet]="groupTemplate">
    </ng-container>
    <ng-container
      *ngIf="(selectedTabs$ | async).includes(tabKeys.Grades)"
      [ngTemplateOutlet]="gradeTemplate">
    </ng-container>
    <ng-container
      *ngIf="(selectedTabs$ | async).includes(tabKeys.Reasons)"
      [ngTemplateOutlet]="reasonTemplate">
    </ng-container>
    <ng-container
      *ngIf="(selectedTabs$ | async).includes(tabKeys.MembershipList)"
      [ngTemplateOutlet]="peopleTemplate">
    </ng-container>
  </div>
</ng-template>

<!-- Role Template -->
<ng-template #roleTemplate>
  <ng-container *ngIf="roles$ | async as roles">
    <mg-form-select
      [placeholder]="MESSAGES.INPUT_PLACEHOLDER_ROLE"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [isClearable]="true"
      [options]="roles"
      [value]="form.get(FORM_RESTRICTION_TABS.Role).value"
      [control]="form.get(FORM_RESTRICTION_TABS.Role)"
      [enableMobileSheet]="true">
    </mg-form-select>
  </ng-container>
</ng-template>

<!-- List Template -->
<ng-template #listTemplate>
  <mg-user-list-filter
    [prefetchData]="[UserListCategory.ALL]"
    [placeholder]="MESSAGES.INPUT_PLACEHOLDER_LIST"
    [value]="form.get(FORM_RESTRICTION_TABS.Lists).value"
    (selectChanged)="form.get(FORM_RESTRICTION_TABS.Lists).setValue($event)">
  </mg-user-list-filter>
</ng-template>

<!-- Sticker Template -->
<ng-template #stickerTemplate>
  <ng-container *ngIf="stickers$ | async as stickers">
    <mg-form-select
      [placeholder]="MESSAGES.INPUT_PLACEHOLDER_STICKER"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [isClearable]="true"
      [options]="stickers"
      [value]="form.get(FORM_RESTRICTION_TABS.Stickers).value"
      [control]="form.get(FORM_RESTRICTION_TABS.Stickers)"
      [enableMobileSheet]="true">
    </mg-form-select>
  </ng-container>
</ng-template>

<!-- Group Template -->
<ng-template #groupTemplate>
  <ng-container *ngIf="groups$ | async as groups">
    <mg-form-select
      [placeholder]="MESSAGES.INPUT_PLACEHOLDER_GROUP"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [isClearable]="true"
      [options]="groups"
      [value]="form.get(FORM_RESTRICTION_TABS.Groups).value"
      [control]="form.get(FORM_RESTRICTION_TABS.Groups)"
      [enableMobileSheet]="true">
    </mg-form-select>
  </ng-container>
</ng-template>

<!-- Grade Template -->
<ng-template #gradeTemplate>
  <ng-container *ngIf="grades$ | async as grades">
    <mg-form-select
      [placeholder]="MESSAGES.INPUT_PLACEHOLDER_GRADE"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [isClearable]="true"
      [options]="grades"
      [value]="form.get(FORM_RESTRICTION_TABS.Grades).value"
      [control]="form.get(FORM_RESTRICTION_TABS.Grades)"
      [enableMobileSheet]="true">
    </mg-form-select>
  </ng-container>
</ng-template>

<!-- Reason Template -->
<ng-template #reasonTemplate>
  <ng-container *ngIf="reasons$ | async as reasons">
    <mg-form-select
      [placeholder]="MESSAGES.INPUT_PLACEHOLDER_REASON"
      [searchable]="true"
      [isFullWidth]="true"
      [isClearable]="true"
      [options]="reasons"
      [value]="form.get(FORM_RESTRICTION_TABS.Reasons).value"
      [control]="form.get(FORM_RESTRICTION_TABS.Reasons)"
      [enableMobileSheet]="true">
    </mg-form-select>
  </ng-container>
</ng-template>

<!-- People Template -->
<ng-template #peopleTemplate>
  <mg-membership-list-table
    [hideHeader]="true"
    [lists]="lists"
    [descriptions]="false"
    (listUpdated)="listUpdated($event, tabKeys.MembershipList)">
  </mg-membership-list-table>
</ng-template>
