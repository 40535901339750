import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IDesignedSvg } from 'minga/libraries/shared/designed_svg/types';
import { ShortCardView } from 'minga/proto/gateway/content_views_pb';
import { DesignedSvgMapper } from 'minga/shared-grpc/designed_svg';

import { BaseShortCardClass } from '../BaseShortCardClass';

export interface IMgPostShortCardElementProperties {}

@Component({
  selector: 'mg-post-short-card',
  templateUrl: './MgPostShortCard.element.html',
  styleUrls: ['./MgPostShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgPostShortCardElement extends BaseShortCardClass {
  /** @internal */
  private _content: ShortCardView.AsObject | null = null;
  /** @internal */
  private _designedSvg: IDesignedSvg | null = null;

  @Input()
  set content(content: ShortCardView.AsObject | null) {
    this._content = content || null;
    this.setDesignedSvgFromContent(content);
  }

  get content(): ShortCardView.AsObject | null {
    return this._content;
  }

  @Input()
  pinned: boolean = false;

  @Input()
  markKeywords: string = '';

  @Input()
  context: string = '';

  @Input()
  commentRoles: string[] | null = null;

  get designedSvg(): IDesignedSvg | null {
    return this._designedSvg;
  }

  private setDesignedSvgFromContent(content: ShortCardView.AsObject | null) {
    if (content && content.designedSvg) {
      this._designedSvg = DesignedSvgMapper.asObjectToIDesignedSvg(
        content.designedSvg,
      );
    } else {
      this._designedSvg = null;
    }
  }
}
