import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { IHallPassErrorType } from 'minga/domain/hallPass';
import { MembershipListType } from 'minga/domain/membershipList';
import { IMembershipList } from 'minga/domain/membershipList';
import { HpmReportsFilter } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { day } from 'minga/libraries/day';
import { MEMBERSHIP_LISTS_DATA } from 'minga/libraries/shared/membership_list/constants';

import { HpmReportsService, HpmRoutes } from '@modules/hallpass-manager';

import { HpmRestrictionsMessages } from './constants';
import { HpmRestrictionsBlackoutService } from './services';
import { HpmRestrictionsService } from './services/hpm-restrictions.service';

@Component({
  selector: 'mg-hpm-restrictions',
  templateUrl: './hpm-restrictions.component.html',
  styleUrls: ['./hpm-restrictions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpmRestrictionsComponent implements AfterViewInit {
  /** Messages */
  public readonly MESSAGES: typeof HpmRestrictionsMessages =
    HpmRestrictionsMessages;

  public readonly isLoading$: Observable<boolean>;

  public readonly noGroupsListMessage =
    MEMBERSHIP_LISTS_DATA[MembershipListType.NO_PARTY].description;

  get noPartyLists(): MembershipListType[] {
    return [MembershipListType.NO_PARTY];
  }

  get noPassLists(): MembershipListType[] {
    return [
      MembershipListType.NO_GRANT,
      MembershipListType.NO_PASS,
      MembershipListType.ONE_PASS,
      MembershipListType.TWO_PASS,
    ];
  }

  /** Component Constructor */
  constructor(
    public _restrictionsService: HpmRestrictionsService,
    public _blackoutService: HpmRestrictionsBlackoutService,
    private _router: Router,
    private _hpmReportsService: HpmReportsService,
  ) {
    this.isLoading$ = this._restrictionsService.isLoading$;
  }

  ngAfterViewInit(): void {
    this._restrictionsService.fetchAllLists();
  }

  public async openDeniedPassesReport(list: IMembershipList) {
    this._hpmReportsService.setFilter(
      HpmReportsFilter.START_DATE,
      day().subtract(30, 'day').startOf('day'),
    );

    this._hpmReportsService.setFilter(
      HpmReportsFilter.END_DATE,
      day().endOf('day'),
    );
    this._hpmReportsService.setFilter(HpmReportsFilter.DENIED_BY, [
      IHallPassErrorType.NO_PARTY,
    ]);
    this._hpmReportsService.setFilter(HpmReportsFilter.DENIED_BY_NO_PARTY, [
      list.id,
    ]);
    this._hpmReportsService.applyFilter();
    await this._router.navigate([
      HpmRoutes.ROOT,
      HpmRoutes.REPORTS,
      ReportTypes.HALL_PASS_HISTORY,
    ]);
  }
}
