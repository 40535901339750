import { Pipe, PipeTransform } from '@angular/core';

import { IConsequenceType } from 'minga/domain/consequences';
import { IPbisType } from 'minga/domain/pbis';
import { PbisCategory } from 'minga/libraries/shared/pbis/constants';

import { BehaviorManagerColors } from '../../../constants';

@Pipe({ name: 'getTileColor' })
export class BmDashboardTileColorsPipe implements PipeTransform {
  transform(item, type: 'behaviorType' | 'consequence' | 'automation'): string {
    if (type === 'behaviorType' || type === 'consequence') {
      const pbisType = item as IPbisType;
      return pbisType.categoryId === PbisCategory.PRAISE
        ? BehaviorManagerColors.PRAISE
        : BehaviorManagerColors.GUIDANCE;
    }
    if (type === 'automation') return BehaviorManagerColors.AUTOMATION;
  }
}
