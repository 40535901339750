import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hallPassTimeFormat',
})
export class HallPassTimeFormatPipe implements PipeTransform {
  transform(totalSeconds: number): string {
    if (isNaN(totalSeconds)) return 'NaN';

    // Handle negative here so caller can maintain cleaner logic
    const isNegative = totalSeconds < 0;
    totalSeconds = Math.round(Math.abs(totalSeconds));

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let formattedTime: string;
    if (hours > 0) {
      formattedTime = `${hours}:${this._pad(minutes)}:${this._pad(seconds)}`;
    } else {
      formattedTime = `${minutes}:${this._pad(seconds)}`;
    }

    return isNegative ? `+${formattedTime}` : formattedTime;
  }

  private _pad(value: number): string {
    return value.toString().padStart(2, '0');
  }
}
