import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EditableCheckin } from 'minga/domain/checkin';
import { MingaPermission } from 'minga/domain/permissions';
import {
  CheckInReportColumns,
  ColumnInfo,
  TemplateColumnKeys,
} from 'minga/libraries/shared/reports_columns';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { PermissionsService } from 'src/app/permissions';

import { BellSchedulePermissionsService } from '@modules/minga-manager/components/mm-bell-schedule/services/bell-schedule-permissions.service';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { CheckinManagerReportsService } from '../checkin-manager-reports.service';
import { CheckinManagerHistoryEditComponent } from '../components/checkin-manager-report-history/checkin-manager-history-edit.component';

@Injectable()
export class CheckinManagerHistoryDatasourceService extends ReportDatasourceService<EditableCheckin> {
  protected _editForm = CheckinManagerHistoryEditComponent;
  protected _displayCol: ColumnInfo[] = [];

  constructor(
    private _checkinManagerReportsService: CheckinManagerReportsService,
    _router: Router,
    private _permissions: PermissionsService,
    _alertModal: SystemAlertModalService,
    _snackBar: SystemAlertSnackBarService,
    _modalOverlay: ModalOverlayService,
    private _bellSchedulePermissions: BellSchedulePermissionsService,
  ) {
    super(
      _router,
      _checkinManagerReportsService,
      CheckinManagerHistoryDatasourceService.name,
      _alertModal,
      _snackBar,
      _modalOverlay,
    );
    this._archiveFn = this._checkinManagerReportsService.archiveCheckin.bind(
      this._checkinManagerReportsService,
    );
    let displayCol: ColumnInfo[] = [];
    if (this._permissions.hasPermission(MingaPermission.CHECKIN_TYPE_MANAGE)) {
      displayCol = [
        { key: TemplateColumnKeys.EDIT, header: '', columnClasses: ['w-16'] },
        {
          header: '',
          key: TemplateColumnKeys.ARCHIVE,
          columnClasses: ['w-16'],
        },
      ];
    }

    this._displayCol = displayCol;
    super.setDisplayColumns(CheckInReportColumns.REASON_HISTORY, displayCol);
  }

  async fetch(offset: number, limit: number) {
    // we need to check if current user has bell schedule permission to show/hide
    // current class column, we can do this asynchronously
    this._bellSchedulePermissions
      .isBellScheduleEnabledForCurrentUser()
      .then(isBellScheduleEnabledForCurrentUser => {
        if (!isBellScheduleEnabledForCurrentUser) {
          super.setDisplayColumns(
            CheckInReportColumns.REASON_HISTORY.filter(
              item => item.key !== 'currentClass',
            ),
            this._displayCol,
          );
        }
      });

    return await this._checkinManagerReportsService.getCheckinReport(
      offset,
      limit,
      this._currentSort,
    );
  }
}
