import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { from, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import QrCodeService from '@shared/services/qrcode';

import { ViewIdCodeFormats } from '../../constants';

@Component({
  selector: 'mg-view-id-code',
  templateUrl: './view-id-code.component.html',
  styleUrls: ['./view-id-code.component.scss'],
})
export class ViewIdCodeComponent implements OnInit, OnDestroy {
  // Constants

  public readonly VIEW_ID_CODE_FORMATS = ViewIdCodeFormats;

  // Inputs

  @Input() idNumber: string;
  @Input() format: string;
  @Input() hideIdNumber: boolean;

  // Observables

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  public qrCodeUrl$: Observable<string>;

  // Constructor

  constructor(private _qrCode: QrCodeService) {}

  // Lifecycle Hooks

  public ngOnInit(): void {
    this.qrCodeUrl$ = from(this._qrCode.createIdQrCode(this.idNumber)).pipe(
      takeUntil(this._destroyedSubject),
    );
  }

  public ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  // Public Methods

  /**
   * ng-switch-case does not properly support executing multiple cases into the same block of code
   * without code duplication, so mapping here in order to avoid redundancy
   */
  public mappedSwitchCase() {
    switch (this.format) {
      case ViewIdCodeFormats.BAR_128:
      case ViewIdCodeFormats.BAR_39:
        return 'barcode';
      default:
        return this.format;
    }
  }
}
