import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, shareReplay, takeUntil } from 'rxjs/operators';

import { mingaSettingTypes } from 'minga/libraries/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { BehaviorManagerService } from '@modules/behavior-manager/services';
import { LayoutService } from '@modules/layout/services';

import {
  BehaviorManagerMessages,
  BehaviorManagerRoutes,
  BehaviorsSubRoutes,
} from '../../constants';
import { BmTypesMessages } from './constants';
import {
  BmTypesAutomationService,
  BmTypesBehaviorService,
  BmTypesConsequenceService,
} from './services';

// can be removed when legacy theme support ends
const getConfigFromRoute = (route: string) => {
  const [, childSegment = ''] = route.split(
    `/${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.VIEW}`,
  );

  let key = BehaviorsSubRoutes.BEHAVIORS;
  if (childSegment.includes(BehaviorsSubRoutes.BEHAVIORS))
    key = BehaviorsSubRoutes.BEHAVIORS;
  if (childSegment.includes(BehaviorsSubRoutes.AUTOMATIONS))
    key = BehaviorsSubRoutes.AUTOMATIONS;
  if (childSegment.includes(BehaviorsSubRoutes.CONSEQUENCES))
    key = BehaviorsSubRoutes.CONSEQUENCES;

  return SUB_ROUTE_CONFIG[getRoute(key)];
};

// can be removed when legacy theme support ends
const getRoute = sub =>
  `/${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.VIEW}/${sub}`;

const SUB_ROUTE_CONFIG = {
  [getRoute(BehaviorsSubRoutes.BEHAVIORS)]: {
    title: 'Behaviors',
  },
  [getRoute(BehaviorsSubRoutes.CONSEQUENCES)]: {
    title: 'Consequences',
  },
  [getRoute(BehaviorsSubRoutes.AUTOMATIONS)]: {
    title: 'Automations',
  },
};

@Component({
  selector: 'mg-bm-types',
  templateUrl: './bm-types.component.html',
  styleUrls: ['./bm-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmTypesComponent {
  constructor() {}
}
