<button (click)="pressed.emit()">
  <div
    fxLayout="row"
    fxLayoutAlign="center center">
    <img
      src="assets/logo/kiosk-logo.svg"
      alt="Kiosk Logo" />

    <mg-icon>mg-arrow-right-alt</mg-icon>
  </div>
</button>
