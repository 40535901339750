import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import {
  ImageCroppedEvent,
  ImageTransform,
  LoadedImage,
} from 'ngx-image-cropper';

import { DEFAULT_IMAGE_ACCEPT } from 'minga/app/src/app/file';
import { MgModalService } from 'minga/app/src/app/minimal/services/MgModal';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  StudentIdImageService,
  StudentIdPresetSize,
} from '../../../shared/services/student-id-image/StudentIdImage.service';
import {
  IStudentIdUploaderDialogData,
  IStudentIdUploaderDialogResponse,
} from './types';

@Component({
  selector: 'mg-student-id-uploader-dialog',
  templateUrl: './student-id-uploader-dialog.component.html',
  styleUrls: ['./student-id-uploader-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentIdUploaderDialogComponent {
  files: File[] = [];
  previewUrl: string = '';
  existingImageUrl: string = '';
  previewSize: number = 150;
  accept: string = DEFAULT_IMAGE_ACCEPT;

  canvasRotation = 0;
  rotation = 0;
  scale = 1;

  transform: ImageTransform = {};
  imageChangedEvent: any = '';

  croppedImage: string = '';

  // IStudentIdUploaderDialogData values
  personHash: string = '';

  @HostBinding('class.loading')
  @Input()
  loading: boolean = false;

  @HostBinding('class.has-file')
  get hasFile() {
    return !!this.previewUrl;
  }

  constructor(
    private dialogRef: MatDialogRef<StudentIdUploaderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IStudentIdUploaderDialogData,
    private idUploaderService: StudentIdImageService,
    private _cdr: ChangeDetectorRef,
    private mgModal: MgModalService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private rootService: RootService,
    private dialog: MatDialog,
  ) {
    this.personHash = data.personHash;
    if (data.currentImage) {
      this.imageChangedEvent = null;
      this.existingImageUrl = data.currentImage;
    }
  }

  close(options?: IStudentIdUploaderDialogResponse) {
    this.dialogRef.close(options);
  }

  async onFileSelected(): Promise<void> {
    // No files. Don't continue
    if (!this.files || this.files.length === 0 || !this.files[0]) {
      console.warn(`[StudentIdUploaderDialogComponent] no file!`);
      return;
    }

    if (!this.personHash) {
      console.warn('[StudentIdUploaderDialogComponent] No personhash set!');
      return;
    }

    this.fileChangeEvent({ target: { files: this.files } });
  }

  async onSubmit() {
    if (!this.files.length && !this.existingImageUrl) {
      this.close({ deleteImage: true });
      return;
    }

    this.loading = true;
    try {
      let name = '';
      if (this.existingImageUrl) {
        name = this.existingImageUrl;
      } else {
        name = this.files[0].name;
      }
      const upload = await this.rootService.addLoadingPromise(
        this.idUploaderService.uploadBase64(
          this.croppedImage,
          name,
          this.personHash,
        ),
      );
      const fileName = upload.ref.name;
      const downloadUrl =
        await this.idUploaderService.getFilePathToStudentIdImage(
          fileName,
          StudentIdPresetSize.RAW,
        );
      this.close({ fileName, previewUrl: downloadUrl });
    } catch (err) {
      this.loading = false;
      console.error(`[StudentIdUploaderDialogComponent] submit error: `, err);
      this._systemAlertSnackBar.error(`An error occured, please try again.`);
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64 as string;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = { ...this.transform, flipH: flippedV, flipV: flippedH };
  }

  flipHorizontal() {
    this.transform = { ...this.transform, flipH: !this.transform.flipH };
  }

  flipVertical() {
    this.transform = { ...this.transform, flipV: !this.transform.flipV };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
    this.files = [];
    this.existingImageUrl = '';
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = { ...this.transform, scale: this.scale };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = { ...this.transform, scale: this.scale };
  }

  updateRotation() {
    this.transform = { ...this.transform, rotate: this.rotation };
  }
}
