import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mg-flex-time-manager',
  templateUrl: './flex-time-manager.component.html',
  styleUrls: ['./flex-time-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexTimeManagerComponent {
  /** Component Constructor */
  constructor() {}
}
