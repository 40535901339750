<div
  class="page-header"
  fxLayout="row"
  fxLayoutAlign="end center">
  <div
    fxFlex
    fxLayoutAlign.gt-sm="end end">
    <mg-btn
      variant="filled"
      [responsive]="true"
      (pressed)="addScheduledReport($event)">
      Schedule a report
    </mg-btn>
  </div>
</div>
<ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>

<!-- Main Content Template -->
<ng-template #mainContentTemplate>
  <div
    class="sections"
    fxLayout="column"
    fxLayoutGap="32px">
    <section
      class="section scheduled"
      fxLayout="column">
      <div
        class="section-intro"
        fxLayout="column"
        fxLayoutAlign="start start">
        <mg-text variant="header-md-secondary">
          {{ MESSAGES.SCHEDULED_TITLE }}
        </mg-text>
      </div>
      <mg-mm-sr-reports-table></mg-mm-sr-reports-table>
    </section>
    <section
      class="section history"
      fxLayout="column">
      <div
        class="section-intro"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.xs="start start"
        fxLayoutGap="12px">
        <mg-text variant="header-md-secondary">
          {{ MESSAGES.HISTORY_TITLE }}
        </mg-text>
        <mg-system-alert
          type="default"
          [message]="MESSAGES.HISTORY_INFO">
        </mg-system-alert>
      </div>

      <mg-mm-sr-history-table></mg-mm-sr-history-table>
    </section>
  </div>
</ng-template>
