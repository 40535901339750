import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  of,
} from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { ExistingConsequenceType } from 'minga/domain/consequences';
import { PbisCategory } from 'minga/libraries/shared/pbis/constants';
import { mingaSettingTypes } from 'minga/libraries/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { newTableRowGlow } from '@shared/animations/table';

import { BehaviorManagerService } from '../../../../services';
import { BmConsequencesMessages } from '../../constants';
import { BmTypesConsequenceService } from '../../services';
import { ICON_CONFIG_DEFAULTS } from '../bm-types-consequence-edit/bm-types-consequence-edit.constants';

/**
 * Behavior Manager Types - Behaviors
 */
@Component({
  selector: 'mg-bm-types-consequences',
  templateUrl: './bm-types-consequences.component.html',
  styleUrls: ['./bm-types-consequences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [newTableRowGlow],
})
export class BmTypesConsequencesComponent implements OnDestroy, OnInit {
  /** Constants */
  public readonly MESSAGES = BmConsequencesMessages;
  public readonly PBIS_CATEGORIES = PbisCategory;

  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);

  private _praisesSubj: BehaviorSubject<ExistingConsequenceType[]> =
    new BehaviorSubject([]);
  public praises$ = this._praisesSubj.asObservable();
  private _guidancesSubj: BehaviorSubject<ExistingConsequenceType[]> =
    new BehaviorSubject([]);
  public guidances$ = this._guidancesSubj.asObservable();
  public defaultPraiseIcon = ICON_CONFIG_DEFAULTS[PbisCategory.PRAISE];
  public defaultGuidanceIcon = ICON_CONFIG_DEFAULTS[PbisCategory.GUIDANCE];
  public consequencesEnabled$ = this._settingsService.getSettingValueObs(
    mingaSettingTypes.BM_CONSEQUENCE_ENABLE,
  );
  public consWithDisabledOverdueCons$: Observable<ExistingConsequenceType[]>;
  private _reloadListSubject = new BehaviorSubject<void>(undefined);

  /** Loading */
  public isLoading$ = this._bmService.isLoadingConsequenceTypesData$;

  /** Component Constructor */
  constructor(
    public typesConsequence: BmTypesConsequenceService,
    private _bmService: BehaviorManagerService,
    private _settingsService: MingaSettingsService,
  ) {
    this._initialFetch();
  }

  ngOnInit() {
    combineLatest([
      this.consequencesEnabled$,
      this._reloadListSubject.asObservable(),
    ])
      .pipe(
        switchMap(([consequencesEnabled, _]) => {
          return consequencesEnabled
            ? this._bmService.consequenceTypes$
            : of([]);
        }),
        takeUntil(this._destroyed),
      )
      .subscribe(data => {
        this._praisesSubj.next(
          data.filter(item => item.categoryId === PbisCategory.PRAISE),
        );
        this._guidancesSubj.next(
          data.filter(item => item.categoryId === PbisCategory.GUIDANCE),
        );
      });

    this.consWithDisabledOverdueCons$ = this.guidances$.pipe(
      map(consequenceTypes => {
        return consequenceTypes.filter(consequenceType => {
          if (consequenceType && consequenceType?.overdueActionId) {
            return !consequenceType?.overdueAction?.active;
          } else return false;
        });
      }),
    );

    this._reloadListSubject.next();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public async changeGuidanceStatus(
    consequenceType: ExistingConsequenceType,
    status: boolean,
  ) {
    await this.typesConsequence.changeStatus(consequenceType, status);

    await this._initialFetch();
    this._reloadListSubject.next();
  }

  private async _initialFetch(): Promise<void> {
    await this._bmService.fetchConsequenceTypes();
  }
}
