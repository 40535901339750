import { NgModule } from '@angular/core';

import { PlaceholderCardModule } from './card/placeholder-card.module';
import { PlaceholderElementsModule } from './elements/placeholder-elements.module';
import { PlaceholderSkeletonModule } from './skeleton/placeholder-skeleton.module';

@NgModule({
  exports: [
    PlaceholderCardModule,
    PlaceholderElementsModule,
    PlaceholderSkeletonModule,
  ],
})
export class PlaceholderModule {}
