<ng-container [ngSwitch]="mappedSwitchCase()">
  <div *ngSwitchCase="VIEW_ID_CODE_FORMATS.QR">
    <div class="student-qrcode">
      <div
        *ngIf="qrCodeUrl$ | async as qrCodeUrl"
        class="qr-image"
        [ngStyle]="{
          'background-image': 'url(;' + qrCodeUrl + ')'
        }"></div>
      <div
        *ngIf="!hideIdNumber"
        class="id-number">
        {{ idNumber }}
      </div>
    </div>
  </div>

  <svg
    *ngSwitchCase="'barcode'"
    #barcode
    mgViewIdBarcode
    [studentId]="idNumber"
    [barcodeFormat]="format"
    [displayIdValue]="!hideIdNumber"
    id="barcode"></svg>
</ng-container>
