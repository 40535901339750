import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

import { MingaFiles } from 'minga/app/src/app/models/MingaFiles';
import { LinkOpenerService } from 'minga/app/src/app/services/LinkOpener';
import { getFileExtension, getFileTitle } from 'minga/libraries/util/File';
import { FileDetails } from 'minga/proto/gateway/file_pb';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { IFileDownloadStatus } from './public_interfaces';

@Injectable()
/**
 * Handle file download requests. Will use browser if desktop, and use the
 * cordova downloading and opening on hybrids.
 */
export class FileDownloaderService {
  initialized: boolean = false;
  downloader: any;
  private downloadStatuses: Map<string, IFileDownloadStatus> = new Map();
  private _onDownloadStatusUpdate: Subject<IFileDownloadStatus>;

  constructor(
    private linkOpener: LinkOpenerService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {
    this._onDownloadStatusUpdate = new Subject();
  }

  get onDownloadStatusUpdate(): Observable<IFileDownloadStatus> {
    return this._onDownloadStatusUpdate.asObservable();
  }

  private _init() {
    if (this.initialized) {
      this.abortDownloads();
    }

    // folder and file system must be initialized per download request
    (<any>window).downloader.init({ folder: 'Minga_Downloads' });
    this.initialized = true;
  }

  abortDownloads() {
    // abort any current downloads
    (<any>window).downloader.abort();
  }

  getDownloadStatus(key: string) {
    return this.downloadStatuses.get(key);
  }

  openDownload(key: string) {
    const currentStatus = this.getDownloadStatus(key);

    if (!currentStatus || !currentStatus.complete || !currentStatus.openData) {
      this._systemAlertSnackBar.error(
        `file not available to open, please re-download`,
      );
    }

    const fileOpenData = currentStatus.openData;
    this._openDownloadedFile(fileOpenData.nativeUrl, fileOpenData.mimeType);
  }

  downloadFromUrl(url: string, name: string = null, mimeType: string = null) {
    if (window.MINGA_APP_BROWSER) {
      this.linkOpener.openFile(url);
    } else {
      this._downloadUrl(url, name, mimeType);
    }
  }

  downloadFromFileDetails(file: FileDetails.AsObject) {
    if (file) {
      let filePath = file.filePath;

      if (file.image && !_.isEmpty(file.image)) {
        // @TODO: add download ability to lightbox and re-enable lightbox here
        //   this.openImagebox.emit(file.image);
        //   console.log(`emitting openImagebox!`, file.image);

        const sizeMap = file.image.sizeMap;
        sizeMap.forEach(entry => {
          sizeMap[entry[0]] = entry[1];
        });

        const sizeInfo =
          sizeMap['longcardbanner'] || sizeMap['cardbanner'] || sizeMap['raw'];
        filePath = window.MINGA_ASSET_URL_PREFIX + sizeInfo.path;
      }
      if (file.url) {
        this.linkOpener.open(file.url);
      } else if (!filePath) {
        this._systemAlertSnackBar.error(
          `The file does not have a path to be opened :(`,
        );
      } else {
        // use complete file name with extension
        const fileName = MingaFiles.getStatusKey(file);

        // download the file
        this.downloadFromUrl(filePath, fileName, file.mimeType);
      }
    } else {
      console.error(`downloadFromFileDetails file is falsey!`, file);
      this._systemAlertSnackBar.error(`cannot open this file :'(`);
    }
  }

  private _downloadUrl(
    url: string,
    name: string = null,
    mimeType: string = null,
  ) {
    console.log(`_downloadUrl(${url}, ${name}, ${mimeType}`);
    if (
      window.MINGA_APP_ANDROID &&
      (<any>window).cordova.plugins['DownloadManager']
    ) {
      this._downloadAndroidUrl(url, name, mimeType);
    } else {
      this._downloadHybridUrl(url, name, mimeType);
    }
  }

  private _getFilenameFromUrl(url: string) {
    let startIndex = url.lastIndexOf('/');
    const name = url.substring(++startIndex);
    return name;
  }

  private _downloadHybridUrl(
    url: string,
    name: string = null,
    mimeType: string = null,
  ) {
    this._init();

    if (name) {
      (<any>window).downloader.get(url, null, name);
    } else {
      name = this._getFilenameFromUrl(url);
      (<any>window).downloader.get(url);
    }

    this.downloadStatuses.set(name, {
      complete: false,
      canceled: false,
      progress: 0,
      fileKey: name,
    });

    document.addEventListener('DOWNLOADER_noWifiConnection', (event: any) => {
      let data = event.data;
      console.log('DOWNLOADER_noWifiConnection', data);

      this._systemAlertSnackBar.error(`lost connection, try again`);
    });

    document.addEventListener(
      'DOWNLOADER_downloadProgress',
      this._downloadProgress.bind(this),
    );
    document.addEventListener(
      'DOWNLOADER_error',
      this._downloadError.bind(this),
    );
    document.addEventListener(
      'DOWNLOADER_downloadSuccess',
      function _onDownloadFinished(data) {
        this._onDownloadSuccess(data, mimeType);
        // remove this event listener now that the download was successful
        document.removeEventListener(
          'DOWNLOADER_downloadSuccess',
          _onDownloadFinished.bind(this),
        );
      }.bind(this),
    );
    document.addEventListener(
      'DOWNLOADER_downloadError',
      this._downloadError.bind(this),
    );
    document.addEventListener(
      'DOWNLOADER_getFileError',
      this._getFileError.bind(this),
    );
  }

  private _removeEventListeners() {
    document.removeEventListener(
      'DOWNLOADER_downloadProgress',
      this._downloadProgress,
    );
    document.removeEventListener('DOWNLOADER_error', this._downloadError);
    document.removeEventListener(
      'DOWNLOADER_downloadError',
      this._downloadError,
    );
    document.removeEventListener('DOWNLOADER_getFileError', this._getFileError);
  }

  private _downloadProgress(eventData: any) {
    const data = eventData.data;
    console.log('DOWNLOADER_downloadProgress', data);

    const currentStatus = this.downloadStatuses.get(data[1]);
    if (!!currentStatus) {
      currentStatus.progress = data[0];
      this.downloadStatuses.set(data[1], currentStatus);
      this._onDownloadStatusUpdate.next(currentStatus);
    }
  }

  private _downloadError(eventData: any) {
    const data = eventData.data;
    console.log('DOWNLOADER_error', data);
    this._removeEventListeners();
    this._errorOutDownloads();

    this._systemAlertSnackBar.error(
      `error occurred while downloading, please try again`,
    );
  }

  private _getFileError(eventData: any) {
    const data = eventData.data;
    console.log('DOWNLOADER_getFileError', data);
    this._systemAlertSnackBar.error(
      `error occurred while downloading file, please try again`,
    );
    this._removeEventListeners();
  }

  /**
   * Uses the Android's native download manager notification system to handle
   * the download.
   * @param url
   * @param name
   * @param mimeType
   */
  private _downloadAndroidUrl(
    url: string,
    name: string = null,
    mimeType: string = null,
  ) {
    let success = data => {
      console.log(`android download success`, data);
      const title = getFileTitle(url);
      const extension = getFileExtension(url);

      // @TODO figure out the full path to use if want to add auto opening
      // this._openDownloadedFile(
      //   `cdvfile://localhost/persistent/Download/${title}.${extension}`,
      //   mimeType);
    };
    let fail = data => {
      console.log(`android download fail`, data);
    };

    (<any>window).cordova.plugins['DownloadManager'].download(
      url,
      success,
      fail,
    );
  }

  private _onDownloadSuccess(eventData: any, mimeType: string) {
    const downloadData = eventData.data;
    console.log(`DOWNLOADER_downloadSuccess type: ${mimeType}`, downloadData);

    this._removeEventListeners();

    const files: Array<any> = <Array<any>>downloadData;
    const fileEntry = files[0];
    const fileName = fileEntry.name;
    const currentStatus = this.downloadStatuses.get(fileName);

    if (!!currentStatus) {
      currentStatus.progress = 100;
      currentStatus.complete = true;
      currentStatus.openData = { nativeUrl: fileEntry.nativeURL, mimeType };

      this.downloadStatuses.set(fileName, currentStatus);
      this._onDownloadStatusUpdate.next(currentStatus);
      console.log(`currentStatus after sending update`, currentStatus);
    }
  }

  private _openWithSnackbar(nativeUrl: string, mimeType: string) {
    let openFileBool = false;

    const snackBar = this._systemAlertSnackBar.success(
      `File downloaded successfully`,
    );
    openFileBool = true;
    if (openFileBool) {
      this._openDownloadedFile(nativeUrl, mimeType);
    }
  }

  private _openDownloadedFile(path: string, mimeType: string) {
    console.log(`_openDownloadedFile() type: ${mimeType}\t path: ${path} `);
    (<any>window).cordova.plugins['fileOpener2'].open(path, mimeType, {
      error: e => {
        console.log(
          'Error status: ' + e.status + ' - Error message: ' + e.message,
        );
      },
      success: () => {
        console.log('file opened successfully');
      },
    });
  }

  private _errorOutDownloads() {
    this.downloadStatuses.forEach(downloadStatus => {
      downloadStatus.error = true;
      downloadStatus.complete = false;
      downloadStatus.progress = null;
      downloadStatus.openData = null;

      this._onDownloadStatusUpdate.next(downloadStatus);
    });
  }
}
