<!-- Main Container -->
<div class="sidebar">
  <!-- Title -->
  <div class="sidebar-title">
    <mg-text
      variant="body-md-secondary"
      textAlign="left"
      spacing="3">
      {{ sideBarTitle$ | async }}
    </mg-text>
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="12px">
    <!-- File Search -->
    <ng-container
      *ngIf="form.filters.hasLoadedFile$ | async; else standardSearch"
      [ngTemplateOutlet]="fileSearchTemplate">
    </ng-container>
  </div>
  <!-- Desktop filters -->
  <ng-container
    *ngIf="(media.isMobileView$ | async) === false"
    [ngTemplateOutlet]="filtersTemplate">
  </ng-container>
</div>

<!-- Standard Sidebar Template -->
<ng-template #standardSearch>
  <div class="tw-flex tw-flex-row tw-gap-2 tw-full">
    <div class="tw-flex tw-flex-column tw-gap-2.5 tw-grow">
      <!-- Search Field -->
      <mg-form-text-input
        #searchField
        inputType="text"
        class="tw-full"
        [autofocus]="true"
        [label]="MSG.LABEL_SEARCH"
        [setFocus]="keywordsFocusSubj"
        [control]="searchFieldControl"
        [id]="'ps-search-input'">
      </mg-form-text-input>
      <!-- BarCode Search Field -->
      <mg-form-text-input
        inputType="text"
        class="tw-full"
        [isDisabled]="
          (form.searchResults.isLoading$ | async) ||
          (form.isSubmitting$ | async)
        "
        [fxHide]="(this.form.searchMode$ | async) !== 'barcode'"
        [setFocus]="barCodeFocusSubj"
        [label]="MSG.LABEL_BARCODE_SEARCH"
        [control]="barCodeFieldControl"
        (returnPressed)="submitBarcodeInput()"
        [id]="'ps-barcode-search-input'">
      </mg-form-text-input>
    </div>
    <!-- Filters button -->
    <div
      *ngIf="showMobileFiltersFormButton$ | async"
      fxFlex="nogrow">
      <mg-filters-form
        appearance="secondary"
        [responsive]="true"
        [data]="filtersFormStructure$ | async"
        [showChips]="false"
        (state)="updateFromFiltersForm($event)">
      </mg-filters-form>
    </div>
  </div>
  <!-- Search Tools  -->
  <ng-container
    *ngIf="(form.filters.hasLoadedFile$ | async) === false"
    [ngTemplateOutlet]="searchTools">
  </ng-container>
</ng-template>

<!-- Filters Template -->
<ng-template #filtersTemplate>
  <div *ngIf="(form.pages.activePage$ | async).filters as supportedFilters">
    <div
      *ngIf="(form.pages.activePageType$ | async) === 'search'"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="12px">
      <!-- Group Filter -->
      <div
        *ngIf="
          (supportedFilters | includes: 'groups') &&
          (settingsService.isCommunityModuleEnabled() | async)
        "
        class="filters-selectContainer">
        <mg-form-select
          placeholder="Group"
          appendTo="body"
          notFoundText="No groups found"
          [value]="(form.filters.state$ | async).groups"
          [closeOnSelect]="true"
          [multiple]="true"
          [searchable]="true"
          [id]="'ps-group-filter'"
          [isFullWidth]="true"
          [options]="groupOptions$ | async"
          (selectionChanged)="form.filters.set('groups', $any($event))">
        </mg-form-select>
      </div>
      <!-- List Filter -->
      <div
        *ngIf="supportedFilters | includes: 'lists'"
        class="filters-selectContainer">
        <mg-user-list-filter
          *ngIf="'USER_LIST_VIEW' | hasPermission"
          [id]="'ps-list-filter'"
          placeholder="User List"
          [value]="selectedLists$ | async"
          (selectChanged)="setUserListFilter($any($event))">
        </mg-user-list-filter>
      </div>
      <!-- Grade Filter -->
      <div
        *ngIf="supportedFilters | includes: 'grades'"
        class="filters-selectContainer">
        <mg-form-select
          placeholder="Grade"
          appendTo="body"
          [value]="(form.filters.state$ | async).grades"
          [closeOnSelect]="true"
          [multiple]="true"
          [searchable]="false"
          [isFullWidth]="true"
          [id]="'ps-grade-filter'"
          [options]="gradeOptions$ | async"
          (selectionChanged)="form.filters.set('grades', $any($event))">
        </mg-form-select>
      </div>
      <!-- Role Type Filter -->
      <ng-container>
        <div
          *ngIf="supportedFilters | includes: 'roles'"
          class="filters-selectContainer">
          <mg-form-select
            #typeFilterSelect
            placeholder="Role"
            appendTo="body"
            [value]="(form.filters.state$ | async).roles"
            [closeOnSelect]="true"
            [multiple]="true"
            [searchable]="false"
            [isFullWidth]="true"
            [id]="'ps-role-filter'"
            [options]="roleOptions$ | async"
            (selectionChanged)="form.filters.set('roles', $any($event))">
          </mg-form-select>
        </div>
      </ng-container>
      <!-- Form Buttons -->
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="start stretch">
        <mg-btn
          [id]="'ps-apply-filters-btn'"
          class="accordion-button"
          variant="outlined"
          [loading]="form.searchResults.isLoading$ | async"
          (pressed)="this.form.search()">
          {{ MSG.BUTTON_SEARCH }}
        </mg-btn>
      </div>
    </div>
  </div>
</ng-template>

<!-- Uploaded File Template -->
<ng-template #fileSearchTemplate>
  <mg-btn
    variant="outlined"
    iconRight="close-thin"
    iconSet="minga"
    [wide]="true"
    (pressed)="form.filters.clear('file')">
    {{ (form.filters.file$ | async).name }}
  </mg-btn>
</ng-template>

<!-- Search Type Buttons Template -->
<ng-template #searchTools>
  <div
    *ngIf="(form.pages.activePage$ | async).searchTools as searchTools"
    class="tw-grid tw-grid-cols-3 md:tw-grid-cols-1 tw-gap-2.5 md:tw-mb-2.5 tw-full">
    <mg-btn
      *ngIf="searchTools | includes: 'camera'"
      [id]="'ps-camera-btn'"
      variant="outlined"
      iconSet="minga"
      [icon]="(isDesktop$ | async) ? 'mg-camera' : ''"
      [wide]="true"
      (pressed)="openCamera()">
      {{ (isDesktop$ | async) ? MSG.BUTTON_CAMERA : MSG.BUTTON_CAMERA_MOBILE }}
    </mg-btn>
    <mg-btn
      *ngIf="searchTools | includes: 'barcode'"
      [id]="'ps-barcode-btn'"
      iconSet="minga"
      [icon]="(isDesktop$ | async) ? 'mg-scanner' : ''"
      [wide]="true"
      [variant]="
        (this.form.searchMode$ | async) === 'barcode' ? 'filled' : 'outlined'
      "
      (click)="toggleBarCode()">
      {{ (isDesktop$ | async) ? MSG.BUTTON_SCAN : MSG.BUTTON_SCAN_MOBILE }}
    </mg-btn>

    <mg-btn
      *ngIf="searchTools | includes: 'file'"
      [id]="'ps-upload-btn'"
      variant="outlined"
      iconSet="minga"
      [icon]="(isDesktop$ | async) ? 'mg-export' : ''"
      [wide]="true">
      <input
        #fileInput
        fileInput
        type="file"
        [accept]="ACCEPTED_FILE_TYPES"
        (change)="uploadFile($event)" />
      {{ (isDesktop$ | async) ? MSG.BUTTON_UPLOAD : MSG.BUTTON_UPLOAD_MOBILE }}
    </mg-btn>
  </div>
</ng-template>
