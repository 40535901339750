import { Pipe, PipeTransform } from '@angular/core';

import { MingaPermission } from 'minga/domain/permissions';
import { PermissionsService } from 'src/app/permissions';

import { IdSettings } from '../types';

@Pipe({
  name: 'hideIdNumber',
})
export class HideIdNumberPipe implements PipeTransform {
  constructor(private _permissions: PermissionsService) {}

  // Hide ID # toggle  | Privacy Override | Hide own ID # | Admin hide # | Teacher hide #
  // ----------------- | ---------------- | ------------- | ------------ | -------------
  //        True       |       True       |      Yes      |     Yes      |      Yes
  //        True       |       False      |      Yes      |     Yes      |      Yes
  //        False      |       True       |      No       |     No       |      Yes
  //        False      |       False      |      No       |     No       |      No

  transform(settings: IdSettings, readonly: boolean): boolean {
    const isAdmin = this._permissions.hasPermission(
      MingaPermission.ADMINISTER_STUDENT_ID,
    );

    if (settings.idHideNumOnId) {
      return true;
    } else if (!readonly || isAdmin) {
      return false;
    }

    return settings.idHideNum;
  }
}
