<!-- Main Overlay -->
<mg-overlay-primary
  [isLoading]="loading$ | async"
  size="auto"
  [overlayTitle]="'Kiosk Mode'">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<ng-template #mainTemplate>
  <main class="kiosk-modal">
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutAlign.lt-md="start stretch"
      fxLayoutGap="12px"
      [ngStyle.gt-sm]="{ 'max-width.px': 668 }">
      <!-- Kiosk Entry -->
      <div
        fxLayout="column"
        fxLayoutAlign="space-between start"
        fxLayoutAlign.lt-md="start start"
        fxLayoutGap="24px"
        class="section-container"
        [ngStyle.gt-sm]="{ 'min-height.px': 303 }">
        <div
          fxLayout="column"
          fxLayoutGap="8px">
          <mg-icon iconName="mg-kiosk"></mg-icon>
          <mg-text
            variant="header-md-secondary"
            textAlign="left">
            {{ MESSAGES.ENTER_TITLE }}
          </mg-text>
          <mg-text
            variant="body-sm"
            color="surface-alt"
            textAlign="left">
            {{ MESSAGES.ENTER_BODY }}
          </mg-text>
        </div>
        <mg-btn
          variant="filled"
          size="small"
          [id]="'tt-kiosk-login-btn'"
          (pressed)="enterKioskModeConfirm()">
          {{ MESSAGES.ENTER_KIOSK_LABEL }}
        </mg-btn>
      </div>

      <!-- Login Credentials -->
      <div
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="8px"
        class="section-container"
        [ngStyle.gt-sm]="{ 'min-height.px': 303 }">
        <mg-icon iconName="mg-password"></mg-icon>
        <mg-text
          variant="header-md-secondary"
          textAlign="left">
          {{ MESSAGES.LOGIN_TITLE }}
        </mg-text>
        <mg-text
          variant="body-sm"
          color="surface-alt"
          textAlign="left">
          <span class="link-container">
            You can also go to
            <a [href]="getKioskLoginUrl()">
              {{ getKioskLoginUrl() }}
            </a>
            and use this login to enter your kiosk account
          </span>
        </mg-text>
        <mg-form-text-input
          [label]="MESSAGES.LOGIN_EMAIL_LABEL"
          [readonly]="true"
          [control]="form.get('email')">
        </mg-form-text-input>
        <mg-form-text-input
          [label]="MESSAGES.LOGIN_PIN_LABEL"
          [readonly]="true"
          [control]="form.get('pin')">
        </mg-form-text-input>
        <mg-btn
          variant="text"
          size="small"
          (pressed)="generateNewPinConfirm()">
          {{ MESSAGES.LOGIN_GENERATE_PIN_LABEL }}
        </mg-btn>
      </div>
    </div>
  </main>
</ng-template>

<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        variant="text"
        (pressed)="close()">
        Cancel
      </mg-btn>
    </div>
  </footer>
</ng-template>
