import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { IdSettings, IdStudentData } from '@shared/components/view-id/types';
import { MediaService } from '@shared/services/media';

import { ViewIdCodeFormats } from '../../constants';
import {
  setGradeSpecificColors,
  setHighlightColor,
} from '../../utils/view-id.utils';

@Component({
  selector: 'mg-view-id-mingalangelo',
  templateUrl: './view-id-mingalangelo.component.html',
  styleUrls: ['./view-id-mingalangelo.component.scss'],
})
export class ViewIdMingalangeloComponent implements AfterViewInit, OnChanges {
  // Children

  @ViewChild('container', { static: false }) container: ElementRef;

  // Inputs

  @Input() studentData: IdStudentData;
  @Input() settings: IdSettings;
  @Input() readonly: boolean;

  // Outputs

  @Output() changePhoto = new EventEmitter<IdStudentData>();

  public ViewIdCodeFormats = ViewIdCodeFormats; // Expose enum to template

  // Constructor

  constructor(public media: MediaService, public domSanitizer: DomSanitizer) {}

  // Lifecycle Hooks

  public ngAfterViewInit(): void {
    this._setColorStyles();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.settings && this.settings) {
      this._setColorStyles();
    }
  }

  // Public Methods

  // Private Methods

  private _setColorStyles() {
    this._setFontColorProperty(this.settings.idFontColor);
    this._setBackgroundColorProperty(this.settings.idBackgroundColor);
    this._setSecurityStripColorProperty(this.settings.idBackgroundColor);
  }

  private _setFontColorProperty(hexColor: string) {
    if (this.settings.idEnableGradeColors) {
      hexColor = setGradeSpecificColors(
        hexColor,
        this.studentData.grade,
        this.settings.idGradeColors,
        'font',
      );
    }

    if (this.container) {
      this.container.nativeElement.style.setProperty(
        '--dynamic-font-color',
        hexColor,
      );
    }
  }

  private _setBackgroundColorProperty(hexColor: string) {
    if (this.settings.idEnableGradeColors) {
      hexColor = setGradeSpecificColors(
        hexColor,
        this.studentData.grade,
        this.settings.idGradeColors,
        'background',
      );
    }

    if (this.container) {
      this.container.nativeElement.style.setProperty(
        '--dynamic-background-color',
        hexColor,
      );
    }
  }

  private _setSecurityStripColorProperty(hexColor: string) {
    if (this.settings.idEnableGradeColors) {
      hexColor = setGradeSpecificColors(
        hexColor,
        this.studentData.grade,
        this.settings.idGradeColors,
        'background',
      );
    }

    hexColor = setHighlightColor(hexColor);

    if (this.container) {
      this.container.nativeElement.style.setProperty(
        '--dynamic-security-strip-color',
        hexColor,
      );
    }
  }

  /** @todo turn into pipe */
  public formatYear(year: number): string {
    return `${year}-${(year + 1).toString().slice(-2)}`;
  }
}
