import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { mingaSettingTypes } from 'minga/libraries/util';
import { LoginManager } from 'minga/proto/gateway/login_ng_grpc_pb';
import {
  KioskPinRequest,
  ValidateKioskPinRequest,
} from 'minga/proto/gateway/login_pb';
import { AuthService } from 'src/app/minimal/services/Auth';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { KioskRoute } from '@modules/kiosk';
import { KioskDisclaimerComponent } from '@modules/kiosk/kiosk-ui/components/kiosk-disclaimer/kiosk-disclaimer.component';
import { KioskStateStorage } from '@modules/kiosk/utils';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

@Injectable({ providedIn: 'root' })
export class KioskService {
  private _pin: string;

  constructor(
    private _authService: AuthService,
    private _loginManager: LoginManager,
    private _router: Router,
    private _modalOverlay: ModalOverlayService,
    private _settingService: MingaSettingsService,
    private _snackbar: SystemAlertSnackBarService,
  ) {}

  /**
   * 1. Logs in as the kiosk user
   * 2. Reloads entire app to reset any state (avoids leaking permissions)
   */
  public async enterKioskMode(email: string, pin: string): Promise<void> {
    if (!email || !pin) {
      throw new Error('Email and pin are required');
    }

    await this._authService.loginToKiosk(email, pin);

    // clear any previous kiosk state
    // otherwise user could get stuck in previous selection state
    KioskStateStorage.clear();

    await this._router.navigate([KioskRoute.ROOT]);
    // lets reload the entire app to reset all the local state to avoid leaking permissions
    window.location.reload(true);
  }

  public async fetchPin(): Promise<string> {
    if (this._pin) {
      return this._pin;
    }

    const request = new KioskPinRequest();
    const response = await this._loginManager.getKioskPin(request);
    const pin = response.getPin();
    this._pin = pin;
    return pin;
  }

  public async generateNewPin(): Promise<string> {
    const request = new KioskPinRequest();
    const response = await this._loginManager.resetKioskPin(request);
    const pin = response.getPin();

    this._pin = pin;
    return pin;
  }

  public async validatePin(pin: string): Promise<boolean> {
    const request = new ValidateKioskPinRequest();
    request.setPin(pin);
    const response = await this._loginManager.validateKioskPin(request);
    return response.getValid();
  }

  public async openDisclaimer(settingKey: mingaSettingTypes): Promise<void> {
    const TYPE_MESSAGE = {
      [mingaSettingTypes.FTM_KIOSK]: 'FlexTime',
      [mingaSettingTypes.CHECKIN_KIOSK]: 'Check in',
      [mingaSettingTypes.PASS_HALLPASS_KIOSK]: 'Hall pass',
    };

    const modalRef = this._modalOverlay.open(KioskDisclaimerComponent);
    const { type } = await modalRef.afterClosed.toPromise();

    if (type === ModalOverlayServiceCloseEventType.CLOSE) {
      this._settingService.updateSetting(settingKey, false);
    } else {
      const type = TYPE_MESSAGE[settingKey];
      this._snackbar.success(`${type} kiosk activated`);
    }
  }
}
