<!-- New layout template -->
<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between center"
  fxLayoutAlign.lt-md="start stretch"
  fxLayoutGap="12px"
  [style.marginBottom]="'16px'">
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap="12px">
    <ng-container
      *ngIf="isFlexAdmin"
      [ngTemplateOutlet]="teacherSearchTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="activitySearchTemplate"></ng-container>
  </div>
  <ng-container *ngTemplateOutlet="createButtonTemplate"></ng-container>
</div>
<ng-container *ngTemplateOutlet="mainContent"></ng-container>

<!-- Main Content -->
<ng-template #mainContent>
  <div class="activities">
    <ng-container *ngIf="activitiesDataSource$ | async as activitiesDataSource">
      <mat-table
        class="activities-table"
        *ngIf="(activitiesDataSource$ | async).data?.length; else noResults"
        [dataSource]="activitiesDataSource$ | async"
        [trackBy]="trackByFn">
        <ng-container matColumnDef="icon">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            [ngClass]="{
              'mat-column-image': item.bannerImage || item.imagePath
            }">
            <mg-ftm-activity-img
              [bannerImage]="item.bannerImage"
              [imagePath]="item.imagePath"></mg-ftm-activity-img>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>
            {{ MESSAGES.TABLE_NAME }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item"> {{ item.name }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <mat-header-cell *matHeaderCellDef>
            {{ MESSAGES.TABLE_TEACHER }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <div class="mobile-title">{{ MESSAGES.TABLE_TEACHER }}:</div>
            {{ item.createdByPerson.name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef>
            {{ MESSAGES.TABLE_LOCATION }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <div class="mobile-title">{{ MESSAGES.TABLE_LOCATION }}:</div>
            {{ item.location }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="seats">
          <mat-header-cell *matHeaderCellDef>
            {{ MESSAGES.TABLE_REGISTRATION_LIMIT }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <div class="mobile-title">
              {{ MESSAGES.TABLE_REGISTRATION_LIMIT }}:
            </div>
            {{
              item.registrationLimit ||
                MESSAGES.TABLE_REGISTRATION_UNLIMITED_LABEL
            }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef>
            {{ MESSAGES.TABLE_EDIT }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <mg-btn
              variant="icon"
              iconSet="minga"
              icon="mg-edit"
              (pressed)="openEditModal(item)">
            </mg-btn>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
      </mat-table>
    </ng-container>
  </div>
</ng-template>

<ng-template #noResults>
  <div class="activities-empty">
    <mg-empty-state
      mg-stream-empty
      [title]="MESSAGES.NO_ACTIVITIES">
    </mg-empty-state>
  </div>
</ng-template>

<!-- Teacher Search Search Template -->
<ng-template #teacherSearchTemplate>
  <div fxFlex.gt-sm="350px">
    <mg-form-select
      mgTeacherSearch
      appendTo="body"
      placeholder="Teacher"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="true"
      [hideSelected]="false"
      [floatingLabel]="false"
      [isClearable]="true"
      (selectionChanged)="onTeacherSelectChange($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Activity Name Search Template -->
<ng-template #activitySearchTemplate>
  <div fxFlex.gt-sm="350px">
    <mg-form-text-input
      label="Search by title"
      [labelBackground]="'white'"
      [control]="searchControl">
    </mg-form-text-input>
  </div>
</ng-template>

<!-- Create Activity Button Template -->
<ng-template #createButtonTemplate>
  <div
    fxFlex="noshrink"
    fxFlex.gt-sm="nogrow">
    <mg-btn
      variant="filled"
      [wide]="true"
      [responsive]="true"
      (pressed)="openEditModal({})">
      {{ MESSAGES.CREATE_ACTIVITY }}
    </mg-btn>
  </div>
</ng-template>

<ng-template #mobileHeaderTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="12px">
    <mg-text variant="header-xl-secondary">
      {{ MESSAGES.PAGE_TITLE_DASHBOARD }}
    </mg-text>
    <div class="w-full">
      <mg-collapsible
        collapseText="Hide filters"
        expandText="Show filters">
        <div
          fxLayout="column"
          fxLayoutGap="12px">
          <ng-container
            *ngIf="isFlexAdmin"
            [ngTemplateOutlet]="teacherSearchTemplate"></ng-container>
          <ng-container
            *ngTemplateOutlet="activitySearchTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="createButtonTemplate"></ng-container>
        </div>
      </mg-collapsible>
    </div>
  </div>
</ng-template>
