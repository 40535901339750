import { Injectable } from '@angular/core';

import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { MingaSettingsService } from 'minga/app/src/app/store/Minga/services';
import { downloadjs } from 'minga/app/src/app/util/downloadjs';
import { MingaPermission } from 'minga/domain/permissions';
import { ITeam } from 'minga/domain/points/leaderboard';
import { PointsManager } from 'minga/proto/points/points_ng_grpc_pb';
import {
  AddPointsRequest,
  ExportLeaderboardRequest,
  GetPersonLeaderboardRequest,
  GetPointCategoriesRequest,
  GetProfilePointsSummaryRequest,
  GetTeamLeaderboardRequest,
  GetTeamsRequest,
  RemovePointsRequest,
  ResetMingaPointsHistoryRequest,
} from 'minga/proto/points/points_pb';
import { TeamMapper } from 'minga/shared-grpc/points';

import { PsData } from '@modules/people-selector';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export interface IPointsPeopleManage {
  state?: PointsPeopleManageState;
  type?: PointsPeopleTypeState;
  people: PsData[];
}

export enum LeaderboardTypeEnum {
  TEAMS = 'TEAMS',
  USERS = 'USERS',
}

export enum LeaderboardTimeFilterEnum {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum PointsPeopleManageState {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export enum PointsPeopleTypeState {
  TEAM = 'TEAM',
  PEOPLE = 'PEOPLE',
}

@Injectable({ providedIn: 'root' })
export class PointsManagerService {
  private _tempPointsPeople: PsData[] = [];
  private _tempPointsPeopleState?: PointsPeopleManageState;
  private _tempPointsPeopleTypeState?: PointsPeopleTypeState;

  private _onPointsUpdated: Subject<null> = new Subject();

  private _canAllocateContentPoints$: Observable<boolean>;

  constructor(
    private _systemAlertSnackbar: SystemAlertSnackBarService,
    private _pointsManager: PointsManager,
    private _authInfo: AuthInfoService,
    private _permissions: PermissionsService,
    private _settingService: MingaSettingsService,
  ) {
    this._canAllocateContentPoints$ =
      this.initContentPointsAllocationObservable();
  }

  initContentPointsAllocationObservable(): Observable<boolean> {
    return combineLatest([
      this._authInfo.authInfo$,
      this.getTrackingExpansionPackObservableState(),
      this.getPointsAllocationObservableState(),
    ]).pipe(
      map(([_authInfo, arePointsEnabled, canAllocatePoints]) => {
        if (!arePointsEnabled) {
          return false;
        }

        // allow points allocation for admin roles with permission
        if (this._permissions.hasPermission(MingaPermission.POINTS_MANAGE)) {
          return true;
        }

        return canAllocatePoints;
      }),
    );
  }

  getTrackingExpansionPackObservableState(): Observable<boolean> {
    return this._settingService.isPbisModuleEnabled();
  }

  getPointsAllocationObservableState(): Observable<boolean> {
    return this._permissions.observePermission(
      MingaPermission.POINTS_ALLOCATE_ALLOWED,
    );
  }

  getCanCurrentUserAllocatePointsObs(): Observable<boolean> {
    return this._canAllocateContentPoints$;
  }

  storeTempPointsPeople(people: PsData[]) {
    this._tempPointsPeople = people;
  }

  setTempPointsPeopleState(state: PointsPeopleManageState) {
    this._tempPointsPeopleState = state;
  }

  setTempPointsPeopleType(type: PointsPeopleTypeState) {
    this._tempPointsPeopleTypeState = type;
  }

  consumePointsPeople() {
    const people = this._tempPointsPeople;
    const state = this._tempPointsPeopleState;
    const type = this._tempPointsPeopleTypeState;
    // reset
    this._tempPointsPeople = [];
    this._tempPointsPeopleState = undefined;
    this._tempPointsPeopleTypeState = undefined;

    return { people, state, type };
  }

  async getUserMonthlyLeaderboard() {
    return await this._getUserLeaderboard(true, false);
  }

  async getUserYearlyLeaderboard() {
    return await this._getUserLeaderboard(false, true);
  }

  private async _getUserLeaderboard(monthly: boolean, yearly: boolean) {
    const request = new GetPersonLeaderboardRequest();

    if (!monthly && !yearly) {
      throw new Error(`getting User Leadboard must be by year or month.`);
    }

    if (monthly) {
      request.setMonth(true);
    } else {
      request.setYear(true);
    }

    const response = await this._pointsManager.personLeaderboard(request);
    return response.toObject();
  }

  async getTeamMonthlyLeaderboard() {
    return await this._getTeamLeaderboard(true, false);
  }

  async getTeamYearlyLeaderboard() {
    return await this._getTeamLeaderboard(false, true);
  }

  private async _getTeamLeaderboard(monthly: boolean, yearly: boolean) {
    const request = new GetTeamLeaderboardRequest();

    if (!monthly && !yearly) {
      throw new Error(`getting User Leadboard must be by year or month.`);
    }

    if (monthly) {
      request.setMonth(true);
    } else {
      request.setYear(true);
    }

    const response = await this._pointsManager.teamLeaderboard(request);
    return response.toObject();
  }

  async getProfilePointsSummary(
    personHash: string = this._authInfo.authPersonHash,
  ) {
    const request = new GetProfilePointsSummaryRequest();
    request.setPersonHash(personHash);

    const response = await this._pointsManager.getProfilePointsSummary(request);
    return response.toObject();
  }

  async getPointCategories() {
    const request = new GetPointCategoriesRequest();
    const response = await this._pointsManager.getPointCategories(request);

    return response.toObject();
  }

  async resetMingaPointsHistory() {
    const request = new ResetMingaPointsHistoryRequest();
    const response = await this._pointsManager.resetMingaPointsHistory(request);

    return response.toObject();
  }

  async addPointsToTeam(
    teamId: number,
    points: number,
    reason: string,
    category: string,
  ) {
    const request = new AddPointsRequest();
    request.setTeamId(teamId);
    request.setPointValue(points);
    request.setReason(reason);
    request.setCategory(category);

    await this._pointsManager.addPoints(request);
    this.emitPointsUpdated();
  }

  async addPointsToPeople(
    personHashes: string[],
    points: number,
    reason: string,
    category: string,
  ) {
    const request = new AddPointsRequest();
    request.setPersonHashList(personHashes);
    request.setPointValue(points);
    request.setReason(reason);
    request.setCategory(category);

    await this._pointsManager.addPoints(request);
    this.emitPointsUpdated();
  }

  async removePointsFromTeam(teamId: number, points: number, reason: string) {
    const request = new RemovePointsRequest();
    request.setTeamId(teamId);
    request.setPointValue(points);
    request.setReason(reason);

    await this._pointsManager.removePoints(request);
    this.emitPointsUpdated();
  }

  async removePointsFromPeople(
    personHashes: string[],
    points: number,
    reason: string,
  ) {
    const request = new RemovePointsRequest();
    request.setPersonHashList(personHashes);
    request.setPointValue(points);
    request.setReason(reason);

    await this._pointsManager.removePoints(request);
    this.emitPointsUpdated();
  }

  get onPointsUpdated() {
    return this._onPointsUpdated.asObservable();
  }

  emitPointsUpdated() {
    this._onPointsUpdated.next();
  }

  async getPointsTeams(): Promise<ITeam[]> {
    const request = new GetTeamsRequest();
    request.setActiveOnly(true);

    try {
      const response = await this._pointsManager.getTeams(request);
      const teams = response.getTeamsList();
      return teams.map(TeamMapper.fromProto);
    } catch (error) {
      this._systemAlertSnackbar.error(
        'Error fetching teams, please try again later',
      );
    }
  }

  async exportLeaderboard(
    type: LeaderboardTypeEnum,
    time: LeaderboardTimeFilterEnum,
  ) {
    const request = new ExportLeaderboardRequest();
    if (type === LeaderboardTypeEnum.USERS) {
      request.setPerson(true);
    } else if (type === LeaderboardTypeEnum.TEAMS) {
      request.setTeam(true);
    }
    if (time === LeaderboardTimeFilterEnum.MONTHLY) {
      request.setMonth(true);
    } else {
      request.setYear(true);
    }
    const obs = this._pointsManager.exportLeaderboard(request);

    let filename = '';
    let fileData = new Uint8Array(0);

    await new Promise((resolve, reject) => {
      obs.subscribe(
        chunk => {
          if (chunk.hasChunk()) {
            const chunkData = chunk.getChunk_asU8();
            const newFileData = new Uint8Array(
              fileData.length + chunkData.length,
            );
            newFileData.set(fileData);
            newFileData.set(chunkData, fileData.length);
            fileData = newFileData;
          } else if (chunk.hasFilename()) {
            filename = chunk.getFilename();
          }
        },
        reject,
        resolve,
      );
    });

    // @TODO: @types/downloadjs doesn't agree with the typings here
    downloadjs(fileData as any, filename);
  }
}
