import { Injectable, OnDestroy } from '@angular/core';

import { ReplaySubject, Subject } from 'rxjs';

import * as reg_pb from 'minga/proto/registration/registration_pb';
import { FlexTimeActivityInstance } from 'minga/domain/flexTime';
import { Registration, RegistrationTypes } from 'minga/domain/registration';
import { RegistrationManager } from 'minga/proto/registration/registration_ng_grpc_pb';
import { RegistrationMapper } from 'minga/shared-grpc/registration';
import { RootService } from 'src/app/minimal/services/RootService';

import { PeopleSelectorService, PsData } from '@modules/people-selector';

import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { ErrorHandlerService } from '@shared/services/error-handler';

import { Messages as TtMessages } from '../constants/tt-view.constants';

@Injectable({ providedIn: 'root' })
export class TeacherToolsFlexTimeService implements OnDestroy {
  private _activityAssignmentSuccess = new Subject<PsData[]>();
  public activityAssignmentSuccess$ =
    this._activityAssignmentSuccess.asObservable();

  private _destroyed = new ReplaySubject<void>(1);
  constructor(
    private _registrationManager: RegistrationManager,
    private _systemAlertModal: SystemAlertModalService,
    private _rootService: RootService,
    private _errorHandler: ErrorHandlerService,
    private _peopleSelector: PeopleSelectorService,
  ) {}

  public openActivityPeopleSelector(
    activityInstance: FlexTimeActivityInstance,
    canOverride: boolean,
  ) {
    this._peopleSelector.open('FlexTime Activity', 'assign', {
      data: {
        registered: activityInstance.registered,
        spaces: activityInstance.spaces,
        flexTimeActivityId: activityInstance.id,
        checkinReasonId: activityInstance.checkinReasonId,
        flexTimePeriodId: activityInstance.flexTimePeriodId,
        date: activityInstance.flexTimePeriod?.date,
        endTime: activityInstance.flexTimePeriod?.endTime,
        enableStudentManagement:
          activityInstance.flexTimePeriod?.enableStudentManagement,
        canOverride,
      },
      title: activityInstance.flexTimeActivity.name,
    });
  }

  public async assignActivity(
    personHashes: string[],
    activityInstanceId: number,
    canUnregister: boolean,
  ): Promise<Registration[]> {
    const request = new reg_pb.AssignRequest();
    request.setActivityInstanceId(activityInstanceId);
    request.setPersonHashList(personHashes);
    request.setType(RegistrationTypes.FLEX_TIME);
    request.setCanUnregister(canUnregister);
    const response = await this._registrationManager.assign(request);

    const registrationList = response
      .getRegistrationList()
      .map(RegistrationMapper.fromProto);

    return registrationList;
  }

  public async onPeopleSelectSave(
    selected: PsData[],
    activityInstanceId: number,
    canUnregister: boolean,
  ) {
    if (!selected.length) return;

    try {
      const selectedHashes = selected.map(person => person.personHash);
      await this._rootService.addLoadingPromise(
        this.assignActivity(selectedHashes, activityInstanceId, canUnregister),
      );

      const names = selected.map(person => person.displayName);

      const modalRef = await this._systemAlertModal.open({
        modalType: SystemAlertModalType.SUCCESS,
        heading: canUnregister
          ? TtMessages.REGISTRATION_SUCCESS_TITLE
          : TtMessages.ASSIGN_SUCCESS_TITLE,
        message: names.join(', '),
        hangTime: SystemAlertModalHangTime.LONG,
      });
      await modalRef.afterClosed().toPromise();
      this._activityAssignmentSuccess.next(selected);
    } catch (e) {
      this._errorHandler.gateWayError(e?.message || e, e, true);
    }
  }

  ngOnDestroy(): void {
    this._destroyed.complete();
  }
}
