import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostBinding,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { VirtualScrollerComponent } from '@minga/ngx-virtual-scroller';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import {
  Observable,
  ReplaySubject,
  BehaviorSubject,
  combineLatest,
  of,
  forkJoin,
} from 'rxjs';
import {
  takeUntil,
  switchMap,
  share,
  debounceTime,
  distinct,
  map,
  take,
} from 'rxjs/operators';

import { MessagingConversationScreenRouteMessages } from 'minga/app/src/app/modules/direct-message/components/dm-conversation/dm-conversation.constants';
import { ConversationNavigator } from 'minga/app/src/app/modules/direct-message/services/conversation-navigator.service';
import {
  IKeywordMatchedMessage,
  IMessageSearchWithCountResult,
  MessagingFacade,
} from 'minga/app/src/app/modules/direct-message/store';
import { MessageState } from 'minga/app/src/app/modules/direct-message/store/state';
import {
  IMessage,
  IMessageAttachment,
  IConversation,
} from 'minga/domain/messaging';
import { MingaPermission } from 'minga/domain/permissions';
import { day } from 'minga/libraries/day';
import { FileInputDisplay } from 'src/app/components/Input/FileInput';
import { MessageLightboxComponent } from 'src/app/components/Lightbox/MessageLightbox';
import {
  FileUploadState,
  FileUploadManager,
  DEFAULT_IMAGE_ACCEPT,
  FileUploadStatus,
} from 'src/app/file';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { RootService } from 'src/app/minimal/services/RootService';
import { IOverlayConfigurable, IOverlayConfig } from 'src/app/misc/overlay';
import { Person, PeopleFacadeService } from 'src/app/people';
import { PermissionsService } from 'src/app/permissions';
import { renameFile } from 'src/app/util/File';
import { inOutAnination } from 'src/app/util/animations';
import { closeCurrentOverlay } from 'src/app/util/overlay';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

day.extend(relativeTime);

const CONVERSATION_COLORS = [
  '#003366',
  '#80BDDE',
  '#C75F13',
  '#489B7F',
  '#737373',
  '#435DC0',
  '#CB6A75',
  '#168286',
];

export interface MessagingScreenConversationConfigOptions {
  showInlineAuthorDisplayNames: boolean;
  showMessageCount: boolean;
  showExactTimestamps: boolean;
  enableMessageSearch: boolean;
}

export interface MessageItem extends MessageState {
  readonly timestampString: string;
  readonly showAuthor: boolean;
}

const isRecent = (input: any, inp?: any): boolean => {
  return day(input).add(5, 'minute').isAfter(inp);
};

const createTimestampString = (date: Date): string => {
  const timestamp = day(date);

  const fiveMintuesAgo = day().subtract(5, 'minutes');
  const somewhatRecent = day().subtract(45, 'minutes');
  const yesterday = day().startOf('day').subtract(1, 'millisecond');
  const twoDaysAgo = day(yesterday).startOf('day').subtract(1, 'millisecond');
  const lastYear = day().startOf('year').subtract(1, 'millisecond');

  if (timestamp.isAfter(fiveMintuesAgo)) {
    return 'Just now';
  } else if (timestamp.isAfter(somewhatRecent)) {
    return timestamp.fromNow();
  } else if (timestamp.isAfter(yesterday)) {
    return 'Today, ' + timestamp.format('LT');
  } else if (timestamp.isAfter(twoDaysAgo)) {
    return 'Yesterday, ' + timestamp.format('LT');
  } else if (timestamp.isAfter(lastYear)) {
    return timestamp.format('MMM Do, LT');
  } else {
    return timestamp.format('MMM Do YYYY, LT');
  }
};

const createExactTimestampString = (date: Date) => {
  const timestamp = day(date);
  return timestamp.format('ddd, MMM D, YYYY h:mm A');
};

const toMessageItem = (message: IMessage, showAuthor = true): MessageItem => {
  return {
    ...message,
    timestampString: createTimestampString(message.createdDate),
    showAuthor,
  };
};

const toMessageItemEmptyTimestamp = (
  message: IMessage,
  showAuthor = false,
): MessageItem => {
  return {
    ...message,
    timestampString: '',
    showAuthor,
  };
};

const toMessageItemExactTimestamp = (
  message: IMessage,
  showAuthor = true,
): MessageItem => {
  return {
    ...message,
    timestampString: createExactTimestampString(message.createdDate),
    showAuthor,
  };
};

/**
 * Adds timestamps to every message. Note that current user checking for author
 * is not necessary as that is determined by route data.
 *
 * @param items
 */
const addExactTimestamps = (
  items: ReadonlyArray<IKeywordMatchedMessage>,
): IKeywordMatchedMessage[] => {
  return items.map(item => ({
    message: toMessageItemExactTimestamp(item.message),
    keywords: item.keywords,
  }));
};

/**
 * Add timestamp to messages, grouping those that are within recent timings of
 * each other and/or by same author. Current user isn't shown when is the
 * author.
 *
 * @param messages
 * @param currentUserHash
 */
const addTimestamps = (
  messages: ReadonlyArray<IKeywordMatchedMessage>,
  currentUserHash: string,
): IKeywordMatchedMessage[] => {
  const items: IKeywordMatchedMessage[][] = [];
  let currentGroup: IKeywordMatchedMessage[] = [];
  items.push(currentGroup);

  let lastDate = day();
  let lastAuthor = '';

  messages.forEach(message => {
    if (
      !isRecent(message.message.createdDate, lastDate) ||
      lastAuthor !== message.message.authorPersonHash
    ) {
      lastDate = day(message.message.createdDate);
      lastAuthor = message.message.authorPersonHash;
      currentGroup = [];
      items.push(currentGroup);
    }

    currentGroup.push(message);
  });

  return ([] as IKeywordMatchedMessage[]).concat(
    ...items.map(group => {
      if (group.length === 0) return [];

      const lastMessageNotCurrentAuthor =
        group[group.length - 1].message.authorPersonHash != currentUserHash;

      return [
        ...group.slice(0, group.length - 1).map(g => ({
          message: toMessageItemEmptyTimestamp(g.message),
          keywords: g.keywords,
        })),
        {
          keywords: group[group.length - 1].keywords,
          message: toMessageItem(
            group[group.length - 1].message,
            lastMessageNotCurrentAuthor,
          ),
        },
      ];
    }),
  );
};

const getMessageState = (item: MessageItem): 'read' | 'pending' | '' => {
  if (item.sendState && item.sendState === 'pending') {
    return 'pending';
  }

  return '';
};

const instanceOfSearchMessage = (
  item: IKeywordMatchedMessage | IMessage,
): item is IKeywordMatchedMessage => {
  return 'message' in item;
};

const instanceOfSearchMessages = (
  items:
    | readonly Readonly<IKeywordMatchedMessage>[]
    | readonly Readonly<IMessage>[],
): items is readonly Readonly<IKeywordMatchedMessage>[] => {
  // if there's no length, assume not
  if (!items.length) return false;

  const firstItem = items[0];
  return instanceOfSearchMessage(firstItem);
};

const instanceOfSearchMatchedMessagesResult = (
  items:
    | Readonly<IMessageSearchWithCountResult<IKeywordMatchedMessage>>
    | readonly Readonly<IMessage>[],
): items is Readonly<IMessageSearchWithCountResult<IKeywordMatchedMessage>> => {
  return (
    'totalCount' in items &&
    (!items.messages.length || instanceOfSearchMessages(items.messages))
  );
};

const convertIMessage2IKeywordMatchedMessage = (
  message: IMessage,
): IKeywordMatchedMessage => {
  return { message, keywords: [] };
};

export interface IMessagingDisabledInfo {
  receiverDisabled: boolean;
  currentUserPersonallyDisabled: boolean;
  bothDisabled: boolean;
  receiverMingaDisabled: boolean;
  receiverDisabledMessage?: string;
  disabledByAdmin?: boolean;
}

export interface IColoredKeywordMatchedMessage extends IKeywordMatchedMessage {
  color: string;
}

export interface IColoredParticipant extends Person {
  color: string;
}

@Component({
  templateUrl: './dm-conversation.component.html',
  styleUrls: ['./dm-conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [inOutAnination],
})
export class DmConversationComponent
  implements IOverlayConfigurable, OnDestroy
{
  readonly showFixedInputContainer$: Observable<boolean>;
  private _conversationId = 0;
  private _destroyed$ = new ReplaySubject<void>(1);
  private _destroyed = false;
  private _messagesUpdateTimeout: any;
  // Flag indicating the messages have been rendered at least once
  private _messagesRendered = false;
  // Whether or not <virtual-scroller> should be adjusting scrolls
  private _skipPrependScrollAdjust = true;
  private _scrolledBottomCheckerAnimFrame: any;

  fileInputDisplayType = FileInputDisplay.ICON;
  pendingFiles: File[] = [];
  fileUploads?: Observable<FileUploadState>[];
  pendingAssetPaths: string[] = [];
  activeMessageAttachments: IMessageAttachment[] = [];
  lightboxIndex = 0;

  loadingParticipantStatus = true;

  readonly overlayPreviousText$: Observable<string>;
  readonly overlayNextText$: Observable<string>;

  messageBodyInput = '';
  readonly conversation$: Observable<IConversation>;
  readonly participants$: Observable<Person[]>;
  readonly messagingDisabled$: Observable<IMessagingDisabledInfo | null>;
  readonly isCurrentParticipant$: Observable<boolean>;
  readonly routeData$: Observable<MessagingScreenConversationConfigOptions>;
  readonly inputPlaceholder$: Observable<string>;

  messages: IColoredKeywordMatchedMessage[] | null = null;
  searchMessageValue = '';
  readonly searchMessageText$: BehaviorSubject<string>;
  readonly userDisplayLimit: number = 3;
  public readonly LABELS = MessagingConversationScreenRouteMessages;

  readonly getMessageState = getMessageState;

  @ViewChild('virtualScroller', { static: false })
  virtualScroller?: VirtualScrollerComponent;

  @ViewChild('overlayNavTop', { static: false })
  overlayTopEl?: ElementRef<HTMLDivElement> | null;

  @ViewChild('imageLightBox', { static: true })
  imageLightBox?: MessageLightboxComponent;

  trackByFn = (index: number, item: IColoredKeywordMatchedMessage) => {
    return item.message.id;
  };

  @HostBinding('class.items-ready')
  get itemsReady() {
    return this._messagesRendered;
  }

  get skipPrependScrollAdjust() {
    return this._skipPrependScrollAdjust;
  }

  constructor(
    route: ActivatedRoute,
    mediaObserver: MediaObserver,
    private _router: Router,
    private _messagingFacade: MessagingFacade,
    private _cdr: ChangeDetectorRef,
    private _authInfo: AuthInfoService,
    private _peopleFacade: PeopleFacadeService,
    private _sanitizer: DomSanitizer,
    private _conversationNavigator: ConversationNavigator,
    private _fileUploadManager: FileUploadManager,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _rootService: RootService,
    private _analyticsService: AnalyticsService,
    private _permissions: PermissionsService,
  ) {
    route.params.subscribe(
      params => (this._conversationId = parseInt(params.id, 10)),
    );
    this.routeData$ =
      route.data as Observable<MessagingScreenConversationConfigOptions>;

    this.searchMessageText$ = new BehaviorSubject('');

    this.showFixedInputContainer$ = mediaObserver
      .asObservable()
      .pipe(
        map(
          mediaChanges => mediaChanges.findIndex(m => m.mqAlias === 'xs') > -1,
        ),
      );

    const conversationId$ = route.params.pipe(
      map(params => parseInt(params.id, 10)),
    );

    this.conversation$ = conversationId$.pipe(
      takeUntil(this._destroyed$),
      switchMap(convId => this._messagingFacade.getConversation(convId)),
    );

    const messages$ = combineLatest(
      this.routeData$,
      conversationId$,
      this.searchMessageText$,
      this._authInfo.authPerson$,
    ).pipe(
      takeUntil(this._destroyed$),
      switchMap(([routeData, convId, searchText, authPerson]) => {
        return combineLatest(
          of(routeData),
          of(authPerson),
          searchText
            ? this._messagingFacade.searchMessagesIncludingMatches(
                convId,
                searchText,
              )
            : this._messagingFacade.getMessages(convId),
        );
      }),
      map(([routeData, authPerson, items]) => {
        if (!instanceOfSearchMatchedMessagesResult(items)) {
          // convert IMessage[] to
          // IMessageSearchWithCountResult<IKeywordMatchedMessage>
          items = {
            totalCount: items.length,
            messages: items.map(convertIMessage2IKeywordMatchedMessage),
          };
        }

        const messages = items.messages;
        if (routeData && routeData.showExactTimestamps) {
          return {
            totalCount: items.totalCount,
            messages: addExactTimestamps(messages).reverse(),
          };
        } else {
          return {
            totalCount: items.totalCount,
            messages: addTimestamps(messages, authPerson?.hash || '').reverse(),
          };
        }
      }),
      share(),
    );

    messages$
      .pipe(
        takeUntil(this._destroyed$),
        debounceTime(3000),
        map(items => items.totalCount),
        distinct(),
      )
      .subscribe(() => {
        this._messagingFacade.markConversationAsRead(this._conversationId);
      });

    this.participants$ = this.conversation$.pipe(
      switchMap(conv => this._peopleFacade.getPeople(conv.participants)),
      map(people => people.filter(p => !!p).map(p => p as Person)),
      share(),
    );

    // force an update of participant data, to ensure fresh data
    // about whether they have DM enabled.
    this.participants$.pipe(take(1)).subscribe(participants => {
      const hashes = participants.map(person => person.hash);
      this._peopleFacade.requestFetchPeopleFromBackend(hashes, true);
    });

    this.isCurrentParticipant$ = combineLatest(
      this._authInfo.authPerson$,
      this.participants$,
    ).pipe(
      map(([authPerson, participants]) => {
        if (authPerson) {
          return !!participants.find(p => p.hash === authPerson.hash);
        }

        return false;
      }),
    );

    const participantsWithoutAuthPerson$: Observable<IColoredParticipant[]> =
      combineLatest(this._authInfo.authPerson$, this.participants$).pipe(
        map(([authPerson, participants]) => {
          if (authPerson) {
            // associate colors to non-auth-person participants
            let colorIndex = 0;
            return participants
              .filter(p => p.hash !== authPerson.hash)
              .map(part => {
                if (colorIndex >= CONVERSATION_COLORS.length) {
                  colorIndex = 0;
                }
                return {
                  ...part,
                  color: CONVERSATION_COLORS[colorIndex++],
                };
              });
          }

          return [];
        }),
      );

    combineLatest([messages$, participantsWithoutAuthPerson$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([messagesResult, participants]) => {
        if (!this.messages) {
          this.messages = [];
        }

        const messages = messagesResult.messages.map(message => ({
          ...message,
          color:
            participants.find(
              part => part.hash === message.message.authorPersonHash,
            )?.color || '',
        }));
        // Keep the reference so <virtual-scroller> doesn't refresh the whole
        // viewport everytime there's new messages.
        this._skipPrependScrollAdjust = true;
        if (this.virtualScroller) {
          this.virtualScroller.skipPrependScrollAdjust =
            this._skipPrependScrollAdjust;
        }
        this.messages.splice(0, this.messages.length, ...messages);
        this._cdr.markForCheck();

        clearTimeout(this._messagesUpdateTimeout);
        this._messagesUpdateTimeout = setTimeout(async () => {
          await this._scrollToBottomIfNeeded();
          if (this._destroyed) return;
          this._messagesRendered = true;
          this._cdr.markForCheck();
          this._skipPrependScrollAdjust = true;
          if (this.virtualScroller) {
            this.virtualScroller.skipPrependScrollAdjust =
              this._skipPrependScrollAdjust;
          }
        }, 0);
      });

    this.messagingDisabled$ = combineLatest(
      this._authInfo.dmPersonalPreference$,
      this._authInfo.dmDisabled$,
      participantsWithoutAuthPerson$,
    ).pipe(
      map(([dmPersonalPreference, dmDisabled, people]) => {
        const info: IMessagingDisabledInfo = {
          bothDisabled: false,
          currentUserPersonallyDisabled: !dmPersonalPreference,
          receiverDisabled: false,
          receiverMingaDisabled: false,
          disabledByAdmin: dmDisabled,
        };

        for (const person of people) {
          if (person.directMessagesMingaDisabled) {
            info.receiverMingaDisabled = true;
          }
          if (
            person.directMessagesPersonallyDisabled ||
            !person.receivingDirectMessages
          ) {
            info.receiverDisabled = true;
            info.receiverDisabledMessage = `Sorry, but ${person.displayName} has DM turned off. You are not able to message them at this time.`;
          }
          if (this._permissions.hasPermission(MingaPermission.DM_CAN_MESSAGE)) {
            Object.keys(info).forEach(key => {
              info[key] = false;
            });
          }
        }

        if (info.currentUserPersonallyDisabled && info.receiverDisabled) {
          info.bothDisabled = true;
        }

        // if nobody in the conversation, it's still loading
        this.loadingParticipantStatus = people.length > 0;

        return info;
      }),
    );

    this.overlayPreviousText$ = participantsWithoutAuthPerson$.pipe(
      map(participants => {
        const extraParticipants = participants.length - this.userDisplayLimit;
        if (extraParticipants > 0) {
          // shorten particpants shown
          participants.splice(
            this.userDisplayLimit - 1,
            participants.length - this.userDisplayLimit,
          );
        }
        let display = participants.map(p => p.displayName).join(', ');

        if (extraParticipants > 0) {
          display += '... + ' + extraParticipants + ' more';
        }

        return display;
      }),
    );

    this.overlayNextText$ = combineLatest(this.routeData$, messages$).pipe(
      takeUntil(this._destroyed$),
      map(([routeData, items]) =>
        this._updateOverlayNextText(items.totalCount, routeData),
      ),
    );

    this.inputPlaceholder$ = this.messagingDisabled$.pipe(
      map(info => {
        return this.hasTruePropertyValue(info)
          ? this.LABELS.DISABLED_PLACEHOLDER_MSG
          : this.LABELS.INPUT_PLACEHOLDER_MSG;
      }),
    );
  }

  @HostListener('window:mousewheel', [])
  onWindowMouseWheel() {
    this._skipPrependScrollAdjust = this._isScrolledToBottom();
    if (this.virtualScroller) {
      this.virtualScroller.skipPrependScrollAdjust =
        this._skipPrependScrollAdjust;
    }
  }

  @HostListener('window:touchstart', [])
  onWindowTouchstart() {
    this._startScrolledBottomCheck();
  }

  @HostListener('window:touchend', [])
  onWindowTouchEnd() {
    this._stopScrolledBottomCheck();
  }

  private _startScrolledBottomCheck() {
    const scrolledBottomChecker = () => {
      this._skipPrependScrollAdjust = this._isScrolledToBottom();
      if (this.virtualScroller) {
        this.virtualScroller.skipPrependScrollAdjust =
          this._skipPrependScrollAdjust;
      }
      this._scrolledBottomCheckerAnimFrame = requestAnimationFrame(
        scrolledBottomChecker,
      );
    };
    scrolledBottomChecker();
  }

  private _stopScrolledBottomCheck() {
    cancelAnimationFrame(this._scrolledBottomCheckerAnimFrame);
  }

  hasTruePropertyValue(object: any) {
    if (!object) return false;

    for (const value of Object.values(object)) {
      if (value) return true;
    }

    return false;
  }

  onViewPeople(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.stopPropagation();

    this._conversationNavigator.navigatePeopleByConversationId(
      this._conversationId,
    );
  }

  getSearchResultsHeaderText(resultssLength: number) {
    const resultString = resultssLength === 1 ? 'result' : 'results';
    return `Showing ${resultssLength} ${resultString}`;
  }

  onSend() {
    this._analyticsService.sendDirectMessageSent();
    const bodyText = this.messageBodyInput.trim();
    if (bodyText || this.pendingAssetPaths.length) {
      this.messageBodyInput = '';
      this._messagingFacade.sendMessage(
        this._conversationId,
        bodyText,
        this.pendingAssetPaths || [],
      );
    }
    // reset assets being sent now that they're sent
    this.pendingAssetPaths = [];
  }

  getSafeStyle(key: string, value: string) {
    return this._sanitizer.bypassSecurityTrustStyle(`${key}: ` + value);
  }

  private async _scrollToBottomIfNeeded() {
    do {
      if (this._destroyed) return;
      this._scrollToBottom();
      await new Promise(resolve => setTimeout(resolve, 0));
      if (this._destroyed) return;
      this._scrollToBottom();

      // Maintain that we've been scrolled to the bottom for at least a little
      // bit of time. This is to compensate for the items being different
      // sizes causing the scroller height to be inaccurate at times.
      await new Promise(resolve => setTimeout(resolve, 300));
    } while (!this._isScrolledToBottom());
  }

  private _isScrolledToBottom(): boolean {
    const scrollElement = this._getScrollElement();
    const maxScroll = this._getMaxScrollY();

    // if no scrolling element, assume scrolled to bottom
    return !!scrollElement
      ? Math.ceil(scrollElement.scrollTop) >= maxScroll
      : true;
  }

  private _scrollToBottom() {
    const scrollElement = this._getScrollElement();
    if (scrollElement) {
      window.scrollTo(scrollElement.scrollLeft, this._getMaxScrollY());
    }
  }

  private _getMaxScrollY() {
    const scrollElement = this._getScrollElement();
    if (scrollElement) {
      return scrollElement.scrollHeight - scrollElement.clientHeight;
    }
    return 0;
  }

  private _getScrollElement() {
    return document.scrollingElement;
  }

  onMessageInputKeypress(ev: KeyboardEvent) {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.stopImmediatePropagation();
      this.onSend();

      return false;
    }
  }

  onMessageInputChange(msgInput: string) {
    this._cdr.markForCheck();
  }

  onSearchMessageChange(searchText: string) {
    this.searchMessageText$.next(searchText);
  }

  messageTimestampText(timestamp: number) {
    return day(timestamp).fromNow();
  }

  private _updateOverlayNextText(
    messagesLength: number,
    routeData: MessagingScreenConversationConfigOptions,
  ) {
    let overlayNextText = '';

    if (routeData && routeData.showMessageCount) {
      overlayNextText = `${messagesLength}`;

      if (messagesLength === 1) {
        overlayNextText += ' Message';
      } else {
        overlayNextText += ' Messages';
      }
    }

    return overlayNextText;
  }

  onOverlayPrevious() {
    closeCurrentOverlay(this._router);
  }

  async onOverlayClose(): Promise<boolean> {
    return await closeCurrentOverlay(this._router);
  }

  registerOnOverlayConfig(setConfig: (config: IOverlayConfig) => void) {
    setConfig({
      background: '#F5F5F5',
    });
  }

  ngOnDestroy() {
    this._destroyed = true;
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onScrollerUpdate(items: IKeywordMatchedMessage[] | null) {
    items?.forEach(item => {
      // clone items with keywords to force a refresh on the item's keyword
      // match highlighting
      if (item.keywords) {
        item.keywords = _.clone(item.keywords);
      }
    });
  }

  /**
   * Return the overlay-nav's height to offset content.
   *
   * @NOTE this is a temporary approach.
   * @TODO remove this with an overlay system offseting content implementation
   */
  getNavTopHeight() {
    if (this.overlayTopEl) {
      let overlayNavHeight = this.overlayTopEl.nativeElement.offsetHeight;
      if (window.innerWidth <= 599) {
        // adjust for minga colors and the overlay nav's bottom border
        overlayNavHeight += 5;
      }

      return overlayNavHeight + 'px';
    }
    return '0px';
  }

  private async _uploadPendingFiles() {
    this.fileUploads = [];
    this.pendingAssetPaths = [];

    this.pendingFiles.forEach(file => {
      if (file.name.length > 10) {
        file = renameFile(file, file.name.substring(0, 10));
      }
      this.fileUploads?.push(
        this._fileUploadManager.uploadFile(file, DEFAULT_IMAGE_ACCEPT),
      );
    });

    if (this.fileUploads.length) {
      let noErrors = true;

      const handleError = () => {
        this._systemAlertSnackBar.error(
          `error occurred while uploading files, please try again.`,
        );
      };

      try {
        const states = await forkJoin(...this.fileUploads).toPromise();

        for (const state of states) {
          if (state.status === FileUploadStatus.DONE) {
            this.pendingAssetPaths.push(state.assetId);
          } else if (state.status === FileUploadStatus.ERROR_UNKNOWN) {
            noErrors = false;
            break;
          }
        }

        if (noErrors) {
          // send the message!
          this.onSend();
        } else {
          handleError();
        }
      } catch (e) {
        console.error(`uploading files error: `, e);
        handleError();
      }
    }
  }

  onFileChange(e: any) {
    this._rootService.addLoadingPromise(this._uploadPendingFiles());
  }

  openLightbox(message: IMessage, lightboxIndex: number = 0) {
    this.activeMessageAttachments = message.attachmentList;
    this.lightboxIndex = lightboxIndex;
    this.imageLightBox?.open();
  }

  getMessageImageWidth(attachment: IMessageAttachment) {
    if (attachment.image && 'bannerlibpreview' in attachment.image) {
      return attachment.image['bannerlibpreview'].width;
    }
    return 165;
  }

  getMessageImageHeight(attachment: IMessageAttachment) {
    if (attachment.image && 'bannerlibpreview' in attachment.image) {
      return attachment.image['bannerlibpreview'].height;
    }
    return 165;
  }
}
