import { Injectable } from '@angular/core';

import { day } from 'minga/libraries/day';

export interface IDateFeedItem {
  // Moment formatted date string, would be value of date separator if
  // applicable
  dateString: string;
  // sort order timestamp
  timestamp: number;
  // used as key for unique date feed items
  id: string;
}

export const DATE_SEPARATOR_FORMAT = 'ddd, MMM DD';

@Injectable({ providedIn: 'root' })
export class DateFeedSeparator {
  separatorDates: Map<string, Map<string, IDateFeedItem>> = new Map();

  constructor() {}

  private _addDateItem(item: IDateFeedItem, streamName = ''): void {
    let items = this.separatorDates.get(streamName);
    if (!items) items = new Map();

    items.set(item.dateString, item);
    this.separatorDates.set(streamName, items);
  }

  initSeparatorItem(timestamp: number, id: string, streamName = '') {
    if (!timestamp) return;

    const dateString = day(timestamp).format(DATE_SEPARATOR_FORMAT);
    let item = this._getDateItem(dateString, streamName);

    if (!item) {
      item = {
        dateString: day(timestamp).format(DATE_SEPARATOR_FORMAT),
        timestamp,
        id,
      };
      // adding will replace or insert the current date item
      this._addDateItem(item, streamName);
    } else if (
      item.dateString &&
      item.dateString == dateString &&
      item.timestamp > timestamp
    ) {
      // replace item as this is the new minimum date item
      item.timestamp = timestamp;
      item.id = id;

      // adding will replace or insert the current date item
      this._addDateItem(item, streamName);
    }
  }

  getSeparatorDate(timestamp: number, id: string, streamName = ''): string {
    if (timestamp) {
      const dateString = day(timestamp).format(DATE_SEPARATOR_FORMAT);
      let item = this._getDateItem(dateString, streamName);

      // we have this date deparator date...
      if (item && item.dateString && item.dateString == dateString) {
        // this isn't the first item with the given date, no separator
        if (item.timestamp <= timestamp && item.id != id) {
          return '';
        } else if (item && item.id == id) {
          // it's the matching item to the get
          return item.dateString;
        }
      }
    }

    return '';
  }

  clearSeparatorData(streamName = '') {
    this.separatorDates.delete(streamName);
  }

  /**
   * Find date feed item within currentDates map matching a given stream name
   * and date separator (dateKey) value.
   */
  private _getDateItem(dateKey: string, streamName = ''): IDateFeedItem | null {
    const items = this.separatorDates.get(streamName);
    if (items) {
      const item = items.get(dateKey);
      return item || null;
    }

    return null;
  }
}
