import { Injectable, OnDestroy } from '@angular/core';

import * as day from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { xor } from 'lodash';
import {
  BehaviorSubject,
  interval,
  NEVER,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { HallPassStatusEnum, IHallPassType } from 'minga/domain/hallPass';
import { MingaPermission } from 'minga/domain/permissions';
import { HallPassManager } from 'minga/proto/hall_pass/hall_pass_ng_grpc_pb';
import { HallPassWithType } from 'minga/proto/hall_pass/hall_pass_pb';
import { PermissionsService } from 'src/app/permissions';
import { UserStorage } from 'src/app/services/UserStorage';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { HallPassService } from '@shared/services/hall-pass';
import { HallPassActionsService } from '@shared/services/hall-pass/hallpass-actions.service';

import {
  AUTO_REFRESH_POLL_RATE,
  AUTO_REFRESH_STATE,
  HPM_DASHBOARD_FILTERS_INITIAL_STATE_ADMIN,
  HPM_DASHBOARD_FILTERS_INITIAL_STATE_TEACHER,
  HpmDashboardFilter,
  mapHallPassToDashboardItem,
  PASS_REQUEST_DEBOUNCE_RATE,
} from '../constants';
import { HpmDashboardFilters, HpmDashboardTableItem } from '../types';

day.extend(isSameOrBefore);
day.extend(isSameOrAfter);

@Injectable()
export class HpmDashboardService implements OnDestroy {
  /** Constants */
  readonly POLL_RATE = AUTO_REFRESH_POLL_RATE;

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);
  private readonly _isLoading$ = new BehaviorSubject(true);
  public readonly isLoading$ = this._isLoading$.asObservable();
  public readonly timeTicker$ = interval(1000);

  /** Filters */
  private readonly _filters$ = new BehaviorSubject<HpmDashboardFilters>({
    show_mine: false,
    pass_status: [
      HallPassStatusEnum.ACTIVE,
      HallPassStatusEnum.OVERDUE,
      HallPassStatusEnum.PENDING_APPROVAL,
    ],
    authors: [],
    pass_id: null,
    recipients: [],
  });
  public readonly filters$ = this._filters$.asObservable();

  /** Settings */
  public readonly canGrantPass$: Observable<boolean>;
  public readonly canViewStudentId$: Observable<boolean>;

  /** Poll / Auto Refresh Passes */
  private readonly _pollPasses$ = new BehaviorSubject(false);
  public readonly pollPasses$ = this._pollPasses$.asObservable();
  public readonly pollRefetch$: Subscription;

  /** Manual Refresh Passes */
  private readonly _refetchPasses$ = new BehaviorSubject<void>(undefined);

  private _initialFilterState: HpmDashboardFilters;

  /** Passes */
  private readonly _passes$ = new BehaviorSubject<HpmDashboardTableItem[]>([]);
  public readonly passes$ = this._passes$.asObservable().pipe(shareReplay(1));

  /** Pass Types */
  private readonly _passTypes$ = new BehaviorSubject<IHallPassType[]>([]);
  public readonly passTypes$ = this._passTypes$
    .asObservable()
    .pipe(shareReplay(1));

  /** Clear Filters */
  private _clearFiltersEventSubject = new Subject();
  public readonly clearFiltersEvent$ =
    this._clearFiltersEventSubject.asObservable();

  /** Service Constructor */
  constructor(
    private _hpManager: HallPassManager,
    private _permissions: PermissionsService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _userStorage: UserStorage,
    private _hallpassService: HallPassService,
    private _hpActionsService: HallPassActionsService,
  ) {
    this._initPassPolling();
    this._fetchTypes();
    this._refetchPasses$
      .asObservable()
      // debounce this because the initial set of the auto refresh can trigger a duplicate fetch on load
      // also if users spams the refresh button it will throttle those requests as well
      .pipe(
        takeUntil(this._destroyed$),
        debounceTime(PASS_REQUEST_DEBOUNCE_RATE),
      )
      .subscribe(() => {
        this._fetchData();
      });
    this.canGrantPass$ = this._permissions
      .observePermission(MingaPermission.HALL_PASS_CREATE)
      .pipe(takeUntil(this._destroyed$));
    this.pollRefetch$ = this.pollPasses$
      .pipe(
        takeUntil(this._destroyed$),
        distinctUntilChanged(),
        switchMap(paused => (paused ? timer(0, this.POLL_RATE) : NEVER)),
      )
      .subscribe(() => {
        this.refetchPasses();
      });

    this._permissions
      .observePermission(MingaPermission.HALL_PASS_TYPE_MANAGE)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(canManageTypes => {
        this._setInitialFilters(canManageTypes);
      });

    this._hpActionsService.passCountdownChange$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(({ id, state, type }) => {
        if (type === 'update') {
          this.updatePassStatus(id, state);
        }

        if (type === 'remove') {
          this.removePass(id);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._isLoading$.complete();
    this._pollPasses$.complete();
    this._passes$.complete();
  }

  public async refetchPasses(): Promise<void> {
    this._refetchPasses$.next();
  }

  public async clearFilters(): Promise<void> {
    this._clearFiltersEventSubject.next();
    this._filters$.next(
      this._initialFilterState || HPM_DASHBOARD_FILTERS_INITIAL_STATE_ADMIN,
    );
    await this.refetchPasses();
  }

  public updatePassStatus(
    passId: number,
    state: HallPassStatusEnum,
    extraFields?: Partial<HpmDashboardTableItem>,
  ) {
    if (!this._passes$) return;
    const currentPasses = this._passes$.getValue();
    const itemIndex = currentPasses.findIndex(passes => passes.id === passId);
    const item = currentPasses[itemIndex];
    item.status.state = state;

    if (extraFields) {
      for (const key in extraFields) {
        if (item[key]) {
          item[key] = extraFields[key];
        }
      }
    }

    this._passes$.next(currentPasses);
  }

  public addPasses(passes: HallPassWithType.AsObject[]) {
    const newPasses = passes.map(pass =>
      mapHallPassToDashboardItem(pass, day()),
    );
    const currentPasses = this._passes$.getValue();
    this._passes$.next([...newPasses, ...currentPasses]);
  }

  public removePass(id: number) {
    if (!this._passes$) return;
    const currentPasses = this._passes$.getValue();
    const itemIndex = currentPasses.findIndex(passes => passes.id === id);
    if (itemIndex !== -1) currentPasses.splice(itemIndex, 1);
    this._passes$.next(currentPasses);
  }

  public setPassEndTime(passId: number) {
    const currentPasses = this._passes$.getValue();
    const itemIndex = currentPasses.findIndex(passes => passes.id === passId);
    const item = currentPasses[itemIndex];
    item.status.end = new Date();
    this._passes$.next(currentPasses);
  }

  public async togglePollPasses(): Promise<void> {
    const enabled = !this._pollPasses$.getValue();
    this._systemAlertSnackBar.success(
      `Auto refresh ${enabled ? 'enabled' : 'disabled'}`,
    );
    this._pollPasses$.next(!this._pollPasses$.getValue());
    await this._userStorage.setItem(AUTO_REFRESH_STATE, enabled);
  }

  public async setLoadingStatus(state: boolean) {
    this._isLoading$.next(state);
  }

  public async updateFilter(
    filterType: HpmDashboardFilter,
    value: any,
    isToggle?: boolean,
  ) {
    const state: any = { ...this._filters$.value };
    if (isToggle) {
      const currentValue = state[filterType];
      if (Array.isArray(value)) {
        state[filterType] = xor(currentValue, value);
      } else {
        state[filterType] = value === currentValue ? undefined : value;
      }
    } else {
      state[filterType] = value;
    }
    this._filters$.next(state);
    if (
      filterType === HpmDashboardFilter.PASS_STATUS ||
      filterType === HpmDashboardFilter.SHOW_MINE
    ) {
      this.refetchPasses();
    }
  }

  private async _fetchData(): Promise<void> {
    try {
      this._isLoading$.next(true);
      const passes = await this._listPasses();
      this._passes$.next(passes);
      this._isLoading$.next(false);
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to fetch data');
    }
  }

  private async _fetchTypes(): Promise<void> {
    const types = await this._hallpassService.listTypes();
    this._passTypes$.next(types);
  }

  private async _listPasses(): Promise<HpmDashboardTableItem[]> {
    const passes = await this._hallpassService.listPasses(
      this._filters$.getValue(),
    );

    const now = day();
    return passes.map(item => mapHallPassToDashboardItem(item, now));
  }

  private async _initPassPolling() {
    const passPollingState: boolean = await this._userStorage.getItem(
      AUTO_REFRESH_STATE,
    );
    this._pollPasses$.next(passPollingState);
  }

  private _setInitialFilters(canManageTypes: boolean) {
    // teacher's dont have ability to manage types
    const initialFilterState = canManageTypes
      ? HPM_DASHBOARD_FILTERS_INITIAL_STATE_ADMIN
      : HPM_DASHBOARD_FILTERS_INITIAL_STATE_TEACHER;
    this._initialFilterState = initialFilterState;

    this._filters$.next(initialFilterState);
  }
}
