import { ChangeDetectionStrategy, Component } from '@angular/core';

import { HpmReportsService } from './components';

/**
 * Hall Pass Manager Component
 *
 * Entry point to the hall pass manager module.
 */
@Component({
  selector: 'mg-hallpass-manager',
  templateUrl: './hallpass-manager.component.html',
  styleUrls: ['./hallpass-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HpmReportsService],
})
export class HallpassManagerComponent {
  constructor() {}
}
