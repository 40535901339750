import { createAction, props } from '@ngrx/store';

import { MingaMinimalModel } from 'minga/domain';
import {
  IAuthPerson,
  IAuthResponse,
  IAuthTokenMingaPayload,
  ISsoAuthError,
  ISsoProviderInfo,
} from 'minga/domain/auth';
import { MingaRoleType } from 'minga/domain/permissions';

export const setPreAuthRoute = createAction(
  '[Auth] set pre auth route',
  props<{
    preAuthUrl: string;
  }>(),
);

/**
 * This action gets triggered when the authentication is first established.
 */
export const authInit = createAction('[Auth] init');

/**
 * A single sign on request was successful and was linked to existing Minga user
 */
export const authSsoLinked = createAction(
  '[Auth] sso linked',
  props<{ ssoProviderInfo: ISsoProviderInfo }>(),
);

/**
 * A single sign on request was successful, but the sso is not attached to any
 * known Minga user.
 */
export const authSsoDetached = createAction(
  '[Auth] sso detached',
  props<{ ssoProviderInfo: ISsoProviderInfo }>(),
);

/**
 * This action gets triggered when the user unlinks their minga account
 * from an SSO provider.
 */
export const authSsoUnlinked = createAction('[Auth] SSO Unlinked');

/**
 * A single sign on request has failed
 */
export const authSsoError = createAction(
  '[Auth] sso error',
  props<{ ssoError: ISsoAuthError }>(),
);

/**
 * A custom sso request has failed
 */
export const authCustomSsoError = createAction(
  '[Auth] custom sso error',
  props<{
    customSsoError: {
      message: string;
    };
  }>(),
);

/**
 * This action gets triggered whenever the current authentication changes, but
 * is still valid.
 */
export const authUpdated = createAction('[Auth] updated');

/**
 * This action gets triggered whenever the current authentication is cleared and
 * the current user is no longer authenticated.
 */
export const authCleared = createAction('[Auth] cleared');

/**
 * This action gets triggered when the auth person gets updated.
 */
export const authPersonUpdated = createAction(
  '[Auth] updated auth person',
  props<{ authPerson: IAuthPerson }>(),
);

/**
 * This action gets triggered when the auth token claims get updated.
 */
export const authTokenUpdated = createAction(
  '[Auth] updated auth token',
  props<{ minga: IAuthTokenMingaPayload }>(),
);

/**
 * This action gets triggered when login/register/validate is successful
 */
export const authSuccess = createAction(
  '[Auth] Auth success',
  props<{ authResponse: IAuthResponse }>(),
);

/**
 * This action gets triggered when login is successful
 */
export const loginSuccess = createAction(
  '[Auth] Auth Login success',
  props<{ usersMingas: MingaMinimalModel[]; role: MingaRoleType }>(),
);
// @ts-ignore
export const authFail = createAction('[Auth] Auth fail', props<any>());

export const updateDmPersonalPreference = createAction(
  '[Auth] Update DM personal preference',
  props<{ dmPersonalPreference: boolean }>(),
);

export const removeAuthParentGroup = createAction(
  '[Auth] Remove parent group',
  props<{ parentGroupHash: string }>(),
);

export const addAuthParentGroup = createAction(
  '[Auth] Add parent group',
  props<{ parentGroupHash: string }>(),
);

export type AuthStoreActions =
  | typeof setPreAuthRoute
  | typeof authInit
  | typeof authUpdated
  | typeof authCleared
  | typeof authTokenUpdated
  | typeof authSuccess
  | typeof authFail
  | typeof updateDmPersonalPreference
  | typeof removeAuthParentGroup
  | typeof addAuthParentGroup
  | typeof authPersonUpdated
  | typeof loginSuccess;
