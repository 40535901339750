import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { combineLatest, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import * as pbis_pb from 'minga/proto/pbis/pbis_pb';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { IConsequenceType } from 'minga/domain/consequences';
import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';
import {
  IPbisBehavior,
  IPbisType,
  IPbisTypeCategory,
  PbisNameCategory,
} from 'minga/domain/pbis';
import { MingaPermission } from 'minga/domain/permissions';
import { day } from 'minga/libraries/day';
import { mingaSettingTypes } from 'minga/libraries/util';
import { PbisManager } from 'minga/proto/pbis/pbis_ng_grpc_pb';
import { StudentIdManager } from 'minga/proto/student/student_id_ng_grpc_pb';
import { ConsequenceTypeMapper } from 'minga/shared-grpc/consequences';
import { PbisBehaviorMapper, PbisTypeMapper } from 'minga/shared-grpc/pbis';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { ListMembershipService } from 'src/app/services/ListMembership';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export type IPbisSettings = {
  [key in mingaSettingTypes]?: boolean;
};

enum IPBISMembershipListType {
  SCHOOL_TEAM = MembershipListType.SCHOOL_TEAM,
  NO_TRACK = MembershipListType.NO_TRACK,
  TRACK = MembershipListType.TRACK,
}

/**
 * PBIS Service
 */
@Injectable({ providedIn: 'root' })
export class PbisService {
  pbisSettings$: Observable<IPbisSettings>;
  private _pbisTypeId: number;
  private _pbisMessage: string = '';
  private _pbisCategory: PbisNameCategory;
  private _consequenceCompleteDate = day();

  constructor(
    private pbisManager: PbisManager,
    private idManager: StudentIdManager,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private mingaStore: MingaStoreFacadeService,
    private authInfoService: AuthInfoService,
    private listManager: ListMembershipService,
    private permissions: PermissionsService,
    private router: Router,
  ) {
    this.pbisSettings$ = this.mingaStore.getMingaAsObservable().pipe(
      map(mingaInfo => {
        const pbisSettings: IPbisSettings = {};

        if (mingaInfo) {
          for (const setting of mingaInfo.settings) {
            pbisSettings[setting.name] = setting.value;
          }
        }

        return pbisSettings;
      }),
    );
  }

  /**
   * Can User Track Behaviors
   *
   * Check if current user can issue PBIS Behaviors to students
   */
  canUserTrackBehaviors(): Observable<boolean> {
    return combineLatest([
      this.permissions.observePermission(MingaPermission.PBIS_CREATE),
      this.permissions.observePermission(MingaPermission.MANAGE_REWARDS),
    ]).pipe(map(([behave, reward]) => behave || reward));
  }

  gotoPbisForm(url: string) {
    return this.router.navigate([
      '',
      {
        outlets: { o: `pbis/assign-behavior${url}` },
      },
    ]);
  }

  resetData() {
    this._pbisTypeId = null;
    this._pbisMessage = '';
  }

  setPbisTypeId(id: number) {
    this._pbisTypeId = id;
  }

  getPbisTypeId() {
    return this._pbisTypeId;
  }

  setPbisMessage(message: string) {
    this._pbisMessage = message;
  }

  getPbisMessage() {
    return this._pbisMessage;
  }

  setPbisCategory(category: PbisNameCategory) {
    this._pbisCategory = category;
  }

  getPbisCategory() {
    return this._pbisCategory;
  }

  setConsequenceCompleteDate(date: day.Dayjs) {
    this._consequenceCompleteDate = date;
  }

  getConsequenceCompleteDate() {
    return this._consequenceCompleteDate;
  }

  /**
   * Get Types
   *
   * This is an observable
   */
  public getTypes(
    getActiveOnly?: boolean,
    getPermittedOnly?: boolean,
  ): Observable<IPbisType[]> {
    const req = new pbis_pb.GetTypesRequest();
    if (getActiveOnly) {
      req.setGetActiveOnly(getActiveOnly);
    }
    if (getPermittedOnly) {
      req.setGetPermittedOnly(getPermittedOnly);
    }

    return from(this._handleGetTypes(req));
  }

  public getConsTypes(
    activeOnly?: boolean,
    getPermittedOnly?: boolean,
  ): Observable<IConsequenceType[]> {
    const req = new pbis_pb.GetConsequenceTypesRequest();
    if (activeOnly) {
      req.setActiveOnly(activeOnly);
    }
    if (getPermittedOnly) {
      req.setGetPermittedOnly(getPermittedOnly);
    }
    return from(this._handleGetTypes(req, true));
  }

  /**
   * Get Types Categories
   *
   * Hardcorded for now.
   */
  public getTypeCategories(): Observable<IPbisTypeCategory[]> {
    const categories: IPbisTypeCategory[] = [
      {
        id: 0,
        name: 'Praise',
      },
      {
        id: 1,
        name: 'Guidance',
      },
    ];
    return of(categories);
  }

  /**
   * Update Type
   */
  public async updateType(type: IPbisType): Promise<IPbisType> {
    const req = new pbis_pb.UpdateTypeRequest();
    req.setType(PbisTypeMapper.toProto(type));
    const res = await this.pbisManager.updateType(req);
    return PbisTypeMapper.fromProto(res.getType());
  }

  /**
   * Delete Type
   */
  public async deleteType(id: number): Promise<void> {
    const req = new pbis_pb.DeleteTypeRequest();
    req.setId(id);
    await this.pbisManager.deleteType(req);
  }

  /**
   * Create Type With Placeholder
   *
   * Creates a new type with placeholder values
   */
  public async createTypeWithPlaceholder(): Promise<IPbisType> {
    return await this.updateType({
      name: 'Behavior Name',
      points: 0,
      categoryId: 0,
      adminEmails: [],
    });
  }

  /**
   * Add Default Pbis Types
   *
   * Adds default PBIS types to the database if none exist.
   */
  public async addDefaultPbisTypes(mingaHash: string): Promise<any> {
    const req = new pbis_pb.AddDefaultPbisTypesRequest();
    req.setMingaHash(mingaHash);

    const res: pbis_pb.AddDefaultPbisTypesResponse =
      await this.pbisManager.addDefaultPbisTypes(req);

    const errorMessage = res.getErrorMessage();

    return errorMessage;
  }

  /**
   * Create Behavior
   */
  public async createBehavior(obj: {
    typeId: number;
    message: string;
    recipientIds: string[];
  }): Promise<void> {
    const req = new pbis_pb.CreateBehaviorRequest();

    req.setTypeId(obj.typeId);
    req.setMessage(obj.message);
    req.setRecipientIdsList(obj.recipientIds);

    await this.pbisManager.createBehavior(req);
  }

  /**
   * Create membership list for PBIS management
   *
   * Create lists relevant to PBIS settings
   */
  async createPbisList(
    name: string,
    type: MembershipListType,
    color?: string,
  ): Promise<IMembershipList> {
    const noTrackListDefaults: IMembershipList = {
      name,
      listType: type,
      color,
    };

    return await this.listManager.updateMembershipList(noTrackListDefaults);
  }

  /*
   * Get Behavior
   *
   * Fetches a single behavior from the backend based on ID.
   */
  public async getBehavior(id: number): Promise<IPbisBehavior> {
    const req = new pbis_pb.GetBehaviorRequest();
    req.setId(id);
    const res = await this.pbisManager.getBehavior(req);
    return PbisBehaviorMapper.fromProto(res.getBehavior());
  }

  /**
   * Handle Get Types
   *
   * This is the actual request to fetch types
   */
  private async _handleGetTypes(
    req: any,
    consequence?: boolean,
  ): Promise<any[]> {
    if (consequence) {
      const res = await this.pbisManager.getConsequenceTypes(req);
      return res.getConsequenceTypeList().map(ConsequenceTypeMapper.fromProto);
    }
    const res = await this.pbisManager.getTypes(req);
    return res.getTypesList().map(PbisTypeMapper.fromProto);
  }
}
