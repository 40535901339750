import { Injectable } from '@angular/core';

import { Terms } from 'minga/proto/term/term_ng_grpc_pb';
import {
  CreateTermRequest,
  UpdateTermRequest,
  GetTermsRequest,
  DeleteTermRequest,
  GetTermRequest,
  TermCreateAndUpdateOptions,
} from 'minga/proto/term/term_pb';
import { termMapper } from 'minga/shared-grpc/term';
import { dateObjectToDateTimeMessage } from 'minga/shared-grpc/util';

import {
  ClientTerm,
  TermId,
} from '@modules/minga-manager/components/mm-bell-schedule/types/mm-bell-schedule.types';

import { CacheService } from '../cache/cache.service';
import { CacheKey } from '../cache/cache.types';
import {
  getUpdatedCacheCollection,
  UpdateListCacheParams,
} from '../cache/cache.utils';
import { ErrorHandlerService } from '../error-handler';
import { BellScheduleTermsService } from './bs-terms.interface';

@Injectable({ providedIn: 'root' })
export class BsTermsService implements BellScheduleTermsService {
  private _cachedTerms = this._cacheService.create<ClientTerm[]>(
    CacheKey.BELL_SCHEDULE_TERMS_LIST,
    data => {
      return this._fetchAll();
    },
    {
      ttl: 60,
    },
  );
  constructor(
    private _cacheService: CacheService,
    private _errorHandler: ErrorHandlerService,
    private _termManager: Terms,
  ) {}

  public async fetchAll(opts?: {
    revalidate?: boolean;
  }): Promise<ClientTerm[]> {
    return await this._cachedTerms.get({}, opts).toPromise();
  }

  private async _fetchAll(): Promise<ClientTerm[]> {
    try {
      const request = new GetTermsRequest();
      // TODO to we need pagination?
      request.setLimit(1000);

      const result = await this._termManager.getTerms(request);
      const terms = result.getTermsList();
      const mapped = terms.map(termMapper.fromProto);

      return mapped as ClientTerm[];
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch terms',
        error,
        true,
        true,
      );
    }
  }

  public async get(id: TermId): Promise<ClientTerm> {
    try {
      const request = new GetTermRequest();
      request.setId(id);
      const result = await this._termManager.getTerm(request);
      return termMapper.fromProto(result.getTerm()) as ClientTerm;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch term',
        error,
        true,
      );
    }
  }

  public async create(term: ClientTerm): Promise<ClientTerm> {
    try {
      const request = new CreateTermRequest();
      const termInstance = this._getCreateUpdateInstance(term);
      request.setTerm(termInstance);
      const result = await this._termManager.createTerm(request);
      return termMapper.fromProto(result.getTerm()) as ClientTerm;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to create term',
        error,
        true,
      );
    }
  }

  public async update(term: ClientTerm): Promise<ClientTerm> {
    try {
      const request = new UpdateTermRequest();
      const termInstance = this._getCreateUpdateInstance(term);
      request.setTerm(termInstance);
      const result = await this._termManager.updateTerm(request);
      return termMapper.fromProto(result.getTerm()) as ClientTerm;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to update term',
        error,
        true,
      );
    }
  }

  public async delete(id: TermId): Promise<void> {
    try {
      const request = new DeleteTermRequest();
      request.setId(id);
      await this._termManager.deleteTerm(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to delete term',
        error,
        true,
      );
    }
  }

  public async updateListCache(
    opts: UpdateListCacheParams<ClientTerm>,
  ): Promise<ClientTerm[]> {
    const items = await this.fetchAll();
    const updatedList = getUpdatedCacheCollection(opts, items);
    this._cachedTerms.set(updatedList);
    return updatedList;
  }

  private _getCreateUpdateInstance(
    term: ClientTerm,
  ): TermCreateAndUpdateOptions {
    const request = new TermCreateAndUpdateOptions();

    if (term.id) {
      request.setId(term.id);
    }

    request.setSourcedId(term.sourcedId);
    request.setTitle(term.title);
    if (term.startDate) {
      request.setStartDate(dateObjectToDateTimeMessage(term.startDate));
    }

    if (term.endDate) {
      request.setEndDate(dateObjectToDateTimeMessage(term.endDate));
    }
    request.setBellScheduleIdsList(term.bellScheduleIds);

    return request;
  }
}
