import {
  Directive,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { from, Observable, Subscription } from 'rxjs';

import { MingaPermission, MingaPermissionName } from 'minga/domain/permissions';

import { PermissionsService } from './Permissions.service';

interface IPermissionViewChecker {
  perms: MingaPermission[];
  sub: Subscription;
  obs: Observable<boolean>;
}

@Directive({ selector: '[hasPermission]' })
export class HasPermissionDirective {
  private _hasPermissionElse?: TemplateRef<any>;
  private _hasPerm?: boolean;
  private _permChecker: IPermissionViewChecker | null = null;

  @Input()
  set hasPermission(arg: MingaPermissionName | MingaPermissionName[]) {
    const perms: MingaPermission[] = [];

    if (Array.isArray(arg)) {
      perms.push(...arg.map(permName => MingaPermission[permName]));
    } else {
      perms.push(MingaPermission[arg]);
    }

    if (this._permChecker) {
      this._permChecker.sub.unsubscribe();
    }

    const obs = this.permissions.observePermission(perms);
    const sub = obs.subscribe({
      next: result => this._updateView(result),
      error: err => {
        console.error(err);
        this._updateView(false);
      },
    });

    this._permChecker = { perms, sub, obs };
  }

  @Input()
  set hasPermissionElse(template: TemplateRef<any> | undefined) {
    if (template) {
      this._hasPermissionElse = template;
    } else {
      delete this._hasPermissionElse;
    }

    // If we're updating the else template and we current do not have the
    // permission we need to re-create the else template
    if (!this._hasPerm) {
      this._createView();
    }
  }

  get hasPermissionElse(): TemplateRef<any> | undefined {
    return this._hasPermissionElse;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissions: PermissionsService,
  ) {}

  private _updateView(hasPerm: boolean) {
    if (hasPerm !== this._hasPerm) {
      this._hasPerm = hasPerm;
      this._createView();
    }
  }

  private _createView() {
    this.viewContainer.clear();
    if (this._hasPerm) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!this._hasPerm && this.hasPermissionElse) {
      this.viewContainer.createEmbeddedView(this.hasPermissionElse);
    }
  }
}
