<div class="manager-overlay-subpage-header">
  <div
    class="header-container"
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="end center"
    fxLayoutAlign.lt-sm="start stretch">
    <mg-btn
      *ngIf="consequencesEnabled$ | async"
      variant="filled"
      [id]="'pbis-automations-click-create'"
      [responsive]="true"
      (pressed)="bmAutomations.openGroupCreateModal(true)">
      {{ MESSAGES.BUTTON_CREATE }}
    </mg-btn>
  </div>
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</div>

<!-- Loading -->
<div
  *ngIf="isLoading$ | async"
  fxLayout="row"
  fxLayoutAlign="center center"
  class="loading-container">
  <mg-spinner
    [diameter]="40"
    [thickness]="3">
  </mg-spinner>
</div>

<!-- Main content -->
<ng-template #mainContent>
  <div
    *ngIf="(isLoading$ | async) === false"
    fxLayout="column"
    fxLayoutGap="8px"
    fxLayoutAlign="start stretch"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start stretch">
    <div fxFlex="50%">
      <div
        fxLayout="column"
        fxLayoutGap="8px"
        fxLayoutAlign="start stretch">
        <!-- Inactive group -->
        <mg-system-alert
          *ngIf="inactiveGroup$ | async"
          type="warning"
          class="inactive-group-warning"
          message="{{ MESSAGES.INACTIVE_GROUP }}">
        </mg-system-alert>

        <ng-container
          *ngIf="
            automationGroupsWithInactiveBehavior$
              | async as automationGroupsWithInactiveBehavior
          ">
          <mg-system-alert
            *ngFor="let group of automationGroupsWithInactiveBehavior"
            type="warning"
            class="inactive-group-warning"
            [message]="
              'The automation group &quot;' +
              group.name +
              '&quot; is using one or more deactivated behaviors'
            ">
          </mg-system-alert>
        </ng-container>
        <ng-container
          *ngIf="
            groupsWithDisabledConsequence$
              | async as groupsWithDisabledConsequence
          ">
          <mg-system-alert
            *ngFor="let group of groupsWithDisabledConsequence"
            type="warning"
            class="inactive-group-warning"
            [message]="
              'The automation group &quot;' +
              group.name +
              '&quot; is using one or more deactivated consequences'
            ">
          </mg-system-alert>
        </ng-container>
      </div>

      <!-- Automation group item card -->
      <section class="automation-list">
        <ng-container
          *ngFor="let group of automationGroups$ | async; trackBy: trackByFn"
          [ngTemplateOutlet]="automationGroupTemplate"
          [ngTemplateOutletContext]="{
            $implicit: group,
            isExpanded:
              ((expandedGroups$ | async)?.includes(group.id) &&
                (scrollToGroup$ | async) === null) ||
              +(activeRoute.firstChild?.params | async)?.groupId === group.id,
            scrollToTop:
              +(activeRoute.firstChild?.params | async)?.groupId === group.id &&
              (scrollToGroup$ | async) === group.id,
            activelyEditing:
              +(activeRoute.firstChild?.params | async)?.groupId === group.id
          }">
        </ng-container>
      </section>
      <!-- No results -->
      <ng-container *ngIf="consequencesEnabled$ | async; else disabledEmpty">
        <mg-empty-state
          *ngIf="noResults$ | async"
          class="no-results"
          [title]="MESSAGES.EMPTY_STATE_TITLE"
          [actionButtonLabel]="MESSAGES.EMPTY_STATE_ACTION_BUTTON"
          (handleAction)="bmAutomations.openGroupCreateModal()">
        </mg-empty-state>
      </ng-container>
    </div>
    <div fxFlex="50%">
      <div class="edit-step-wrap">
        <!-- Subview router outlet -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #disabledEmpty>
  <mg-empty-state
    *ngIf="noResults$ | async"
    class="no-results"
    [title]="MESSAGES.EMPTY_DISABLED_TITLE"
    [subtitle]="MESSAGES.EMPTY_DISABLED_DESC">
  </mg-empty-state>
</ng-template>

<!-- Automation group template -->
<ng-template
  #automationGroupTemplate
  let-automationGroup
  let-isExpanded="isExpanded"
  let-activelyEditing="activelyEditing"
  let-scrollToTop="scrollToTop">
  <mat-expansion-panel
    class="automation-list-item"
    hideToggle
    [mgScrollIntoView]="scrollToTop"
    [expanded]="isExpanded"
    [class.is-editing]="activelyEditing"
    (opened)="expandItem(automationGroup.id)"
    (closed)="collapseItem(automationGroup.id)">
    <!-- Header -->
    <mat-expansion-panel-header
      matRipple
      matRippleColor="rgba(0, 0, 0, 0.02)">
      <div class="header-wrap">
        <div
          fxLayout="row"
          fxLayoutAlign="start center">
          <div
            fxFlex="grow"
            fxLayout="row"
            fxLayoutAlign="start center"
            [style.overflow]="'hidden'">
            <mg-icon
              class="toggle-icon"
              [iconName]="isExpanded ? 'up-toggle' : 'down-toggle'">
            </mg-icon>
            <div
              fxLayout="column"
              fxLayoutAlign="start center">
              <mg-text
                class="automation-name"
                variant="header-sm-secondary"
                [numberOfLines]="1">
                {{ automationGroup.name }}
              </mg-text>
              <div class="behaviors">
                <div
                  *ngFor="
                    let trigger of automationGroup?.triggers?.pbisTriggers
                      | getAutomationGroupTriggers: BEHAVIOR_TYPES
                  "
                  class="behavior">
                  <mg-icon
                    *ngIf="trigger.icon as iconName; else noIconTemplate"
                    icon-namespace="minga.behaviors"
                    class="behavior-icon"
                    [style.color]="trigger.color">
                    {{ iconName }}
                  </mg-icon>
                  <ng-template #noIconTemplate>
                    <span
                      class="icon"
                      [style.background-color]="trigger.color">
                    </span>
                  </ng-template>
                  <mg-text
                    variant="body-sm"
                    [numberOfLines]="1">
                    {{ trigger.label }}
                  </mg-text>
                </div>
              </div>
            </div>
          </div>
          <div
            (click)="$event.stopPropagation()"
            fxLayout="row"
            fxLayoutAlign="end center"
            fxLayoutGap="4px"
            fxFlex="nogrow"
            class="action-btn-container">
            <mg-btn
              variant="icon"
              iconSet="minga"
              icon="mg-flex-report"
              tooltip="Summary"
              [id]="'pbis-automations-click-summary'"
              [href]="getReportUrl(automationGroup)"
              target="_blank"
              (pressed)="goToSummary(automationGroup)">
            </mg-btn>

            <mg-btn
              variant="icon"
              iconSet="minga"
              icon="mg-edit"
              tooltip="Edit"
              [id]="'pbis-automations-click-edit'"
              (pressed)="editGroup(automationGroup.id)">
            </mg-btn>
            <mg-form-toggle
              class="side-padding"
              [id]="'pbis-automations-click-toggle'"
              [value]="automationGroup?.active"
              (valueChange)="toggleGroupActiveStatus(automationGroup, $event)">
            </mg-form-toggle>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <!-- Body -->
    <div class="body">
      <mg-bm-types-automation-step
        *ngIf="isExpanded"
        [automationGroupId]="automationGroup.id"
        (stepPressed)="editStep(automationGroup, $event)">
      </mg-bm-types-automation-step>
      <div class="add-automation">
        <mg-btn
          variant="outlined"
          [id]="'pbis-automations-click-add-auto'"
          (pressed)="editStep(automationGroup)">
          Add automation
        </mg-btn>
      </div>
    </div>
  </mat-expansion-panel>
</ng-template>
