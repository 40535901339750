<div
  class="container"
  [ngClass]="{ 'has-no-access': noAccess }">
  <div
    *ngIf="noAccess"
    class="no-access-wrap">
    <img
      class="sticker-image"
      [src]="noAccess" />
  </div>

  <div
    *ngIf="sanitizedStickerList.length"
    class="carousel-wrap">
    <mg-tiles-carousel [stylePreset]="CAROUSEL_PRESETS.LIST">
      <ng-container
        *ngFor="let sticker of sanitizedStickerList"
        [ngTemplateOutlet]="stickerTileTemplate"
        [ngTemplateOutletContext]="{ $implicit: sticker }">
      </ng-container>
    </mg-tiles-carousel>
  </div>
</div>

<ng-template
  #stickerTileTemplate
  let-sticker>
  <img
    class="sticker-image"
    [src]="sticker" />
</ng-template>
