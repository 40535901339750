import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { combineLatest, from, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { GroupCollectionActions } from 'minga/app/src/app/groups/actions';
import { MingaManagerService } from 'minga/app/src/app/services/MingaManager';
import {
  fetchAllMingas,
  MingaActions,
  setActiveMingaSubscription,
  setCurrentMinga,
  setMingaCollection,
} from 'minga/app/src/app/store/Minga/actions';
import { MingaStoreFacadeService } from 'minga/app/src/app/store/Minga/services';
import { MingaPermission } from 'minga/domain/permissions';
import { MingaMinimalProtoMapper } from 'minga/shared-grpc/minga';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { RootService } from 'src/app/minimal/services/RootService';
import { StreamManager } from 'src/app/minimal/services/StreamManager';
import { PermissionsService } from 'src/app/permissions';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { CacheService } from '@shared/services/cache/cache.service';
import { RemoteConfigService } from '@shared/services/config/remote-config.service';

import { ChangeMingaAction, TypeEnum, TypeUnion } from '../../root/rootActions';

@Injectable()
export class MingaEffects {
  /**
   * Get the new minga's data and fill the store with it when you switch
   * mingas.
   */
  @Effect()
  readonly changedMinga$ = this._actions$.pipe(
    ofType(TypeEnum.ChangeMinga),
    map((action: ChangeMingaAction) => action.contextHash),
    switchMap(hash => {
      const mingaPromise = this._mingaManager.getMinga(hash);
      const settingsPromise = this._mingaManager.getMingaSettings(hash);
      const togglesPromise = this._mingaManager.getMingaFeatureToggles(hash);

      return from(
        this._rootService.addLoadingPromise(
          Promise.all([mingaPromise, settingsPromise, togglesPromise]),
        ),
      );
    }),
    catchError(err => {
      this._systemAlertSnackBar.error('Failed to change Minga');
      return throwError(err);
    }),
    map(([minga, settings, toggles]) =>
      MingaMinimalProtoMapper.MingaProtoToMingaMinimal(
        minga,
        settings.getSettingsList(),
        toggles.getFeatureToggle(),
      ),
    ),

    tap(mingaMinimal => {
      this._mingaFacade.setMingaSettings(mingaMinimal);
      this._mingaManager.emitMingaChange();
      this._streamManager.restartStreamIfAvailable('HomeFeed');
      this._remoteConfig.refreshConfig();
      this._analytics.setMingaUserProperties(mingaMinimal);
      // clear all client cache on switch
      this._cacheService.clearAll();
    }),
    switchMap(mingaMinimal => [
      new GroupCollectionActions.InvalidateGroupsCollection(),
    ]),
  );

  @Effect()
  readonly mingas$ = this._actions$.pipe(
    ofType(fetchAllMingas),
    switchMap(() =>
      combineLatest([
        this._mingaFacade.getMingaAsObservable(),
        from(this._mingaManager.getMingas([])),
      ]),
    ),
    map(([currentMinga, mingas]) => {
      const mingaMinimals = mingas.map(minga =>
        MingaMinimalProtoMapper.MingaProtoToMingaMinimal(minga, []),
      );
      // don't add our current minga to the collection, because the one we have
      // here won't have any settings.
      const filtered = mingaMinimals.filter(
        minga => minga.hash !== currentMinga.hash,
      );
      return setMingaCollection({ payload: filtered });
    }),
  );

  @Effect()
  readonly distinctMingaChange$ = this._actions$.pipe(
    ofType(setCurrentMinga),
    map(action => action.payload),
    distinctUntilChanged((a, b) => a.hash === b.hash),
    filter(() =>
      this._permissions.hasPermission(MingaPermission.MINGA_PEOPLE_MANAGE),
    ),
    switchMap(() => {
      return from(this._mingaManager.fetchActiveMingaSubscription());
    }),
    map(mingaSubscription => setActiveMingaSubscription({ mingaSubscription })),
  );

  constructor(
    private _actions$: Actions<TypeUnion | MingaActions>,
    private _mingaManager: MingaManagerService,
    private _mingaFacade: MingaStoreFacadeService,
    private _permissions: PermissionsService,
    private _streamManager: StreamManager,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _remoteConfig: RemoteConfigService,
    private _analytics: AnalyticsService,
    private _cacheService: CacheService,
    private _rootService: RootService,
  ) {}
}
