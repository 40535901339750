import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';

import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MOBILE_BREAKPOINTS } from 'src/styles/flex-breakpoints/constants';

import {
  BmReportSummary,
  IPbisSummaryResult,
  IPbisType,
} from 'minga/domain/pbis';
import { BmReportsFilters } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { getGroupRangeByDate, GroupType } from 'minga/domain/timeManipulation';

import { PbisService } from '@shared/services/pbis';

import {
  BM_REPORTS_BAR_CHART_OPTIONS,
  BmReportsFilterType,
  BmReportsSummaryMessages,
} from '../../constants';
import {
  BmReportsService,
  BmReportsStaffDatasourceService,
  BmReportsStudentDatasourceService,
} from '../../services';
import { BmReportsBehaviorsDatasourceService } from '../../services/bm-reports-types-data.service';
import { ChartData } from '../../types';
import {
  generateGraphData,
  generateLabels,
  getSeperateTypesFromSummary,
} from '../../utils';

@Component({
  selector: 'mg-bm-reports-summary',
  templateUrl: './bm-reports-summary.component.html',
  styleUrls: ['./bm-reports-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    BmReportsStudentDatasourceService,
    BmReportsStaffDatasourceService,
    BmReportsBehaviorsDatasourceService,
  ],
})
export class BmReportsSummaryComponent implements OnDestroy {
  /** Enums & Constants */
  public readonly MESSAGES = BmReportsSummaryMessages;
  public readonly REPORT_TYPES = ReportTypes;
  public readonly CHART_OPTIONS = BM_REPORTS_BAR_CHART_OPTIONS;

  private _destroyed$ = new ReplaySubject<void>(1);
  private _mqAliasSubscription!: Subscription;
  isOnMobile = true;
  mobileBreakpoints = MOBILE_BREAKPOINTS;
  mqAlias = '';

  /** Filters */
  private readonly _serviceFilter$: Observable<BmReportsFilters>;

  /** Summary */
  private readonly _summary$: Observable<IPbisSummaryResult[]>;
  public summary: BmReportSummary = {
    isLoading: false,
    totalBehaviors: 0,
    totalPraise: 0,
    totalGuidance: 0,
    groupBy: GroupType.BY_DAY,
    startDate: null,
    endDate: null,
    xLabels: [],
    dataSets: [],
    noStats: true,
  };

  /** Types */
  readonly pbisBehaviorTypes$: Observable<IPbisType[]>;
  types = {
    isLoading: true,
    data: [],
  };

  praiseFilter = {
    filterFn: (item: IPbisSummaryResult) => {
      return item.typeCategory === 0;
    },
  };
  guidanceFilter = {
    filterFn: (item: IPbisSummaryResult) => {
      return item.typeCategory === 1;
    },
  };

  /** Component Constructor */
  constructor(
    public pbisReportsService: BmReportsService,
    public studentsDataservice: BmReportsStudentDatasourceService,
    public staffDataservice: BmReportsStaffDatasourceService,
    public behaviorsDataservice: BmReportsBehaviorsDatasourceService,
    private _cdr: ChangeDetectorRef,
    private _media: MediaObserver,
    private _pbisService: PbisService,
    private _router: Router,
    private _element: ElementRef,
  ) {
    /** Types */
    this.pbisBehaviorTypes$ = this._pbisService.getTypes();
    this.pbisBehaviorTypes$
      .pipe(
        takeUntil(this._destroyed$),
        tap(() => {
          this.types.isLoading = true;
          this._cdr.markForCheck();
        }),
      )
      .subscribe(d => {
        this.types.isLoading = false;
        this.types.data = d;
        this._cdr.markForCheck();
      });

    /** Filters */
    this._serviceFilter$ = this.pbisReportsService.filter$;
    this._serviceFilter$.pipe(takeUntil(this._destroyed$)).subscribe(d => {
      this.summary.startDate = d.startDate;
      this.summary.endDate = d.endDate;
      this.summary.groupBy = getGroupRangeByDate(d.startDate, d.endDate);
    });

    /** Summary */
    this._summary$ = this._serviceFilter$.pipe(
      takeUntil(this._destroyed$),
      tap(() => {
        this.summary.totalBehaviors = 0;
        this.summary.totalGuidance = 0;
        this.summary.totalPraise = 0;
        this.summary.isLoading = true;
        this.summary.noStats = true;
        this._cdr.markForCheck();
      }),
      switchMap(() => {
        return this.pbisReportsService.getSummary();
      }) as any,
    );
    // we combine latest here with the types, because the types
    // are actually a dependency of the code in handleSummarySub,
    // so we want handle summary code to be run if the types change.
    combineLatest(this._summary$, this.pbisBehaviorTypes$)
      .pipe(
        takeUntil(this._destroyed$),
        // ignore the actual typoes data here, since _handleSummarySub is using
        // the class variable to get the types.
        map(([summary, types]) => {
          return summary;
        }),
        filter(summary => summary !== null),
      )
      .subscribe(d => this._handleSummarySub(d));

    this.studentsDataservice.setLimitAndOffset(5, 0);
    this.staffDataservice.setLimitAndOffset(5, 0);

    /** Media Observable */
    this._mqAliasSubscription = this._media
      .asObservable()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(mediaChanges => {
        this.isOnMobile = this.mobileBreakpoints.includes(
          mediaChanges[0].mqAlias,
        )
          ? true
          : false;
        this._cdr.markForCheck();
      });
  }

  /** Component Lifecycle: On Unmount */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Handle Summary Subscription
   */
  private _handleSummarySub(data: IPbisSummaryResult[]): void {
    const { totalCount, totalGuidanceCount, totalPraiseCount } =
      getSeperateTypesFromSummary(data, true);

    const chartData: ChartData = {
      endDate: this.summary.endDate,
      groupBy: this.summary.groupBy,
      types: this.types.data,
      startDate: this.summary.startDate,
      summaryData: data,
    };
    if (data.length && this.types) {
      this.summary.noStats = false;
    }
    const xLabels = generateLabels(chartData);
    const dataSets = generateGraphData(chartData, xLabels);
    this.summary.xLabels = xLabels;
    this.summary.dataSets = dataSets;
    this.summary.totalBehaviors = totalCount;
    this.summary.totalGuidance = totalGuidanceCount;
    this.summary.totalPraise = totalPraiseCount;
    this.summary.isLoading = false;
    this._cdr.markForCheck();
    return;
  }

  async onClickViewTypeSummmary(item: IPbisSummaryResult): Promise<void> {
    const value = item.typeId;
    await this.pbisReportsService.setFilter(BmReportsFilterType.TYPE, value);
  }

  async onClickViewTypeHistory(item: IPbisSummaryResult): Promise<void> {
    const value = item.typeId;
    await this.pbisReportsService.setFilter(BmReportsFilterType.TYPE, value);
    this._router.navigate(['/pbis', 'reports', ReportTypes.PBIS_HISTORY]);
  }

  public goUp(): void {
    const modalElement =
      this._element.nativeElement.closest('.overlay-content');

    if (modalElement) {
      modalElement.scrollTo({ top: 0 });
    }
  }
}
