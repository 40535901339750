<!-- Overlay container -->
<div class="print-overlay"></div>

<!-- Modal overlay container -->
<mg-overlay-primary
  class="id-container"
  size="auto"
  secondaryButtonIcon="mg-phone-911"
  [titleIcon]="(isOffline$ | async) ? 'mg-offline' : ''"
  [overlayTitle]="
    (isOffline$ | async) ? 'Offline' : (currentDate$ | async | date: 'HH:mm:ss')
  "
  [class.offline]="isOffline$ | async"
  [bodyNoPadding]="true"
  [secondaryButton]="true"
  [overlayTitleScale]="idClockFontScale$ | async"
  (closeModal)="handleCloseModal()"
  (secondaryAction)="openSuicidePreventionModal()">
  <!-- Main content -->
  <div
    *ngIf="isLoading$ | async; else mainContentTemplate"
    class="loading-container">
    <mg-spinner></mg-spinner>
  </div>
  <!-- Footer -->
  <ng-template
    *ngIf="showPrint$ | async"
    #overlayFooter>
    <ng-container *ngTemplateOutlet="modalFooterTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main content template -->
<ng-template #mainContentTemplate>
  <div
    *ngIf="studentData$ | async as studentId; else noDataAvailableTemplate"
    class="id-container">
    <!-- Stickers -->
    <mg-view-id-sticker
      *ngIf="studentId.active && studentId?.stickerIcons?.length > 0"
      [stickerList]="studentId.stickerIcons"
      [noAccess]="studentId.noAccess">
    </mg-view-id-sticker>
    <!-- My class schedule -->
    <mg-view-id-schedule
      *ngIf="showCurrentClass$ | async"
      [data]="studentId?.currentClasses">
    </mg-view-id-schedule>
    <!-- Active hall passes -->
    <mg-view-id-hallpass
      *ngFor="let pass of studentId.activePasses"
      [activePass]="pass"
      [endPassSetting]="(viewId.viewIdSettings$ | async).passStudentsEndPasses">
    </mg-view-id-hallpass>
    <!-- Main details -->
    <mg-view-id-body
      [template]="idCardLayout$ | async"
      [studentData]="studentId"
      [settings]="viewId.viewIdSettings$ | async"
      [readonly]="isReadOnly$ | async"
      (changePhoto)="changeIdPhoto($event)">
    </mg-view-id-body>
  </div>
</ng-template>

<!-- No data available template -->
<ng-template #noDataAvailableTemplate>
  <div class="empty-state-container">
    <mg-empty-state
      title="You are offline"
      subtitle="Please check your internet connection and try again.">
    </mg-empty-state>
  </div>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #modalFooterTemplate>
  <div class="footer">
    <mg-btn
      variant="filled"
      [disabled]="isLoading$ | async"
      (pressed)="print()">
      {{ MESSAGES.PRINT }}
    </mg-btn>
  </div>
</ng-template>
