import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { populatePreviewUrlFromFile } from 'minga/app/src/app/util/image';

/**
 * Component for previewing a `File` object.
 */
@Component({
  selector: 'mg-file-preview',
  templateUrl: './FilePreview.component.html',
  styleUrls: ['./FilePreview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewComponent {
  @Input()
  file: File | null = null;

  @Input()
  previewSize: number = 150;

  filePreviewUrl: string = '';

  constructor(private _cdr: ChangeDetectorRef) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.file) {
      this.filePreviewUrl = await this.getfilePreviewUrl();
      this._cdr.markForCheck();
    }
  }

  async getfilePreviewUrl(): Promise<string> {
    if (!this.file || this.previewSize <= 0) {
      return '';
    }

    return populatePreviewUrlFromFile(this.file, this.previewSize);
  }
}
