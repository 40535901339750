import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, ReplaySubject, combineLatest, interval } from 'rxjs';
import { map, share, startWith, takeUntil } from 'rxjs/operators';

import { mingaSettingTypes } from 'minga/libraries/util';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { LoginManager } from 'minga/proto/gateway/login_ng_grpc_pb';
import { ForgotKioskPinRequest } from 'minga/proto/gateway/login_pb';
import { AuthService } from 'src/app/minimal/services/Auth';
import { RootService } from 'src/app/minimal/services/RootService';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { LandingRoute } from '@modules/landing';

import { KIOSK_OPTIONS, KioskCategoryMessage, KioskRoute } from '../constants';
import { KioskCategory, KioskCategoryOption } from '../types';
import { KioskStateStorage } from '../utils';

@Injectable()
export class KioskService implements OnDestroy {
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State

  public alertModalOpenSubject = new BehaviorSubject<boolean>(false);
  public readonly alertModalOpen$ = this.alertModalOpenSubject.asObservable();

  private readonly _selectedCategorySubject =
    new BehaviorSubject<KioskCategory | null>(
      KioskStateStorage.get().selectedCategory,
    );
  public readonly selectedCategory$ =
    this._selectedCategorySubject.asObservable();

  public readonly categoryOptions$ = combineLatest([
    this._settingService.getSettingValueObs(
      mingaSettingTypes.PASS_HALLPASS_KIOSK,
    ),
    this._settingService.getSettingValueObs(mingaSettingTypes.CHECKIN_KIOSK),
    this._settingService.getSettingValueObs(mingaSettingTypes.FTM_KIOSK),
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(([hallPassEnabled, checkInEnabled, flexTimeEnabled]) => {
      const options: KioskCategoryOption[] = [];
      if (hallPassEnabled)
        options.push(KIOSK_OPTIONS[KioskCategoryMessage.HALLPASS]);
      if (checkInEnabled || flexTimeEnabled)
        options.push(KIOSK_OPTIONS[KioskCategoryMessage.CHECKIN]);
      return options;
    }),
  );

  /**
   * Kiosk mode state
   *
   * used to lock down the kiosk, i.e disable the ability to
   * change the category
   */
  private readonly _isKioskModeSubject = new BehaviorSubject<boolean>(
    KioskStateStorage.get().isKioskMode,
  );
  public readonly isKioskMode$ = this._isKioskModeSubject.asObservable();

  public readonly tickerInSeconds$ = interval(1000).pipe(startWith(0), share());

  // Service constructor

  constructor(
    private _rootService: RootService,
    private _loginManager: LoginManager,
    private _router: Router,
    private _auth: AuthService,
    private _settingService: MingaSettingsService,
  ) {}

  // Lifecycle

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._selectedCategorySubject.complete();
    this._isKioskModeSubject.complete();
  }

  // Public methods

  public reset() {
    this.setCategory(null);
  }

  public setIsKioskMode(isKioskMode: boolean): void {
    this._isKioskModeSubject.next(isKioskMode);
    KioskStateStorage.set({ isKioskMode });
  }

  public getSelectedCategory(): KioskCategory | null {
    return this._selectedCategorySubject.getValue();
  }

  public async setCategory(option: KioskCategory): Promise<void> {
    this._selectedCategorySubject.next(option);
    KioskStateStorage.set({ selectedCategory: option });

    await this._router.navigate([`/${KioskRoute.ROOT}`, option ?? '']);
  }

  public async requestPin(): Promise<StatusCode> {
    const request = new ForgotKioskPinRequest();
    const response = await this._rootService.addLoadingPromise(
      this._loginManager.forgotKioskPin(request),
    );

    return response.getStatus();
  }

  public async logout() {
    this.reset();
    await this._router.navigate(
      ['/', LandingRoute.ROOT, LandingRoute.KIOSK_LOGIN],
      {
        state: {
          kioskLogout: true,
        },
      },
    );
    KioskStateStorage.clear();

    await this._auth.logout();
  }

  public async editKiosk() {
    const selectedCategory = this.getSelectedCategory();
    KioskStateStorage.clear();
    this.setIsKioskMode(false);

    await this._router.navigate(['/', KioskRoute.ROOT]);

    this.setCategory(selectedCategory);
  }

  public resetKiosk() {
    KioskStateStorage.clear();
    this.setIsKioskMode(false);
    this._router.navigate(['/', KioskRoute.ROOT]);
  }
}
