import textClipper from '../text-clipper';

export default function(str: string, maxLength?: number, options?: any) {
  try {
    return textClipper(str, maxLength, options);
  } catch(err) {
    console.error(err);
    return `Invalid HTML`;
  }
}
