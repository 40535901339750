import {
  AbstractControl,
  ValidationErrors,
  FormGroup,
  FormArray,
} from '@angular/forms';

import { SCHEDULE_PERIOD_FIELDS } from '../constants/mm-bs-schedules.constants';

export const noOverlappingPeriodsValidator = (
  formArray: FormArray,
): ValidationErrors | null => {
  const periods = formArray.controls;
  const overlapError = {
    overlap: { message: 'Periods cannot overlap. Please enter a valid time' },
  };

  periods
    .filter(period => period.hasError('overlap'))
    .forEach(period => {
      delete period.errors['firstError'];
      period.updateValueAndValidity();
    });

  if (periods.length < 2) return null;

  for (let i = 0; i < periods.length; i++) {
    const period = periods[i];
    const startTime = period.get(SCHEDULE_PERIOD_FIELDS.START_TIME).value;
    const endTime = period.get(SCHEDULE_PERIOD_FIELDS.END_TIME).value;

    for (let j = i + 1; j < periods.length; j++) {
      const otherPeriod = periods[j];
      const otherStartTime = otherPeriod.get(
        SCHEDULE_PERIOD_FIELDS.START_TIME,
      ).value;
      const otherEndTime = otherPeriod.get(
        SCHEDULE_PERIOD_FIELDS.END_TIME,
      ).value;

      // Check for overlap logic
      if (startTime < otherEndTime && endTime > otherStartTime) {
        // this line will also set and error on the first period
        // period.setErrors({ ...period.errors, ...overlapError });
        otherPeriod.setErrors({ ...otherPeriod.errors, ...overlapError });
      }
    }
  }

  return null;
};
