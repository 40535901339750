export enum StudentScheduleMessage {
  COLUMN_LABEL_PERIOD = 'Period',
  COLUMN_LABEL_COURSE = 'Course Title',
  COLUMN_LABEL_ROOM = 'Room',
  COLUMN_LABEL_START_TIME = 'Start Time',
  COLUMN_LABEL_END_TIME = 'End Time',
  COLUMN_LABEL_TEACHER = 'Teacher',
  COLUMN_LABEL_DAYS = 'Days',

  DAY_LABEL_MONDAY = 'Mon',
  DAY_LABEL_TUESDAY = 'Tue',
  DAY_LABEL_WEDNESDAY = 'Wed',
  DAY_LABEL_THURSDAY = 'Thu',
  DAY_LABEL_FRIDAY = 'Fri',
  DAY_LABEL_SATURDAY = 'Sat',
  DAY_LABEL_SUNDAY = 'Sun',

  EMPTY_SCHEDULE = "You don't currently have any classes scheduled",
}

export const STUDENT_SCHEDULE_DESKTOP_DISPLAY_COLUMNS = [
  'period',
  'name',
  'location',
  'teachers',
];

export const BELL_SCHEDULE_DESKTOP_DISPLAY_COLUMNS = [
  'periodStartDate',
  'periodEndDate',
];
