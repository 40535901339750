<div
  class="mg-points-history-item"
  *ngIf="item">
  <div class="type-col">
    <mg-points-history-icon [type]="item.type"></mg-points-history-icon>
  </div>
  <div class="right-col">
    <div
      class="date"
      *ngIf="itemDate">
      {{ itemDate | date: 'MMM d, y' }}
    </div>
    <div
      class="points"
      *ngIf="item.points">
      {{ item.points }} {{ item.points === 1 ? 'PT' : 'PTS' }}
    </div>
    <div
      class="text"
      [innerHTML]="item.reason | mgTextClipper: { clipAmount: 50 }"></div>
  </div>
</div>
