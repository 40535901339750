import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

/**
 * Manager Overlay Subpage
 */
@Component({
  selector: 'mg-manager-overlay-subpage',
  templateUrl: './manager-overlay-subpage.component.html',
  styleUrls: ['./manager-overlay-subpage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagerOverlaySubpageComponent {
  /** Template Ref Forwarding */
  @ContentChild('headerLeft', { read: TemplateRef })
  headerLeft: TemplateRef<any>;
  @ContentChild('headerRight', { read: TemplateRef })
  headerRight: TemplateRef<any>;
  @ContentChild('headerBottom', { read: TemplateRef })
  headerBottom: TemplateRef<any>;
  @ContentChild('subpageHeader', { read: TemplateRef })
  subpageHeader: TemplateRef<any>;

  /** Inputs */
  @Input()
  public loading = false;

  @Input()
  public headerRightAlign = 'center';

  @Input()
  noBottomPadding: boolean;

  @Input()
  isNewLayout: boolean;

  /** Component Constructor */
  constructor() {}
}
