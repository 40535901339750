import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MingaPermission } from 'minga/domain/permissions';
import { IPointsHistoryItem } from 'minga/domain/points';
import { PmReportsFilter } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import {
  PointsReportColumns,
  TemplateColumnKeys,
} from 'minga/libraries/shared/reports_columns';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { PermissionsService } from 'src/app/permissions';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PmReportsService } from './pm-reports.service';

@Injectable()
export class PmReportsHistoryDatasourceService extends ReportDatasourceService<IPointsHistoryItem> {
  constructor(
    _router: Router,
    private _pmService: PmReportsService,
    private _permissions: PermissionsService,
    _alertModal: SystemAlertModalService,
    _snackBar: SystemAlertSnackBarService,
  ) {
    super(
      _router,
      _pmService,
      PmReportsHistoryDatasourceService.name,
      _alertModal,
      _snackBar,
    );
    this._archiveFn = this._pmService.archivePointAction.bind(this._pmService);
    const displayCol = [
      {
        header: '',
        key: TemplateColumnKeys.SUMMARY,
        columnClasses: ['w-16'],
      },
    ];
    if (this._permissions.hasPermission(MingaPermission.POINTS_MANAGE))
      displayCol.push({
        header: '',
        key: TemplateColumnKeys.ARCHIVE,
        columnClasses: ['w-16'],
      });
    super.setDisplayColumns(PointsReportColumns.HISTORY, displayCol);
  }

  async fetch(offset: number, limit: number) {
    return await this._pmService.fetchPointHistories(
      offset,
      limit,
      this._currentSort,
    );
  }

  async viewSummary(item: IPointsHistoryItem): Promise<void> {
    if (this._reportsService && this._router) {
      if (item.teamId) {
        this._reportsService.setAndApplyFilter(
          PmReportsFilter.TEAMS,
          item.teamId,
          true,
        );
        this._router.navigateByUrl(
          '/points-manager/reports/' + ReportTypes.POINTS_TEAM_SUMMARY,
        );
      } else {
        this._reportsService.setAndApplyFilter(
          PmReportsFilter.ISSUED_TO,
          item.issuedTo.personHash,
          true,
        );
        this._router.navigateByUrl(
          '/points-manager/reports/' + ReportTypes.POINTS_SUMMARY,
        );
      }
    } else {
      console.error(
        'Cannot go to summary, missing a service or necessary info.',
      );
    }
  }
}
