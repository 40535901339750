import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MingaPermission } from 'minga/domain/permissions';
import { mingaSettingTypes } from 'minga/libraries/util';
import { MingaAppRoutes } from 'src/app/types';

import { AccessRestrictionsGuard } from '@shared/guards';

import { MmBsCalendarComponent } from './components/mm-bell-schedule/components/mm-bs-calendar/mm-bs-calendar.component';
import { MmBsSchedulesComponent } from './components/mm-bell-schedule/components/mm-bs-schedules/mm-bs-schedules.component';
import { MmBsTermsComponent } from './components/mm-bell-schedule/components/mm-bs-terms/mm-bs-terms.component';
import { MmBellScheduleComponent } from './components/mm-bell-schedule/mm-bell-schedule.component';
import { MmBellScheduleModule } from './components/mm-bell-schedule/mm-bell-schedule.module';
import { MmDashboardComponent } from './components/mm-dashboard/mm-dashboard.component';
import { MmDashboardModule } from './components/mm-dashboard/mm-dashboard.module';
import { MmDistrictsComponent } from './components/mm-districts/mm-districts.component';
import { MmDistrictsModule } from './components/mm-districts/mm-districts.module';
import { MmReportsComponent } from './components/mm-reports/mm-reports.component';
import { MmReportsModule } from './components/mm-reports/mm-reports.module';
import { MmScheduledReportsComponent } from './components/mm-scheduled-reports/mm-scheduled-reports.component';
import { MmScheduledReportsModule } from './components/mm-scheduled-reports/mm-scheduled-reports.module';
import { MmSettingsComponent } from './components/mm-settings/mm-settings.component';
import { MmSettingsModule } from './components/mm-settings/mm-settings.module';
import { MmSubscriptionComponent } from './components/mm-subscription/mm-subscription.component';
import { MmSubscriptionModule } from './components/mm-subscription/mm-subscription.module';
import { MingaManagerRoutes } from './constants/minga-manager.constants';
import { MingaManagerRedirectGuard } from './guards/minga-manager-redirect.guard';
import { MingaManagerComponent } from './minga-manager.component';
import { MingaManagerModule } from './minga-manager.module';
import { MingaDashboardGuard } from './services/minga-dashboard.guard';
import { MingaScheduledReportsGuard } from './services/minga-scheduled-reports.guard';

export const MINGA_ADMIN_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: MingaManagerComponent,
    data: {
      title: 'Minga Manager',
    },
    children: [
      {
        path: '',
        component: MmDashboardComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Dashboard',
          enableAutoRedirect: true,
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.VIEW_MINGA_DASHBOARD],
            },
          },
        },
      },
      {
        path: MingaManagerRoutes.DISTRICTS,
        component: MmDistrictsComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Districts',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.SUPERADMIN],
            },
          },
        },
      },
      {
        path: MingaManagerRoutes.REPORTS,
        component: MmReportsComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Reports',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.MINGA_ACCOUNT_MANAGE],
            },
          },
        },
      },
      {
        path: MingaManagerRoutes.SCHEDULED_REPORTS,
        component: MmScheduledReportsComponent,
        canActivate: [MingaScheduledReportsGuard, AccessRestrictionsGuard],
        data: {
          title: 'Scheduled Reports',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.SCHEDULED_REPORTS_MANAGE],
            },
          },
        },
      },
      {
        path: MingaManagerRoutes.BELL_SCHEDULE,
        component: MmBellScheduleComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Bell Schedule',
          navigationVisibility: true,
          isBeta: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.BELL_SCHEDULE_MANAGE],
            },
            settings: {
              only: [mingaSettingTypes.BELL_SCHEDULE_ENABLE],
            },
            configFlag: {
              only: ['bellScheduleEnabled'],
            },
          },
        },
        children: [
          {
            path: '',
            redirectTo: MingaManagerRoutes.BELL_SCHEDULE_TERMS,
            pathMatch: 'full',
          },
          {
            path: MingaManagerRoutes.BELL_SCHEDULE_TERMS,
            component: MmBsTermsComponent,
            canActivate: [AccessRestrictionsGuard],
            data: {
              title: 'Terms',
              navigationVisibility: true,
              accessRestrictions: {
                permissions: {
                  atLeastOne: [MingaPermission.BELL_SCHEDULE_MANAGE],
                },
              },
            },
          },
          {
            path: MingaManagerRoutes.BELL_SCHEDULE_SCHEDULES,
            component: MmBsSchedulesComponent,
            canActivate: [AccessRestrictionsGuard],
            data: {
              title: 'Schedules',
              navigationVisibility: true,
              accessRestrictions: {
                permissions: {
                  atLeastOne: [MingaPermission.BELL_SCHEDULE_MANAGE],
                },
              },
            },
          },
          {
            path: MingaManagerRoutes.BELL_SCHEDULE_CALENDAR,
            component: MmBsCalendarComponent,
            canActivate: [AccessRestrictionsGuard],
            data: {
              title: 'Calendar',
              navigationVisibility: true,
              accessRestrictions: {
                permissions: {
                  atLeastOne: [MingaPermission.BELL_SCHEDULE_MANAGE],
                },
              },
            },
          },
        ],
      },
      {
        path: MingaManagerRoutes.SUBSCRIPTION,
        component: MmSubscriptionComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Subscription',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.MINGA_MANAGE],
            },
          },
        },
      },
      {
        path: MingaManagerRoutes.SETTINGS,
        component: MmSettingsComponent,
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Settings',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.MINGA_ACCOUNT_MANAGE],
            },
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    // Minga dependencies
    MingaManagerModule,
    MmDashboardModule,
    MmReportsModule,
    MmSettingsModule,
    MmScheduledReportsModule,
    MmSubscriptionModule,
    MmDistrictsModule,
    MmBellScheduleModule,

    // External dependencies
    RouterModule.forChild(MINGA_ADMIN_ROUTES),
  ],
  providers: [
    MingaDashboardGuard,
    MingaScheduledReportsGuard,
    MingaManagerRedirectGuard,
  ],
})
export class MingaManagerRoutesModule {}
