import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { wrap } from 'minga/libraries/util/wrap';

import {
  IStreamSwipeStackHead,
  IStreamSwipeStackItem,
  IStreamSwipeStackLoading,
  IStreamSwipeStackTail,
} from './IStreamSwipeStackItem';
import { IStreamSwipeStackDataSource } from './types';

@Component({
  selector: 'mg-stream-swipe-stack',
  templateUrl: './StreamSwipeStack.component.html',
  styleUrls: ['./StreamSwipeStack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StreamSwipeStackComponent<T = any> implements OnDestroy {
  _items: IStreamSwipeStackItem<T>[] = [];
  _heads: IStreamSwipeStackHead[] = [];
  _tails: IStreamSwipeStackTail[] = [];
  _loadings: IStreamSwipeStackLoading[] = [];

  /** @internal */
  private _dataSourceSubscription = new Subscription();
  /** @internal */
  private _dataSource: IStreamSwipeStackDataSource<T> | null = null;
  /** @internal */
  private _stackSize: number = 0;
  /** @internal */
  private _stackIndex: number = 0;
  /** @internal - cached current item data for convenient retrieval */
  private _currentItemData: T | null = null;

  /**
   * Stream swipe stacks are always cyclic
   * @SEE SwipeStackComponent.cyclic
   */
  readonly cyclic = true;

  @Input()
  set dataSource(newDataSource: IStreamSwipeStackDataSource<T> | null) {
    if (this._dataSource && newDataSource !== this._dataSource) {
      this._cleanupDataSource(this._dataSource);
    }
    this._dataSource = newDataSource;
    if (this._dataSource) {
      this._initDataSource(this._dataSource);
    }
  }

  get dataSource() {
    return this._dataSource;
  }

  /**
   * The stream swipe stack size. This size differs from the length of the
   * data items source by compensating for the psuedo items (head/tail.)
   *
   * @NOTE This value cannot be set because it is calculated from the data
   * source and pseudo items (head/tail.)
   */
  get stackSize() {
    return this._stackSize;
  }

  @Output()
  readonly stackSizeChange: EventEmitter<number>;

  /**
   * The stream swipe stack index. This index differs from the index of the
   * data source by compensating for the psuedo items (head/tail.)
   *
   * @NOTE This value cannot be set because it is calculated from the data
   * source and pseudo items (head/tail.)
   */
  get stackIndex() {
    return this._stackIndex;
  }

  @Output()
  readonly stackIndexChange: EventEmitter<number>;

  /**
   * Check if swipe stack has a pseudo item at the front (the head)
   */
  get hasHead(): boolean {
    return this._heads.length > 0;
  }

  /**
   * Check if swipe stack has a pseudo item at the back (the tail)
   */
  get hasTail(): boolean {
    return this._tails.length > 0;
  }

  get showingHead(): boolean {
    return this.hasHead && this._stackIndex === 0;
  }

  get showingHeadPreview(): boolean {
    return this.hasHead && wrap(this._stackIndex + 1, 0, this._stackSize) === 0;
  }

  get showingHeadPreviewPlaceholder(): boolean {
    return this.hasHead && wrap(this._stackIndex + 2, 0, this._stackSize) === 0;
  }

  get showingTail(): boolean {
    return this.hasTail && this._stackSize - 1 === this._stackIndex;
  }

  get currentItemData(): T | null {
    if (this.hasHead && this.showingHead) return null;
    if (this.hasTail && this.showingTail) return null;
    return this._currentItemData;
  }

  /**
   * Showing top of the stack
   */
  get showingTop(): boolean {
    return this._stackSize > 0 && this._stackIndex === 0;
  }

  /**
   * Showing bottom of the stack
   */
  get showingBottom(): boolean {
    return this._stackSize > 0 && this._stackIndex === this._stackSize - 1;
  }

  /**
   * forwarded output from `<mg-swipe-stack>`
   */
  @Output()
  readonly swipeStackTap: EventEmitter<HammerInput>;

  constructor(private _cdr: ChangeDetectorRef) {
    this.stackIndexChange = new EventEmitter<number>();
    this.stackSizeChange = new EventEmitter<number>();
    this.swipeStackTap = new EventEmitter();
  }

  setIndex(index: number) {
    if (index < 0) throw new Error('index cannot be negative');
    else if (index > this._stackSize - 1)
      throw new Error('index cannot be greater than stack size');

    try {
      this.changeStackIndex(index);
    } finally {
      this._cdr.markForCheck();
    }
  }

  /**
   * Set the data source index to `dataSourceIndex` and make it the top of the
   * stack.
   * @param dataSourceIndex The data source index to set
   */
  setDataSourceIndex(dataSourceIndex: number) {
    if (!this._dataSource) throw new Error('no data source');
    else if (dataSourceIndex < 0) throw new Error('index cannot be negative');
    else if (dataSourceIndex > this._dataSource.getStackSize() - 1)
      throw new Error('index cannot be greater than data source stack size');

    this._stackIndex = dataSourceIndex;
    if (this.hasHead) {
      this._stackIndex += 1;
    }
    try {
      this.stackIndexChange.emit(this._stackIndex);
      this._dataSource.setIndex(dataSourceIndex);
    } finally {
      this._cdr.markForCheck();
    }
  }

  next() {
    if (this.hasTail && this._stackIndex == this._stackSize - 2) {
      try {
        this.changeStackIndex(this._stackIndex + 1);
      } finally {
        this._cdr.markForCheck();
      }
      this._cdr.markForCheck();
    } else if (this.showingHead && this._stackSize > 0) {
      try {
        this.changeStackIndex(1);
      } finally {
        this._cdr.markForCheck();
      }
    } else if (this.hasHead && this._stackIndex === this._stackSize - 1) {
      try {
        this.changeStackIndex(0);
      } finally {
        this._cdr.markForCheck();
      }
      this._cdr.markForCheck();
    } else {
      this._dataSource!.next();
    }
  }

  previous() {
    if (this.hasTail && this._stackIndex === 0) {
      try {
        this.changeStackIndex(this._stackSize - 1);
      } finally {
        this._cdr.markForCheck();
      }
    } else if (this.hasHead && this._stackIndex == 1) {
      try {
        this.changeStackIndex(this._stackIndex - 1);
      } finally {
        this._cdr.markForCheck();
      }
      this._cdr.markForCheck();
    } else {
      this._dataSource!.previous();
    }
  }

  ngOnDestroy() {
    if (this._dataSource) {
      this._cleanupDataSource(this._dataSource);
      this._dataSource = null;
    }
  }

  /**
   * Handles a stack index change from the internal swipe stack component
   */
  onStackIndexChange(stackIndex: number) {
    this.changeStackIndex(stackIndex);
  }

  /** @internal */
  private changeStackIndex(stackIndex: number) {
    this._stackIndex = stackIndex;
    this.stackIndexChange.emit(this._stackIndex);

    if (this._dataSource) {
      if (!this.showingHead && !this.showingTail) {
        let dataSourceIndex = this._stackIndex;
        if (this.hasHead) {
          dataSourceIndex -= 1;
        }

        this._dataSource.setIndex(dataSourceIndex);
      } else if (this.showingHead) {
        this._dataSource.setIndex(0);
      }
    }
  }

  /**
   * Registers a template that should be used to render an item from the data
   * source.
   * @NOTE Do not use directly. Should only be used by
   *       `StreamSwipeStackItemDirective`
   */
  registerItem(item: IStreamSwipeStackItem<T>) {
    const index = this._items.findIndex(i => i === item);
    if (index === -1) {
      this._items.push(item);
      this._cdr.markForCheck();
    }
  }

  /**
   * Unregisters an item previously registered with `registerItem`
   * @NOTE Do not use directly. Should only be used by
   *       `StreamSwipeStackItemDirective`
   */
  unregisterItem(item: IStreamSwipeStackItem<T>) {
    const index = this._items.findIndex(i => i === item);

    if (index !== -1) {
      const [deletedItem] = this._items.splice(index, 1);
      this._cdr.markForCheck();
    }
  }

  /**
   * Registers a template that should be used to render the pseudo item at the
   * front (head) of the swipe stack.
   * @NOTE Do not use directly. Should only be used by
   *       `StreamSwipeStackHeadDirective`
   */
  registerHead(head: IStreamSwipeStackHead) {
    const index = this._heads.findIndex(i => i === head);
    if (index === -1) {
      this._heads.push(head);

      try {
        if (this._heads.length === 1) {
          this._stackSize += 1;
          this.stackSizeChange.emit(this._stackSize);

          // Only change the stack index if we already have a data source. If we
          // didn't have a datasource changing the index would be more jarring
          // since the stack would start at a different index. If we DO have a
          // data source we want to keep our 'visual' index so changing the
          // index would be ideal.
          if (this._dataSource) {
            // @NOTE: We purposely do not call `changeStackIndex` because our
            // data source index should be the same here.
            this._stackIndex = wrap(this._stackIndex + 1, 0, this._stackSize);
            this.stackIndexChange.emit(this._stackIndex);
          }
        }
      } finally {
        this._cdr.markForCheck();
      }
    }
  }

  /**
   * Unregisters an item previously registered with `registerHead`
   * @NOTE Do not use directly. Should only be used by
   *       `StreamSwipeStackHeadDirective`
   */
  unregisterHead(head: IStreamSwipeStackHead) {
    const index = this._heads.findIndex(i => i === head);

    if (index !== -1) {
      const [deletedHead] = this._heads.splice(index, 1);

      try {
        if (this._heads.length === 0) {
          this._stackSize -= 1;
          this._stackIndex = wrap(this._stackIndex - 1, 0, this._stackSize);
          this.stackSizeChange.emit(this._stackSize);
          this.stackIndexChange.emit(this._stackIndex);
        }
      } finally {
        this._cdr.markForCheck();
      }
    }
  }

  /**
   * Registers a template that should be used to render the pseudo item at the
   * back (tail) of the swipe stack.
   * @NOTE Do not use directly. Should only be used by
   *       `StreamSwipeStackTailDirective`
   */
  registerTail(tail: IStreamSwipeStackTail) {
    const index = this._tails.findIndex(i => i === tail);
    if (index === -1) {
      this._tails.push(tail);

      try {
        if (this._tails.length === 1) {
          this._stackSize += 1;
          this.stackSizeChange.emit(this._stackSize);
        }
      } finally {
        this._cdr.markForCheck();
      }
    }
  }

  /**
   * Unregisters an item previously registered with `registerTail`
   * @NOTE Do not use directly. Should only be used by
   *       `StreamSwipeStackTailDirective`
   */
  unregisterTail(tail: IStreamSwipeStackTail) {
    const index = this._tails.findIndex(i => i === tail);

    if (index !== -1) {
      const [deletedTail] = this._tails.splice(index, 1);
      try {
        if (this._tails.length === 0) {
          this._stackSize -= 1;
          this.stackSizeChange.emit(this._stackSize);
        }
      } finally {
        this._cdr.markForCheck();
      }
    }
  }

  /**
   * Registers a template that should be used to render a loading state while
   * a stream item is being loaded.
   * @NOTE Do not use directly. Should only be used by
   *       `StreamSwipeStackLoadingDirective`
   */
  registerLoading(loading: IStreamSwipeStackLoading) {
    const index = this._loadings.findIndex(i => i === loading);
    if (index === -1) {
      this._loadings.push(loading);

      try {
        if (this._loadings.length === 1) {
          this._stackSize += 1;
          this.stackSizeChange.emit(this._stackSize);
        }
      } finally {
        this._cdr.markForCheck();
      }
    }
  }

  /**
   * Unregisters an item previously registered with `registerLoading`
   * @NOTE Do not use directly. Should only be used by
   *       `StreamSwipeStackLoadingDirective`
   */
  unregisterLoading(loading: IStreamSwipeStackLoading) {
    const index = this._loadings.findIndex(i => i === loading);

    if (index !== -1) {
      const [deletedLoading] = this._loadings.splice(index, 1);
      try {
        if (this._loadings.length === 0) {
          this._stackSize -= 1;
          this.stackSizeChange.emit(this._stackSize);
        }
      } finally {
        this._cdr.markForCheck();
      }
    }
  }

  /** @internal */
  private _onDataSourceStackIndexChange(dataSourceStackIndex: number) {
    // If we're showing the pseudo head item and our data source index is `0`
    // we can just ignore this change.
    if (this.showingHead && dataSourceStackIndex == 0) {
      return;
    }

    const dataSourceStackSize = this._dataSource!.getStackSize();

    // If we're showing the pseudo tail item and our data source is at the end
    // we can just ignore this change.
    if (this.showingTail && dataSourceStackIndex == dataSourceStackSize - 1) {
      return;
    }

    this._stackIndex = dataSourceStackIndex;
    if (this.hasHead) {
      this._stackIndex += 1;
    }

    try {
      this.stackIndexChange.emit(this.stackIndex);
    } finally {
      this._cdr.markForCheck();
    }
  }

  /** @internal */
  private _onDataSourceStackSizeChange(dataSourceStackSize: number) {
    this._stackSize = dataSourceStackSize;
    if (this.hasHead) {
      this._stackSize += 1;
    }
    if (this.hasTail) {
      this._stackSize += 1;
    }
    try {
      this.stackSizeChange.emit(this._stackSize);
    } finally {
      this._cdr.markForCheck();
    }
  }

  /** @internal */
  private _initDataSource(dataSource: IStreamSwipeStackDataSource<T>) {
    const indexSub = dataSource.stackIndex$.subscribe(dataSourceStackIndex => {
      this._onDataSourceStackIndexChange(dataSourceStackIndex);
    });

    const sizeSub = dataSource.stackSize$.subscribe(dataSourceStackSize => {
      this._onDataSourceStackSizeChange(dataSourceStackSize);
    });

    const t1 = dataSource.currentItemData$.subscribe(currentItemData => {
      this._currentItemData = currentItemData;
      this._cdr.detectChanges();
    });
    const t2 = dataSource.previewItemData$.subscribe(() => {
      this._cdr.detectChanges();
    });
    const t3 = dataSource.previewPlaceholderItemData$.subscribe(() => {
      this._cdr.detectChanges();
    });

    this._dataSourceSubscription.add(t1);
    this._dataSourceSubscription.add(t2);
    this._dataSourceSubscription.add(t3);
    this._dataSourceSubscription.add(indexSub);
    this._dataSourceSubscription.add(sizeSub);
  }

  /** @internal */
  private _cleanupDataSource(dataSource: IStreamSwipeStackDataSource<T>) {
    this._dataSourceSubscription.unsubscribe();
  }
}
