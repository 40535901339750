import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CheckinManagerReportsService } from './components';

@Component({
  selector: 'mg-checkin-manager',
  templateUrl: './checkin-manager.component.html',
  styleUrls: ['./checkin-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CheckinManagerReportsService],
})
export class CheckinManagerComponent {
  constructor() {}
}
