import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { addNewLinesAtTags } from 'minga/app/src/app/util/html';
import textClipper from 'minga/app/src/app/util/text-clipper';

import { Group } from '../../models';
import { GroupContextMenuEvent } from '../../services';
import { gotoGroupPage } from '../../util';

export enum GroupPreviewClickMode {
  // Navigates to group page
  GROUP = 'GROUP',
  // Enables join button
  JOIN = 'JOIN',
  // Enables select button
  SELECT = 'SELECT',
}

@Component({
  selector: 'mg-group-preview',
  templateUrl: './MgGroupPreview.component.html',
  styleUrls: ['./MgGroupPreview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgGroupPreviewComponent implements OnChanges {
  @Input()
  group?: Group;

  @Input()
  memberCount: number = 0;

  @Input()
  hasNewContent: boolean = false;

  @Input()
  clickMode: GroupPreviewClickMode = GroupPreviewClickMode.GROUP;

  @Input()
  showFullBody: boolean = false;

  @Output()
  leaveGroup = new EventEmitter<Group>();

  @Output()
  requestToJoin = new EventEmitter<Group>();

  @Output()
  cancelRequestToJoin = new EventEmitter<Group>();

  @Output()
  groupContextMenu = new EventEmitter<GroupContextMenuEvent>();

  groupDescription: string = '';
  showMoreLink: boolean = false;

  groupName: string = '';

  parentGroup$!: Observable<Group | null>;

  @HostListener('contextmenu', ['$event'])
  onContextMenu(e: MouseEvent) {
    if (!this.group) return;

    e.preventDefault();

    this.groupContextMenu.emit({
      group: this.group,
      x: e.x,
      y: e.y,
    });
  }

  constructor(
    private element: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    public groupsfacade: GroupsFacadeService,
  ) {
    this._updateParentGroup();
  }

  get hash() {
    return this.group?.hash || '';
  }

  @HostListener('click', ['$event'])
  onElementClick(e: MouseEvent) {
    if ('path' in e) {
      const paths = (e as any).path;

      if (paths.length && !this.showFullBody) {
        const firstPath = <HTMLElement>paths[0];
        if (firstPath.className.includes('more-description')) {
          this.onMoreDescriptionClick(e);
          return;
        }
      }
    }

    if (this.group) {
      const groupRank = this.group.rank;

      // if in select mode, no further functionality on click
      if (this.clickMode === GroupPreviewClickMode.SELECT) {
        return;
      }

      if (this.clickMode === GroupPreviewClickMode.JOIN) {
        // Only click to join if we are not part of the group
        if (!groupRank) {
          this.requestToJoin.emit(this.group);
        }
      } else if (this.clickMode === GroupPreviewClickMode.GROUP) {
        this.gotoGroupPage();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.group) {
      this._updateParentGroup();
    }
  }

  private _updateParentGroup() {
    if (this.group && this.group.parentGroupHash) {
      this.parentGroup$ = this.groupsfacade.getGroup(
        this.group.parentGroupHash,
      );
    } else {
      this.parentGroup$ = new Observable();
    }
  }

  gotoGroupPage() {
    gotoGroupPage(this.router, this.hash);
  }

  onMoreDescriptionClick(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    this.showFullBody = true;
    this.showMoreLink = false;
    this.changeDetectorRef.markForCheck();
  }

  onRequestToJoin(group: Group) {
    this.requestToJoin.emit(group);
  }

  onCancelRequestToJoin(group: Group) {
    this.cancelRequestToJoin.emit(group);
  }

  getNameText(html: string) {
    if (this.groupName) {
      return this.groupName;
    }

    const rect = this.element.nativeElement.getBoundingClientRect();
    const elementWidth = rect.width;

    let charCount = elementWidth > 340 ? 62 : 45;
    if (elementWidth > 375 && elementWidth < 500) {
      charCount = 65;
    } else if (elementWidth > 500) {
      charCount = 90;
    }

    const newLinesHtml = addNewLinesAtTags(html);
    const lines = newLinesHtml.split('\n');
    let shortHtml = lines.length > 1 ? lines[0] : html;
    const clippedText: string = textClipper(shortHtml, charCount, {
      html: false,
      maxLines: 2,
      breakWords: true,
    });

    this.groupName = clippedText;

    return clippedText;
  }

  getBodyText(html: string) {
    if (this.showFullBody) {
      this.showMoreLink = false;
      return html;
    }

    if (this.groupDescription) {
      return this.groupDescription;
    }

    const newLinesHtml = addNewLinesAtTags(html);
    const lines = newLinesHtml.split('\n');
    let shortHtml = lines.length > 1 ? lines[0] : html;
    const indicator = `<a class="more-description">... More</a>`;
    const clippedText: string = textClipper(shortHtml, 80, {
      html: true,
      maxLines: 4,
      breakWords: true,
      indicator,
    });

    if (!clippedText.includes(indicator) && lines.length > 2) {
      this.showMoreLink = true;
    } else {
      this.showMoreLink = false;
    }
    this.groupDescription = clippedText;
    if (this.showMoreLink) {
      this.changeDetectorRef.detectChanges();
    }

    return clippedText;
  }

  isGroupMember(): boolean {
    if (!this.group) return false;
    return Group.isMember(this.group);
  }

  getGroupBannerImageInfo() {
    if (!this.group || (!this.group.banner && !this.group.bannerObject)) {
      return '';
    }
    return this.group.bannerObject
      ? this.group.bannerObject.getImage()
      : this.group.banner;
  }
}
