import { Pipe, PipeTransform } from '@angular/core';

import { ColumnInfo } from 'minga/libraries/shared/reports_columns';

@Pipe({ name: 'reportTableHeaderCellClasses' })
export class ReportTableHeaderCellClassesPipe implements PipeTransform {
  transform(column: ColumnInfo, isLast: boolean, sortable: boolean) {
    const classes = {
      'is-number': column?.type === 'number',
      'sort-column': sortable && column?.sort,
      'add-space': isLast,
    };
    if (column?.width && column?.type)
      classes[`${column.type}-${column.width}`] = true;
    return classes;
  }
}
