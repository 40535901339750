import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IPbisType } from 'minga/domain/pbis';
import { PbisCategory } from 'minga/libraries/shared/pbis/constants';

import { newTableRowGlow } from '@shared/animations/table';

import { BehaviorManagerService } from '../../../../services';
import { BmTypesBehaviorMessages } from '../../constants';
import { BmTypesBehaviorService } from '../../services';
import { ICON_CONFIG_DEFAULTS } from '../bm-types-behavior-edit/bm-types-behavior-edit.constants';

/**
 * Behavior Manager Types - Behaviors
 */
@Component({
  selector: 'mg-bm-types-behaviors',
  templateUrl: './bm-types-behaviors.component.html',
  styleUrls: ['./bm-types-behaviors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [newTableRowGlow],
})
export class BmTypesBehaviorsComponent implements OnDestroy, OnInit {
  /** Constants */
  public readonly MESSAGES = BmTypesBehaviorMessages;
  public readonly PBIS_CATEGORIES = PbisCategory;

  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);

  private _praisesSubj: BehaviorSubject<IPbisType[]> = new BehaviorSubject([]);
  public praises$ = this._praisesSubj.asObservable();
  private _guidancesSubj: BehaviorSubject<IPbisType[]> = new BehaviorSubject(
    [],
  );
  public guidances$ = this._guidancesSubj.asObservable();

  public defaultPraiseIcon = ICON_CONFIG_DEFAULTS[PbisCategory.PRAISE];
  public defaultGuidanceIcon = ICON_CONFIG_DEFAULTS[PbisCategory.GUIDANCE];

  /** Loading */
  public isLoading$ = this._bmService.isLoadingTypesData$;

  /** Component Constructor */
  constructor(
    public typesBehavior: BmTypesBehaviorService,
    private _bmService: BehaviorManagerService,
  ) {
    this._initialFetch();
  }

  ngOnInit() {
    this._bmService.types$.pipe(takeUntil(this._destroyed)).subscribe(data => {
      this._praisesSubj.next(
        data.filter(item => item.categoryId === PbisCategory.PRAISE),
      );
      this._guidancesSubj.next(
        data.filter(item => item.categoryId === PbisCategory.GUIDANCE),
      );
    });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  private async _initialFetch(): Promise<void> {
    await this._bmService.fetchTypes();
  }
}
