import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { day } from 'minga/libraries/day';

export interface IMgDateBadgeElementProperties {
  date?: Date | string | number;
}

@Component({
  selector: 'mg-date-badge',
  templateUrl: './MgDateBadge.element.html',
  styleUrls: ['./MgDateBadge.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgDateBadgeElement {
  @Input()
  date?: Date | string | number;

  displayMonth() {
    const m = day(this.date);

    if (!m.isValid()) {
      return '';
    }

    return m.format('MMM');
  }

  displayDay() {
    const m = day(this.date);

    if (!m.isValid()) {
      return '';
    }

    return m.format('DD');
  }
}
