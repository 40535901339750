import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
  BmReportsAutoCounterDatasourceService,
  BmReportsConsequenceHistoryDatasourceService,
  BmReportsService,
} from './components/bm-reports/services';
import { BmReportActionService } from './components/bm-reports/services/bm-reports-action.service';

@Component({
  selector: 'mg-behavior-manager',
  templateUrl: './behavior-manager.component.html',
  styleUrls: ['./behavior-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    BmReportsService,
    BmReportsAutoCounterDatasourceService,
    BmReportActionService,
    BmReportsConsequenceHistoryDatasourceService,
  ],
})
export class BehaviorManagerComponent {
  constructor() {}
}
