import { config } from 'localforage';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { MingaRoleType } from 'minga/domain/permissions';
import { AppConfigService } from 'src/app/minimal/services/AppConfig';
import { PermissionsService } from 'src/app/permissions';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { NavigationPermissionService } from '@shared/services/navigation/navigation-permission.service';

import {
  AccessRestrictions,
  ConfigFlagAccessRestriction,
  CustomAccessRestriction,
  DeviceAccessType,
  FeatureFlagAccessRestriction,
  PermissionAccessRestriction,
  RoleAccessRestriction,
  SettingAccessRestriction,
} from './access-restrictions-validator.types';

export class AccessRestrictionsValidator {
  constructor(
    private _permissions: PermissionsService,
    private _settings: MingaSettingsService,
    private _navPermissions: NavigationPermissionService,
    private _appConfig: AppConfigService,
  ) {}

  public observeAccess$(
    accessRestrictions: AccessRestrictions,
    detailId?: string,
  ) {
    if (!accessRestrictions) return of(true);
    return combineLatest(
      Object.keys(accessRestrictions).map((check: keyof AccessRestrictions) => {
        switch (check) {
          case 'featureFlag':
            return this.checkFeatureFlagRestrictions(accessRestrictions[check]);
          case 'configFlag':
            return this.checkConfigFlagRestrictions(accessRestrictions[check]);
          case 'settings':
            return this.checkSettingsRestrictions(accessRestrictions[check]);
          case 'permissions':
            return this.checkPermissionsRestrictions(accessRestrictions[check]);
          case 'role':
            return this.checkRoleRestrictions(accessRestrictions[check]);
          case 'device':
            return this.checkDeviceRestrictions(accessRestrictions[check]);
          case 'custom':
            return this.checkCustomRestrictions(
              accessRestrictions[check],
              detailId,
            );
          default:
            return of(true);
        }
      }),
    ).pipe(map(check => check.every(value => value)));
  }

  public checkFeatureFlagRestrictions(
    featureFlag: FeatureFlagAccessRestriction,
  ) {
    return combineLatest(
      featureFlag.only.map(flag => this._settings.getModuleEnabledObs(flag)),
    ).pipe(
      map(flags => {
        return flags.every(isEnabled => isEnabled);
      }),
    );
  }
  public checkConfigFlagRestrictions(settings: ConfigFlagAccessRestriction) {
    const { atLeastOne = [], only = [] } = settings;

    if (atLeastOne.length) {
      const hasPermission = atLeastOne.some(flag =>
        this._appConfig.getValue(flag),
      );
      return of(hasPermission);
    } else if (only.length) {
      const hasPermission = only.every(flag => this._appConfig.getValue(flag));
      return of(hasPermission);
    } else {
      return of(true);
    }
  }

  public checkSettingsRestrictions(settings: SettingAccessRestriction) {
    const { atLeastOne = [], only = [] } = settings;
    if (atLeastOne.length) {
      return combineLatest(
        atLeastOne.map(setting => this._settings.getSettingValueObs(setting)),
      ).pipe(map(setting => setting.some(isEnabled => isEnabled)));
    } else if (only.length) {
      return combineLatest(
        only.map(setting => this._settings.getSettingValueObs(setting)),
      ).pipe(map(setting => setting.every(isEnabled => isEnabled)));
    } else return of(true);
  }

  public checkPermissionsRestrictions(
    permissions: PermissionAccessRestriction,
  ) {
    if (
      !permissions?.atLeastOne?.length &&
      !permissions?.only?.length &&
      !permissions?.except?.length
    )
      return of(false);

    if (permissions?.atLeastOne) {
      return combineLatest(
        permissions.atLeastOne.map(permission =>
          this._permissions.observePermission(permission),
        ),
      ).pipe(map(flags => flags.some(isEnabled => isEnabled)));
    } else if (permissions?.only) {
      return this._permissions.observePermission(permissions?.only);
    }
  }
  public checkCustomRestrictions(
    permissions: CustomAccessRestriction,
    detailId?: string,
  ) {
    if (!permissions) return of(false);
    return permissions(this._navPermissions, detailId);
  }

  public checkRoleRestrictions(roles: RoleAccessRestriction) {
    if (!roles?.only?.length && !roles?.except?.length) return of(false);
    return this._permissions.mingaRoleType$.pipe(
      map(role =>
        roles?.only?.length
          ? roles.only.includes(role as MingaRoleType)
          : !roles.except.includes(role as MingaRoleType),
      ),
    );
  }

  /**
   * This isn't really reactive, just checks once during initialization
   * this will also not working for "responsive" testing, if that is a
   * requirement, this needs to use the media service and listen for isMobile$
   */
  public checkDeviceRestrictions(device: DeviceAccessType[]) {
    if (!device || !device?.length) return of(true);
    const state: Record<DeviceAccessType, boolean> = {
      android: window.MINGA_APP_ANDROID,
      ios: window.MINGA_APP_IOS,
      browser: window.MINGA_APP_BROWSER,
    };
    return of(device.every(d => state[d] === true));
  }
}
