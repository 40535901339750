/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import * as localforage from 'localforage';
import * as _ from 'lodash';
import { IMobileAccountCredentials } from '@lib/cordova/account-manager';
import {
  backgroundModeDisable,
  backgroundModeEnable,
} from '@lib/cordova/background-mode';
import { _getDeviceUUID } from '@lib/cordova/device';
import { Store } from '@ngrx/store';
import { fromEvent, Subscription, timer } from 'rxjs';
import { $enum } from 'ts-enum-util';

import { FirebaseAuthUserCredentialMapper } from 'minga/app/src/app/auth/mappers';
import { SaveCancelDialog } from 'minga/app/src/app/dialog/SaveCancel';
import { FirebaseMessaging } from 'minga/app/src/app/firebase/messaging';
import { FtueService } from 'minga/app/src/app/ftue';
import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';
import {
  ICreateAccountExtraDetails,
  ILoginResult,
} from 'minga/app/src/app/minimal/services/Auth/types';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { AppRateService } from 'minga/app/src/app/services/AppRate';
import { MobileAccountManagerService } from 'minga/app/src/app/services/MobileAccountManager';
import {
  authCleared,
  authCustomSsoError,
  authFail,
  authInit,
  authPersonUpdated,
  authSsoDetached,
  authSsoError,
  authSsoLinked,
  authSsoUnlinked,
  authSuccess,
  authTokenUpdated,
  authUpdated,
  loginSuccess,
} from 'minga/app/src/app/store/AuthStore/actions';
import {
  setCurrentMinga,
  setMingaCollection,
} from 'minga/app/src/app/store/Minga/actions';
import { validateIdTokenRace } from 'minga/app/src/app/util/grpc-multi-invoke';
import { firebase } from 'minga/app/src/firebase';
import { IAuthPerson, IAuthResponse } from 'minga/domain/auth';
import { CustomSsoType } from 'minga/domain/oauth';
import { MingaPermission, MingaRoleType } from 'minga/domain/permissions';
import { Date as DateMessage } from 'minga/proto/common/date_pb';
import { StatusCode, StatusReason } from 'minga/proto/common/legacy_pb';
import { AppConfigResponse } from 'minga/proto/gateway/connect_pb';
import { LoginManager } from 'minga/proto/gateway/login_ng_grpc_pb';
import {
  CreateAccountRequest,
  DeviceLoginRequest,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  KioskLoginRequest,
  LoginRequest,
  LoginValidationRequest,
  LogoutRequest,
  PersonContentMeta,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from 'minga/proto/gateway/login_pb';
import { MingaInfoMinimal } from 'minga/proto/gateway/minga_pb';
import { Account } from 'minga/proto/gateway/people_pb';
import { PersonViewMinimal } from 'minga/proto/gateway/person_view_pb';
import { SsoManager } from 'minga/proto/sso/sso_ng_grpc_pb';
import { UnlinkAccountRequest } from 'minga/proto/sso/sso_pb';
import { IAuthPersonMapper } from 'minga/shared-grpc/auth';
import { MingaMinimalProtoMapper } from 'minga/shared-grpc/minga';
import { dateMessageToDateObject } from 'minga/shared-grpc/util';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { SentryService } from 'src/app/minimal/services/Sentry/Sentry.service';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';
import { LogoutAction } from 'src/app/store/root/rootActions';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { UserpilotService } from '@shared/services/userpilot/Userpilot.service';

/**
 * @internal
 * readonly common properties between the 3 authentication rpc calls
 */
interface ICommonProtoAuthResponse {
  getStatus(): number | undefined;
  getReason?: () => StatusReason | undefined;
  getUid(): string;
  getCustomAuthToken(): string;
  hasAccount(): boolean;
  getAccount(): Account | undefined;
  getPersonContentMeta(): PersonContentMeta | undefined;
  getMingaName?: () => string;
  getMingaLogo?: () => string;
  getPersonViewMinimal?: () => PersonViewMinimal;
  getBirthdate(): DateMessage | undefined;
  getPublicBirthdate(): boolean;
  getAppConfig(): AppConfigResponse;
  getAccountRoleType?: () => string;
  getMingaInfo(): MingaInfoMinimal;
  getParentGroupHashList(): string[];
  getDmPersonalPreference(): boolean;
  getFtueScreensList(): string[];
  getShowRateApp(): boolean;
  getDisabledDm(): boolean;
  getLogins(): number;
  getAccountCreated(): DateMessage;
  getUserMingasList(): MingaInfoMinimal[];
  getEmail(): string;
}

export type CustomOauthParams = {
  token: string;
  provider: CustomSsoType;
};

const generateOAuthStateValue = (numOfChars: number) => {
  var chars = [];
  var allowedChars =
    '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  while (numOfChars > 0) {
    var index = Math.floor(Math.random() * allowedChars.length);
    chars.push(allowedChars.charAt(index));
    numOfChars--;
  }
  return chars.join('');
};

const checkOAuthStateValue = async (state: string): Promise<boolean> => {
  const storedValue = await localforage.getItem('OAuthStateValue');
  console.log('checkOAuthStateValue', storedValue, state);
  return storedValue === state;
};

const storeOAuthStateValue = async (state: string) => {
  return await localforage.setItem('OAuthStateValue', state);
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  /** @internal */
  private _detachedSsoCredential: firebase.auth.AuthCredential | null = null;
  private _lastIdToken: string = '';

  private _tokenExpirationTime: Date | null = null;
  /** How long before token expires do we force a refresh, 2min */
  readonly TOKEN_LEAD_TIME = 120000;
  private _tokenExpireCheckSub: Subscription | null;
  private _customSsoProviderParam;
  private _customSsoTokenParam;

  get detachedSsoCredential(): firebase.auth.AuthCredential | null {
    return this._detachedSsoCredential;
  }

  constructor(
    private permissions: PermissionsService,
    private rootService: RootService,
    private analytics: AnalyticsService,
    private loginManager: LoginManager,
    private firebaseMessaging: FirebaseMessaging,
    private store: Store<any>,
    private dialog: MatDialog,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private mobileAccountManager: MobileAccountManagerService,
    private ftueService: FtueService,
    private appRateService: AppRateService,
    private appConfigService: AppConfigService,
    private ssoService: SsoManager,
    private sentryService: SentryService,
    private mingaStore: MingaStoreFacadeService,
    private authInfoService: AuthInfoService,
    private zone: NgZone,
    private userpilotService: UserpilotService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
    this._route.queryParams.subscribe(async event => {
      if (event?.provider?.toLowerCase() === 'clever') {
        await this.rootService.addLoadingPromise(
          this._checkCustomOAuthRedirect(event),
        );
      }
    });
    (<any>window).handleOpenURL = async (url: string) => {
      const path = url.split('://')[1];
      const params = path.split('?')[1];
      const paramsObj = params.split('&').reduce((acc, curr) => {
        acc[curr.split('=')[0]] = curr.split('=')[1];
        return acc;
      }, {});
      await this.rootService.addLoadingPromise(
        this._checkCustomOAuthRedirect(paramsObj),
      );
    };
  }

  async startup(): Promise<void> {
    await this._loadCachedPerson().catch(console.error);
    this.startupOnLineAuth();
  }

  /**
   * Function that gets called at app initialization. This method should never
   * throw.
   */
  async startupOnLineAuth() {
    const deviceId = _getDeviceUUID();
    if (!window.MINGA_APP_BROWSER) {
      (<any>window).universalLinks?.subscribe('login', event => {
        console.log('got universal link', event);
      });
    }
    // check for any redirect from our custom oauth providers
    await this.rootService.addLoadingPromise(
      this._checkCustomOAuthRedirect(this._route.snapshot.queryParams),
    );

    // check if we have had a redirect
    await this.rootService.addLoadingPromise(this._checkRedirectResult());

    // make sure our stored token gets changed every time the token changes.
    firebase.auth().onIdTokenChanged(user => {
      if (user) {
        this._refreshToken(user, false);
      }
    });

    // make sure we are logged in and have a token before moving on and
    // validating auth info.
    await new Promise<void>(resolve => {
      let resolved = false;
      // this gets triggered just when we go from logged in-> logged out
      // and vice versa
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          // force a new token in case the one stored was expired.
          // this will trigger onIdTokenChanged above.
          await user.getIdToken(true);
          // this._refreshToken(user, false);
        } else {
          // Only 'clear' the auth if we have already been authenticated before
          if (this._lastIdToken) {
            this.store.dispatch(authCleared());
          }
          // no user, so the auth state has changed to not logged in.
          this._lastIdToken = '';
          this.updatePerson(null);
          await this.clearCachedAuth();

          resolve();
        }

        if (!resolved && this._lastIdToken) {
          resolved = true;
          resolve();
        }
      });
    });

    if (this._lastIdToken) {
      // Fire and forget! We can just use the cached person info until this
      // finishes.
      this.verifyIdToken(this._lastIdToken)
        .then(() => {})
        .catch(console.error);
    }

    if (!window.MINGA_APP_BROWSER) {
      // on resume, ensure the token is checked in case it has expired
      // since we were last in the foreground.
      console.log('ADDING ON RESUME HANDLER');
      fromEvent(document, 'resume').subscribe(event => {
        console.log('GOT A RESUME EVENT', event);
        this.zone.run(() => {
          this._onResumeCheckToken();
        });
      });
    }
  }

  private async _checkCustomOAuthRedirect(params: any): Promise<boolean> {
    //const params = this._route.snapshot.queryParams;
    console.log('_checkCustomOAuthRedirect', params);
    const providerParam = params?.provider?.toLowerCase();
    const tokenParam = params?.accessToken;
    const state = params?.state;
    const error = params?.error;
    if (error) {
      this._systemAlertSnackBar.error(error);
      return;
    }
    /*
    if (!state) {
      // no state, might be a link from clever portal, redirect back to clever with state.
      this.signInWithClever();
      return;
    }
    */
    // @TODO: finish checking state value
    if (await checkOAuthStateValue(state)) {
      //console.log('state value doesnt match');
    } else {
      //console.log('state value matches');
    }

    this._customSsoProviderParam =
      providerParam || this._customSsoProviderParam;
    this._customSsoTokenParam = tokenParam || this._customSsoTokenParam;

    if (!this._customSsoProviderParam || !this._customSsoTokenParam) {
      return true;
    }

    // lets just remove these params from the url
    // now that we have it in local state
    const navigationExtras: NavigationExtras = {
      queryParams: {},
    };
    this._router.navigate([], navigationExtras);

    if (!$enum(CustomSsoType).isValue(this._customSsoProviderParam)) {
      console.warn(`Unsupported custom oauth: ${this._customSsoProviderParam}`);
      return true;
    }

    try {
      if (
        window.MINGA_APP_ANDROID ||
        (window.MINGA_APP_IOS && (<any>window).cordova.plugins.browsertab)
      ) {
        (<any>window).cordova.plugins.browsertab.close();
      }
      await this.loginWithCustomOauth({
        token: this._customSsoTokenParam,
        provider: this._customSsoProviderParam,
      });
    } catch (err) {
      this.sentryService.captureMessageAsError(
        'There was an issue login into custom sso provider: ' + err.message,
        { request: err, provider: this._customSsoProviderParam },
      );
      this.store.dispatch(
        authCustomSsoError({
          customSsoError: {
            message: `There was an issue login into to ${this._customSsoProviderParam}, please try again`,
          },
        }),
      );
    }

    return true;
  }

  /**
   * @returns `true` if call resulted in any store dispatches `false` if
   * nothing happens. Likely due to having no redirect results or results are
   * unhandled.
   */
  private async _checkRedirectResult(): Promise<boolean> {
    console.log('checking firebase redirect result');
    let storeDispatched = false;
    const redirectResult = await firebase
      .auth()
      .getRedirectResult()
      .catch(err => {
        this.sentryService.captureMessageAsError(
          'Got an error checking redirect result ' + err.message,
          { request: err },
        );
        this.store.dispatch(authSsoError({ ssoError: err }));
        storeDispatched = true;
        return null;
      });

    const ssoProviderInfo =
      redirectResult &&
      (await FirebaseAuthUserCredentialMapper.toISsoProviderInfo(
        redirectResult,
      ));

    if (redirectResult && redirectResult.user && redirectResult.credential) {
      const appUrls = await this.appConfigService.getCountryAppUrls();
      const idToken = await redirectResult.user.getIdToken();
      const raceResult = await validateIdTokenRace(
        {
          idToken,
        },
        appUrls.map(appUrlObj => appUrlObj.apiUrl),
      );

      this._dispatchLoginSuccess(raceResult.response);
      const newAuthProvider = raceResult.response.getNewAuthProvider();
      // user is trying to use SSO using an email that IS in minga, but
      // hasn't actually been linked yet.
      if (newAuthProvider) {
        // Delete the redirect user since the backend told us this is a new auth
        // provider. We're going to link the provider instead.
        await redirectResult.user.delete();
        const customAuthToken = raceResult.response.getCustomAuthToken();
        await firebase.auth().signInWithCustomToken(customAuthToken);
        await firebase
          .auth()
          .currentUser!.linkWithCredential(redirectResult.credential);

        raceResult.response.setNewAuthProvider(false);
        await this.appConfigService.setAppCountryFromApiUrl(raceResult.host);
        await this.handleAuthResponse(raceResult.response);
        // handleAuthResponse dispatches stuff
        storeDispatched = true;

        if (ssoProviderInfo) {
          this.store.dispatch(authSsoLinked({ ssoProviderInfo }));
        }
      } else if (ssoProviderInfo) {
        //
        if (!raceResult.response.getCustomAuthToken()) {
          // a token wasn't returned, so that means this isn't a current user.
          this._detachedSsoCredential = redirectResult.credential;
          this.store.dispatch(authSsoDetached({ ssoProviderInfo }));
          storeDispatched = true;
        } else {
          // normal sign in via sso.
          this._detachedSsoCredential = null;
          await this.appConfigService.setAppCountryFromApiUrl(raceResult.host);
          await this.handleAuthResponse(raceResult.response);
          // handleAuthResponse dispatches stuff
          storeDispatched = true;
        }
      }
    }

    return storeDispatched;
  }

  /**
   * Discard the current user from firebase. BE CAREFUL using this.
   * Should only really be used in the case of trying to login through SSO
   * when the account doesn't actually exist in minga. In that case, we need
   * to clean up firebase so that the email can be used again.
   */
  async discardCurrentUser() {
    if (this._detachedSsoCredential) {
      await firebase.auth().currentUser?.delete();
      this.store.dispatch(authSsoUnlinked());
      this._detachedSsoCredential = null;
    }
  }

  async linkWithDetachedSSOCredential() {
    if (this._detachedSsoCredential) {
      await firebase
        .auth()
        .currentUser!.linkWithCredential(this._detachedSsoCredential);
      this._detachedSsoCredential = null;
    }
  }

  public async storeCachedPerson(person: IAuthPerson) {
    await localforage.setItem('cachedPerson', person).catch(console.error);
  }

  private async _loadCachedPerson() {
    await localforage
      .getItem('cachedPerson')
      .then((cachedPerson: any) =>
        this.store.dispatch(authPersonUpdated({ authPerson: cachedPerson })),
      )
      .catch(console.error);
  }

  async clearCachedAuth() {
    await localforage.setItem('lastPersonContentMeta', null);
    await localforage.setItem('cachedPerson', null).catch(console.error);
    await localforage.setItem('cachedToken', null).catch(console.error);
  }

  /**
   * schedule the token refresh so that we force a token refresh before
   * it actually expires, otherwise we may make requests with an expired token
   */
  private scheduleTokenRefresh() {
    if (this._tokenExpireCheckSub) {
      this._tokenExpireCheckSub.unsubscribe();
    }

    const now = new Date().getTime();
    const expirationDate = this._tokenExpirationTime.getTime();
    const timeout = expirationDate - now - this.TOKEN_LEAD_TIME;

    this._tokenExpireCheckSub = timer(timeout).subscribe(() => {
      this._idTokenExpired();
    });
  }

  private async _refreshToken(
    user: firebase.User,
    forceRefresh: boolean = false,
  ): Promise<firebase.auth.IdTokenResult> {
    // Do not update if we have detached sso credentials. The token will be
    // invalid.
    if (this._detachedSsoCredential) {
      return;
    }
    const token = await user.getIdToken(forceRefresh);
    let hasAuthInited = !!this._lastIdToken;

    const result = await user.getIdTokenResult();

    if (token != this._lastIdToken) {
      this._tokenExpirationTime = new Date(result.expirationTime);
      this._lastIdToken = token;
      if (this.handleClaims(result.claims)) {
        // if we already had a token, then we are just updating auth
        if (hasAuthInited) {
          this.store.dispatch(authUpdated());
        } else {
          this.store.dispatch(authInit());
        }
      } else {
        this._lastIdToken = '';
      }

      this.scheduleTokenRefresh();
    }
    return result;
  }

  private async _idTokenExpired() {
    // Do not set the last id token with detached sso credentials since the
    // the token will be _invalid_.
    if (this._detachedSsoCredential) {
      console.warn('Not setting lastIdToken due to detached sso credentials');
      return;
    }

    const currentUser = firebase.auth().currentUser;
    // Note: we do not need to keep track of when currentUser is null because
    // onIdTokenChanged in startup takes care of that.

    if (currentUser) {
      await this._refreshToken(currentUser, true);
    }
  }

  getLastIdToken() {
    return this._lastIdToken;
  }

  private _onResumeCheckToken() {
    console.log(
      'on resume triggered by cordova, checking token',
      this._tokenExpirationTime,
    );
    this.refreshTokenIfExpired();
  }

  /**
   * If token stored is expired (or should be considered expired), then force a
   * refresh from firebase.
   */
  refreshTokenIfExpired() {
    if (this.isTokenExpired()) {
      this._idTokenExpired();
    }
  }

  /**
   * Test if the token is expired (or soon enough to be expired that we should
   * force a refresh)
   */
  isTokenExpired() {
    // if no expiration time is set then it is expired.
    if (!this._tokenExpirationTime) {
      return true;
    }
    const now = new Date().getTime();
    const expirationDate = this._tokenExpirationTime.getTime();
    const timeRemainingBeforeRefreshNeeded =
      expirationDate - now - this.TOKEN_LEAD_TIME;
    if (timeRemainingBeforeRefreshNeeded > 0) {
      return false;
    }
    return true;
  }

  async signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    await this._signInWithRedirect(provider);
  }

  async signInWithMicrosoft() {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    await this._signInWithRedirect(provider);
  }

  // TODO if we expose this functionality to the user
  // we need to implement a way to handle state parameter
  // functionality to guard agaisnt CSFR -> https://dev.clever.com/docs/il-design#protecting-against-cross-site-request-forgery-csrf
  // eg we need to either keep track of the value in session/db to validate it on oauth callback
  async signInWithClever() {
    const signInHandler = async () => {
      const { clientId, redirectUri, devMode } =
        await this.appConfigService.getClever();
      const state = await this._getStateParam();
      //const url = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}&state=${state}&district_id=6442eaf8e5ff8620792beb71`;
      let url = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}`;
      if (devMode) {
        url += '&district_id=6442eaf8e5ff8620792beb71';
      }
      if (
        window.MINGA_APP_ANDROID ||
        (window.MINGA_APP_IOS && (<any>window).cordova.plugins.browsertab)
      ) {
        (<any>window).cordova.plugins.browsertab.openUrl(url, '_blank');
      } else {
        window.location.href = url;
      }
    };

    const checkRedirectHandler = async () => {
      return this._checkCustomOAuthRedirect(this._route.snapshot.queryParams);
    };

    const errorHandler = () => {
      this.store.dispatch(
        authCustomSsoError({
          customSsoError: {
            message: `There was an issue login into to Clever, please try again`,
          },
        }),
      );
    };

    await this._ssoSignInRedirectHandler(
      signInHandler,
      checkRedirectHandler,
      errorHandler,
    );
  }

  /** @internal */
  private _isProviderConnected(providerId: string) {
    const user = firebase.auth().currentUser;

    if (user) {
      for (const data of user.providerData) {
        if (data && data.providerId === providerId) {
          return true;
        }
      }
    }
    return false;
  }

  /** @internal */
  private async _signInWithRedirect(provider: firebase.auth.AuthProvider) {
    const signInHandler = async () => {
      const currentUser = firebase.auth().currentUser;

      // Link if we're already authenticated and the provider is not already
      // linked. Otherwise just re-login because some firebase operations
      // require the user to be recently signed in.
      if (currentUser && !this._isProviderConnected(provider.providerId)) {
        await currentUser.linkWithRedirect(provider);
      } else {
        await firebase.auth().signInWithRedirect(provider);
      }
    };

    const checkRedirectHandler = async () => {
      return this._checkRedirectResult();
    };

    const errorHandler = err => {
      this.store.dispatch(authSsoError({ ssoError: err }));
    };

    await this._ssoSignInRedirectHandler(
      signInHandler,
      checkRedirectHandler,
      errorHandler,
    );
  }

  /**
   * Helper function to continuously check for redirect
   * since on the hybrid apps it opens in new tab
   */
  private async _ssoSignInRedirectHandler(
    signInHandler: () => Promise<void>,
    checkRedirectHandler: () => Promise<boolean>,
    errorHandler?: (e) => void,
  ) {
    try {
      // seems to be crashing on android 12 phones
      if (!window.MINGA_APP_ANDROID) {
        backgroundModeEnable();
      }

      await signInHandler();

      // Run some recursion that reruns the check redirect functions until true is returns
      // Only happens on hybrid since redirect opens a browser tab
      if (!(await checkRedirectHandler())) {
        // @HACK: Keep checking until we get a result.
        const checkRedirectResultLoop = () => {
          setTimeout(async () => {
            if (!(await checkRedirectHandler())) {
              console.log('checking redirect handler');
              checkRedirectResultLoop();
            }
          }, 1000);
        };

        checkRedirectResultLoop();
      }
    } catch (err) {
      console.log('Got an erroor in redirect', err);
      await this.sentryService.captureMessageAsError(
        'Got an error signing in with redirect ' + err.message,
        { request: err },
      );

      errorHandler?.(err);
    } finally {
      // Regardless of success we want to disable background mode so we don't
      // accidentlly get left in the background and be blamed for bad battery
      // life.
      if (!window.MINGA_APP_ANDROID) {
        backgroundModeDisable();
      }
    }
  }

  /**
   * unlink a minga account from all SSO providers.
   * @returns boolean - whether an sso provider was unlinked or not.
   */
  async unlinkAllAccounts() {
    const ms = this.unLinkAccount(
      new firebase.auth.OAuthProvider('microsoft.com'),
    );

    const google = await this.unLinkAccount(
      new firebase.auth.GoogleAuthProvider(),
    );

    if (google || ms) {
      await this.ssoService.unlinkAccount(new UnlinkAccountRequest());
    }
    return google || ms;
  }

  /**
   * unlink a minga account from a particular SSO provider
   * @param providerToUnlink
   * @returns boolean - whether the sso provider was unlinked or not.
   */
  async unLinkAccount(
    providerToUnlink:
      | firebase.auth.OAuthProvider
      | firebase.auth.GoogleAuthProvider,
  ): Promise<boolean> {
    const currentUser = firebase.auth().currentUser;
    if (
      currentUser?.providerData.find(
        provider => provider?.providerId == providerToUnlink.providerId,
      )
    ) {
      return currentUser
        .unlink(providerToUnlink.providerId)
        .then(async user => {
          this.store.dispatch(authSsoUnlinked());
          return true;
        });
    }

    return false;
  }

  async loginWithDevice(deviceId: string) {
    const deviceLoginRequest = new DeviceLoginRequest();
    deviceLoginRequest.setDeviceId(deviceId);

    if ((await this.firebaseMessaging.getPermissionStatus()) == 'granted') {
      const token = await this.firebaseMessaging.getToken();
      if (token) {
        deviceLoginRequest.setFirebaseRegistrationToken(token);
      } else {
        this.analytics.logEvent('LoginDevice', {
          success: 0,
          error: `No firebase registration token`,
        });
      }
    }

    const response = await this.loginManager.deviceLogin(deviceLoginRequest);

    if (response.getStatus() == StatusCode.OK) {
      this.analytics.logEvent('LoginDevice', { success: 1 });
    } else {
      const err_msg = response.hasReason()
        ? response.getReason().getMessage()
        : 'unknown';
      this.analytics.logEvent('LoginDevice', {
        success: 0,
        error: err_msg,
      });
    }

    return await this.handleAuthResponse(<ICommonProtoAuthResponse>response);
  }

  async loginWithCustomOauth(config: CustomOauthParams) {
    const appUrls = await this.appConfigService.getCountryAppUrls();

    const raceResult = await validateIdTokenRace(
      {
        customOAuth: config,
      },
      appUrls.map(appUrlObj => appUrlObj.apiUrl),
    );

    const customAuthToken = raceResult.response.getCustomAuthToken();
    const status = raceResult.response.getStatus();

    this._dispatchLoginSuccess(raceResult.response);

    if (status !== StatusCode.OK || !customAuthToken) {
      throw new Error(
        `Unable to login to custom sso provider status: ${status} ${!customAuthToken}`,
      );
    }

    await this.appConfigService.setAppCountryFromApiUrl(raceResult.host);
    await this.handleAuthResponse(raceResult.response);
  }

  private async _storeCredentialsOnLogin(
    email: string,
    password: string,
    response: ICommonProtoAuthResponse,
  ) {
    if (window.MINGA_APP_IOS && response.getStatus() == StatusCode.OK) {
      const data = {
        text: 'dialog.credentials.remember',
        saveButtonLocale: 'button.remember',
        cancelButtonLocale: 'button.forget',
      };

      const dialog = this.dialog.open(SaveCancelDialog, { data });
      dialog.afterClosed().subscribe(async result => {
        if (result === true) {
          await this.mobileAccountManager.saveCredentials(email, password);
        } else if (result === false) {
          // user chose not to keep credentials, so let's remove any stored
          await this.mobileAccountManager.removeCredentials();
        }
      });
    }
  }

  async getLoginCredentials(): Promise<IMobileAccountCredentials | null> {
    if ((<any>window).MINGA_APP_IOS) {
      return await this.mobileAccountManager.getCredentials();
    }
    return null;
  }

  async login(email: string, password: string) {
    const loginRequest = new LoginRequest();
    loginRequest.setEmail(email);
    loginRequest.setPassword(password);

    const response = await this.loginManager.login(loginRequest);
    this._dispatchLoginSuccess(response);

    if (response.getStatus() === StatusCode.OK) {
      this.analytics.logEvent('Login', { type: 'manual', success: 1 });
    } else {
      const err_msg = response.hasReason()
        ? response.getReason().getMessage()
        : 'unknown';
      this.analytics.logEvent('Login', {
        type: 'manual',
        success: 0,
        error: err_msg,
      });
    }

    // fire and forget storing credentials in mobile account manager
    await this._storeCredentialsOnLogin(
      email,
      password,
      <ICommonProtoAuthResponse>response,
    );

    return await this.handleAuthResponse(<ICommonProtoAuthResponse>response);
  }

  public async loginToKiosk(email: string, pin: string) {
    const kioskRequest = new KioskLoginRequest();
    kioskRequest.setEmail(email);
    kioskRequest.setPin(pin);
    const response = await this.loginManager.kioskLogin(kioskRequest);
    this._dispatchLoginSuccess(response);
    return await this.handleAuthResponse(<ICommonProtoAuthResponse>response);
  }

  async verifyIdToken(idToken: string) {
    const verifyRequest = new LoginValidationRequest();
    verifyRequest.setIdentityToken(idToken);
    const response = await this.loginManager.validate(verifyRequest);

    if (response.getStatus() === StatusCode.OK) {
      this.analytics.logEvent('VerifyIdToken', { success: 1 });
    } else {
      this.analytics.logEvent('VerifyIdToken', { success: 0 });
    }

    return await this.handleAuthResponse(<ICommonProtoAuthResponse>response);
  }

  /**
   * Logout with a dialog. Resolves true if user selected yes and has logged out
   * successfully.
   */
  logoutDialog(): Promise<boolean> {
    const data = {
      text: 'dialog.logoutConfirm',
      saveButtonLocale: 'button.logout',
    };

    return new Promise<boolean>((resolve, reject) => {
      const dialog = this.dialog.open(SaveCancelDialog, { data });
      dialog.afterClosed().subscribe(result => {
        if (result === true) {
          const resolveTrue = () => resolve(true);
          this.logout().then(resolveTrue, reject);
        } else {
          resolve(false);
        }
      });
    });
  }

  async logout(opts?: { snackMessage?: string; forceReload?: boolean }) {
    const { snackMessage, forceReload = true } = opts || {};
    const logoutActions = async () => {
      const logoutRequest = new LogoutRequest();
      const deviceId = await this.firebaseMessaging.getDeviceId();
      logoutRequest.setDeviceId(deviceId);
      logoutRequest.setIdentityToken(this._lastIdToken);

      // let other services know we are logging out
      this.store.dispatch(new LogoutAction());

      this._lastIdToken = '';

      await Promise.all([
        this.loginManager.logout(logoutRequest),
        this.firebaseMessaging.deleteCurrentToken(),
        firebase.auth().signOut(),
        this.clearCachedAuth(),
      ]).catch(err => console.error(err));

      this.permissions.flushPermissions();

      if (snackMessage) {
        this._systemAlertSnackBar.default(snackMessage);
      }
    };

    try {
      await this.rootService.addLoadingPromise(logoutActions());
    } catch (e) {
      console.error('problem logging out', e);
    } finally {
      console.log(
        'logout finished, reloading app',
        (window as any).cordova?.plugins?.browsertab,
      );
      // lets reload the entire app to reset the all the state, listeners etc...
      // we were running into an bug with root services and observables being destroyed
      if (forceReload) {
        window.location.reload(true);
      }
    }
  }

  async forgotPassword(email: string): Promise<ForgotPasswordResponse> {
    const request = new ForgotPasswordRequest();
    request.setEmail(email);

    const response = await this.loginManager.forgotPassword(request);
    return response;
  }

  async resetPassword(resetToken: string): Promise<ResetPasswordResponse> {
    const request = new ResetPasswordRequest();
    request.setResetToken(resetToken);

    const response = await this.loginManager.resetPassword(request);
    return response;
  }

  async createAccountWithResetToken(
    email: string,
    password: string,
    resetToken: string,
  ) {
    let createAccountRequest = new CreateAccountRequest();

    createAccountRequest.setEmail(email);
    createAccountRequest.setResetToken(resetToken);
    createAccountRequest.setPassword(password);

    return this._doCreateAccount(createAccountRequest);
  }

  async createAccountWithPin(email: string, password: string, pin: string) {
    const createAccountRequest = new CreateAccountRequest();

    createAccountRequest.setEmail(email);
    createAccountRequest.setPin(pin);
    createAccountRequest.setPassword(password);

    return this._doCreateAccount(createAccountRequest);
  }

  async createAccountWithInviteCode(
    email: string,
    inviteCode: string,
    personDetails: ICreateAccountExtraDetails,
  ) {
    let createAccountRequest = new CreateAccountRequest();
    createAccountRequest.setEmail(email);
    createAccountRequest.setMingaInviteCode(inviteCode);
    createAccountRequest.setFirstName(personDetails.firstName);
    createAccountRequest.setLastName(personDetails.lastName);
    createAccountRequest.setPassword(personDetails.password);

    if (personDetails.grade) {
      createAccountRequest.setGrade(personDetails.grade);
    }

    if (personDetails.phoneNumber) {
      createAccountRequest.setPhoneNumber(personDetails.phoneNumber);
    }

    if (personDetails.roleType) {
      createAccountRequest.setRoleType(personDetails.roleType);
    }

    return this._doCreateAccount(createAccountRequest);
  }

  /** @internal */
  private async _doCreateAccount(req: CreateAccountRequest) {
    let detachedSsoCredential = this._detachedSsoCredential;

    if (detachedSsoCredential) {
      const idToken = await firebase.auth().currentUser!.getIdToken();
      req.setDetachedSsoIdToken(idToken);
    }

    const response = await this.loginManager.createAccount(req);

    if (detachedSsoCredential) {
      const user = firebase.auth().currentUser;
      if (user) {
        await user.delete();
      } else {
        console.error(
          'No current user. Unable to use detached SSO credentials to link with new account.',
        );
        detachedSsoCredential = null;
      }
    }

    let authResponse = await this.handleAuthResponse(
      response as ICommonProtoAuthResponse,
    );

    if (authResponse.success) {
      if (detachedSsoCredential) {
        await firebase
          .auth()
          .currentUser!.linkWithCredential(detachedSsoCredential);
        // Clear it now that it is no longer needed.
        this._detachedSsoCredential = null;
        // After linking if we don't verify our id token our custom claims are
        // missing.
        authResponse = await this.verifyIdToken(
          await firebase.auth().currentUser!.getIdToken(),
        );
      }

      const email = req.getEmail();
      const password = req.getPassword();

      if (email && password) {
        // fire and forget storing credentials in mobile account manager
        await this._storeCredentialsOnLogin(
          email,
          password,
          <ICommonProtoAuthResponse>response,
        );
      }
    }

    return authResponse;
  }

  private handleClaims(claims: any) {
    if (!claims || !claims.minga) {
      return false;
    }

    // If we don't have an auth person set we can take the information from
    // our identity token
    if (!this.authInfoService.authPerson) {
      const person: PersonViewMinimal.AsObject | null = _.get(
        claims,
        'minga.person',
        null,
      );

      if (person) {
        this.store.dispatch(
          authPersonUpdated({
            authPerson: IAuthPersonMapper.fromPersonMinimalAsObject(person),
          }),
        );
      }
    }

    if (typeof claims.minga !== 'object') {
      return false;
    }

    if (Array.isArray(claims.minga.perms)) {
      this.setupPermissions(claims.minga.perms);
    } else {
      this.analytics.logEvent('Auth', {
        error: 'Missing permissions in token claims!',
      });
      console.error('[AUTH] Missing permissions in token claims!');
    }

    this.store.dispatch(authTokenUpdated({ minga: claims.minga }));

    return true;
  }

  async handleAuthResponse(
    response: ICommonProtoAuthResponse,
  ): Promise<ILoginResult> {
    const status = response.getStatus();

    if (status !== StatusCode.OK) {
      let reason = 'Unknown error. Please try again later';
      if (response.getReason) {
        let statusReason = response.getReason();
        if (statusReason) {
          reason = statusReason.getMessage();
        }
        this.analytics.logEvent('Auth', { error: reason });
      }

      this.store.dispatch(authFail({}));

      return <ILoginResult>{
        success: false,
        reason: reason,
        personName: '',
      };
    }

    const customAuthToken = response.getCustomAuthToken();

    if (customAuthToken) {
      const userCreds = await firebase
        .auth()
        .signInWithCustomToken(customAuthToken);
      if (userCreds.user) {
        if (userCreds.user.providerData.length > 0) {
          const ssoProviderInfo =
            await FirebaseAuthUserCredentialMapper.toISsoProviderInfo(
              userCreds,
            );
          if (ssoProviderInfo) {
            this.store.dispatch(authSsoLinked({ ssoProviderInfo }));
          }
        }
      }
    }

    const usersMingas = (response.getUserMingasList() || []).map(
      MingaMinimalProtoMapper.mingaMinimalProtoToMingaMinimal,
    );

    this.store.dispatch(setMingaCollection({ payload: usersMingas }));

    const mingaInfo = response.getMingaInfo();
    const mingaName = mingaInfo.getMingaName();
    const mingaLogo = mingaInfo.getMingaLogo();
    if (mingaInfo) {
      const mingaMinimal =
        MingaMinimalProtoMapper.mingaMinimalProtoToMingaMinimal(mingaInfo);
      this.mingaStore.setMingaSettings(mingaMinimal);
      this.analytics.setMingaUserProperties(mingaMinimal);
      this.store.dispatch(setMingaCollection({ payload: [mingaMinimal] }));
      this.store.dispatch(setCurrentMinga({ payload: mingaMinimal }));
    } else {
      this.analytics.logEvent('Auth', {
        error: `No minga info in auth response`,
      });
      console.warn('No minga info in auth response');
    }

    const disableDM = response.getDisabledDm();

    const authResponse: IAuthResponse = {
      dmPersonalPreference: response.getDmPersonalPreference(),
      dmDisabled: disableDM ? disableDM : false,
    };

    this.store.dispatch(authSuccess({ authResponse }));

    if (response.getPersonViewMinimal) {
      const personView = response.getPersonViewMinimal();
      const authPerson = IAuthPersonMapper.fromPersonMinimalProto(personView);
      const email = response.getEmail();
      authPerson.email = email;
      const loginCount = response.getLogins();
      const createdAt = response.getAccountCreated();
      authPerson.loginCount = loginCount;
      authPerson.dateCreated = dateMessageToDateObject(createdAt);
      this.updatePerson(authPerson);
    }

    if (response.getAccountRoleType) {
      // set role for permissions by role
      this.permissions.setMingaRoleType(response.getAccountRoleType());
    }

    const ftueScreens = response.getFtueScreensList();
    await this.ftueService.init(ftueScreens);
    this.appRateService.setShowRateApp(response.getShowRateApp());
    this.userpilotService.identify();

    // Disabling for now b/c it appears it's polluting the web ga data too much
    // this.analytics.sendLoginToWebsiteTracker();

    /*
    userflow.identify(this.person.hash, {
      name: this.person.displayName,
      role: {set: this.person.badgeRoleName, data_type: 'string'}
    });
    */
    /*
    userflow.group(mingaInfo.getMingaHash(), {
      name: mingaInfo.getMingaName(),
    });
    */
    return <ILoginResult>{
      success: true,
      personName: this.authInfoService.authPerson.displayName,
      mingaName: mingaName || '',
      mingaLogo: mingaLogo || '',
    };
  }

  private setupPermissions(permissionsList: any[]) {
    this.permissions.clearPermissions();
    const perms = permissionsList.filter(p =>
      $enum(MingaPermission).isValue(p),
    );

    const allPerms = $enum(MingaPermission).getEntries();

    if (perms.length !== permissionsList.length) {
      console.warn(
        'Got invalid permissions while setting up auth permissions:',
        permissionsList,
        perms,
        MingaPermission,
      );
    }

    this.permissions.addPermission(perms);
    this.rootService.emitRefreshNavConfig();
  }

  private async updatePerson(person: IAuthPerson) {
    this.storeCachedPerson(person);
    this.store.dispatch(authPersonUpdated({ authPerson: person }));
  }

  getPersonViewMinimalFromCurrentPerson(): PersonViewMinimal {
    const person = this.authInfoService.authInfo.person;
    const personViewMinimal = new PersonViewMinimal();

    if (person) {
      personViewMinimal.setDisplayName(person.displayName || '');
      personViewMinimal.setPersonHash(person.personHash || '');
      personViewMinimal.setBadgeIconColor(person.badgeIconColor || '');
      personViewMinimal.setBadgeRoleName(person.badgeRoleName || '');
      personViewMinimal.setBadgeIconUrl(person.badgeIconUrl || '');
      personViewMinimal.setProfileImageUrl(person.profileImageUrl || '');
      personViewMinimal.setFirstName(person.firstName || '');
      personViewMinimal.setLastName(person.lastName || '');
    }

    return personViewMinimal;
  }

  authExpiredSnackbar() {
    this._systemAlertSnackBar.error(
      'Woops! We had an issue contacting our servers. Please wait a few seconds and try again!',
    );
  }

  private _getStateParam() {
    const state = generateOAuthStateValue(8);
    storeOAuthStateValue(state);
    return state;
  }

  private _dispatchLoginSuccess(response: ICommonProtoAuthResponse) {
    const status = response.getStatus();
    const role = response.getAccountRoleType() as MingaRoleType;

    if (status === StatusCode.OK) {
      const usersMingas = (response.getUserMingasList() || []).map(
        MingaMinimalProtoMapper.mingaMinimalProtoToMingaMinimal,
      );
      this.store.dispatch(loginSuccess({ usersMingas, role }));
    }
  }
}
