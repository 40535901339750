import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilePreviewComponent } from './FilePreview.component';

@NgModule({
  imports: [
    // Minga dependencies

    // External dependencies
    CommonModule,
  ],
  declarations: [FilePreviewComponent],
  exports: [FilePreviewComponent],
})
export class FilePreviewModule {}
