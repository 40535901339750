import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { MingaPermission } from 'minga/domain/permissions';
import { PermissionsService } from 'src/app/permissions';

import { CheckinManagerRoutes } from '../checkin-manager.constants';

@Injectable()
export class ViewReportsGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _permissions: PermissionsService,
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): boolean {
    const canActivate = this._permissions.hasPermission(
      MingaPermission.CHECKIN_VIEW_REPORTS,
    );

    if (!canActivate)
      this._router.navigate([
        CheckinManagerRoutes.ROOT,
        CheckinManagerRoutes.REPORTS,
      ]);

    return canActivate;
  }
}
