<div
  fxLayout="row"
  fxLayoutAlign.gt-sm="end end"
  [style.marginBottom]="'16px'">
  <mg-btn
    fxFlex="nogrow"
    variant="filled"
    [responsive]="true"
    (pressed)="openEditModal()">
    {{ MESSAGES.BUTTON_LABEL_NEW_REWARD }}
  </mg-btn>
</div>
<ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>

<!-- Main Content Template -->
<ng-template #mainContentTemplate>
  <mat-table
    matSort
    class="list-table"
    [dataSource]="dataSource"
    [trackBy]="trackByFn">
    <!-- Asset Column -->
    <ng-container matColumnDef="imagePath">
      <mat-header-cell *matHeaderCellDef>
        {{ MESSAGES.COLUMN_LABEL_ASSET }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <ng-container
          [ngTemplateOutlet]="assetTemplate"
          [ngTemplateOutletContext]="{
            $implicit: item.imagePath
          }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_NAME }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <span [style.color]="item.color">
          {{ item.name }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="active">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_STATUS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <ng-container
          [ngTemplateOutlet]="statusToggleTemplate"
          [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef>
        {{ MESSAGES.COLUMN_LABEL_EDIT }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <ng-container
          [ngTemplateOutlet]="editButtonTemplate"
          [ngTemplateOutletContext]="{ $implicit: item.id }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Mobile View -->
    <ng-container matColumnDef="mobile">
      <mat-header-cell
        *matHeaderCellDef
        [class]="'mobile-layout'">
      </mat-header-cell>
      <mat-cell
        *matCellDef="let item"
        [class]="'mobile-layout'">
        <ng-container
          [ngTemplateOutlet]="mobileCardTemplate"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns$ | async">
    </mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns$ | async">
    </mat-row>
  </mat-table>

  <mg-empty-state
    *ngIf="!dataSource.data.length"
    [title]="MESSAGES.EMPTY_STATE_TITLE"
    [subtitle]="MESSAGES.EMPTY_STATE_SUBTITLE">
  </mg-empty-state>
</ng-template>

<!-- Asset Template -->
<ng-template
  #assetTemplate
  let-imagePath>
  <mg-image
    *ngIf="imagePath; else assetPlaceholder"
    class="mobile-asset"
    [srcs]="
      imagePath
        | mgImageUrls
          : ['blurloading1', ['studio', 'bannerlibpreview', 'cardbanner']]
    ">
  </mg-image>
  <ng-template #assetPlaceholder>
    <div class="asset-placeholder">
      <mg-icon>points-star1</mg-icon>
    </div>
  </ng-template>
</ng-template>

<!-- Edit Button Template -->
<ng-template
  #editButtonTemplate
  let-itemId>
  <mg-btn
    variant="icon"
    iconSet="minga"
    iconSet="minga"
    icon="mg-edit"
    (pressed)="openEditModal(itemId)">
  </mg-btn>
</ng-template>

<!-- Status Toggle Template -->
<ng-template
  #statusToggleTemplate
  let-item>
  <mg-form-toggle
    [value]="item.active"
    (valueChange)="rewardsService.changeRewardStatus(item, $event)">
  </mg-form-toggle>
</ng-template>

<!-- Mobile Card Template -->
<ng-template
  #mobileCardTemplate
  let-item="item">
  <mg-mobile-card
    [title]="item.name"
    [imagePath]="item.imagePath"
    [alwaysOpen]="true">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <mg-btn
        variant="icon"
        iconSet="minga"
        icon="mg-edit"
        (pressed)="openEditModal(item.id)">
      </mg-btn>
      <mg-form-toggle
        [value]="item.active"
        (valueChange)="rewardsService.changeRewardStatus(item, $event)">
      </mg-form-toggle>
    </div>
  </mg-mobile-card>
</ng-template>
