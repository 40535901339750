import { Validators } from '@angular/forms';

import { MingaRoleType } from 'minga/domain/permissions';

import { SaSelectorTypes } from '../types';

export const SaHallPassSelectorType: SaSelectorTypes = 'hall-pass';

export enum HallpassAssignmentLabels {
  MODAL_LABEL = 'Assign Hall Pass',
  ASSIGNED_BY_LABEL = 'Approved by',
  ASSIGNED_BY_LABEL_STAFF = 'Approved by (optional)',
  NOTE_LABEL = 'Note to student (optional)',
  START_LABEL = 'Assign hall pass',
  STUDENT_START_LABEL = 'Create hall pass',
  DURATION_LABEL = 'Pass duration (mins)',
  SCHEDULE_LABEL = 'Schedule for later (optional)',
  SCHEDULE_DATE_LABEL = 'Start date',
  SCHEDULE_TIME_LABEL = 'Start time',
  SCHEDULE_DATE_ERROR = 'You need to select a date to schedule a pass',
  SCHEDULE_TIME_ERROR = 'You need to select a time to schedule a pass',
}

export const allowedAssignedByRoles: string[] = [
  MingaRoleType.TEACHER,
  MingaRoleType.STAFF,
  MingaRoleType.MANAGER,
  MingaRoleType.OWNER,
];

export const MAX_NOTE_LENGTH = 180;

export const DEFAULT_STAFF_PASS_DURATION = 5; // mins

export enum SaHallPassFormFields {
  DURATION = 'hpDuration',
  NOTE = 'hpNote',
  ASSIGNED_BY = 'hpAssignedBy',
  SELF_GRANT = 'hpSelfGrant',
  SCHEDULED_DATE = 'hpScheduledDate',
  SCHEDULED_TIME = 'hpScheduledTime',
  IS_SCHEDULED = 'hpIsScheduled',
}

export const hpFormGroup = {
  [SaHallPassFormFields.DURATION]: [
    undefined,
    [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')],
  ],
  [SaHallPassFormFields.NOTE]: [
    '',
    [Validators.minLength(1), Validators.maxLength(180)],
  ],
  [SaHallPassFormFields.ASSIGNED_BY]: [undefined],
  [SaHallPassFormFields.SELF_GRANT]: [false],
  [SaHallPassFormFields.SCHEDULED_DATE]: [undefined],
  [SaHallPassFormFields.SCHEDULED_TIME]: [undefined],
  [SaHallPassFormFields.IS_SCHEDULED]: [false],
};
