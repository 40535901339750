<div
  fxLayout="row"
  fxLayoutAlign.gt-sm="end end"
  [style.marginBottom]="'16px'">
  <mg-btn
    fxFlex="nogrow"
    variant="filled"
    [id]="'checkin-types-click-create'"
    [responsive]="true"
    (pressed)="handleOnClickCreateNew()">
    {{ MESSAGES.BUTTON_CREATE_NEW }}
  </mg-btn>
</div>
<ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>

<!-- Main template content -->
<ng-template #mainContentTemplate>
  <ng-container *ngIf="loadingTypes; else tableTemplate">
    <div class="loading-container">
      <mg-spinner
        [diameter]="40"
        [thickness]="3">
      </mg-spinner>
    </div>
  </ng-container>
  <ng-template #tableTemplate>
    <mg-checkin-manager-reasons-table
      [data]="types$ | async"
      [onNewReasonCreated]="onNewReasonCreated.asObservable()"
      (refetchReasons)="fetchReasons($event)">
    </mg-checkin-manager-reasons-table>
  </ng-template>
</ng-template>
