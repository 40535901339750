import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';

import { IMingaFileOptions } from 'minga/app/src/app/file/public_interfaces';
import { IMingaAccountInfo } from 'minga/app/src/app/util/minga';
import { MingaMinimalModel } from 'minga/domain';
import { IMingaFeatureToggle } from 'minga/domain/featureToggle';
import {
  IMingaDistrict,
  IMingaPartner,
  IMingaSubscription,
  MingaSearchInfo,
} from 'minga/domain/minga';
import { RosteringMethod, SFTPCredentials } from 'minga/domain/rostering';
import {
  MingaStatusValue,
  MingaTypeValue,
  SchoolTypeValue,
  getSettingType,
  settingsCategories,
} from 'minga/libraries/util';
import {
  DateDelta,
  Int32Delta,
  StringDelta,
} from 'minga/proto/common/delta_pb';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { FileInfo } from 'minga/proto/gateway/file_pb';
import { MingaManager } from 'minga/proto/gateway/minga_ng_grpc_pb';
import {
  ActivateRosteringMethodRequest,
  AddMingaLinkRequest,
  ChangeCustomJoinCodeRequest,
  CreateFilesRequest,
  CreateMingasRequest,
  CreateUserMingaJoinCodeRequest,
  DeactivateAllRosteringMethodsRequest,
  DeleteMingasRequest,
  DeleteSISRequest,
  GenerateMingaSFTPGoUserRequest,
  GenerateMingaSFTPGoUserResponse,
  GetActiveMingaSubscriptionRequest,
  GetMingaDistrictsRequest,
  GetMingaFeatureTogglesRequest,
  GetMingaFeatureTogglesResponse,
  GetMingaPartnersRequest,
  GetMingaSettingsRequest,
  GetMingaSettingsResponse,
  GetSISRequest,
  GetSISSyncRequest,
  Minga,
  MingaDelta,
  MingaDistrict,
  MingaFilesRequest,
  MingaFilesResponse,
  MingaLink,
  MingaPartner,
  MingaSetting,
  PauseMingaRequest,
  ReadMingaSubscriptionsRequest,
  ReadMingasRequest,
  ReadUserMingaGradesRequest,
  ReadUserMingaJoinCodeRequest,
  ReadUserMingaRequest,
  RemoveFileRequest,
  RemoveMingaLinkRequest,
  RemoveMingaStudentIdImageRequest,
  ResetAutomationCountersRequest,
  ResetGroupsMembersRequest,
  ResetGroupsRequest,
  ResetPointsRequest,
  ResetUserListsRequest,
  SearchForMingaRequest,
  SearchForMingaResponse,
  TestSISRequest,
  UpdateMingaFeatureTogglesRequest,
  UpdateMingaImageRequest,
  UpdateMingaLinkRequest,
  UpdateMingaProfileRequest,
  UpdateMingaProfileResponse,
  UpdateMingaSettingRequest,
  UpdateMingaStudentIdImageRequest,
  UpdateMingasRequest,
  UpdateMingasResponse,
  UpdateSISRequest,
  UpdateUserMingaFeatureTogglesRequest,
  UpdateUserMingaSettingsRequest,
  UserMingaAccountInfoRequest,
  sisFields,
} from 'minga/proto/gateway/minga_pb';
import { PeopleManager } from 'minga/proto/gateway/people_ng_grpc_pb';
import { OpenMingaRequest } from 'minga/proto/gateway/people_pb';
import { RosterManager } from 'minga/proto/roster/roster_ng_grpc_pb';
import {
  GetRosterJobsRequest,
  GetRosterJobsResponse,
} from 'minga/proto/roster/roster_pb';
import { MingaFeatureToggleMapper } from 'minga/shared-grpc/feature_toggle';
import {
  MingaDistrictMapper,
  MingaPartnerMapper,
  MingaSubscriptionProtoMapper,
} from 'minga/shared-grpc/minga';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  ModalOverlaySuccessComponent,
  ModalOverlaySuccessData,
} from '@shared/components/modal-overlay/components/modal-overlay-success';
import { SwitchMingaModalComponent } from '@shared/components/switch-minga-modal/switch-minga-modal.component';
import { SwitchMingaModalData } from '@shared/components/switch-minga-modal/switch-minga-modal.constants';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { sortMingaSubscriptions } from '@shared/utils/sort-minga-subscriptions.util';

import { IMingaFile, MingaFiles } from '../../models/MingaFiles';
import {
  dateMessageToDateObject,
  dateObjectToDateTimeMessage,
} from '../../util/date';
import { UserStorage } from '../UserStorage/UserStorage.service';

export interface IMingaLinkFormDisplayOptions {
  showIcons?: boolean;
  showTitle?: boolean;
  formTitleWord?: string;
}

export interface IMingaLinkFormOptions {
  link?: IMingaLink;
  displayOptions?: IMingaLinkFormDisplayOptions;
  inputPattern?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
}

export interface IMingaFormFields {
  name?: string;
  logo?: string;
  placeId?: string;
  email?: string;
  websiteUrl?: string;
  phone?: string;
  mingaType?: MingaTypeValue;
  mingaStatus?: MingaStatusValue;
  mingaSize?: number;
  mingaSubscriptionId?: number;
  schoolType?: SchoolTypeValue;
  startDate?: Date;
  endDate?: Date;
  adminFullName?: string;
  adminFirstName?: string;
  adminLastName?: string;
  adminEmail?: string;
  adminPhone?: string;
  adminID?: string;
  mingaPartnerId?: number;
  mingaDistrictId?: number;
  featureToggles?: IMingaFeatureToggle;
  hubSpotId?: string;
}

export interface IMingaProfile extends IMingaFormFields {
  memberCount: number;
  logoUrl?: string;
  links?: IMingaLink[];
  mingaAddress?: string;
}

export interface IMingaLink {
  title: string;
  icon: string;
  url: string;
  id?: number;
  index?: number;
}

export interface IMingaLinkSaveSuccess {
  link: IMingaLink;
}

export interface IMingaLinkDelete {
  id?: number;
  index?: number;
}

export function fieldsFromMingaMessage(minga: Minga): IMingaFormFields {
  return {
    name: minga.getName(),
    placeId: minga.getPlaceId(),
  };
}

export function mingaMessageFromFields(fields: IMingaFormFields): Minga {
  const minga = new Minga();
  minga.setPlaceId(fields.placeId);
  minga.setName(fields.name);
  minga.setEmail(fields.email);
  minga.setPhoneNumber(fields.phone);
  minga.setWebsiteUrl(fields.websiteUrl);
  minga.setMingaStatus(fields.mingaStatus);
  minga.setMingaSize(fields.mingaSize);
  minga.setMingaType(fields.mingaType);
  minga.setSchoolType(fields.schoolType);
  minga.setMingaSubscriptionId(fields.mingaSubscriptionId);
  if (fields.startDate) {
    minga.setStartDate(dateObjectToDateTimeMessage(fields.startDate));
  }
  if (fields.endDate) {
    minga.setEndDate(dateObjectToDateTimeMessage(fields.endDate));
  }
  minga.setAdminEmail(fields.adminEmail);
  minga.setAdminFirstName(fields.adminFirstName);
  minga.setAdminLastName(fields.adminLastName);
  minga.setAdminPhone(fields.adminPhone);
  if (fields.adminID) {
    minga.setAdminId(fields.adminID);
  }

  if (fields.mingaPartnerId) {
    const partner = new MingaPartner();
    partner.setId(fields.mingaPartnerId);
    minga.setPartner(partner);
  }
  if (fields.mingaDistrictId) {
    const district = new MingaDistrict();
    district.setId(fields.mingaDistrictId);
    minga.setDistrict(district);
  }
  if (fields.featureToggles) {
    const protoFeatures = MingaFeatureToggleMapper.toProto(
      fields.featureToggles,
    );
    minga.setFeatureToggle(protoFeatures);
  }
  if (fields.hubSpotId) {
    minga.setHubSpotId(fields.hubSpotId);
  }

  return minga;
}

@Injectable({ providedIn: 'root' })
export class MingaManagerService {
  private _mingaLinkFormData: IMingaLinkFormOptions | null = null;
  private _onMingaLinkSaveSuccess: Subject<IMingaLinkSaveSuccess>;
  private _onMingaLinkDelete: Subject<IMingaLinkDelete>;
  private _onMingasUpdate: Subject<void>;
  private _onMingaChange: Subject<void>;

  constructor(
    private mingaManager: MingaManager,
    private router: Router,
    private userStorage: UserStorage,
    private _peopleManager: PeopleManager,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _modalService: ModalOverlayService,
    private _rosterManager: RosterManager,
  ) {
    this._onMingaLinkSaveSuccess = new Subject();
    this._onMingaLinkDelete = new Subject();
    this._onMingasUpdate = new Subject();
    this._onMingaChange = new Subject();
  }

  get onMingaLinkSaveSuccess(): Observable<IMingaLinkSaveSuccess> {
    return this._onMingaLinkSaveSuccess.asObservable();
  }
  get onMingaLinkDelete(): Observable<IMingaLinkDelete> {
    return this._onMingaLinkDelete.asObservable();
  }
  get onMingasUpdate() {
    return this._onMingasUpdate.asObservable();
  }
  get onMingaChange() {
    return this._onMingaChange.asObservable();
  }

  emitMingasUpdate() {
    this._onMingasUpdate.next();
  }

  emitMingaChange() {
    this._onMingaChange.next();
  }

  async getMingaLinkFormData() {
    if (!this._mingaLinkFormData) {
      this._mingaLinkFormData = await this.userStorage.getItem(
        'mingaLinkFormData',
      );
    }
    return this._mingaLinkFormData;
  }

  public showMingaChangeSuccess() {
    this._modalService.open(ModalOverlaySuccessComponent, {
      disposeOnNavigation: false,
      canClose: true,
      hasBackdrop: true,
      data: {
        hangTime: 800,
        iconName: 'aboutminga-o',
        iconColor: '#1c2f559',
        message: 'Success! Minga changed.',
      } as ModalOverlaySuccessData,
    });
  }

  // Note: currently configured to create a single minga
  async createMingas(fields: IMingaFormFields): Promise<Minga> {
    const request = new CreateMingasRequest();
    const minga = mingaMessageFromFields(fields);
    request.setMingaList([minga]);

    return this.mingaManager.createMingas(request).then(response => {
      const status = response.getStatus();
      const mingaList = response.getMingaList();

      if (status != StatusCode.OK) {
        const reasons = response.getReasonList();
        const error = new Error(reasons.length ? reasons[0].getMessage() : '');
        return Promise.reject(error);
      }

      if (mingaList.length == 0) {
        return Promise.reject(
          new Error(
            'Create minga did not return a minga, but the status code was OK',
          ),
        );
      }

      return Promise.resolve(mingaList[0]);
    });
  }

  async getMinga(hash: string): Promise<Minga> {
    return (await this.getMingas([hash]))[0];
  }

  async getMingas(hashes: string[]): Promise<Minga[]> {
    const request = new ReadMingasRequest();
    request.setHashList(hashes);

    return this.mingaManager.readMingas(request).then(response => {
      const status = response.getStatus();
      const mingaList = response.getMingaList();

      if (status != StatusCode.OK) {
        return Promise.reject(response.toObject());
      }

      if (mingaList.length == 0) {
        return Promise.reject(
          new Error(
            'Create minga did not return a minga, but the status code was OK',
          ),
        );
      }

      return Promise.resolve(mingaList);
    });
  }

  async updateMingaField(hash: string, fieldName: string, fieldValue: any) {
    const fields = {};
    fields[fieldName] = fieldValue;
    console.warn('UPDATING FIELD:', hash, fieldName, fieldValue);
    return this.updateMinga(hash, fields, new UpdateMingasRequest());
  }

  async updateStudentIdImage(assetUrl: string) {
    const request = new UpdateMingaStudentIdImageRequest();
    request.setAssetPath(assetUrl);

    const response = await this.mingaManager.updateMingaStudentIdImage(request);
  }

  async removeStudentIdImage() {
    const request = new RemoveMingaStudentIdImageRequest();

    const response = await this.mingaManager.removeMingaStudentIdImage(request);
  }

  updateMinga(
    hash: string,
    fields: any,
    request: UpdateMingasRequest,
  ): Promise<UpdateMingasResponse>;
  updateMinga(
    hash: string,
    fields: any,
    request: UpdateMingaProfileRequest,
  ): Promise<UpdateMingaProfileResponse>;
  async updateMinga(
    hash: string,
    fields: any,
    request: UpdateMingasRequest | UpdateMingaProfileRequest,
  ) {
    const minga = new MingaDelta();

    if (Object.keys(fields).length == 0) {
      return Promise.reject('Nothing to update');
    }

    if ('name' in fields) {
      const nameDelta = new StringDelta();
      nameDelta.setNewString(fields.name);
      minga.setName(nameDelta);
    }

    if ('placeId' in fields) {
      const placeIdDelta = new StringDelta();
      placeIdDelta.setNewString(fields.placeId);
      minga.setPlaceId(placeIdDelta);
    }

    if ('email' in fields) {
      const emailDelta = new StringDelta();
      emailDelta.setNewString(fields.email);
      minga.setEmail(emailDelta);
    }

    if ('phone' in fields) {
      const phoneDelta = new StringDelta();
      phoneDelta.setNewString(fields.phone);
      minga.setPhoneNumber(phoneDelta);
    }

    if ('websiteUrl' in fields) {
      const urlDelta = new StringDelta();
      urlDelta.setNewString(fields.websiteUrl);
      minga.setWebsiteUrl(urlDelta);
    }

    if ('mingaType' in fields) {
      const mingaDelta = new StringDelta();
      mingaDelta.setNewString(fields.mingaType);
      minga.setMingaType(mingaDelta);
    }

    if ('mingaSize' in fields) {
      const sizeDelta = new Int32Delta();
      sizeDelta.setNewInt32(fields.mingaSize);
      minga.setMingaSize(sizeDelta);
    }

    if ('mingaStatus' in fields) {
      const statusDelta = new StringDelta();
      statusDelta.setNewString(fields.mingaStatus);
      minga.setMingaStatus(statusDelta);
    }

    if ('mingaSubscriptionId' in fields) {
      const subDelta = new Int32Delta();
      subDelta.setNewInt32(fields.mingaSubscriptionId);
      minga.setMingaSubscriptionId(subDelta);
    }

    if ('schoolType' in fields) {
      const schoolDelta = new StringDelta();
      schoolDelta.setNewString(fields.schoolType);
      minga.setSchoolType(schoolDelta);
    }

    if ('adminFullName' in fields) {
      const adminNameDelta = new StringDelta();
      adminNameDelta.setNewString(fields.adminFullName);
      minga.setAdminFullName(adminNameDelta);
    }

    if ('adminEmail' in fields) {
      const adminEmailDelta = new StringDelta();
      adminEmailDelta.setNewString(fields.adminEmail);
      minga.setAdminEmail(adminEmailDelta);
    }

    if ('adminPhone' in fields) {
      const adminPhoneDelta = new StringDelta();
      adminPhoneDelta.setNewString(fields.adminPhone);
      minga.setAdminPhone(adminPhoneDelta);
    }

    if ('startDate' in fields) {
      const startDateDelta = new DateDelta();
      startDateDelta.setNewDate(dateObjectToDateTimeMessage(fields.startDate));
      minga.setStartDate(startDateDelta);
    }

    if ('endDate' in fields) {
      const endDateDelta = new DateDelta();
      endDateDelta.setNewDate(dateObjectToDateTimeMessage(fields.endDate));
      minga.setEndDate(endDateDelta);
    }

    if ('mingaPartnerId' in fields) {
      const partnerDelta = new Int32Delta();
      partnerDelta.setNewInt32(fields.mingaPartnerId);
      minga.setMingaPartnerId(partnerDelta);
    }

    if ('mingaDistrictId' in fields) {
      const districtDelta = new Int32Delta();
      districtDelta.setNewInt32(fields.mingaDistrictId);
      minga.setMingaDistrictId(districtDelta);
    }

    if ('hubSpotId' in fields) {
      const hubSpotDelta = new StringDelta();
      hubSpotDelta.setNewString(fields.hubSpotId);
      minga.setHubSpotId(hubSpotDelta);
    }

    if ('featureToggles' in fields) {
      const featureToggleMsg = MingaFeatureToggleMapper.toProto(
        fields.featureToggles,
      );
      minga.setFeatureToggles(featureToggleMsg);
    }

    if (request instanceof UpdateMingasRequest) {
      minga.setHash(hash);
      request.addMinga(minga);
      return this.mingaManager.updateMingas(request);
    } else {
      request.setMinga(minga);
      return this.mingaManager.updateMingaProfile(request);
    }
  }

  async deleteMinga(mingaHash: string) {
    const request = new DeleteMingasRequest();
    request.setHashList([mingaHash]);

    return this.mingaManager.deleteMingas(request);
  }

  async updateMingaImage(mingaHash: string, asset: string) {
    const request = new UpdateMingaImageRequest();
    request.setAsset(asset);
    request.setMingaHash(mingaHash);

    return this.mingaManager.updateMingaImage(request);
  }

  async readUserMinga(): Promise<IMingaProfile> {
    const request = new ReadUserMingaRequest();
    const response = await this.mingaManager.readUserMinga(request);

    const status = response.getStatus();

    if (status == StatusCode.OK) {
      const minga = response.getMinga();
      if (!minga) {
        return null;
      }

      const mingaLinks: IMingaLink[] = [];
      const responseLinks = minga.getLinksList();

      if (responseLinks && responseLinks.length) {
        for (const responseLink of responseLinks) {
          const linkObj = responseLink.toObject();
          mingaLinks.push({
            title: linkObj.title,
            icon: linkObj.icon,
            url: linkObj.url,
            id: linkObj.id,
          });
        }
      }

      const startDate = minga.getStartDate()
        ? dateMessageToDateObject(minga.getStartDate())
        : null;
      const endDate = minga.getEndDate()
        ? dateMessageToDateObject(minga.getEndDate())
        : null;

      const mingaAddress = response.getMingaAddress();

      const mingaProfile: IMingaProfile = {
        memberCount: response.getMemberCount(),
        name: minga.getName(),
        phone: minga.getPhoneNumber(),
        websiteUrl: minga.getWebsiteUrl(),
        email: minga.getEmail(),
        logoUrl: minga.getImageUrl(),
        placeId: minga.getPlaceId(),
        links: mingaLinks,
        mingaSize: minga.getMingaSize(),
        mingaStatus: MingaStatusValue[minga.getMingaStatus()],
        mingaType: MingaTypeValue[minga.getMingaType()],
        schoolType: SchoolTypeValue[minga.getSchoolType()],
        mingaSubscriptionId: minga.getMingaSubscriptionId(),
        startDate,
        endDate,
        adminEmail: minga.getAdminEmail(),
        adminFullName: minga.getAdminFullName(),
        adminPhone: minga.getAdminPhone(),
        mingaAddress,
      };

      return mingaProfile;
    } else {
      return null;
    }
  }

  async updateMingaProfile(minga: IMingaFormFields | IMingaProfile) {
    const request = new UpdateMingaProfileRequest();
    return this.updateMinga(null, minga, request);
  }

  async addMingaLink(mingaLink: IMingaLink) {
    const request = new AddMingaLinkRequest();
    const link = new MingaLink();
    link.setUrl(mingaLink.url);
    link.setTitle(mingaLink.title);
    link.setIcon(mingaLink.icon);
    request.setLink(link);

    return this.mingaManager.addMingaLink(request);
  }

  async updateMingaLink(mingaLink: IMingaLink) {
    if (!mingaLink.id) {
      const err = `updateMingaLink was given no id for the link to update`;
      return Promise.reject(err);
    }
    const request = new UpdateMingaLinkRequest();
    const link = new MingaLink();
    link.setUrl(mingaLink.url);
    link.setTitle(mingaLink.title);
    link.setIcon(mingaLink.icon);
    link.setId(mingaLink.id);
    request.setLink(link);

    return this.mingaManager.updateMingaLink(request);
  }

  async removeMingaLink(linkId: number) {
    const request = new RemoveMingaLinkRequest();
    request.setId(linkId);

    return this.mingaManager.removeMingaLink(request);
  }

  async openMingaLinkForm(linkFormOptions: IMingaLinkFormOptions) {
    this._mingaLinkFormData = linkFormOptions;
    await this.userStorage.setItem('mingaLinkFormData', linkFormOptions);

    await this.router.navigate([
      '/',
      { outlets: { o: ['minga-profile-link'] } },
    ]);
  }

  emitMingaLinkSaveSuccess(ev: IMingaLinkSaveSuccess) {
    this._onMingaLinkSaveSuccess.next(ev);
    this.resetFormData();
  }

  emitMingaLinkDelete(ev: IMingaLinkDelete) {
    this._onMingaLinkDelete.next(ev);
    this.resetFormData();
  }

  async pauseMinga(paused: boolean, mingaHash: string) {
    const request = new PauseMingaRequest();
    request.setPaused(paused);
    request.setMingaHash(mingaHash);
    await this.mingaManager.pauseMinga(request);
  }

  async getMingaSettings(mingaHash: string): Promise<GetMingaSettingsResponse> {
    const request = new GetMingaSettingsRequest();
    request.setMingaHash(mingaHash);

    return this.mingaManager.getMingaSettings(request);
  }

  async getMingaFeatureToggles(
    mingaHash: string,
  ): Promise<GetMingaFeatureTogglesResponse> {
    const request = new GetMingaFeatureTogglesRequest();
    request.setMingaHash(mingaHash);

    return this.mingaManager.getMingaFeatureToggles(request);
  }

  async updateMingaSettings(request: UpdateMingaSettingRequest) {
    return this.mingaManager.updateMingaSetting(request);
  }

  async updateUserMingaFeatureToggles(toggles: IMingaFeatureToggle) {
    const toggleProto = MingaFeatureToggleMapper.toProto(toggles);
    const request = new UpdateUserMingaFeatureTogglesRequest();
    request.setFeatureToggle(toggleProto);
    return this.mingaManager.updateUserMingaFeatureToggles(request);
  }

  async updateMingaFeatureToggles(
    mingaHash: string,
    toggles: IMingaFeatureToggle,
  ) {
    const toggleProto = MingaFeatureToggleMapper.toProto(toggles);
    const request = new UpdateMingaFeatureTogglesRequest();
    request.setFeatureToggle(toggleProto);
    request.setMingaHash(mingaHash);
    return this.mingaManager.updateMingaFeatureToggles(request);
  }

  async updateUserMingaSettings(settingKey: string, value: string) {
    const request = new UpdateUserMingaSettingsRequest();

    const newSetting = new MingaSetting();
    newSetting.setKeyName(settingKey);
    newSetting.setValue(value);
    request.addSetting(newSetting);

    const response = await this.mingaManager.updateUserMingaSettings(request);

    const settings = response.getSettingList();
    const mingaSettings: MingaSetting.AsObject[] = [];
    for (const setting of settings) {
      mingaSettings.push(setting.toObject());
    }

    return mingaSettings;
  }

  async getUserMingaAccountInfo(): Promise<IMingaAccountInfo> {
    const request = new UserMingaAccountInfoRequest();

    const response = await this.mingaManager.getUserMingaAccountInfo(request);

    const settings = response.getSettingList();
    const featureToggle = response.getFeatureToggle();
    const minga = response.getMinga();

    const mingaSettings: MingaSetting.AsObject[] = [];
    for (const setting of settings) {
      mingaSettings.push(setting.toObject());
    }
    return {
      dashboardInfo: minga.toObject(),
      settings: mingaSettings,
      featureToggle: featureToggle.toObject(),
    };
  }

  async getStudentIdMingaSettings(): Promise<MingaSetting.AsObject[]> {
    const accountInfo = await this.getUserMingaAccountInfo();
    const allSettings = accountInfo.settings;
    return allSettings.filter(setting => {
      const currentSettingMeta = getSettingType(setting.keyName);

      return (
        currentSettingMeta?.category === settingsCategories.STUDENT_ID_SETTINGS
      );
    });
  }

  async searchForMinga(searchQuery: string): Promise<MingaSearchInfo[]> {
    const request = new SearchForMingaRequest();
    request.setSearchText(searchQuery);

    const response: SearchForMingaResponse =
      await this.mingaManager.searchForMinga(request);
    const protoMingaItems = response.getMingasList();
    return protoMingaItems.map(item => {
      return {
        name: item.getName(),
        hash: item.getHash(),
      } as MingaSearchInfo;
    });
  }

  async openMinga(mingaHash: string) {
    const request = new OpenMingaRequest();
    request.setMingaHash(mingaHash);
    try {
      await this._peopleManager.openMinga(request);
    } catch (e) {
      const errorMsg = 'Failed to change Minga';
      this._systemAlertSnackBar.error(errorMsg);
      throw new Error(errorMsg);
    }
  }

  async addFiles(fileOptions: IMingaFileOptions[]): Promise<IMingaFile[]> {
    const request = new CreateFilesRequest();

    for (const file of fileOptions) {
      if (!file.assetPath && !file.url) {
        throw new Error(`Unable to add non asset or url group file`);
      }
      const fileInfo = new FileInfo();
      fileInfo.setAssetPath(file.assetPath);
      fileInfo.setUrl(file.url);
      fileInfo.setName(file.title || '');

      request.addFile(fileInfo);
    }

    const response = await this.mingaManager.addFiles(request);

    return this._convertFilesResponse(response);
  }

  private _convertFilesResponse(response: MingaFilesResponse): IMingaFile[] {
    const fileDetails = response.getFileList();
    return fileDetails.map(fileDetail => {
      const file = fileDetail.toObject();
      return { file, status: { fileKey: MingaFiles.getStatusKey(file) } };
    });
  }

  async getFiles(): Promise<IMingaFile[]> {
    const request = new MingaFilesRequest();

    const response = await this.mingaManager.getFiles(request);
    return MingaFiles.fromFilesResponse(response);
  }

  async removeFile(fileHash: string): Promise<IMingaFile[]> {
    const request = new RemoveFileRequest();
    request.setFileHash(fileHash);

    const response = await this.mingaManager.removeFile(request);
    return MingaFiles.fromFilesResponse(response);
  }

  async readUserJoinCode(): Promise<string> {
    const request = new ReadUserMingaJoinCodeRequest();

    const response = await this.mingaManager.readUserMingaJoinCode(request);
    return response.getMingaJoinCode();
  }

  async readMingaGradeOptions(): Promise<string[]> {
    const request = new ReadUserMingaGradesRequest();
    const response = await this.mingaManager.readUserMingaGrades(request);
    return response.getGradeOptionsList();
  }

  async createUserJoinCode(): Promise<string> {
    const request = new CreateUserMingaJoinCodeRequest();

    const response = await this.mingaManager.createUserMingaJoinCode(request);
    return response.getMingaJoinCode();
  }

  async changeCustomJoinCode(code: string): Promise<string> {
    const request = new ChangeCustomJoinCodeRequest();
    request.setMingaJoinCode(code);
    const response = await this.mingaManager.changeCustomJoinCode(request);
    return response.getMingaJoinCode();
  }

  /**
   * Get subscriptions available in the minga
   *
   * @param mingaType - what type of minga this is
   * @param useDisplaySubscriptions - whether to filter out subs that are
   * priced per default role, and replace with display subscriptions, meant
   * for display only.
   */
  async getMingaSubscriptions(
    mingaType?: string,
    useDisplaySubscriptions: boolean = false,
  ): Promise<IMingaSubscription[]> {
    const request = new ReadMingaSubscriptionsRequest();
    if (mingaType) {
      request.setFilterMingaType(mingaType);
    }
    const response = await this.mingaManager.getMingaSubscriptions(request);
    const subList = response.getSubscriptionList();
    let subscriptions: IMingaSubscription[] = [];
    if (subList) {
      subscriptions = subList.map(sub =>
        MingaSubscriptionProtoMapper.toIMingaSubscription(sub),
      );
      subscriptions = sortMingaSubscriptions(subscriptions);
    }
    if (useDisplaySubscriptions) {
      subscriptions = subscriptions.filter(sub => !sub.pricedPerDefaultRole);
      const displaySubs = response.getDisplaySubscriptionsList();
      if (displaySubs) {
        const dp = displaySubs.map(sub =>
          MingaSubscriptionProtoMapper.toIMingaSubscription(sub),
        );
        subscriptions = subscriptions.concat(
          displaySubs.map(sub =>
            MingaSubscriptionProtoMapper.toIMingaSubscription(sub),
          ),
        );
      }
    }

    return subscriptions;
  }

  async fetchActiveMingaSubscription(): Promise<IMingaSubscription> {
    const request = new GetActiveMingaSubscriptionRequest();
    const response = await this.mingaManager.getActiveMingaSubscription(
      request,
    );

    const subscriptionResp = response.getSubscription();
    if (!subscriptionResp) {
      throw new Error('Received no active subscription from backend');
    }

    return MingaSubscriptionProtoMapper.toIMingaSubscription(subscriptionResp);
  }

  async fetchMingaPartners(): Promise<IMingaPartner[]> {
    const response = await this.mingaManager.getMingaPartners(
      new GetMingaPartnersRequest(),
    );

    return response
      .getPartnersList()
      .map(value => MingaPartnerMapper.fromProto(value));
  }

  async fetchMingaDistricts(): Promise<IMingaDistrict[]> {
    const response = await this.mingaManager.getMingaDistricts(
      new GetMingaDistrictsRequest(),
    );

    return response
      .getDistrictsList()
      .map(value => MingaDistrictMapper.fromProto(value));
  }

  async updateSIS(fields: sisFields) {
    const request = new UpdateSISRequest();
    request.setFields(fields);
    const response = await this.mingaManager.updateSIS(request);
    return response;
  }

  async deleteSIS(rosteringMethod: RosteringMethod) {
    const request = new DeleteSISRequest();
    request.setRosteringMethod(rosteringMethod);
    await this.mingaManager.deleteSIS(request);
  }

  async activateRosteringMethod(rosteringMethod: RosteringMethod) {
    const request = new ActivateRosteringMethodRequest();
    request.setRosteringMethod(rosteringMethod);
    await this.mingaManager.activateRosteringMethod(request);
  }

  async deactivateAllRosteringMethods() {
    await this.mingaManager.deactivateAllRosteringMethods(
      new DeactivateAllRosteringMethodsRequest(),
    );
  }

  async getSIS() {
    const request = new GetSISRequest();
    const response = await this.mingaManager.getSIS(request);
    return response.getFieldsList();
  }

  async generateMingaSFTPCredentials(): Promise<SFTPCredentials> {
    const response: GenerateMingaSFTPGoUserResponse =
      await this.mingaManager.generateMingaSFTPGoUser(
        new GenerateMingaSFTPGoUserRequest(),
      );

    return {
      username: response.getUsername(),
      password: response.getPassword(),
      host: response.getHost(),
    };
  }

  async getRosterJobs() {
    const response: GetRosterJobsResponse =
      await this._rosterManager.getRosterJobs(new GetRosterJobsRequest());

    return response.getJobsList();
  }

  private resetFormData() {
    // clear saved form data
    this.userStorage.removeItem('mingaLinkFormData');
    this._mingaLinkFormData = null;
  }

  async getMingaSchoolType(): Promise<SchoolTypeValue> {
    const minga = await this.readUserMinga();
    return minga.schoolType;
  }

  async testSISIntegration(testURL?: string) {
    const request = new TestSISRequest();
    if (testURL) {
      request.setTesturl(testURL);
    }
    const response = await this.mingaManager.testSISIntegration(request);
    return {
      error: response.getError(),
      infos: response.getSchoolInfosList()?.map(school => ({
        id: school.getId(),
        name: school.getName(),
      })),
      rawOutput: response.getRawresponse(),
    };
  }

  async getSISSync() {
    const request = new GetSISSyncRequest();
    const response = await this.mingaManager.getSISSync(request);
    return response.getLogsList();
  }

  async resetGroups() {
    const request = new ResetGroupsRequest();
    const response = await this.mingaManager.resetGroups(request);
    return response;
  }

  async resetUserLists() {
    const request = new ResetUserListsRequest();
    return await this.mingaManager.resetUserLists(request);
  }

  async resetPoints() {
    const resetRequest = new ResetPointsRequest();
    const response = await this.mingaManager.resetPoints(resetRequest);
    return response;
  }

  async resetAutomationCounters() {
    const resetRequest = new ResetAutomationCountersRequest();
    const response = await this.mingaManager.resetAutomationCounters(
      resetRequest,
    );
    return response;
  }

  async resetGroupsMembers() {
    const resetMembersRequest = new ResetGroupsMembersRequest();
    const response = await this.mingaManager.resetGroupsMembers(
      resetMembersRequest,
    );
    return response;
  }

  public async openSwitchMingaModal(
    data?: SwitchMingaModalData,
  ): Promise<MingaMinimalModel> {
    return new Promise((resolve, reject) => {
      const modalRef = this._modalService.open(SwitchMingaModalComponent, {
        data: data || {},
      });

      modalRef.afterClosed.subscribe(async response => {
        if (!response) reject(null);
        const { type, data: modalData } = response;

        if (
          type === ModalOverlayServiceCloseEventType.SUBMIT &&
          modalData.minga
        ) {
          resolve(modalData.minga);
        }

        reject(null);
      });
    });
  }
}
