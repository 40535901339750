import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { ReplaySubject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { HallPassStatusEnum } from 'minga/domain/hallPass';
import { RealtimeEventType } from 'minga/domain/realtimeEvents';
import { mingaSettingTypes } from 'minga/libraries/util';
import { RealtimeEvents } from 'src/app/realtime-event/RealtimeEvents';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { HPM_DASHBOARD_STATUS_COLOR_MAP } from '@modules/hallpass-manager/components/hpm-dashboard/constants/hpm-dashboard-styling.constants';
import {
  KIOSK_HALL_PASS_TABLE_COLUMNS,
  KioskHallPassTableColumn,
  KioskHallPassTableMessage,
} from '@modules/kiosk/constants';
import { KioskHallPassTableService } from '@modules/kiosk/services/kiosk-hall-pass-table.service';

import { MediaService } from '@shared/services/media';

@Component({
  selector: 'mg-kiosk-hall-pass-table',
  templateUrl: './kiosk-hall-pass-table.component.html',
  styleUrls: ['./kiosk-hall-pass-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskHallPassTableComponent implements OnDestroy {
  @Input() set hallPasses(items) {
    this.dataSource.data = items || [];
  }
  // Mat Table
  public dataSource = new MatTableDataSource([]);

  // Constants
  public readonly MESSAGES = KioskHallPassTableMessage;
  public readonly COLUMN_DEF = KioskHallPassTableColumn;
  public readonly PASS_STATUS = HallPassStatusEnum;
  public readonly STATUS_COLOR_MAP = HPM_DASHBOARD_STATUS_COLOR_MAP;

  // General Subjects
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  public readonly displayedColumns$ = this.media.isMobileView$.pipe(
    takeUntil(this._destroyedSubject),
    map(isMobile => {
      if (isMobile) {
        return ['mobile'];
      } else {
        return KIOSK_HALL_PASS_TABLE_COLUMNS;
      }
    }),
  );

  public manuallyEndedPassesSetting: boolean;
  public readonly manuallyEndedPassesSetting$ = this._mingaSettings
    .getSettingValueObs(mingaSettingTypes.PASS_MUST_MANUALLY_END)
    .pipe(
      tap(value => {
        this.manuallyEndedPassesSetting = value;
      }),
    );

  constructor(
    public media: MediaService,
    public kioskHallPassTable: KioskHallPassTableService,
    private _mingaSettings: MingaSettingsService,
    private _realtimeEvents: RealtimeEvents,
  ) {
    this._realtimeEvents
      .observe([RealtimeEventType.HALL_PASS_ENDED])
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(data => {
        const id = data.payload.notification?.metadata?.hallPass?.id;

        if (id) {
          this.kioskHallPassTable.removePass(id);
        }
      });
  }

  public ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public showIcon(typeSetting: boolean): boolean {
    return !(typeSetting ?? this.manuallyEndedPassesSetting);
  }
}
