import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatColumnDef } from '@angular/material/table';

import {
  ColumnInfo,
  TemplateColumnKeys,
} from 'minga/libraries/shared/reports_columns';
import {
  ReportDatasourceService,
  ReportLinkInfo,
} from 'src/app/components/manager-report/services/report-datasource.service';

import { MingaColorsType } from '@shared/constants';

import { ReportTableColumnTemplateComponent } from './rt-column-template.component';

type HistoryDropdown = ReportLinkInfo & { label: string };

@Component({
  selector: 'mg-history-column',
  styles: ["[class*='w-'] {flex: initial; min-width: 0 !important }"],
  template: `<ng-container
    matColumnDef="{{ datasource.TEMPLATE_KEYS.HISTORY }}">
    <mat-header-cell
      *matHeaderCellDef
      [mat-sort-header]="datasource.TEMPLATE_KEYS.HISTORY"
      [disabled]="true"
      [ngClass]="historyColumn.columnClasses">
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="button-container"
      [ngClass]="historyColumn.columnClasses">
      <mg-btn
        variant="icon"
        iconSet="minga"
        icon="history-o"
        tooltip="History"
        [matMenuTriggerFor]="dropdownMenu"
        (pressed)="!dropdown?.length ? datasource.viewHistory(item) : null">
      </mg-btn>
      <mat-menu #dropdownMenu="matMenu">
        <div *ngIf="dropdown?.length">
          <ng-container *ngFor="let drop of dropdown">
            <button
              mat-menu-item
              (click)="datasource.viewHistory(item, drop)">
              {{ drop.label }}
            </button>
          </ng-container>
        </div>
      </mat-menu>
    </mat-cell>
  </ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ReportTableColumnTemplateComponent,
      useExisting: ReportTableHistoryTemplateComponent,
    },
  ],
})
export class ReportTableHistoryTemplateComponent implements AfterContentInit {
  @ViewChild(MatColumnDef, { static: true }) columnDef: MatColumnDef;

  historyColumn: ColumnInfo;

  @Input()
  datasource: ReportDatasourceService<any>;

  @Input()
  iconName = 'history-o';

  @Input()
  colorTheme: MingaColorsType = 'navy';

  @Input()
  dropdown: HistoryDropdown[];

  constructor() {}

  ngAfterContentInit(): void {
    this.historyColumn = this.datasource
      .getTemplateColumns()
      .find(col => col.key === TemplateColumnKeys.HISTORY);
  }
}
