import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { SettingsRecord } from 'minga/libraries/util';
import {
  MingaDashboardInfo,
  MingaFeatureToggle,
} from 'minga/proto/gateway/minga_pb';
import { MingaManagerService } from 'src/app/services/MingaManager';
import { MingaSettingsService } from 'src/app/store/Minga/services';

@Injectable()
export class MMSettingService {
  private _mingaSettings$: Observable<SettingsRecord>;
  private _mingaDashboardInfo$: Observable<MingaDashboardInfo.AsObject>;
  private _mingaFeatureToggles$: Observable<MingaFeatureToggle.AsObject>;

  constructor(
    private _mingaSettingsService: MingaSettingsService,
    private _mingaService: MingaManagerService,
  ) {
    this._initSettingsService();
  }

  get mingaSettings$() {
    return this._mingaSettings$;
  }

  get mingaDashboardInfo$() {
    return this._mingaDashboardInfo$;
  }

  get mingaFeatureToggles$() {
    return this._mingaFeatureToggles$;
  }

  private async _initSettingsService() {
    this._mingaSettings$ = this._mingaSettingsService.getAllSettingsAsObject();

    const mingaAccountInfo$ = from(
      this._mingaService.getUserMingaAccountInfo(),
    );

    this._mingaFeatureToggles$ = mingaAccountInfo$.pipe(
      map(accountInfo => accountInfo.featureToggle),
      shareReplay(1),
    );

    this._mingaDashboardInfo$ = mingaAccountInfo$.pipe(
      map(accountInfo => accountInfo.dashboardInfo),
      shareReplay(1),
    );
  }
}
