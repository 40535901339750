<div
  class="desktop edit-wrap"
  [@widthAnimation]>
  <div class="desktop-bar">
    <mg-btn
      class="back-arrow"
      iconSet="minga"
      variant="icon-secondary"
      icon="mg-arrow-right-alt"
      (pressed)="cancel()"></mg-btn>
  </div>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<div class="mobile edit-wrap">
  <div class="mobile-bar">
    <mg-text
      *ngIf="automationGroup$ | async as automationGroup"
      variant="header-md-secondary">
      {{
        (isNew$ | async) ? MESSAGES.FORM_TITLE_ADD : MESSAGES.FORM_TITLE_EDIT
      }}
      {{ automationGroup?.name }}
    </mg-text>
    <mg-btn
      class="mobile-bar-button"
      size="large"
      variant="icon-secondary"
      iconSet="minga"
      icon="mg-cross"
      (pressed)="onCancel()"></mg-btn>
  </div>

  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
  <!-- Loading -->
  <div
    *ngIf="(formState$ | async) === 'loading'"
    class="loading-container">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>

  <!-- Main form -->
  <div class="form-container">
    <form
      *ngIf="(formState$ | async) !== 'loading'"
      id="automations-edit-step-form"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px">
        <fieldset class="form-title">
          <mg-text
            *ngIf="automationGroup$ | async as automationGroup"
            variant="header-sm-secondary">
            {{
              (isNew$ | async)
                ? MESSAGES.FORM_TITLE_ADD
                : MESSAGES.FORM_TITLE_EDIT
            }}
            {{ automationGroup?.name }}
          </mg-text>
          <div
            *ngIf="(formState$ | async) === 'invalid'"
            class="form-error">
            <mg-system-alert
              type="error"
              [message]="MESSAGES.INVALID_FORM_MESSAGE"></mg-system-alert>
          </div>

          <div
            class="consequence-warning"
            *ngIf="disabledConsequenceWarning$ | async">
            <mg-system-alert
              type="warning"
              message="{{ MESSAGES.DISABLED_CONSEQUENCE_WARNING }}">
            </mg-system-alert>
          </div>
        </fieldset>
        <fieldset>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <mg-form-text-input
              [labelBackground]="
                (media.isMobileView$ | async) ? 'white' : 'surface-card'
              "
              [label]="MESSAGES.FIELD_NAME"
              [control]="form.get(FORM_FIELDS.NAME)">
            </mg-form-text-input>
            <mg-form-text-input
              [labelBackground]="
                (media.isMobileView$ | async) ? 'white' : 'surface-card'
              "
              [label]="MESSAGES.FIELD_INTERNAL_DESC"
              [control]="form.get(FORM_FIELDS.INTERNAL_DESC)">
            </mg-form-text-input>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center">
              <div>
                <mg-text variant="body-md">
                  {{ MESSAGES.FIELD_BEHAVIOR_THRESHOLD }}
                </mg-text>
                <mg-text
                  variant="body-xs"
                  color="surface-alt">
                  {{ MESSAGES.FIELD_BEHAVIOR_THRESHOLD_DESC }}
                </mg-text>
              </div>
              <div class="number-dropdown">
                <mg-form-text-input
                  inputType="number"
                  min="1"
                  [control]="form.get(FORM_FIELDS.BEHAVIOR_THRESHOLD)">
                </mg-form-text-input>
              </div>
            </div>
            <div
              fxLayout="column"
              fxLayoutGap="4px">
              <mg-form-select
                [labelBackground]="
                  (media.isMobileView$ | async) ? 'white' : 'surface-card'
                "
                [placeholder]="MESSAGES.FIELD_CONSEQUENCE_TO_ASSIGN"
                [options]="consequenceOptions$ | async"
                [closeOnSelect]="true"
                [searchable]="true"
                [control]="form.get(FORM_FIELDS.CONSQUENCE_TO_ASSIGN)"
                [value]="form.get(FORM_FIELDS.CONSQUENCE_TO_ASSIGN).value"
                [enableMobileSheet]="true">
                <ng-template let-item="item">
                  <mg-form-select-option
                    [option]="item"></mg-form-select-option>
                </ng-template>
              </mg-form-select>
              <mg-text
                *ngIf="form.get(FORM_FIELDS.CONSQUENCE_TO_ASSIGN).value"
                variant="body-xs"
                color="surface-alt">
                {{
                  'Type: ' +
                    (consequenceOptions$
                      | async
                      | getConsequenceTypeFromId
                        : form.get(FORM_FIELDS.CONSQUENCE_TO_ASSIGN).value)
                }}
              </mg-text>
            </div>
            <mg-form-toggle
              [label]="MESSAGES.FIELD_SEND_STUDENT_NOTE"
              [control]="form.get(FORM_FIELDS.SEND_STUDENT_NOTE)">
            </mg-form-toggle>

            <div *ngIf="form.get(FORM_FIELDS.SEND_STUDENT_NOTE).value === true">
              <mg-form-text-input
                inputType="textarea"
                [labelBackground]="
                  (media.isMobileView$ | async) ? 'white' : 'surface-card'
                "
                [templateVariables]="notesTemplateVariables"
                [label]="MESSAGES.FIELD_STUDENT_NOTE"
                [placeholder]="MESSAGES.FIELD_STUDENT_NOTE_PLACEHOLDER"
                [control]="form.get(FORM_FIELDS.STUDENT_NOTE)">
              </mg-form-text-input>
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="space-between center">
              <div
                fxLayout="row"
                fxLayoutGap="4px">
                <mg-text
                  variant="body-md"
                  [color]="
                    (consequenceOptions$
                    | async
                    | getDueDateActiveFromId
                      : form.get(FORM_FIELDS.CONSQUENCE_TO_ASSIGN).value)
                      ? 'surface'
                      : 'on-disabled-bg'
                  ">
                  {{ MESSAGES.FIELD_DAYS_TO_COMPLETED }}
                </mg-text>
                <mg-tooltip
                  [label]="
                    (consequenceOptions$
                    | async
                    | getDueDateActiveFromId
                      : form.get(FORM_FIELDS.CONSQUENCE_TO_ASSIGN).value)
                      ? MESSAGES.FIELD_DAYS_TO_COMPLETED_TOOLTIP
                      : MESSAGES.FIELD_DAYS_TO_COMPLETED_TOOLTIP_DISABLED
                  ">
                </mg-tooltip>
              </div>
              <div class="number-dropdown">
                <mg-form-text-input
                  inputType="number"
                  min="0"
                  [isDisabled]="
                    !(
                      consequenceOptions$
                      | async
                      | getDueDateActiveFromId
                        : form.get(FORM_FIELDS.CONSQUENCE_TO_ASSIGN).value
                    )
                  "
                  [control]="form.get(FORM_FIELDS.DAYS_TO_COMPLETED)">
                </mg-form-text-input>
              </div>
            </div>
          </div>
        </fieldset>

        <hr />

        <fieldset>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <mg-form-toggle
              [id]="'pbis-auto-edit-click-repeat'"
              [label]="MESSAGES.FIELD_REPEAT_AUTOMATION"
              [control]="form.get(FORM_FIELDS.REPEAT_AUTOMATION)">
            </mg-form-toggle>
            <div *ngIf="form.get(FORM_FIELDS.REPEAT_AUTOMATION).value === true">
              <div
                fxLayout="column"
                fxLayoutAlign="start stretch"
                fxLayoutGap="12px">
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center">
                  <div>
                    <mg-text variant="body-md">
                      {{ MESSAGES.FIELD_REPEAT_FREQUENCY }}
                    </mg-text>
                  </div>
                  <div>
                    <mg-form-text-input
                      inputType="number"
                      min="1"
                      [control]="form.get(FORM_FIELDS.REPEAT_FREQUENCY)"
                      [suffix]="
                        form.get(FORM_FIELDS.REPEAT_FREQUENCY).value > 1
                          ? 'behaviors'
                          : 'behavior'
                      ">
                    </mg-form-text-input>
                  </div>
                </div>
                <div>
                  <mg-text
                    variant="body-md"
                    spacing="3">
                    {{ MESSAGES.FIELD_REPEAT_END }}
                  </mg-text>
                  <mat-radio-group
                    [formControl]="$any(form.get(FORM_FIELDS.REPEAT_END))">
                    <div
                      fxLayout="column"
                      fxLayoutAlign="start stretch"
                      fxLayoutGap="8px">
                      <div>
                        <mat-radio-button
                          [attr.data-analytics]="
                            'pbis-auto-edit-click-end-never'
                          "
                          [value]="RepeatEnd.NEVER">
                          <mg-text variant="body-md">
                            {{ RepeatEnd.NEVER }}
                          </mg-text></mat-radio-button
                        >
                      </div>
                      <div>
                        <div
                          fxLayout="row"
                          fxLayoutAlign="space-between center">
                          <div>
                            <mat-radio-button
                              [attr.data-analytics]="
                                'pbis-auto-edit-click-end-after'
                              "
                              [value]="RepeatEnd.AFTER">
                              <mg-text variant="body-md">
                                {{ RepeatEnd.AFTER }}
                              </mg-text>
                            </mat-radio-button>
                          </div>
                          <div>
                            <mg-form-text-input
                              inputType="number"
                              min="2"
                              [isDisabled]="
                                form.get(FORM_FIELDS.REPEAT_END).value !==
                                RepeatEnd.AFTER
                              "
                              [control]="
                                form.get(FORM_FIELDS.ADDITIONAL_BEHAVIORS)
                              "
                              suffix="occurences">
                            </mg-form-text-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <hr class="action-separator" />

        <fieldset>
          <footer
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <div>
              <mg-btn
                [variant]="(isNew$ | async) === true ? 'text' : 'destructive'"
                (pressed)="confirmDelete()">
                {{ deleteLabel }}
              </mg-btn>
            </div>
            <div>
              <mg-btn
                attrType="submit"
                attrFormId="automations-edit-step-form"
                variant="outlined"
                [disabled]="!canSubmit">
                {{ submitLabel }}
              </mg-btn>
            </div>
          </footer>
        </fieldset>
      </div>
    </form>
  </div>
</ng-template>
