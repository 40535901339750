import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { QuickFilterType } from 'minga/app/src/app/components/CalendarQuickFilter';
import { CalendarScheduleView } from 'minga/app/src/app/components/CalendarSchedule/types';
import { Group } from 'minga/app/src/app/groups/models';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { EventContentService } from 'minga/app/src/app/minimal/services/EventContent';
import {
  EventFeed,
  EventFeedOptions,
  IEventFeedItem,
  toEventFeedItem,
} from 'minga/app/src/app/services/EventFeed';
import { UserStorage } from 'minga/app/src/app/services/UserStorage';
import { EventStatus } from 'minga/domain/event';
import { day } from 'minga/libraries/day';
import { Cancellable } from 'minga/libraries/shared/cancellable/cancellable';
import { isMultiDay } from 'minga/libraries/util';
import { ContentEvents } from 'src/app/minimal/services/ContentEvents';

export interface IEventCalendarFeedItem extends IEventFeedItem {
  eventStatus: EventStatus;
}

export interface IEventCalendarSettings {
  calendarView: CalendarScheduleView;
  excludeWeekend: boolean;
  groupHashes?: string[];
  quickFilter?: QuickFilterType;
  showPastEvents?: boolean;
  showCalendarSettings?: boolean;
  category?: string;
}

function defaultCalendarSettings(): IEventCalendarSettings {
  return {
    calendarView: 'week',
    excludeWeekend: false,
    showCalendarSettings: true,
  };
}

function filterEvent(
  event: Readonly<IEventCalendarFeedItem>,
  settings: Readonly<IEventCalendarSettings>,
): boolean {
  if (settings.groupHashes && settings.groupHashes.length > 0) {
    if (event.shortCard) {
      const groupHash = event.shortCard.ownerGroupHash;
      if (!settings.groupHashes.includes(groupHash)) {
        return false;
      }
    }
  }
  if (settings.category && event.shortCard) {
    if (event.shortCard.category != settings.category) {
      return false;
    }
  }

  if (settings.quickFilter) {
    if (settings.quickFilter == 'My Events') {
      // Statuses that make an event 'my' event
      const myEventsStatuses = [
        EventStatus.CHECKED_IN,
        EventStatus.CHECKED_OUT,
        EventStatus.GOING,
        EventStatus.INVITED,
        EventStatus.INTERESTED,
      ];
      return myEventsStatuses.includes(event.eventStatus);
    } else if (settings.quickFilter == EventStatus.NONE) {
      return true;
    } else if (event.eventStatus == settings.quickFilter) {
      return true;
    }
    return false;
  }
  return true;
}

function fromCacheItem(cachedEvent: any): IEventFeedItem {
  return {
    ...cachedEvent,
    start: cachedEvent.start ? new Date(cachedEvent.start) : null,
    end: cachedEvent.end ? new Date(cachedEvent.end) : null,
  };
}

function toCacheItem(event: Readonly<IEventFeedItem>): any {
  // Caching minimal information in local storage for next load
  const cachedEvent: any = { ...event };
  // eventStatus is tracked in other places and caching it might have some
  // adverse effects
  delete cachedEvent.eventStatus;
  // Short card has a bit too much to keep around
  delete cachedEvent.shortCard;
  if (cachedEvent.start) {
    cachedEvent.start = cachedEvent.start.getTime();
  }
  if (cachedEvent.end) {
    cachedEvent.end = cachedEvent.end.getTime();
  }
  return cachedEvent;
}

@Injectable({ providedIn: 'root' })
export class EventCalendarService {
  /** @internal */
  private _fetchInst: Cancellable<Observable<IEventFeedItem[]>> | null = null;
  /** @internal */
  private _eventsSubj: Subject<IEventFeedItem[]>;
  /** @internal Do not modify or expose */
  private _events: IEventFeedItem[] = [];
  /** @internal */
  private _settingsSubj: BehaviorSubject<IEventCalendarSettings>;
  /** @internal */
  private _lastFetch: Date | null = null;

  /** Consider using a date range instead of a single date. */
  private _activeDate: Date = new Date();
  private _calendarView?: CalendarScheduleView;

  /** Fetched start and end help us determine if we need to fetch again. */
  private _fetchedStart: Date | null = null;
  private _fetchedEnd: Date | null = null;

  private _forceUpdate: boolean = true;

  private myGroups: Group[] = [];

  readonly settings$: Observable<Readonly<IEventCalendarSettings>>;

  /**
   * All events
   */
  readonly events$: Observable<ReadonlyArray<Readonly<IEventCalendarFeedItem>>>;

  /**
   * Events filtered by calendar settings
   */
  readonly filteredEvents$: Observable<
    ReadonlyArray<Readonly<IEventCalendarFeedItem>>
  >;

  constructor(
    private eventFeed: EventFeed,
    private userStorage: UserStorage,
    private eventContentService: EventContentService,
    private groupsFacadeService: GroupsFacadeService,
    private _contentEvents: ContentEvents,
  ) {
    this._eventsSubj = new ReplaySubject<IEventFeedItem[]>();
    this._settingsSubj = new BehaviorSubject<IEventCalendarSettings>(
      defaultCalendarSettings(),
    );
    this.settings$ = this._settingsSubj.asObservable().pipe(shareReplay());

    this.events$ = this._eventsSubj.asObservable().pipe(
      map(items => {
        const toUpdate: IEventFeedItem[] = [];
        for (const event of items) {
          if (isMultiDay(event.start, event.end, event.allDay)) {
            let copy: IEventFeedItem = { ...event };
            const date = new Date(event.start);
            date.setDate(date.getDate() + 1);
            date.setHours(0, 0);
            copy.end = new Date(date);
            // Push the copy now as the first of the events
            toUpdate.push(copy);
            while (date < event.end) {
              copy = { ...copy };
              copy.start = new Date(date);
              date.setDate(date.getDate() + 1);
              if (date < event.end) {
                // Push the copy if we are going to have another day after this
                copy.allDay = true;
                copy.end = new Date(date);
                toUpdate.push(copy);
              }
            }
            // Push the last copy with the original end
            copy.allDay = event.allDay;
            copy.end = new Date(event.end);
            toUpdate.push(copy);
          } else {
            toUpdate.push(event);
          }
        }
        const resultItems: Observable<IEventCalendarFeedItem>[] = toUpdate.map(
          item => {
            const obs = eventContentService
              .observeCheckIn(item.contentContext)
              .pipe(
                map(eventStatus => {
                  const feedItem: IEventCalendarFeedItem = {
                    ...item,
                    eventStatus,
                  };
                  return feedItem;
                }),
              );

            return obs;
          },
        );
        return resultItems;
      }),
      switchMap(itemObs => combineLatest(itemObs)),
      shareReplay(),
    );
    this.filteredEvents$ = combineLatest([this.events$, this.settings$]).pipe(
      map(([events, settings]) =>
        events.filter(event => filterEvent(event, settings)),
      ),
    );

    // commenting out for now, but may want to re-enable depending on
    // performance
    /*  this.userStorage.getItem('calendar_cache') .then(cachedItems
    => { this._events = ((cachedItems as any) || []).map(fromCacheItem);
        this._eventsSubj.next(this._events);
      })
      .catch(err => console.error(err));

  */
    this.resetSettingsToDefault();

    // we want to track what groups the user is in, because if it changes
    // we will need to do another fetch to make sure the new groups events
    // are viewable now.
    this.groupsFacadeService.getMyGroups().subscribe((groups: Group[]) => {
      this.myGroups = groups;
      this._forceUpdate = true;
    });

    _contentEvents.onContentFeedsUpdate.subscribe(() => {
      this._forceUpdate = true;
      this.fetch();
    });
  }

  async updateSettings(
    settings: Readonly<IEventCalendarSettings>,
    saveLocally: boolean = false,
    noFetch: boolean = false,
  ) {
    const currentSettings = this.getCurrentSettingsValue();
    // if the group selected has changed, then lets force an update.
    if (
      !_.isEqual(currentSettings.groupHashes, settings.groupHashes) ||
      !_.isEqual(currentSettings.category, settings.category)
    ) {
      this._forceUpdate = true;
    }
    this._settingsSubj.next({ ...settings });

    if (saveLocally) {
      try {
        await this.userStorage.setItem('calendar_settings', {
          calendarView: settings.calendarView,
          excludeWeekend: settings.excludeWeekend,
        });
      } catch (err) {
        console.error(err);
      }
    }
    if (this._forceUpdate && !noFetch) {
      this.fetch();
    }
  }

  getCurrentSettingsValue() {
    return this._settingsSubj.getValue();
  }

  async resetSettingsToDefault() {
    await this.userStorage
      .getItem<IEventCalendarSettings>('calendar_settings')
      .then(settings => {
        if (!settings) {
          settings = defaultCalendarSettings();
        }
        const currentValue = this.getCurrentSettingsValue();

        // only two things coming from local storage, use
        // whatever has been set alaready at this point
        // for other values.
        let newSettings: IEventCalendarSettings = {
          ...currentValue,
          calendarView: settings.calendarView,
          excludeWeekend: settings.excludeWeekend,
        };
        this._settingsSubj.next(newSettings);
      })
      .catch(err => {
        console.error(err);
        // use default settings when there's an error
        this._settingsSubj.next(defaultCalendarSettings());
      });
  }

  /**
   *
   * @returns The date the last time a fetch was done
   */
  getLastFetch(): Date {
    if (this._lastFetch) return new Date(this._lastFetch);
    return new Date(0);
  }

  shouldFetch(activeDate: Date): boolean {
    if (!this._fetchedStart || !this._fetchedEnd) {
      return true;
    }

    const startDate = this._getNewFetchStart(activeDate);
    const endDate = this._getNewFetchEnd(activeDate);

    if (
      startDate.getTime() != this._fetchedStart.getTime() ||
      endDate.getTime() != this._fetchedEnd.getTime()
    ) {
      return true;
    }
    return false;
  }

  /**
   * Get the lesser between the last fetched start date and the newly requested
   * date, to see if we should fetch again.
   * @param activeDate
   * @returns
   */
  _getNewFetchStart(activeDate: Date): Date {
    const date = day(activeDate).startOf('month');
    const startDate = date.subtract(1, 'month');

    if (!this._fetchedStart) {
      return startDate.toDate();
    }

    const lastFetchedStart = day(this._fetchedStart).startOf('month');

    // if the startDate is greater than what we've already fetched
    // we don't need to fetch again.
    if (lastFetchedStart < startDate) {
      return lastFetchedStart.toDate();
    }

    // start date was less, so we need to fetch again.

    return startDate.toDate();
  }

  /**
   * Get the bigger between the last fetched end date and the newly requested
   * date, to see if we should fetch again.
   * @param activeDate
   * @returns
   */
  _getNewFetchEnd(activeDate: Date): Date {
    const date = day(activeDate).startOf('month');
    const endDate = day(activeDate).endOf('month');

    if (!this._fetchedEnd) {
      return endDate.toDate();
    }

    const lastFetchedEnd = day(this._fetchedEnd).startOf('month');

    // if the end date is less than what we've already fetched
    // we don't need to fetch again.
    if (lastFetchedEnd > endDate) {
      return lastFetchedEnd.toDate();
    }

    // end date was greater, so we need to fetch again.

    return endDate.toDate();
  }

  /** Fetch events for the specified date range. */
  async fetch() {
    const startDate =
      this._calendarView === 'week'
        ? day(this._activeDate).startOf('week')
        : day(this._activeDate).startOf('month');
    const endDate =
      this._calendarView === 'week'
        ? day(this._activeDate).endOf('week')
        : day(this._activeDate).endOf('month');

    const currentSettings = this.getCurrentSettingsValue();
    const events = await this.eventContentService.getEventsAsStreamItem(
      currentSettings,
      undefined,
      undefined,
      startDate.toDate(),
      endDate.toDate(),
    );
    const eventItems = events.map(event => toEventFeedItem(event.item));

    this._events = eventItems;
    this._eventsSubj.next(eventItems);
  }

  fetchIfNeeded() {
    if (!this._fetchInst) {
      this.fetch();
    }
  }

  isLastFetchOld(): boolean {
    const lastFetch = day(this.getLastFetch());
    const now = day();
    if (lastFetch) {
      // fetch should expire if it was last done more than 2 mins ago.
      if (now.isAfter(lastFetch.add(2, 'minute'))) {
        return true;
      }
    }
    return false;
  }

  /** ActiveDate might be better as a DateRange instead of a single date. */
  setActiveDate(date: Date, view?: CalendarScheduleView) {
    this._activeDate = new Date(date);
    this._calendarView = view;
    this.fetch();
  }

  setQuickFilter(filter: QuickFilterType) {
    const settings = this.getCurrentSettingsValue();
    settings.quickFilter = filter;
    this.updateSettings(settings);
  }

  // store whether to use calendar settings or list settings view.
  setUseCalendarSettings(value: boolean) {
    const settings = this.getCurrentSettingsValue();
    const newSettings = { ...settings };
    newSettings.showCalendarSettings = value;
    this.updateSettings(newSettings);
  }

  setForceUpdate() {
    this._forceUpdate = true;
  }
}
