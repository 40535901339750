<mat-button-toggle-group
  class="filter-toggle-group"
  name="tabToggle"
  [multiple]="false">
  <mat-button-toggle
    *ngFor="let stat of primaryStatFilters"
    class="toggle-button"
    routerLinkActive="mat-button-toggle-checked"
    (click)="toggleFilter(stat)">
    <div
      fxLayout="row"
      fxLayoutGap="4px">
      <mg-text variant="body-sm-secondary">
        {{ stat.title }}
      </mg-text>
      <mg-text
        *ngIf="!globalContent && stat.searchFilter !== SEARCH_FILTER_TYPE.ALL"
        variant="body-sm-secondary"
        fontWeight="900">
        · {{ stat.countText || stat.total }}
      </mg-text>
    </div>
  </mat-button-toggle>
</mat-button-toggle-group>

<div class="feed-items">
  <mg-stream-scroller
    *ngIf="contentFilter"
    [filter]="contentFilter"
    [service]="adminModerationManager"
    rpcStream="streamDetailedModerationResults"
    rpcControl="streamDetailedModerationResultsControl"
    mgStreamControl="MingaModerationFeed"
    [clearOnRestart]="true"
    [bufferAmount]="20">
    <ng-template
      let-item
      let-itemId="itemId"
      mgStreamItem>
      <mg-detailed-moderation-item
        [content]="item"
        [showMinga]="globalContent"
        (openContentCallback)="onOpenContent($event)"
        (openImagesCallback)="onOpenImages($event)"
        [showLongDateFormat]="showLongFormatDateComment">
      </mg-detailed-moderation-item>
    </ng-template>

    <p
      mg-stream-empty
      class="mg-centered mg-minicopy">
      {{ noResultsText }}
    </p>
  </mg-stream-scroller>
</div>
