import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';

import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { CheckinReasonType, ICheckinReason } from 'minga/domain/checkin';

import { CheckinService } from '@shared/services/checkin';

import { CheckinTypesMessages } from './checkin-manager-reasons.constants';

/**
 * Check In / Check Out Types
 *
 * Also known as "reasons"
 */
@Component({
  selector: 'mg-checkin-manager-reasons',
  templateUrl: './checkin-manager-reasons.component.html',
  styleUrls: ['./checkin-manager-reasons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinManagerReasonsComponent implements OnDestroy {
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Messages */
  public readonly MESSAGES: typeof CheckinTypesMessages = CheckinTypesMessages;

  /** Reasons / Types */
  public types$: Observable<ICheckinReason[]>;

  /** General Variables */
  loadingTypes = true;

  onNewReasonCreated: Subject<ICheckinReason> = new Subject<ICheckinReason>();

  /** Component Constructor */
  constructor(
    private _checkinService: CheckinService,
    private _cdr: ChangeDetectorRef,
  ) {
    this.fetchReasons();
    this.types$
      .pipe(
        takeUntil(this._destroyed$),
        tap(() => {
          this.loadingTypes = true;
          this._cdr.markForCheck();
        }),
      )
      .subscribe(d => {
        this.loadingTypes = false;
        this._cdr.markForCheck();
      });
  }

  /** Component Lifecyle: On Unmount */
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Fetch Reasons
   */
  public async fetchReasons(args?: any): Promise<void> {
    this.types$ = this._checkinService.getReasonsObs(false);
  }

  /**
   * Handle On Click Create New
   */
  public async handleOnClickCreateNew(): Promise<void> {
    const newReason: ICheckinReason = {
      name: '',
      mingaId: 0,
      active: false,
      pointReward: 0,
      icon: 'checkin-1',
      showAbsentees: false,
      selfCheckIn: false,
      stickerIds: [],
      roles: [],
      checkinReasonType: CheckinReasonType.CHECKIN,
    };

    this.onNewReasonCreated.next(newReason);
    this._cdr.markForCheck();
  }
}
