<div class="settings">
  <!-- Enable Hall Passes Settings -->
  <section class="section">
    <mg-setting-toggle
      variant="header-md-secondary"
      [id]="'hallpass-settings-click-enable-hp'"
      [showWarning]="true"
      [warningTitle]="
        hallPassesEnabled
          ? MESSAGES.SETTING_ENABLE_HALLPASS_TITLE_DISABLING
          : MESSAGES.SETTING_ENABLE_HALLPASS_TITLE_ENABLING
      "
      [warningBody]="
        hallPassesEnabled
          ? MESSAGES.SETTING_ENABLE_HALLPASS_BODY_DISABLING
          : MESSAGES.SETTING_ENABLE_HALLPASS_BODY_ENABLING
      "
      [setting]="hallPassSettings?.PASS_HALLPASS_ENABLE"
      [label]="MESSAGES.SETTING_ENABLE_HALLPASS"
      (valueChange)="showSnackbar($event)">
    </mg-setting-toggle>
  </section>

  <!-- Membership List Settings -->
  <section class="section">
    <mg-membership-list-table
      *ngIf="'MINGA_ACCOUNT_MANAGE' | hasPermission"
      [hideHeader]="true"
      [autoCreate]="true"
      [types]="LIST_TYPES">
    </mg-membership-list-table>
  </section>

  <!-- General Settings -->
  <section class="section">
    <mg-text
      variant="header-md-secondary"
      spacing="1">
      {{ MESSAGES.SECTION_TITLE_GENERAL }}
    </mg-text>
    <mg-setting-select
      selectSize="small"
      [disabled]="!hallPassesEnabled"
      [label]="MESSAGES.SETTING_MUST_MANUALLY_END"
      [setting]="hallPassSettings.PASS_MUST_MANUALLY_END"
      [options]="END_PASS_OPTIONS">
    </mg-setting-select>
    <mg-setting-number-textbox
      [setting]="hallPassSettings?.PASS_TIMEOUT"
      [label]="MESSAGES.SETTING_TIMEOUT"
      [disabled]="!hallPassesEnabled"
      [min]="0"
      [required]="true"
      [helpText]="MESSAGES.HELP_TIMEOUT">
    </mg-setting-number-textbox>
    <mg-setting-toggle
      [helpTooltip]="MESSAGES.HELP_SHOW_RECENTLY_ENDED"
      [setting]="hallPassSettings?.PASS_SHOW_RECENTLY_ENDED"
      [label]="MESSAGES.SETTING_SHOW_RECENTLY_ENDED"
      [disabled]="!hallPassesEnabled">
    </mg-setting-toggle>
    <ng-container *ngIf="settings$ | async as settings">
      <div
        *ngIf="settings[hallPassSettings?.PASS_SHOW_RECENTLY_ENDED]"
        class="indent">
        <mg-setting-number-textbox
          [setting]="hallPassSettings.PASS_RECENTLY_ENDED_FILTER"
          [disabled]="!hallPassesEnabled"
          [label]="MESSAGES.SETTING_RECENTLY_ENDED_FILTER"
          [min]="0"
          [required]="true"
          [maxNumDigits]="3">
        </mg-setting-number-textbox>
      </div>
    </ng-container>
    <mg-setting-toggle
      [setting]="hallPassSettings?.PASS_ALLOW_NOTE"
      [label]="MESSAGES.SETTING_ALLOW_NOTE"
      [disabled]="!hallPassesEnabled">
    </mg-setting-toggle>
    <mg-setting-toggle
      *ngIf="kioskPermissions.isKioskFeatureEnabled()"
      [id]="'hallpass-settings-click-kiosk'"
      [setting]="hallPassSettings?.PASS_HALLPASS_KIOSK"
      [label]="MESSAGES.SETTING_ENABLE_KIOSK"
      [disabled]="!hallPassesEnabled"
      (valueChange)="openDisclaimer($event)">
    </mg-setting-toggle>
  </section>

  <!-- Hall Pass Limit Settings -->
  <section class="section">
    <mg-text
      variant="header-md-secondary"
      spacing="1">
      {{ MESSAGES.SECTION_TITLE_PASS_LIMIT }}
    </mg-text>
    <mg-setting-number-textbox
      [setting]="hallPassSettings.PASS_MAX_PASSES"
      [label]="MESSAGES.SETTING_MAX_PASSES"
      [disabled]="!hallPassesEnabled"
      [min]="0"
      [required]="true">
    </mg-setting-number-textbox>
    <mg-setting-number-textbox
      [setting]="hallPassSettings.PASS_MAX_ACTIVE_PASSES"
      [label]="MESSAGES.SETTING_MAX_ACTIVE_PASSES"
      [helpText]="MESSAGES.HELP_MAX_ACTIVE_PASSES"
      [disabled]="!hallPassesEnabled"
      [min]="0"
      [required]="true"
      [noCap]="true">
    </mg-setting-number-textbox>
  </section>

  <!-- Hall Pass Request Settings -->
  <section class="section">
    <mg-text
      variant="header-md-secondary"
      spacing="1">
      {{ MESSAGES.SECTION_TITLE_REQUEST_TIMEOUT }}
    </mg-text>
    <mg-setting-number-textbox
      [setting]="hallPassSettings.PASS_APPROVAL_REQUEST_TIMEOUT_DURATION"
      [label]="MESSAGES.SETTING_REQUEST_DURATION_STUDENT"
      [disabled]="!hallPassesEnabled"
      [min]="0"
      [required]="true">
    </mg-setting-number-textbox>

    <mg-setting-number-textbox
      [setting]="hallPassSettings.PASS_APPROVAL_REQUEST_TIMEOUT_DURATION_STAFF"
      [label]="MESSAGES.SETTING_REQUEST_DURATION_STAFF"
      [disabled]="!hallPassesEnabled"
      [min]="1"
      [required]="true">
    </mg-setting-number-textbox>
  </section>

  <!-- Teacher / Staff Settings -->
  <section class="section">
    <mg-text
      variant="header-md-secondary"
      spacing="1">
      {{ MESSAGES.SECTION_TITLE_TEACHER_STAFF }}
    </mg-text>
    <mg-setting-toggle
      [id]="'hallpass-settings-click-teacher-assign'"
      [setting]="hallPassSettings?.PASS_TEACHERS_GRANT"
      [label]="MESSAGES.SETTING_TEACHERS_GRANT"
      [disabled]="!hallPassesEnabled">
    </mg-setting-toggle>
    <mg-setting-toggle
      [id]="'hallpass-settings-click-staff-assign'"
      [setting]="hallPassSettings?.PASS_STAFF_GRANT"
      [label]="MESSAGES.SETTING_STAFF_GRANT"
      [disabled]="!hallPassesEnabled">
    </mg-setting-toggle>
    <mg-setting-toggle
      [id]="'hallpass-settings-click-staff-report'"
      [setting]="hallPassSettings?.PASS_TEACHER_VIEW_REPORTS"
      [label]="MESSAGES.SETTING_TEACHER_VIEW_REPORTS"
      [disabled]="!hallPassesEnabled">
    </mg-setting-toggle>
    <mg-setting-toggle
      [id]="'hallpass-settings-click-staff-bypass'"
      [setting]="hallPassSettings?.PASS_STAFF_BLACKOUT_BYPASS"
      [label]="MESSAGES.SETTING_TEACHERS_BYPASS_BLACKOUT"
      [disabled]="!hallPassesEnabled">
    </mg-setting-toggle>
  </section>

  <!-- Student Settings -->
  <section class="section">
    <mg-text
      variant="header-md-secondary"
      spacing="1">
      {{ MESSAGES.SECTION_TITLE_STUDENT }}
    </mg-text>
    <mg-setting-toggle
      [id]="'hallpass-settings-click-student-created'"
      [setting]="hallPassSettings?.PASS_STUDENTS_GRANT"
      [label]="MESSAGES.SETTING_STUDENTS_GRANT"
      [disabled]="!hallPassesEnabled">
    </mg-setting-toggle>
    <ng-container *ngIf="settings$ | async as settings">
      <div
        *ngIf="settings[hallPassSettings?.PASS_STUDENTS_GRANT]"
        class="indent">
        <mg-setting-toggle
          [id]="'hallpass-settings-click-teacher-approved'"
          [disabled]="!hallPassesEnabled"
          [setting]="hallPassSettings?.PASS_ASSIGN_STAFF"
          [label]="MESSAGES.SETTING_ASSIGN_STAFF">
        </mg-setting-toggle>
        <mg-setting-number-textbox
          [disabled]="!hallPassesEnabled"
          [setting]="hallPassSettings.PASS_MAX_STUDENT_PASSES"
          [label]="MESSAGES.SETTING_MAX_STUDENT_PASSES"
          [min]="1"
          [noCap]="true"
          [required]="true">
        </mg-setting-number-textbox>
        <mg-setting-number-textbox
          [disabled]="!hallPassesEnabled"
          [setting]="hallPassSettings.PASS_MAX_ACTIVE_STUDENT_PASSES"
          [label]="MESSAGES.SETTING_MAX_ACTIVE_STUDENT_PASSES"
          [min]="1"
          [noCap]="true"
          [required]="true">
        </mg-setting-number-textbox>
        <mg-setting-number-textbox
          [disabled]="!hallPassesEnabled"
          [setting]="hallPassSettings.PASS_DURATION_STUDENT_PASSES"
          [label]="MESSAGES.SETTING_DEFAULT_STUDENT_PASS_DURATION"
          [min]="1"
          [required]="true">
        </mg-setting-number-textbox>
        <mg-setting-number-textbox
          [disabled]="!hallPassesEnabled"
          [setting]="hallPassSettings.PASS_MIN_WAIT_STUDENT_PASS"
          [label]="MESSAGES.SETTING_MIN_WAIT_BETWEEN_PASSES"
          [min]="0"
          [required]="true">
        </mg-setting-number-textbox>
      </div>
    </ng-container>
    <mg-setting-toggle
      [id]="'hallpass-settings-click-manually-end'"
      [disabled]="!hallPassesEnabled"
      [setting]="hallPassSettings?.PASS_STUDENTS_END_PASSES"
      [label]="MESSAGES.SETTING_STUDENTS_END_PASSES">
    </mg-setting-toggle>
    <ng-container *ngIf="settings$ | async as settings">
      <div
        *ngIf="settings[hallPassSettings?.PASS_STUDENTS_END_PASSES]"
        class="indent">
        <mg-setting-toggle
          [id]="'hallpass-settings-click-mobile-end'"
          [disabled]="!hallPassesEnabled"
          [setting]="hallPassSettings?.PASS_STUDENTS_END_PASSES_FROM_MOBILE"
          [label]="MESSAGES.SETTING_STUDENTS_END_PASSES_FROM_MOBILE">
        </mg-setting-toggle>
      </div>
    </ng-container>
  </section>
</div>
