import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import * as _ from 'lodash';
import * as Mark from 'mark.js';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import {
  ContentEvents,
  ICommentSuccessEvent,
  IContentDeletedEvent,
} from 'minga/app/src/app/minimal/services/ContentEvents';
import { LikeService } from 'minga/app/src/app/minimal/services/Like';
import { CommentHandlerService } from 'minga/app/src/app/services/CommentHandler';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import {
  mgResolveImageUrl,
  mgResolveImageUrls,
} from 'minga/app/src/app/util/asset';
import { Color } from 'minga/app/src/app/util/color';
import { getImageInfoSize } from 'minga/app/src/app/util/image';
import { numeral } from 'minga/app/src/app/util/numeral';
import { day } from 'minga/libraries/day';
import {
  FeedComment,
  ShortCardView,
} from 'minga/proto/gateway/content_views_pb';
import { ImageInfo, ImageSize } from 'minga/proto/image/image_pb';

export interface IMgShortCardElementProperties {
  content?: ShortCardView.AsObject;
  context?: string;
}

@Component({
  selector: 'mg-short-card-byline',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgShortCardSlot_Byline {}

@Component({
  selector: 'mg-short-card-featured-image',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgShortCardSlot_FeaturedImage {}

@Component({
  selector: 'mg-short-card-group-name',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgShortCardSlot_GroupName {}

@Component({
  selector: 'mg-short-card-title',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgShortCardSlot_Title {}

@Component({
  selector: 'mg-short-card-body',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgShortCardSlot_Body {}

@Component({
  selector: 'mg-short-card-action-bar',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgShortCardSlot_ActionBar {}

@Component({
  selector: 'mg-short-card-comment-list',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgShortCardSlot_CommentList {}

@Component({
  selector: 'mg-short-card',
  templateUrl: './MgShortCard.element.html',
  styleUrls: ['./MgShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgShortCardElement
  implements IMgShortCardElementProperties, OnInit, OnDestroy
{
  readonly imageSizes = ['blurloading1', ['cardbanner', 'raw']];

  @ContentChild(MgShortCardSlot_Byline, { static: false })
  bylineSlot?: MgShortCardSlot_Byline;

  @ContentChild(MgShortCardSlot_FeaturedImage, { static: false })
  featuredImageSlot?: MgShortCardSlot_FeaturedImage;

  @ContentChild(MgShortCardSlot_GroupName, { static: false })
  groupNameSlot?: MgShortCardSlot_GroupName;

  @ContentChild(MgShortCardSlot_Title, { static: false })
  titleSlot?: MgShortCardSlot_Title;

  @ContentChild(MgShortCardSlot_Body, { static: false })
  bodySlot?: MgShortCardSlot_Body;

  @ContentChild(MgShortCardSlot_ActionBar, { static: false })
  actionBarSlot?: MgShortCardSlot_ActionBar;

  @ContentChild(MgShortCardSlot_CommentList, { static: false })
  commentListSlot?: MgShortCardSlot_CommentList;

  @Input()
  pinned: boolean;

  @HostBinding('style.backgroundColor')
  @Input()
  backgroundColor: string = '';

  @Input()
  gradientOverlap: boolean = false;

  @Input()
  gradientSize: number = 0;

  @HostBinding('class.light-content')
  @Input()
  lightContent: boolean = false;

  @Input()
  ownerGroupHash: string;

  @Input()
  deleted: boolean = false;

  @Output()
  deletedChange: EventEmitter<boolean>;

  @Input()
  context: string = '';

  @Input()
  whiteactions: boolean = false;

  @Input()
  showFullBody: boolean = false;

  @Input()
  markKeywords: string = '';

  @Input()
  groupName: string = '';

  @Output()
  groupNameChange: EventEmitter<string>;

  @Input()
  showTitleSlot: boolean = false;

  @Input()
  banner?: any;

  @Input()
  title: string = '';

  @Input()
  videoUrl: string = '';

  @Input()
  authorPersonView?: any;

  @Input()
  featuredImage?: ImageInfo.AsObject;

  @Input()
  timestamp: number = 0;

  @Input()
  body: string = '';

  @Input()
  commentList: FeedComment.AsObject[] = [];

  @Output()
  commentListChange: EventEmitter<FeedComment.AsObject[]>;

  /**
   * Top level comment count (no replies)
   */
  @Input()
  commentCount: number = 0;

  @Output()
  commentCountChange: EventEmitter<number>;

  /**
   * Total comment count including replies
   */
  @Input()
  totalCommentCount: number = 0;

  @Output()
  totalCommentCountChange: EventEmitter<number>;

  @Input()
  likeCount: number = 0;

  @Input()
  imageCount: number = 0;

  @Input()
  commentRoles: string[] | null = null;

  @Input()
  content: any;

  @HostBinding('class.has-bg-color')
  get hasBgColor(): boolean {
    return !!this.backgroundColor;
  }

  @HostBinding('class.has-gradient-overlay')
  get hasGradientOverlay() {
    return this.hasBgColor && !!this.gradientOverlap;
  }

  get hasFeaturedImage() {
    return !!this.featuredImage;
  }

  get previousCommentCount() {
    return this.commentCount - this.commentList.length;
  }

  _bannerImageUrl: string = '';

  // @TODO: Set this to a base type that event, post and video can all derive
  //        from. Used to be: `ShortCardView.AsObject`
  assetUrls: string[] = [];

  allowSingleClick: boolean = false;
  clickTarget: any;
  disableNoClick: boolean = false;
  actionsclass: string = '';

  canComment$: Observable<boolean>;
  private _commentDataChanged: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );
  private _commentDataChanged$: Observable<boolean>;

  private _markInstance?: Mark;

  private _contentDeletedSub?: Subscription;
  private _onCardViewUpdateSub?: Subscription;
  private _onContentUnpinSub?: Subscription;
  private _onGroupLoadedSub?: Subscription;
  private _onCommentSuccessSub?: Subscription;

  // short card legacy support
  get clientWidth(): number {
    if (this._elementRef && this._elementRef.nativeElement) {
      return this._elementRef.nativeElement.clientWidth;
    }

    return 0;
  }

  get showViewAllComments() {
    return this.commentCount > this.commentList.length;
  }

  constructor(
    public contentevents: ContentEvents,
    public likeservice: LikeService,
    public groupsfacade: GroupsFacadeService,
    public contentstate: ContentState,
    private _elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private commentHandler: CommentHandlerService,
  ) {
    this.totalCommentCountChange = new EventEmitter();
    this.commentListChange = new EventEmitter();
    this.commentCountChange = new EventEmitter();
    this.deletedChange = new EventEmitter();
    this.groupNameChange = new EventEmitter();
    this._commentDataChanged$ = this._commentDataChanged.asObservable();
    this.canComment$ = this._commentDataChanged$.pipe(
      switchMap(() => {
        return this.commentHandler.canCurrentUserComment(
          this.commentRoles,
          this.ownerGroupHash,
        );
      }),
    );
  }

  // Get the targets that the marked keywords should process
  protected getMarkTargets(): ReadonlyArray<HTMLElement> | NodeList | null {
    return (<any>this).shadowRoot ? [(<any>this).shadowRoot] : null;
  }

  private _updateMarkedKeywords(
    markedKeywords: string[],
    markTargets: ReadonlyArray<HTMLElement> | NodeList,
  ) {
    if (this._markInstance) {
      this._markInstance.unmark({
        caseSensitive: false,
        accuracy: 'partially',
      });
    }

    this._markInstance = new Mark(markTargets);
    this._markInstance.mark(markedKeywords, {
      caseSensitive: false,
      accuracy: 'partially',
    });
  }

  /**
   * Handle new group hashes, remove any prior subscriptions and subscribe to
   * the new group for the name.
   * @param newValue  new group hash string
   */
  private _ownerGroupHashChanged(newValue) {
    // unsubscribe from the old
    if (this._onGroupLoadedSub) {
      this._onGroupLoadedSub.unsubscribe();
    }
    // reset the group name
    this.groupName = '';

    const currentGroupContext = this.groupsfacade.getCurrentGroupHash();

    if (newValue && !currentGroupContext) {
      // subscribe to all groups first to load them before getting group name
      this._onGroupLoadedSub = this.groupsfacade
        .getGroup(newValue)
        .subscribe(async group => {
          if (group) {
            // update the group name property
            this.groupName = group.name;
            this.groupNameChange.emit(group.name);
            this.cdr.markForCheck();
          }
        });
    }
  }

  shouldShowGroupLinkSlot() {
    return !!this.groupName;
  }

  updateMarkedKeywords(markedKeywordsStr: string = this.markKeywords) {
    const markedKeywords = markedKeywordsStr.split(/\s+/g).filter(a => !!a);

    if (this._markInstance) {
      this._markInstance.unmark({
        caseSensitive: false,
        accuracy: 'partially',
      });
    }

    if (markedKeywords.length > 0) {
      const markTargets = this.getMarkTargets();

      if (markTargets && markTargets.length > 0) {
        // Because we have several dom-if's we need to wait for them to be
        // available. Unsure if this is robust.
        setTimeout(() => {
          this._updateMarkedKeywords(markedKeywords, markTargets);
        }, 0);
      }
    }
  }

  private _gradientSize(size: number) {
    const gradientSize = this.gradientSize || 0;
    return (gradientSize * size - 1) * -1 * 100 + '%';
  }

  _bannerGradientStyle(size: number) {
    const hasBgColor = !!this.backgroundColor;
    const hasGradientOverlap = hasBgColor && !!this.gradientOverlap;

    const style: any = {};
    if (hasGradientOverlap) {
      const bgColorObj = new Color(this.backgroundColor);
      const transBgColor = bgColorObj.alpha(0).toString();

      style[
        'background'
      ] = `linear-gradient(${transBgColor}, ${this.backgroundColor})`;
    }

    style['top'] = this._gradientSize(size);

    return style;
  }

  _contextChanged() {
    this.updateMarkedKeywords();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.context) {
      this._contextChanged();
    }

    if (changes.commentRoles || changes.ownerGroupHash) {
      this._commentDataChanged.next(true);
    }
  }

  ngOnInit() {
    this._ownerGroupHashChanged(this.ownerGroupHash);

    this._onCommentSuccessSub = this.contentevents.onCommentSuccess.subscribe(
      ev => this.onCommentSuccess(ev),
    );

    this._contentDeletedSub = this.contentevents.onContentDeleted.subscribe(
      ev => this._onContentDeleted(ev),
    );

    if (this.content) {
      if (this.content.hasLiked) {
        this.likeservice.setLike(this.context);
      }
    }
  }

  private onCommentSuccess(ev: ICommentSuccessEvent) {
    if (ev.replyContentContextHash == this.context) {
      this.totalCommentCount += 1;
      this.totalCommentCountChange.emit(this.totalCommentCount);
      const currentPerson = this.auth.getPersonViewMinimalFromCurrentPerson();

      this.commentList.push({
        body: ev.newCommentBody,
        bodyMentionRangeList: [],
        commentContextHash: ev.newCommentContextHash,
        likeCount: 0,
        timestamp: Date.now(),
        displayName: '',
        personView: currentPerson.toObject(),
        hasLiked: false,
      });

      if (this.commentList.length > 2) {
        this.commentList.shift();
      }

      this.commentCount += 1;
      this.commentCountChange.emit(this.commentCount);
      this.commentListChange.emit(this.commentList);
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this._contentDeletedSub) {
      this._contentDeletedSub.unsubscribe();
    }

    if (this._onCardViewUpdateSub) {
      this._onCardViewUpdateSub.unsubscribe();
    }

    if (this._onContentUnpinSub) {
      this._onContentUnpinSub.unsubscribe();
    }

    if (this._onGroupLoadedSub) {
      this._onGroupLoadedSub.unsubscribe();
    }

    if (this._onCommentSuccessSub) {
      this._onCommentSuccessSub.unsubscribe();
    }
  }

  hasVideo() {
    return !!this.videoUrl;
  }

  hasBannerHeadline() {
    return !!(this.banner && this.banner.headlineOptions);
  }

  getContext(context) {
    return this.context;
  }

  getCommentContext(comment, context) {
    // console.log('getCommentContext comment: ', this);
    return comment.commentContextHash;
  }

  get devicePixelRatio() {
    return Math.round(devicePixelRatio);
  }

  humanize(timestamp) {
    if (typeof timestamp == 'string') {
      timestamp = parseInt(timestamp);
    }

    return day(timestamp).fromNow();
  }

  // Return value in percentage
  protected calcBannerSize(): number {
    const featuredImage = this.featuredImage;

    if (featuredImage) {
      try {
        const size = getImageInfoSize(featuredImage, 'raw');
        if (size && size.height && size.width) {
          return (size.height / size.width) * 100;
        }
      } catch (err) {
        console.error(`[ShortCard] couldn't get image info size: `, err);
      }
    }

    return 0;
  }

  _presentCalcBannerSize() {
    const bannerSize = this.calcBannerSize();
    if (bannerSize) {
      return bannerSize + '%';
    }

    return '';
  }

  getAssetUrl(imageInfo: ImageInfo.AsObject) {
    if (!imageInfo) {
      return '';
    }

    return mgResolveImageUrl(imageInfo);
  }

  numberize(count: number | string) {
    let value = numeral(count).value();

    if (value > 1000) {
      let numberized: string = numeral(value).format('0a');
      return numberized;
    } else {
      return value;
    }
  }

  noClick(e?) {
    if (e && !this.allowSingleClick) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
  }

  groupLinkClick(e) {
    // stop propagation and default
    this.noClick(e);
    // open group page
    this.contentstate.openGroup(this.ownerGroupHash);
  }

  allowClick(e: any) {
    this.allowSingleClick = true;
  }

  moreImageCount() {
    return this.imageCount;
  }

  commentReplyActionClick(e: any) {}

  shouldShowTitleSlot(): boolean {
    return true;
  }

  clickViewComments(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
  }

  private _onContentDeleted(ev: IContentDeletedEvent) {
    if (ev.typeString == 'COMMENT' && ev.parentContextHash == this.context) {
      this.totalCommentCount -= 1;
      this.commentCount -= 1;
      this.totalCommentCountChange.emit(this.totalCommentCount);
    }
  }
}
