import Color from 'color';

/**
 * Overrides the "default" font/background color with a grade specific one depending on the user's grade
 *
 * @param hexColor - A color string in the format of `#aabbcc` or `#abc`
 * @param grade - A string representing the grade
 * @param gradeColors - A record with the grade as the key and an array of two colors as the value
 * @param colorType - specifies which color to return between `font` and `background`
 * @returns The color in the format of `#aabbcc`
 */
export const setGradeSpecificColors = (
  hexColor: string,
  grade: string,
  gradeColors: Record<string, string>,
  colorType: 'font' | 'background',
) => {
  // value is in the format of ['#backgroundColor', '#fontColor']
  const value = gradeColors[grade];
  if (!value) return hexColor;

  const [backgroundColor, fontColor] = value;

  if (colorType === 'font') {
    return fontColor || hexColor;
  } else {
    return backgroundColor || hexColor;
  }
};

/**
 * Converts a hexadecimal color string to an RGB string.
 *
 * @param hexColor - A color string in the format of `#aabbcc` or `#abc`
 * @returns The parsed color in the format of `r, g, b`
 */
export const setHighlightColor = (hexColor: string) => {
  let color = Color(hexColor).hsl();

  // Check if the color is black or very dark
  if (color.luminosity() < 0.01) {
    // Set a lighter grey color for proper color manipulation
    color = Color('#999').rgb();
  }

  const highlightColor = color.isLight()
    ? color.darken(0.3).rgb()
    : color.lighten(0.3).rgb();

  const parsedHighlightColor = `
    ${Math.floor(highlightColor.red())}, 
    ${Math.floor(highlightColor.green())}, 
    ${Math.floor(highlightColor.blue())}`;

  return parsedHighlightColor;
};
