<div
  class="tw-flex tw-flex-column sm:tw-flex-row tw-gap-4 tw-py-2 tw-mb-3 sm:tw-items-center">
  <mg-text
    variant="header-md-secondary"
    class="tw-grow">
    {{ MESSAGES.HEADER }}
  </mg-text>
  <!-- TODO we don't currently support manual creation, terms need to be pulled from SIS
  <mg-btn
    variant="filled"
    (pressed)="openEditModal()">
    {{ MESSAGES.ADD_TERM }}
  </mg-btn> -->
</div>

<div *ngIf="(isLoading$ | async) === false; else loadingTemplate">
  <!-- alerts -->
  <div
    *ngIf="(hasSchedules$ | async) === false && (terms$ | async)?.length > 0"
    class="tw-mb-4">
    <mg-system-alert
      type="default"
      [message]="MESSAGES.NO_SCHEDULES"
      [buttonText]="MESSAGES.NO_SCHEDULES_BUTTON"
      [isAlert]="true"
      [dismissable]="true"
      [routerLink]="'/minga-manager/bell-schedule/schedules'">
    </mg-system-alert>
  </div>

  <!-- errors -->
  <div
    *ngIf="(terms$ | async)?.length === 0"
    class="tw-mb-4">
    <mg-system-alert
      type="error"
      [message]="MESSAGES.MISSING_TERMS"
      [isAlert]="true"
      [dismissable]="true"
      [buttonText]="MESSAGES.MISSING_TERMS_BTN"
      (dismissSnackbar)="navigateToSis()">
    </mg-system-alert>
  </div>

  <div *ngIf="tableDataSource.data?.length > 0; else noResultsTemplate">
    <mat-table
      matSort
      class="list-table"
      [dataSource]="tableDataSource">
      <!-- Short Code -->
      <ng-container [matColumnDef]="TABLE_COLUMN.CODE">
        <mat-header-cell
          *matHeaderCellDef
          class="w-sm-flex">
          <mg-text
            class="column-header"
            variant="label-md-secondary"
            color="grey-300">
            {{ TABLE_COLUMN_LABEL.CODE }}
          </mg-text>
          <mg-tooltip
            class="tooltip"
            [label]="MESSAGES.TABLE_SHORT_CODE_HINT"></mg-tooltip>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let term"
          class="w-sm-flex">
          <mg-text
            variant="body-sm"
            class="clickable-text">
            {{ term.shortCode || '—' }}
          </mg-text>
        </mat-cell>
      </ng-container>

      <!-- Term Name -->
      <ng-container [matColumnDef]="TABLE_COLUMN.TERM">
        <mat-header-cell
          *matHeaderCellDef
          class="w-sm-flex">
          <mg-text
            class="column-header"
            variant="label-md-secondary"
            color="grey-300">
            {{ TABLE_COLUMN_LABEL.TERM }}
          </mg-text>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let term"
          class="w-sm-flex">
          <mg-text
            variant="body-sm"
            [numberOfLines]="2"
            matTooltipClass="mg-tooltip"
            [matTooltip]="term?.title">
            {{ term.title || '—' }}
          </mg-text>
        </mat-cell>
      </ng-container>

      <!-- Start Date -->
      <ng-container [matColumnDef]="TABLE_COLUMN.START">
        <mat-header-cell
          *matHeaderCellDef
          class="">
          <mg-text
            class="column-header"
            variant="label-md-secondary"
            color="grey-300">
            {{ TABLE_COLUMN_LABEL.START }}
          </mg-text>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let term"
          class="">
          <mg-text variant="body-sm">
            {{ term.startDate | date: 'mediumDate' }}
          </mg-text>
        </mat-cell>
      </ng-container>

      <!-- End Date -->
      <ng-container [matColumnDef]="TABLE_COLUMN.END">
        <mat-header-cell
          *matHeaderCellDef
          class="">
          <mg-text
            class="column-header"
            variant="label-md-secondary"
            color="grey-300">
            {{ TABLE_COLUMN_LABEL.END }}
          </mg-text>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let term"
          class="">
          <mg-text
            variant="body-sm"
            [numberOfLines]="2">
            {{ term.endDate | date: 'mediumDate' }}
          </mg-text>
        </mat-cell>
      </ng-container>

      <!-- Bell Schedules -->
      <ng-container [matColumnDef]="TABLE_COLUMN.BELL">
        <mat-header-cell
          *matHeaderCellDef
          class="">
          <mg-text
            class="column-header"
            variant="label-md-secondary"
            color="grey-300">
            {{ TABLE_COLUMN_LABEL.BELL }}
          </mg-text>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let term"
          class="">
          <ng-container
            [ngTemplateOutlet]="bellScheduleTemplate"
            [ngTemplateOutletContext]="{
              $implicit: term.bellSchedules
            }">
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Edit -->
      <ng-container [matColumnDef]="TABLE_COLUMN.EDIT">
        <mat-header-cell
          *matHeaderCellDef
          class="">
        </mat-header-cell>
        <mat-cell
          *matCellDef="let term"
          class="text-center">
          <mg-btn
            icon="mg-edit"
            variant="icon"
            iconSet="minga"
            (pressed)="openEditModal(term.id)">
          </mg-btn>
        </mat-cell>
      </ng-container>

      <!-- Mobile Card -->
      <ng-container [matColumnDef]="TABLE_COLUMN.MOBILE">
        <mat-header-cell
          *matHeaderCellDef
          class=""></mat-header-cell>
        <mat-cell
          *matCellDef="let term"
          class="mobile-layout">
          <ng-container
            [ngTemplateOutlet]="mobileRowTemplate"
            [ngTemplateOutletContext]="{ $implicit: term }">
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Header row and row definitions -->
      <mat-header-row *matHeaderRowDef="displayedColumns$ | async">
      </mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns$ | async"></mat-row>
    </mat-table>
  </div>
</div>

<!-- Loading Indicator Template -->
<ng-template #loadingTemplate>
  <div class="tw-py-4">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>
</ng-template>

<!-- Empty State Template -->
<ng-template #noResultsTemplate>
  <mg-empty-state
    mg-stream-empty
    [title]="MESSAGES.NO_RESULTS_TITLE"
    [subtitle]="MESSAGES.NO_RESULTS_SUBTITLE">
  </mg-empty-state>
</ng-template>

<!-- Bell Schedule -->
<ng-template
  #bellScheduleTemplate
  let-schedules>
  <mg-tag-collection
    *ngIf="schedules.length; else emptyCellTemplate"
    class="bell-schedules tw-flex tw-w-full"
    color="green"
    [tags]="schedules"></mg-tag-collection>
</ng-template>

<!-- Empty Cell Template -->
<ng-template #emptyCellTemplate>
  <mg-text
    class="tw-text-right"
    variant="body-sm"
    [numberOfLines]="1">
    —
  </mg-text>
</ng-template>

<!-- Mobile row template -->
<ng-template
  #mobileRowTemplate
  let-term>
  <mg-mobile-card
    class="tw-grow"
    [title]="term.title">
    <div class="tw-pt-3 tw-pb-1 tw-grid tw-gap-2">
      <div class="tw-grid tw-grid-cols-2">
        <mg-text
          variant="body-sm"
          color="surface-alt"
          class="tw-grow">
          {{ TABLE_COLUMN_LABEL.CODE }}
        </mg-text>
        <mg-text
          variant="body-sm"
          class="tw-justify-self-end">
          {{ term.shortCode || '—' }}
        </mg-text>
      </div>
      <div class="tw-grid tw-grid-cols-2">
        <mg-text
          variant="body-sm"
          color="surface-alt"
          class="tw-grow">
          {{ TABLE_COLUMN_LABEL.START }}
        </mg-text>
        <mg-text
          variant="body-sm"
          class="tw-justify-self-end">
          {{ term.startDate | date: 'mediumDate' }}
        </mg-text>
      </div>
      <div class="tw-grid tw-grid-cols-2">
        <mg-text
          variant="body-sm"
          color="surface-alt"
          class="tw-grow">
          {{ TABLE_COLUMN_LABEL.END }}
        </mg-text>
        <mg-text
          variant="body-sm"
          class="tw-justify-self-end">
          {{ term.endDate | date: 'mediumDate' }}
        </mg-text>
      </div>
      <div class="tw-grid tw-grid-cols-2">
        <mg-text
          variant="body-sm"
          color="surface-alt"
          class="tw-grow">
          {{ TABLE_COLUMN_LABEL.BELL }}
        </mg-text>
        <ng-container
          [ngTemplateOutlet]="bellScheduleTemplate"
          [ngTemplateOutletContext]="{
            $implicit: term.bellSchedules
          }">
        </ng-container>
      </div>
    </div>
    <div class="tw-flex tw-mb-2">
      <div class="tw-grow"></div>
      <mg-btn
        icon="mg-edit"
        variant="icon"
        iconSet="minga"
        (pressed)="openEditModal(term.id)">
      </mg-btn>
    </div>
  </mg-mobile-card>
</ng-template>
