import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

import { StudentIdUploaderDialogComponent } from './student-id-uploader-dialog.component';
import { IStudentIdUploaderDialogData } from './types';

export type StudentIdUploaderDialogRef =
  MatDialogRef<StudentIdUploaderDialogComponent>;
export type StudentIdUploaderDialogConfig =
  MatDialogConfig<IStudentIdUploaderDialogData>;

/**
 * Service to simply open up the event guest list dialog.
 */
@Injectable({ providedIn: 'root' })
export class StudentIdUploaderDialogService {
  constructor(private matDialog: MatDialog) {}

  open(config: StudentIdUploaderDialogConfig): StudentIdUploaderDialogRef {
    return this.matDialog.open(StudentIdUploaderDialogComponent, {
      ...config,
      panelClass: [
        'mg-bordered-dialog',
        'mg-no-padding-dialog',
        'mg-filled-dialog',
        'mg-light-border-dialog',
      ],
      backdropClass: 'mg-white-backdrop',
    });
  }
}
