import { ChartDataset } from 'chart.js';
import { Label } from 'ng2-charts';

import { Person } from 'minga/app/src/app/people';
import { IPbisSummaryResult } from 'minga/domain/pbis';
import { MingaRoleType } from 'minga/domain/permissions';
import { ReportTypes } from 'minga/domain/reportTypes';
import { GroupType } from 'minga/domain/timeManipulation';
import { day } from 'minga/libraries/day';
import {
  PbisCategoriesObj,
  PbisCategory,
} from 'minga/libraries/shared/pbis/constants';

import { ChartData } from '../types';

export interface GetSeperateTypesFromSummary {
  totalCount?: number;
  totalGuidanceCount?: number;
  totalPraiseCount?: number;
  guidance: IPbisSummaryResult[];
  praise: IPbisSummaryResult[];
}

export const getSeperateTypesFromSummary = (
  data: IPbisSummaryResult[],
  includeCounts?: boolean,
): GetSeperateTypesFromSummary => {
  const guidanceTypes = new Map<number, IPbisSummaryResult>();
  const praiseTypes = new Map<number, IPbisSummaryResult>();
  let totalPraise = 0;
  let totalGuidance = 0;
  for (const type of data) {
    if (type.typeCategory === PbisCategory.GUIDANCE) {
      if (includeCounts) {
        totalGuidance += type.total;
      }
      let storedType = guidanceTypes.get(type.typeId);
      if (storedType) {
        storedType.total += type.total;
      } else {
        storedType = { ...type };
      }
      guidanceTypes.set(storedType.typeId, storedType);
    } else {
      if (includeCounts) {
        totalPraise += type.total;
      }
      let storedType = praiseTypes.get(type.typeId);
      if (storedType) {
        storedType.total += type.total;
      } else {
        storedType = { ...type };
      }
      praiseTypes.set(storedType.typeId, storedType);
    }
  }
  if (includeCounts) {
    return {
      totalCount: totalPraise + totalGuidance,
      totalGuidanceCount: totalGuidance,
      totalPraiseCount: totalPraise,
      guidance: Array.from(guidanceTypes.values()),
      praise: Array.from(praiseTypes.values()),
    };
  } else {
    return {
      guidance: Array.from(guidanceTypes.values()),
      praise: Array.from(praiseTypes.values()),
    };
  }
};

/**
 * Generate Graph Data
 *
 * Returns data mapped correctly to be used by the chart.js graph
 */
export const generateGraphData = (
  { types, summaryData, startDate, endDate, groupBy }: ChartData,
  labels: Label[],
): ChartDataset[] => {
  if (!types || !summaryData.length) {
    return [{ data: [], label: '', stack: '1' }];
  }

  const result: ChartDataset[] = [];
  for (const behaviorType of types) {
    const pbisStats = summaryData.filter(d => d.typeId === behaviorType.id);
    const rowData: number[] = [];
    const loopLimit = labels.length;
    const date = day(startDate);

    for (let i = 0; i < loopLimit; i++) {
      switch (groupBy) {
        case GroupType.BY_WEEK:
          rowData[i] = pbisStats
            .filter(stat => {
              const newDate = day(date).add(i, 'week');
              return newDate.week() === stat.week;
            })
            .reduce((acc, stat) => acc + stat.total, 0);
          break;
        case GroupType.BY_DAY: {
          rowData[i] = pbisStats
            .filter(stat => {
              const newDate = day(date).add(i, 'd');
              return day(stat.date).isSame(newDate, 'date');
            })
            .reduce((acc, stat) => acc + stat.total, 0);
          break;
        }

        case GroupType.BY_HOUR:
          rowData[i] = pbisStats
            .filter(stat => stat.hour - 1 === i)
            .reduce((acc, stat) => acc + stat.total, 0);
          break;
        default:
          rowData[i] = pbisStats
            .filter(stat => {
              const newDate = day(date).add(i, 'month');
              return (
                newDate.format('M') === (stat.month + 1).toString() &&
                newDate.format('YYYY') === stat.year.toString()
              );
            })
            .reduce((acc, stat) => acc + stat.total, 0);
      }
    }

    const category = PbisCategoriesObj[behaviorType.categoryId];

    result.push({
      label: behaviorType.name,
      stack: '1',
      data: rowData,
      backgroundColor: category.color,
      hoverBackgroundColor: category.color,
    });
  }
  return result;
};

/**
 * Get Label
 *
 * Generates labels for the Y axis on the bar graph
 */
export const generateLabels = ({
  groupBy,
  startDate,
  endDate,
}: ChartData): Label[] => {
  const results: string[] = [];
  if (!startDate) {
    return [];
  }
  if (!endDate) {
    endDate = startDate.clone();
  }
  if (groupBy === GroupType.BY_WEEK) {
    let start = day(startDate);
    while (endDate > start || start.format('ww') === endDate.format('ww')) {
      const startofWeek = start.clone().startOf('week');
      const endofWeek = start.clone().endOf('week');
      results.push(
        startofWeek.format('MMM D') + ' - ' + endofWeek.format('MMM D'),
      );
      start = start.add(1, 'week');
    }
  } else if (groupBy === GroupType.BY_HOUR) {
    const totalHours = 24;
    for (let i = 1; i <= totalHours; i++) {
      if (i < 12) {
        results.push(`${i} AM`);
      } else if (i > 12 && i < 24) {
        results.push(`${i - 12} PM`);
      } else if (i === 12) {
        results.push(`${i} PM`);
      } else {
        results.push(`${i - 12} AM`);
      }
    }
  } else if (groupBy === GroupType.BY_DAY) {
    if (!startDate) {
      startDate = day().subtract(7, 'd');
    }
    if (!endDate) {
      endDate = day();
    }
    let date = day(startDate);

    results.push(date.format('MMM D'));
    const totalDays = endDate.diff(startDate, 'days');
    for (let i = 1; i <= totalDays; i++) {
      date = date.add(1, 'day');
      results.push(date.format('MMM D'));
    }
  } else {
    let start = day(startDate);
    while (endDate > start || start.format('M') === endDate.format('M')) {
      results.push(start.format('MMMM'));
      start = start.add(1, 'month');
    }
  }
  return results;
};

export const peopleToIssuedFilters = (
  people: Partial<Person>[],
  reportType: ReportTypes,
): {
  issuedTo: Partial<Person>[];
  issuedBy: Partial<Person>[];
} => {
  let issuedTo: Partial<Person>[] = [];
  let issuedBy: Partial<Person>[] = [];

  if (!people?.length) {
    return { issuedTo, issuedBy };
  }

  if (reportType === ReportTypes.PBIS_STUDENT) {
    issuedTo = people;
  } else if (reportType === ReportTypes.PBIS_STAFF) {
    issuedBy = people;
  } else {
    for (const person of people) {
      if (
        person.roleType === MingaRoleType.STUDENT ||
        person.roleType === MingaRoleType.STUDENT_LEADER
      ) {
        issuedTo.push(person);
      } else {
        issuedBy.push(person);
      }
    }
  }

  return { issuedTo, issuedBy };
};
